import { Col, Row, Form, Tabs } from 'antd';
import React, { Component } from 'react'
import { formatAmount } from '../../../utils/order';
import { TEXT_VND } from '../../../constants/common';
import './payment-method.scss';
import { images } from '../../../constants/imagesConstant';

const { TabPane } = Tabs;

export class PaymentMethod extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            initialData: this.props.initialData,
            showShippingFeeDetail: false,
            rentalPrice: this.props.initialData?.rentalPrice,
            total: this.props.initialData?.total
        }
        this.formRef = React.createRef();
    }

    render() {
        let { initialData } = this.state;
        let { paymentMethods, t } = this.props;
        return (
            <Form 
                ref={this.formRef}
                initialValues={initialData}
                className='beecow-payment-method'>
                <div className='title'>
                    <i className='fa fa-credit-card' aria-hidden='true'></i>
                    <p>{t('createOrEditOrderPage.paymentForm.paymentMethod')}</p>
                </div>
                <Row>
                    <Col span={6}>
                    </Col>
                    <Col span={10}>
                        <div className='tab-payment'>
                            <Tabs defaultActiveKey={initialData?.paymentMethodId} onChange={this.onChangeTab.bind(this)}>
                                {
                                    paymentMethods?.map(({ id, name, languageKey }, index) => {
                                        if (name === 'Cash') return <TabPane tab={t(languageKey)} key={id} className='cash'>
                                            {
                                                this.renderPayment()
                                            }
                                        </TabPane>
                                        if (name === 'Bank') return <TabPane tab={t(languageKey)} key={id}>
                                            <div className='bank-payment'>
                                                <p>{t('createOrEditOrderPage.paymentForm.pleaseTransferToThisBankAccount')}:</p>
                                                <div className='info-bank'>
                                                    <p>{t('createOrEditOrderPage.paymentForm.bankName')}: <span>Vietcombank HCM</span></p>
                                                    <p>{t('createOrEditOrderPage.paymentForm.accountNumber')}: <span>0987654312</span></p>
                                                    <p>{t('createOrEditOrderPage.paymentForm.accountName')}: <span>CTY TNHH BEECOW VN</span></p>
                                                </div>
                                                <div className='des-bank'>
                                                    <p>{t('createOrEditOrderPage.paymentForm.afterTheReceiveThisPayment')}</p>
                                                </div>
                                                {
                                                    this.renderPayment()
                                                }
                                            </div>
                                        </TabPane>
                                        return <TabPane tab={t(languageKey)} key={id} className='e-wallet'>
                                            <div className='img-qr-code'>
                                                <img src={images.qrCode} alt='momo-qr-code' />
                                            </div>
                                            {
                                                this.renderPayment()
                                            }
                                        </TabPane>
                                    })
                                }
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Form>
        )
    }

    renderPayment() {
        const { t } = this.props;
        let { initialData, rentalPrice, total } = this.state;
        return (
            <div>
                <p className='beecow-line-item'>
                    <span><b>{t('createOrEditOrderPage.paymentForm.rentalPrice')}: </b></span>
                    <span>{formatAmount(rentalPrice) + TEXT_VND}</span>
                </p>
                <p className='beecow-line-item'>
                    <span><b>{t('createOrEditOrderPage.paymentForm.total')}: </b></span>
                    <span>{formatAmount(total) + TEXT_VND}</span>
                </p>
            </div>
        )
    }

    setFieldsValue(values) {
        this.formRef.current.setFieldsValue(values);
        this.setState({ ...this.state, initialData: { ...this.state.initialData, ...values } });
    }

    validateFields(){
        return this.formRef.current.validateFields();
    }

    getFieldsValue() {
        let fieldsValue = this.formRef.current.getFieldsValue();
        let mergedValues = { ...this.state.initialData, ...fieldsValue};
        return mergedValues;
    }

    componentWillMount() {
        this.calcLeasingFee();
    }

    onChangeTab(paymentMethodId) {
        this.setState({ initialData: { ...this.state.initialData, paymentMethodId } });
    }

    calcLeasingFee() {
        this.setState({ loading: true });
        let { warehouseInfo } = this.props;
        let leasingFeeCalculator = {
            rentalPrice: warehouseInfo?.months * warehouseInfo?.stationArea?.area?.leasingFee,
            total: warehouseInfo?.months * warehouseInfo?.stationArea?.area?.leasingFee
        }

        this.setState({ 
            initialData: { ...this.state.initialData, leasingFeeCalculator, paymentMethodId: 1 },
            rentalPrice: warehouseInfo?.months * warehouseInfo?.stationArea?.area?.leasingFee,
            total: warehouseInfo?.months * warehouseInfo?.stationArea?.area?.leasingFee,
            loading: false
        });
    }
}