import { Col, Row, Form, Tabs, Input, Button, Card } from 'antd';
import React, { Component } from 'react'
import { formatAmount } from '../../../utils/order';
import { StationToWarehouser } from '../../../constants/leasing-contract-constant';
import { TEXT_VND } from '../../../constants/common';
import leasingContractDataService from '../../../data-services/leasing-contract/leasing-contract-data.service';
import './payment-method.scss';
import { images } from '../../../constants/imagesConstant';

const { TabPane } = Tabs;

export class PaymentMethod extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            initialData: this.props.initialData,
            showShippingFeeDetail: false,
        }
        this.formRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        let { initialData } = this.state;
        let { paymentMethods } = this.props;
        return (
            <Form 
                ref={this.formRef}
                initialValues={initialData}
                className='beecow-payment-method'>
                <div className='title'>
                    <i className='fa fa-credit-card' aria-hidden='true'></i>
                    <p>{t('createOrEditOrderPage.paymentForm.paymentMethod')}</p>
                </div>
                <Row>
                    <Col span={6}>
                    </Col>
                    <Col span={10}>
                        <div className='tab-payment'>
                            <Tabs defaultActiveKey={initialData?.paymentMethodId} onChange={this.onChangeTab.bind(this)}>
                                {
                                    paymentMethods?.map(({ id, name, languageKey }, index) => {
                                        if (name === 'Cash') return <TabPane tab={t(languageKey)} key={id} className='cash'>
                                            {
                                                this.renderPayment()
                                            }
                                        </TabPane>
                                        if (name === 'Bank') return <TabPane tab={t(languageKey)} key={id}>
                                            <div className='bank-payment'>
                                                <p>{t('createOrEditOrderPage.paymentForm.pleaseTransferToThisBankAccount')}:</p>
                                                <div className='info-bank'>
                                                    <p>{t('createOrEditOrderPage.paymentForm.bankName')}: <span>Vietcombank HCM</span></p>
                                                    <p>{t('createOrEditOrderPage.paymentForm.accountNumber')}: <span>0987654312</span></p>
                                                    <p>{t('createOrEditOrderPage.paymentForm.accountName')}: <span>CTY TNHH BEECOW VN</span></p>
                                                </div>
                                                <div className='des-bank'>
                                                <p dangerouslySetInnerHTML={{ __html: t('createOrEditOrderPage.paymentForm.afterTheReceiveThisPayment') }}></p>
                                                </div>
                                                {
                                                    this.renderPayment()
                                                }
                                            </div>
                                        </TabPane>
                                        return <TabPane tab={t(languageKey)} key={id} className='e-wallet'>
                                            <div className='img-qr-code'>
                                                <img src={images.qrCode} alt='momo-qr-code' />
                                            </div>
                                            {
                                                this.renderPayment()
                                            }
                                        </TabPane>
                                    })
                                }
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Form>
        )
    }

    renderPayment() {
        const { t } = this.props;
        let { initialData } = this.state;
        let leasingFeeCalculator = initialData?.leasingFeeCalculator;
        return (
            <div>
                <div className='beecow-line-item'>
                    <p><b>{t('createOrEditOrderPage.paymentForm.shippingFee')}: </b></p>
                    <div className='beecow-line-item-fee'>
                        <span>{formatAmount(leasingFeeCalculator?.shippingFee?.totalFee) + TEXT_VND}</span>
                        { !this.isStationSameWarehouse() && !this.state.showShippingFeeDetail && <Button className='beecow-link' type='link' onClick={this.toggleCollapse.bind(this)}><i className='fa fa-caret-down' aria-hidden='true'></i></Button> }
                        { !this.isStationSameWarehouse() && this.state.showShippingFeeDetail && <Button className='beecow-link' type='link' onClick={this.toggleCollapse.bind(this)}><i className='fa fa-caret-up' aria-hidden='true'></i></Button> }
                    </div>
                </div>
                { this.isStationSameWarehouse() && <>
                    <Card>
                        <span>{t('createOrEditOrderPage.deliveryForm.warningChoosingStation')}</span>
                        <span>{t('createOrEditOrderPage.deliveryForm.warningMeansYouWillBringPackage')}</span>
                    </Card>
                </> }
                { !this.isStationSameWarehouse() && this.state.showShippingFeeDetail && <>
                    <p className='beecow-line-item pl-5'>
                        <span><b>{t('createOrEditOrderPage.paymentForm.pickupFromDoorFee')}: </b></span>
                        <span>{formatAmount(leasingFeeCalculator?.shippingFee?.pickupFee) + TEXT_VND}</span>
                    </p>
                    <p className='beecow-line-item pl-5'>
                        <span><b>{t('createOrEditOrderPage.paymentForm.interProvinceFee')}: </b></span>
                        <span>{formatAmount(leasingFeeCalculator?.shippingFee?.interProvinceFee) + TEXT_VND}</span>
                    </p>
                </> }
                <p className='beecow-line-item'>
                    <span><b>{t('createOrEditOrderPage.paymentForm.total')}: </b></span>
                    <span style={{color: 'red'}}>{formatAmount(leasingFeeCalculator?.total) + TEXT_VND}</span>
                </p>
            </div>
        )
    }

    setFieldsValue(values) {
        this.formRef.current.setFieldsValue(values);
        this.setState({ ...this.state, initialData: { ...this.state.initialData, ...values } });
    }

    validateFields(){
        return this.formRef.current.validateFields();
    }

    getFieldsValue() {
        let fieldsValue = this.formRef.current.getFieldsValue();
        let mergedValues = { ...this.state.initialData, ...fieldsValue};
        return mergedValues;
    }

    componentWillMount() {
        this.calcLeasingFee();
    }

    onChangeTab(paymentMethodId) {
        this.setState({ initialData: { ...this.state.initialData, paymentMethodId } });
    }

    toggleCollapse() {
        this.setState({
            showShippingFeeDetail: !this.state.showShippingFeeDetail,
        })
    }

    isStationSameWarehouse() {
        return (this.props.deliveryInfo?.station?.id === this.props.deliveryInfo?.arrivalStationId) ? true : false;
    }

    async calcLeasingFee() {
        try {
            this.setState({ loading: true });
            let { bookingInfo, warehouseInfo, deliveryInfo } = this.props;

            let request = {
                spaceIds: [],
                months: 0,
                shippingFeeDetails: deliveryInfo?.selectedRoute?.shippingFeeDetails,
                deliveryId: deliveryInfo?.deliveryOptionId,
                companyId: deliveryInfo?.selectedRoute?.route?.company?.id,
                pickupCityId: bookingInfo?.sender?.address?.city.id,
            }
            let leasingFeeCalculator = await leasingContractDataService.leasingFeeCalculator(request);
            this.setState({ 
                initialData: { ...this.state.initialData, leasingFeeCalculator },
            });
        } finally {
            this.setState({ loading: false });
        }
    }
}