import { Component } from "react";
import Layout, { Content } from "antd/lib/layout/layout";
import {
  SnippetsOutlined,
  CarOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Space, Card, Steps, Timeline, Input, Row, Col, Button, Image } from "antd";
import TopBar from "../../components/topbar";
import orderDataService from "../../data-services/orders/orders-data.service";
import moment from "moment";
import {
  orderTrackingPhaseType,
  orderTrackingStatusType,
} from "../../constants/order-history.constant";
import { Link } from "react-router-dom";
import { images } from "../../constants/imagesConstant";
import './view-order-history.screen.scss';

const { Step } = Steps;
const { Search } = Input;

export class ViewOrderHistoryScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderCode: "",
      orderInfo: null,
      showLabelNoData: false
    };
  }

  componentDidMount() {
    const { orderCode } = this.props?.match?.params || {};
    if (orderCode) {
      this.setState({ orderCode: (orderCode.toLowerCase().includes('undefined') || orderCode.toLowerCase().includes('null')) ? '' : orderCode?.toUpperCase() });
      this.getOrderTrackingListFromServer(orderCode);
    }
  }

  onSearch = (orderCode) => {
    orderCode = orderCode?.trim();
    this.getOrderTrackingListFromServer(orderCode);
  };

  onChange = (input) => {
    let value = input.target.value?.trim();
    this.setState({ orderCode: value });
  };

  onPressEnter = (e) => {
    let orderCode = this.state.orderCode;
    this.getOrderTrackingListFromServer(orderCode);
  };

  getOrderTrackingListFromServer(orderCode) {
    if (orderCode?.length > 0) {
      orderDataService
        .getOrderTrackingByOrderCode(orderCode)
        .then((response) => this.setState({ orderInfo: response, showLabelNoData: true }));
    }
  }

  renderOrderInfo = (order) => {
    const { t } = this.props;
    return (
      <Card>
        <b>{t("orderTrackingPage.senderInformation")}: </b>
        <br />
        <span>
          &emsp;- {t("orderTrackingPage.senderPhone")}: {order?.senderPhone}
        </span>
        <br />
        <span>
          &emsp;- {t("orderTrackingPage.senderName")}: {order?.senderName}
        </span>
        <br />
        <span>
          &emsp;- {t("orderTrackingPage.senderAddress")}: {order?.senderAddress}
        </span>
        <br />
        <br />

        <b>{t("orderTrackingPage.receiverInformation")}: </b>
        <br />
        <span>
          &emsp;- {t("orderTrackingPage.receiverPhone")}: {order?.receiverPhone}
        </span>
        <br />
        <span>
          &emsp;- {t("orderTrackingPage.receiverName")}: {order?.receiverName}
        </span>
        <br />
        <span>
          &emsp;- {t("orderTrackingPage.receiverAddress")}:{" "}
          {order?.receiverAddress}
        </span>
        <br />
        <br />
      </Card>
    );
  };

  writeStoryAsTimeline(orderTrackingItem) {
    const { t } = this.props;

    let createdDate = moment
      .utc(orderTrackingItem.createdDate)
      .local()
      .format("HH:mm DD-MM-yyyy");

    let json = orderTrackingItem?.jsonData;

    switch (orderTrackingItem.phaseType) {
      case orderTrackingPhaseType.createdOrEditOrder:
        return (
          <Timeline.Item>
            {json.modified ? (
              <span>
                {createdDate} "{json.modifiedByAccountName}"{" "}
                {t("orderTrackingPage.steps.editOrder.modifiedThis")} "
                <Link to={`/detail-order/${json?.orderId}`}>
                  {json?.orderCode}
                </Link>
                ".{" "}
                {t(
                  "orderTrackingPage.steps.editOrder.pleaseOpenViewOrderDetail"
                )}
              </span>
            ) : (
              <span>
                {createdDate}{" "}
                {t("orderTrackingPage.steps.createdOrder.createdOrder")} "
                <Link to={`/detail-order/${json?.orderId}`}>
                  {json?.orderCode}
                </Link>
                " {t("orderTrackingPage.steps.createdOrder.by")} "
                {json.senderName}"
              </span>
            )}
          </Timeline.Item>
        );
      case orderTrackingPhaseType.createOrEditPickupList:
        return (
          <Timeline.Item>
            {
              json?.modified ?
                <span>
                  {createdDate}{" "}
                  "{json.currentStationAdminName}" {t("orderTrackingPage.steps.editPickupList.modifiedThis")}{" "}
                  "
                  <Link to={`/view-pickup-list/${json?.orderPickupId}`}>
                    {json?.orderPickupCode}
                  </Link>
                  ". {t("orderTrackingPage.steps.editPickupList.pleaseOpenViewPickupListForMoreDetail")}
                </span>
                :
                <span>
                  {createdDate}{" "}
                  {t("orderTrackingPage.steps.createdPickupList.createdPickupList")}{" "}
                  "
                  <Link to={`/view-pickup-list/${json?.orderPickupId}`}>
                    {json?.orderPickupCode}
                  </Link>
                  " {t("orderTrackingPage.steps.createdPickupList.by")} "
                  {json.currentStationAdminName}"
                </span>
            }
          </Timeline.Item>
        );

      case orderTrackingPhaseType.orderAssignedToDriver:
        return (
          <Timeline.Item>
            <span>
              {createdDate}{" "}
              {t(
                "orderTrackingPage.steps.orderAssignedToDriver.orderAssignedTo"
              )}{" "}
              "{json?.vehicleCode}" - "{t(json?.vehicleTypeName)}" - "
              {json?.driverName}"
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.driverGoingToPickOrder:
        return (
          <Timeline.Item>
            <span>
              {createdDate} "{json?.driverName}"{" "}
              {t(
                "orderTrackingPage.steps.driverGoingToPickOrder.goingToPickTheOrder"
              )}
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.driverPickedOrder:
        return (
          <Timeline.Item>
            <span>
              {createdDate} "{json?.driverName}"{" "}
              {t("orderTrackingPage.steps.driverPickedOrder.pickedTheOrder")}
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.driverCanNotPickOrderWithReason:
        return (
          <Timeline.Item>
            <span>
              {createdDate} "{json?.driverName}"{" "}
              {t(
                "orderTrackingPage.steps.driverCanNotPickOrderWithReason.canNotPickOrder"
              )}
              : "{json?.reason}"
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.orderFinishedPickingProcessAndWaitingForImport:
        return (
          <Timeline.Item>
            <span>
              {createdDate}{" "}
              {t(
                "orderTrackingPage.steps.orderFinishedPickingProcessAndWaitingForImport.orderFinishedPicking"
              )}{" "}
              "{json?.stationCode} {json?.stationName}"
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.createOrEditOrderImportedToStation:
        return (
          <Timeline.Item>
            {
              json?.modified ?
                <span>
                  {createdDate}{" "}
                  "{json?.stationAdminAccountName}" {t("orderTrackingPage.steps.editImportToStation.modifiedThis")} {" "}
                  "
                  <Link to={`/packages/view/${json?.importListId}`}>
                    {json?.importListCode}
                  </Link>
                  ". {t("orderTrackingPage.steps.editImportToStation.pleaseOpenViewImportListForMoreDetail")}
                </span>
                :
                <span>
                  {createdDate}{" "}
                  {t(
                    "orderTrackingPage.steps.orderImportedToStation.orderImportedTo"
                  )}{" "}
                  "{json?.stationCode} {json?.stationName}"{" "}
                  {t("orderTrackingPage.steps.orderImportedToStation.by")} "
                  {json?.stationAdminAccountName}".{" "}
                  {json?.totalNumberOfProductsBeforeScanning -
                    json?.totalNumberOfProductsAfterScanning >
                    0
                    ? `${t("orderTrackingPage.steps.orderImportedToStation.missProducts")}.`
                    : ""}{" "}
                  {t(
                    "orderTrackingPage.steps.orderImportedToStation.pleaseSeeMore"
                  )}{" "}
                  "
                  <Link to={`/packages/view/${json?.importListId}`}>
                    {json?.importListCode}
                  </Link>
                  ".
                </span>
            }
          </Timeline.Item>
        );

      case orderTrackingPhaseType.createOrEditExportListByStation:
        return (
          <Timeline.Item>
            {
              json?.modified ?
                <span>
                  {createdDate}{" "}
                  "{json?.stationAdminAccountName}" {t("orderTrackingPage.steps.editExportList.modifiedThis")} {" "}
                  "<Link to={`/view-export-list/${json?.orderExportListId}`}>
                    {json?.orderExportListCode}
                  </Link>". {t("orderTrackingPage.steps.editExportList.pleaseOpenViewExportListForMoreDetail")}
                </span>
                :
                <span>
                  {createdDate}{" "}
                  {t(
                    "orderTrackingPage.steps.createdExportListByStation.createdBy"
                  )}{" "}
                  "{json?.stationAdminAccountName}".{" "}
                  {json?.totalNumberOfProductsBeforeScanning -
                    json?.totalNumberOfProductsAfterScanning >
                    0
                    ? `${t("orderTrackingPage.steps.createdExportListByStation.missProducts")}.`
                    : ""}{" "}
                  {t(
                    "orderTrackingPage.steps.createdExportListByStation.pleaseSeeMore"
                  )}{" "}
                  "
                  <Link to={`/view-export-list/${json?.orderExportListId}`}>
                    {json?.orderExportListCode}
                  </Link>
                  " .
                </span>
            }
          </Timeline.Item>
        );

      case orderTrackingPhaseType.driverPickedThisExportListToTransportWithRoute:
        return (
          <Timeline.Item>
            <span>
              {createdDate} "{json?.driverAccountName}"{" "}
              {t(
                "orderTrackingPage.steps.driverPickedThisExportListToTransportWithRoute.pickedExportListToTransport"
              )}{" "}
              "{json?.routeCode} {json?.routeName}"{" "}
              {t(
                "orderTrackingPage.steps.driverPickedThisExportListToTransportWithRoute.andVehicle"
              )}{" "}
              "{json?.vehicleCode} {t(json?.vehicleTypeName)}"
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.orderExportedToVehicle:
        return (
          <Timeline.Item>
            <span>
              {createdDate}{" "}
              {t(
                "orderTrackingPage.steps.orderExportedToVehicle.orderExportedTo"
              )}{" "}
              "{json?.vehicleCode}"
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.orderIsInTransitToStation:
        return (
          <Timeline.Item>
            <span>
              {createdDate}{" "}
              {t(
                "orderTrackingPage.steps.orderIsInTransitToStation.orderIsInTransitToNextStation"
              )}{" "}
              "{json?.stationCode} {json?.stationName}"
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.orderIsInTransitToLastStation:
        return (
          <Timeline.Item>
            <span>
              {createdDate}{" "}
              {t(
                "orderTrackingPage.steps.orderIsInTransitToLastStation.orderIsInTransitToLastStation"
              )}{" "}
              "{json?.stationCode} {json?.stationName}"
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.driverArrivedAndImportingToLastStation:
        return (
          <Timeline.Item>
            <span>
              {createdDate} "{json?.driverAccountName}"{" "}
              {t(
                "orderTrackingPage.steps.driverArrivedAndImportingToLastStation.arrivedAndImportingToLastStation"
              )}{" "}
              "{json?.stationCode} {json?.stationName}"
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.createOrEditImportedToLastStation:
        return (
          <Timeline.Item>
            {
              json?.modified ?
                <span>
                  {createdDate} "{json?.currentStationAccountName}"{" "}
                  {t(
                    "orderTrackingPage.steps.editImportFromVehicle.modifiedThis"
                  )}{" "}
                  "
                  <Link to={`/view-import-from-vehicle/${json?.orderImportId}`}>
                    {json?.orderImportCode}
                  </Link>
                  ". {t(
                    "orderTrackingPage.steps.editImportFromVehicle.pleaseOpenViewImportFromVehicleListForMoreDetail"
                  )}
                </span>
                :
                <span>
                  {createdDate} "{json?.currentStationAccountName}"{" "}
                  {t(
                    "orderTrackingPage.steps.stationImportedToLastStation.importedToLastStation"
                  )}{" "}
                  "{json?.stationCode} {json?.stationName}"
                  {json?.totalNumberOfProductsBeforeScanning -
                    json?.totalNumberOfProductsAfterScanning >
                    0
                    ? `. ${t("orderTrackingPage.steps.stationImportedToLastStation.missProducts")}`
                    : ""}
                  .{" "}
                  {t(
                    "orderTrackingPage.steps.stationImportedToLastStation.pleaseSeeMore"
                  )}{" "}
                  "
                  <Link to={`/view-import-from-vehicle/${json?.orderImportId}`}>
                    {json?.orderImportCode}
                  </Link>
                  "
                </span>
            }
          </Timeline.Item>
        );

      case orderTrackingPhaseType.createOrEditDeliveryListByStation:
        return (
          <Timeline.Item>
            {
              json?.modified ?
                <span>
                  {createdDate}{" "}
                  "{json?.currentStationAccountName}" {t("orderTrackingPage.steps.editDeliveryList.modifiedThis")}
                  {" "}
                  "
                  <Link to={`/view-delivery-list-detail/${json?.orderDeliveryId}`}>
                    {json?.orderDeliveryCode}
                  </Link>
                  ".
                  {" "}
                  {t("orderTrackingPage.steps.editDeliveryList.pleaseOpenViewDeliveryListForMoreDetail")}

                </span>
                :
                <span>
                  {createdDate}{" "}
                  {t(
                    "orderTrackingPage.steps.createdDeliveryListByStation.createdDeliveryListBy"
                  )}{" "}
                  "{json?.currentStationAccountName}"
                </span>
            }
          </Timeline.Item>
        );

      case orderTrackingPhaseType.orderAssignedToDriverToDeliver:
        return (
          <Timeline.Item>
            <span>
              {createdDate}{" "}
              {t(
                "orderTrackingPage.steps.orderAssignedToDriverToDeliver.orderAssignedTo"
              )}{" "}
              "{json?.vehicleCode}" - "{t(json?.vehicleType)}" - "
              {json?.driverAccountName}"
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.driverGoingToDeliverOrder:
        return (
          <Timeline.Item>
            <span>
              {createdDate} "{json?.driverName}"{" "}
              {t(
                "orderTrackingPage.steps.driverGoingToDeliverOrder.goingToDeliverOrder"
              )}
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.driverDeliveredOrderToRecipient:
        return (
          <Timeline.Item>
            <span>
              {createdDate} "{json?.driverName}"{" "}
              {t(
                "orderTrackingPage.steps.driverDeliveredOrderToRecipient.deliveredOrderTo"
              )}{" "}
              "{json?.receiverName}"
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.driverCanNotDeliverOrderWithReason:
        return (
          <Timeline.Item>
            <span>
              {createdDate} "{json?.driverName}"{" "}
              {t(
                "orderTrackingPage.steps.driverCanNotDeliverOrderWithReason.canNotDeliverOrder"
              )}
              : "{json?.reason}"
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.orderFinishedDeliveryProcess:
        return (
          <Timeline.Item>
            <span>
              {createdDate}{" "}
              {t(
                "orderTrackingPage.steps.orderFinishedDeliveryProcess.orderFinishedDeliveryProcess"
              )}
            </span>
          </Timeline.Item>
        );

      case orderTrackingPhaseType.recipientOrderAtStation:
        return (
          <Timeline.Item>
            <span>
              {createdDate} The "{json?.receiverName}"{" "}
              {t(
                "orderTrackingPage.steps.recipientOrderAtStation.receivedOrderAtStation"
              )}{" "}
              "{json?.stationCode} {json?.stationName}"
            </span>
          </Timeline.Item>
        );

      default:
        return <Timeline.Item></Timeline.Item>;
    }
  }

  getOrderTrackingStatusType(type) {
    if (this.state?.orderInfo && this.state.orderInfo?.orderTrackings) {
      let lastOrderTracking = this.state.orderInfo?.orderTrackings[0];
      if (lastOrderTracking) {
        if (type === orderTrackingStatusType.orderBeingProcessed) {
          if (
            lastOrderTracking.phaseType >=
            orderTrackingPhaseType.createdOrEditOrder
          ) {
            return "finish";
          }
        }

        if (type === orderTrackingStatusType.inTransit) {
          if (
            lastOrderTracking.phaseType >
            orderTrackingPhaseType.createOrEditPickupList
          ) {
            return "finish";
          }
        }

        if (type === orderTrackingStatusType.delivered) {
          if (
            lastOrderTracking.phaseType ===
            orderTrackingPhaseType.driverDeliveredOrderToRecipient ||
            lastOrderTracking.phaseType >=
            orderTrackingPhaseType.orderFinishedDeliveryProcess
          ) {
            return "finish";
          }
        }
      }
    }
    return "wait";
  }

  render() {
    const { t } = this.props;
    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar title={t("orderTrackingPage.title")} />
          <Content className="content">
            <Card>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Row gutter={24}>
                  <Col className="gutter-row" span={8}>
                    <Search
                      value={this.state.orderCode}
                      onChange={this.onChange}
                      onPressEnter={this.onPressEnter}
                      onSearch={this.onSearch}
                      placeholder={t("orderTrackingPage.pleaseInputTrackingOrderId")}
                      allowClear
                      enterButton={t("button.search")}
                      size="large"
                    />
                  </Col>
                </Row>
                {this.state.orderInfo?.order && this.state.orderInfo?.orderTrackings && (
                  <>
                    <Card style={{ borderRadius: 20 }}>
                      <Steps>
                        <Step
                          status={this.getOrderTrackingStatusType(
                            orderTrackingStatusType.orderBeingProcessed
                          )}
                          title={t("orderTrackingPage.orderBeingProcessed")}
                          icon={<SnippetsOutlined />}
                        />
                        <Step
                          status={this.getOrderTrackingStatusType(
                            orderTrackingStatusType.inTransit
                          )}
                          title={t("orderTrackingPage.inTransit")}
                          icon={<CarOutlined />}
                        />
                        <Step
                          status={this.getOrderTrackingStatusType(
                            orderTrackingStatusType.delivered
                          )}
                          title={t("orderTrackingPage.delivered")}
                          icon={<FileDoneOutlined />}
                        />
                      </Steps>
                    </Card>

                    <Card style={{ borderRadius: 20 }}>
                      <h4>
                        <b style={{ textDecoration: "underline" }}>
                          {t("orderTrackingPage.orderDetails")}:
                        </b>
                      </h4>
                      <Row gutter={24}>
                        <Col className="gutter-row" span={8}>
                          {this.renderOrderInfo(this.state.orderInfo.order)}
                        </Col>
                        <Col className="gutter-row" span={15} offset={1}>
                          <Timeline>
                            {this.state.orderInfo?.orderTrackings &&
                              this.state.orderInfo?.orderTrackings.map(
                                (value, index) =>
                                  this.writeStoryAsTimeline(value)
                              )}
                          </Timeline>
                        </Col>
                      </Row>
                    </Card>
                  </>
                )}
                {!this.state.orderInfo?.order && !this.state.orderInfo?.orderTrackings && this.state.showLabelNoData && (
                  <>
                    <div className='noRecord'>
                      <Image src={images.noRecordFound} />
                    </div>
                    <div className='noRecord'>{t("text.noDataToDisplay")}</div>
                  </>
                )
                }
              </Space>
            </Card>
          </Content>
        </div>
      </Layout>
    );
  }
}
