import React, { Component } from 'react';
import { Button, message, Steps } from "antd";
import Layout from 'antd/lib/layout/layout';
import TopBar from '../../../components/topbar';
import { BookingInfo } from '../../../components/booking-info/booking-info.component';
import { ProductInfo } from '../../../components/product-info/product-info.component';
import { WarehouseSelection } from '../../../components/warehouse-selection/warehouse-selection.component';
import { LeasingDelivery } from '../../../components/delivery/delivery.component';
import { PaymentMethod } from '../../../components/payment-method/payment-method.component';
import { Summary } from '../../../components/summary/summary.component';
import leasingContractDataService from '../../../data-services/leasing-contract/leasing-contract-data.service';
import contractDataService from '../../../data-services/contracts/contracts-data.service';
import { Utilities } from '../../../common/utilities';
import './edit-leasing-contract.scss';
import moment from 'moment';
import deliveryDataService from '../../../data-services/delivery/delivery-data.service';
import { DeliveryTypeConstants } from '../../../constants/delivery.constants';

const { Step } = Steps;

const screens = {
    bookingInfo: 0,
    productInfo: 1,
    warehouseSelection: 2,
    leasingDelivery: 3,
    paymentMethod: 4,
    summary: 5
}

export class EditLeasingContractScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            skipDeliveryMethod: false,
            title: "title.editContract",
            current: 0,
            initialData: {
                contractId: null,
                contractCode: null,
                bookingInfo: {
                    renter: {
                        id: this.props.userId,
                        fullName: "",
                        phone: "",
                        email: "",
                        userTypeId: "",
                        paymentTypeId: "",
                        address: {
                            street: null,
                            city: {
                                id: null,
                                name: null
                            },
                            district: {
                                id: null,
                                name: null
                            },
                            ward: {
                                id: null,
                                name: null
                            }
                        }
                    },
                    isSender: true,
                    sender: {
                        fullName: "",
                        phone: "",
                        address: {
                            street: null,
                            city: {
                                id: null,
                                name: null
                            },
                            district: {
                                id: null,
                                name: null
                            },
                            ward: {
                                id: null,
                                name: null
                            }
                        }
                    },
                },
                productInfo: {
                    description: "",
                    products: [{
                        key: Utilities.randomUuid(),
                        name: "",
                        packageTypeId: "",
                        othersCategory: "",
                        length: "",
                        width: "",
                        height: "",
                        weight: "",
                        inventory: "",
                        total: "",
                    }],
                },
                warehouseInfo: {
                    leaseDate: "",
                    months: 1,
                    selectedAreaId: "",
                    selectedWarehouseId: "",
                    selectedWarehouse: {},
                },
                deliveryInfo: {
                    specialServiceId: "",
                    deliveryOptionId: "",
                    deliveryLocationId: "",
                    arrivalStationId: "",
                    selectedRouteId: "",
                    selectedRoute: {},
                    isShipping: true,
                },
                paymentInfo: {
                    paymentMethodId: null,
                    leasingFeeCalculator: {},
                }
            },
            warehouseDeliveryType: DeliveryTypeConstants.DELIVERY_WAREHOUSE_ENDUSER,
            personalWarehouseDeliveryType: DeliveryTypeConstants.DELIVERY_PERSONAL_WAREHOUSE,
            productCategories: [],
            leasingAreas: [],
            specialServices: [],
            deliveryOptions: [],
            paymentMethods: [],
        }

        this.bookingFormRef = React.createRef();
        this.productFormRef = React.createRef();
        this.warehouseFormRef = React.createRef();
        this.deliveryFormRef = React.createRef();
        this.paymentFormRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        let { current, initialData, productCategories, leasingAreas, specialServices, deliveryOptions, paymentMethods, skipDeliveryMethod } = this.state;
        let { bookingInfo, productInfo, warehouseInfo, deliveryInfo, paymentInfo } = initialData;
        let steps = [
            {
                title: t('createOrEditNewSpacePage.steps.bookingInformation'),
                content: <BookingInfo
                    t={t}
                    ref={this.bookingFormRef}
                    initialData={bookingInfo}
                />,
            },
            {
                title: t('createOrEditNewSpacePage.steps.productInfo'),
                content: <ProductInfo
                    t={t}
                    ref={this.productFormRef}
                    initialData={productInfo}
                    categories={productCategories}
                />,
            },
            {
                title: t('createOrEditNewSpacePage.steps.selectWarehouse'),
                content: <WarehouseSelection
                    t={t}
                    ref={this.warehouseFormRef}
                    initialData={warehouseInfo}
                    products={productInfo?.products}
                    areas={leasingAreas}
                    onChange={this.onChangeSkipDeliveryMethod}
                    skipDeliveryMethod={skipDeliveryMethod}
                />,
            },
            {
                title: t('createOrEditNewSpacePage.steps.deliveryMethod'),
                content: (
                    <LeasingDelivery 
                        t={t}
                        ref={this.deliveryFormRef} 
                        initialData={deliveryInfo} 
                        specialServices={specialServices} 
                        deliveryOptions={deliveryOptions}
                        bookingInfo={bookingInfo} 
                        products={productInfo?.products} 
                        warehouseInfo={warehouseInfo}
                    />
                ),
            },
            {
                title: t('createOrEditNewSpacePage.steps.payment'),
                content: (
                    <PaymentMethod 
                        t={t}
                        ref={this.paymentFormRef} 
                        initialData={paymentInfo} 
                        paymentMethods={paymentMethods}
                        bookingInfo={bookingInfo}
                        warehouseInfo={warehouseInfo} 
                        deliveryInfo={deliveryInfo}
                    />
                ),
            },
            {
                title: t('createOrEditNewSpacePage.steps.summarize'),
                content: (
                    <Summary 
                        t={t}
                        bookingInfo={bookingInfo} 
                        productInfo={productInfo} 
                        warehouseInfo={warehouseInfo} 
                        deliveryInfo={deliveryInfo} 
                        paymentInfo={paymentInfo}
                        categories={productCategories} 
                        areas={leasingAreas} 
                        specialServices={specialServices} 
                        deliveryOptions={deliveryOptions}
                        paymentMethods={paymentMethods}
                    />
                ),
            },
        ];

        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t(this.state.title)} />
                    <div className="bg-white clearfix beecow-leasing-contract">
                        <Steps
                            type="navigation"
                            current={current}
                            className="site-navigation-steps"
                            >
                            {steps.map(item => (
                                <Step key={item.title} title={item.title} />
                            ))}
                        </Steps>
                        <div className="steps-content">
                            <h2 className='float-right mr-5'>{initialData?.contractCode  }</h2>
                            {steps[current].content}
                        </div>
                        <div className="steps-action">
                            {current > 0 && (
                                <Button type="primary" onClick={this.prev.bind(this)}>{t('button.back')}</Button>
                            )}
                            {current < steps.length - 1 && (
                                <Button type="primary" onClick={this.next.bind(this)}>{t('button.next')}</Button>
                            )}
                            {current === steps.length - 1 && (
                                <Button type="primary" onClick={this.handleFinish.bind(this)}>{t('button.done')}</Button>
                            )}
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    async componentDidMount() {
        const { id } = this.props?.match?.params || {};
        if (!id) return;
        let leasingContractTask = contractDataService.getContractById(id);
        let getInitialDataTask = leasingContractDataService.getInitData();
        let [leasingContract, initialData] = await Promise.all([leasingContractTask, getInitialDataTask]);

        var { data } = leasingContract;
        var leaseDate = moment.utc(data.warehouseInfo.leaseDate).local()
        this.setState({
            ...this.state,
            initialData: {
                ...this.state.initialData,
                contractId: data.contractId,
                contractCode: data.contractCode,
                bookingInfo: data.bookingInfo,
                productInfo: data.productInfo,
                warehouseInfo: {
                    ...data.warehouseInfo,
                    leaseDate: leaseDate,
                    stationTypes: initialData.stationTypeList,
                    contractId: data.contractId
                },
                deliveryInfo: {
                    ...data.deliveryInfo,
                },
                paymentInfo: data.paymentInfo
            },
            productCategories: initialData.packageCategory,
            leasingAreas: initialData.areas,
            specialServices: initialData.specialServices,
            deliveryOptions: initialData.deliveries,
            paymentMethods: initialData.paymentTypes,
        }, () => {
            this.refresh();
        });

    }

    next() {
        const { t } = this.props;
        let currentFormRef;
        switch (this.state.current) {
            case 0:
                currentFormRef = this.bookingFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, bookingInfo: currentFormRef.current.getFieldsValue()} })
                break;
            case 1:
                currentFormRef = this.productFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, productInfo: currentFormRef.current.getFieldsValue()} })
                break;
            case 2:
                currentFormRef = this.warehouseFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, warehouseInfo: currentFormRef.current.getFieldsValue()} }, () => {
                    let selectedWarehouse = this.state.initialData?.warehouseInfo?.selectedWarehouse;
                    this.getDeliveries(selectedWarehouse.isPersonalWarehouse);
                })
                break;
            case 3:
                currentFormRef = this.deliveryFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, deliveryInfo: currentFormRef.current.getFieldsValue()} })
                break;
            case 4:
                currentFormRef = this.paymentFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, paymentInfo: currentFormRef.current.getFieldsValue()} })
                break;
            default:
                break;
        }
        if (currentFormRef && currentFormRef.current) {
            currentFormRef.current.validateFields()
                .then(() => {
                    if(this.state.current === 2 && this.state.initialData.warehouseInfo.selectedWarehouseId === '00000000-0000-0000-0000-000000000000'){
                        message.warn(t('message.invalidData'));
                        return;
                    }
                    let nextStep = this.state.current + 1;
                    if (nextStep === screens.leasingDelivery && this.state.skipDeliveryMethod === true) {
                        nextStep = nextStep + 1;
                        this.setState({ current: nextStep }, () => {
                            this.refresh();
                        });
                    }
                    else if (nextStep <= screens.summary) {
                        this.setState({ current: nextStep }, () => {
                            this.refresh();
                        });
                    }
                })
                .catch(ex => console.log(ex));
        }
    }

    prev() {
        var previousStep = this.state.current - 1;
        if (previousStep === screens.leasingDelivery && this.state.skipDeliveryMethod === true) {
            this.setState({
                current: previousStep - 1
            }, () => {
                this.refresh();
            });
        }
        else {
            this.setState({
                current: previousStep
            }, () => {
                this.refresh();
            });
        }
    }

    refresh() {
        switch(this.state.current) {
            case 0:
                this.bookingFormRef.current.setFieldsValue(this.state.initialData.bookingInfo);
                break;
            case 1:
                this.productFormRef.current.setFieldsValue(this.state.initialData.productInfo);
                break;
            case 2:
                this.warehouseFormRef.current.setFieldsValue(this.state.initialData.warehouseInfo);
                break;
            case 3:
                this.deliveryFormRef.current.setFieldsValue(this.state.initialData.deliveryInfo);
                break;
            case 4:
                this.paymentFormRef.current.setFieldsValue(this.state.initialData.paymentInfo);
                break;
            default:
                break;
        }
    }

    handleFinish() {
        contractDataService.updateContract(this.state.initialData).then((res) => {
            if (res === true) {
                this.props.history.push(`/leasing-contract`);
            }
        });
    }

    onChangeSkipDeliveryMethod = (value) => {
        this.setState({ skipDeliveryMethod: value });
    }

    getDeliveries = (isPersonalWarehouse) => {
        if (isPersonalWarehouse) {
            deliveryDataService.getDeliveryByDeliveryType(this.state.personalWarehouseDeliveryType).then(res => {
                if (res.deliveries.length > 0) {
                    this.setState({ deliveryOptions: res.deliveries });
                }
            })
        }
        else {
            deliveryDataService.getDeliveryByDeliveryType(this.state.warehouseDeliveryType).then(res => {
                if (res.deliveries.length > 0) {
                    this.setState({ deliveryOptions: res.deliveries });
                }
            })
        }
    }
}