import React, { Component } from 'react';
import { Button, message, Steps } from 'antd';
import Layout from 'antd/lib/layout/layout';
import TopBar from '../../../components/topbar';
import { BookingInfo } from '../../../components/booking-info/booking-info.component';
import { WarehouseSelection } from '../../../components/steps-contract-rent-area/warehouse-selection-component/warehouse-selection.component';
import { PaymentMethod } from '../../../components/steps-contract-rent-area/payment-method/payment-method.component';
import { Summary } from '../../../components/steps-contract-rent-area/summary/summary.component';
import userDataService from '../../../data-services/user/user-data.service';
import leasingContractDataService from '../../../data-services/leasing-contract/leasing-contract-data.service';
import contractDataService from '../../../data-services/contracts/contracts-data.service';
import './rent-new-area.scss';

const { Step } = Steps;

export class RentNewAreaScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            initialData: {
                bookingInfo: {
                    renter: {
                        id: this.props.userId,
                        fullName: '',
                        phone: '',
                        email: '',
                        userTypeId: '',
                        paymentTypeId: '',
                        address: {
                            street: null,
                            city: {
                                id: null,
                                name: null
                            },
                            district: {
                                id: null,
                                name: null
                            },
                            ward: {
                                id: null,
                                name: null
                            }
                        }
                    }
                },
                warehouseInfo: {
                    startTime: '',
                    length: 1,
                    width: 1,
                    height: 1,
                    selectedStationAreaId: '',
                    months: 1,
                    leasingFee: 0,
                    selectedAreaId: '',
                    stationArea: {},
                    isPersonalWarehouse: false
                },
                paymentInfo: {
                    paymentMethodId: '',
                }
            },
            leasingAreas: [],
            specialServices: [],
            paymentMethods: [],
        }
        this.bookingFormRef = React.createRef();
        this.warehouseFormRef = React.createRef();
        this.paymentFormRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        let { current, initialData, leasingAreas, specialServices, paymentMethods } = this.state;
        let { bookingInfo, warehouseInfo, paymentInfo } = initialData;
        let steps = [
            {
                title: t('createOrEditNewSpacePage.steps.bookingInformation'),
                content: <BookingInfo t={t} ref={this.bookingFormRef} initialData={bookingInfo} isRentArea={true} />,
            },
            {
                title: t('createOrEditNewSpacePage.steps.selectWarehouse'),
                content: <WarehouseSelection t={t} ref={this.warehouseFormRef} initialData={warehouseInfo} areas={leasingAreas} />,
            },
            {
                title: t('createOrEditNewSpacePage.steps.payment'),
                content: (
                    <PaymentMethod t={t} ref={this.paymentFormRef} 
                        initialData={paymentInfo} 
                        paymentMethods={paymentMethods}
                        bookingInfo={bookingInfo}
                        warehouseInfo={warehouseInfo} />
                ),
            },
            {
                title: t('createOrEditNewSpacePage.steps.summarize'),
                content: (
                    <Summary t={t}
                        bookingInfo={bookingInfo} 
                        warehouseInfo={warehouseInfo} 
                        paymentInfo={paymentInfo}
                        areas={leasingAreas} 
                        specialServices={specialServices} 
                        paymentMethods={paymentMethods} 
                        isRentArea={true}/>
                ),
            },
        ];
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t('rentNewArea.title')} />
                    <div className='bg-white clearfix beecow-leasing-contract'>
                        <Steps
                            type='navigation'
                            current={current}
                            className='site-navigation-steps'
                            >
                            {steps.map(item => (
                                <Step key={item.title} title={item.title} />
                            ))}
                        </Steps>
                        <div className='steps-content'>{ steps[current].content }</div>
                        <div className='steps-action'>
                            {current > 0 && (
                                <Button type='primary' onClick={this.prev.bind(this)}>{t('button.back')}</Button>
                            )}
                            {current < steps.length - 1 && (
                                <Button type='primary' onClick={this.next.bind(this)}>{t('button.next')}</Button>
                            )}
                            {current === steps.length - 1 && (
                                <Button type='primary' onClick={this.handleFinish.bind(this)}>{t('button.done')}</Button>
                            )}
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    async componentWillMount() {
        let getUserProfileTask = userDataService.getUserProfileById(this.props.userId);
        let getInitialDataTask = leasingContractDataService.getInitData();
        let [userProfile, initialData] = await Promise.all([getUserProfileTask, getInitialDataTask]);

        let renterInfo = { ...this.state.initialData.bookingInfo.renter, ...userProfile };
        renterInfo['address']['city']['id'] = userProfile.address?.city?.id;
        renterInfo['address']['city']['name'] = userProfile.address?.city?.name;
        renterInfo['address']['district']['id'] = userProfile.address?.district?.id;
        renterInfo['address']['district']['name'] = userProfile.address?.district?.name;
        renterInfo['address']['ward']['id'] = userProfile.address?.ward?.id;
        renterInfo['address']['ward']['name'] = userProfile.address?.ward?.name;
        
        this.setState({ 
            ...this.state, 
            initialData: { ...this.state.initialData, bookingInfo: { ...this.state.initialData.bookingInfo, renter: renterInfo }, 
                            warehouseInfo: { ...this.state.initialData.warehouseInfo, stationTypes: initialData.stationTypeList }, 
                            paymentInfo: { ...this.state.initialData.paymentInfo, paymentMethodId: initialData.paymentTypes[0].id } },
            leasingAreas: initialData.areas,
            specialServices: initialData.specialServices,
            paymentMethods: initialData.paymentTypes,
        }, () => {
            this.refresh();
        });
    }

    next() {
        const { t } = this.props;
        let currentFormRef;
        switch (this.state.current) {
            case 0:
                currentFormRef = this.bookingFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, bookingInfo: currentFormRef.current.getFieldsValue()} })
                break;
            case 1:
                currentFormRef = this.warehouseFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, warehouseInfo: currentFormRef.current.getFieldsValue()} })
                break;
            case 2:
                currentFormRef = this.paymentFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, paymentInfo: currentFormRef.current.getFieldsValue()} })
                break;
                default:
                break;
        }
        if (currentFormRef && currentFormRef.current) {
            currentFormRef.current.validateFields()
                .then(() => {
                    if(!this.state.initialData.warehouseInfo?.selectedStationAreaId && this.state.current === 1){
                        message.warning(t('message.pleaseSelectArea'));
                    } else{
                        let nextStep = this.state.current + 1;
                        if (nextStep < 6) {
                            this.setState({ current: nextStep });
                            this.refresh();
                        }
                    }
                })
                .catch(ex => console.log(ex));
        }
    }

    prev() {
        this.setState({
            current: this.state.current - 1
        }, () => {
            this.refresh();
        });
    }

    refresh() {
        switch(this.state.current) {
            case 0:
                this.bookingFormRef.current.setFieldsValue(this.state.initialData.bookingInfo);
                break;
            case 1:
                this.warehouseFormRef.current.setFieldsValue(this.state.initialData.warehouseInfo);
                break;
            case 2:
                this.paymentFormRef.current.setFieldsValue(this.state.initialData.paymentInfo);
                break;
            default:
                break;
        }
    }

    handleFinish() {
        const { t } = this.props;
        const { initialData } = this.state;
        let areaSize = {
            length: initialData.warehouseInfo.length,
            width: initialData.warehouseInfo.width,
            height: initialData.warehouseInfo.height
        };
        let warehouseInfo = {
            areaSize: areaSize,
            leaseDate: initialData.warehouseInfo.startTime,
            months: initialData.warehouseInfo.months,
            stationId: initialData.warehouseInfo.stationArea.id,
            areaStationId: initialData.warehouseInfo.selectedStationAreaId,
            priceLeasing: initialData.warehouseInfo.stationArea.area.leasingFee,
            isPersonalWarehouse: initialData.warehouseInfo.isPersonalWarehouse
        };
        let request = {
            bookingInfo: initialData.bookingInfo,
            warehouseInfo: warehouseInfo,
            paymentInfo: initialData.paymentInfo
        };
        contractDataService.createContractForRentArea(request).then((res) => {
            if(res) {
                message.success(t('createOrEditNewSpacePage.youHaveSuccessfullyRentedNewSpace'));
                this.props.history.push(`/leasing-contract`);
            }
        });
    }
}