import { Image, Modal, Card, Col, Row, Button, Table, notification, message } from "antd";
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Layout, { Content } from 'antd/lib/layout/layout';
import { EStatusProductLeasing, EStatusContractLeasing } from '../../constants/constants';
import TopBar from "../../components/topbar";
import contractDataService from '../../data-services/contracts/contracts-data.service';
import './contract-detail.scss';
import { ImageConstants } from '../../constants/system.constants';
import languageService from "../../services/language.service";
import moment from "moment";
import { images } from "../../constants/imagesConstant";

export class ContractDetailPageClient extends Component {
  constructor(props) {
    super(props);
    let { id } = this.props.match?.params;
    const path = this.props?.match?.path;
    const isFromPersonalWarehouse = path.includes('personal-warehouse-contract');
    const { t } = props;
    this.state = {
      contractInfo:
      {
        id: id,
        code: '',
        areaStationId: '',
        createdTime: '',
        expiryDate: '',
        name: '',
        address: '',
        productOfContracts: [],
        phoneNumber: '',
        price: '',
        spaceAreaId: '',
        status: {},
        isShipToWarehouse: true,
      },
      ModalConfirmChangeStatus:
      {
        visible: false,
        modalText: t('contractDetailPage.areYouSureWantToRenewThisContract') ?? '',
        confirmLoading: false
      },
      AddMoreShow: false,
      PlaceOrder: false,
      areaInfo: [],
      showVerifyButton: false,
      verifyContractModal: {
        visible: false,
        confirmLoading: false
      },
      isFromPersonalWarehouse,
      ModalConfirmCleanUpWarehouse: {
        visible: false,
        confirmLoading: false
      }
    }
  }

  async componentWillMount() {
    this.props.setContractRenewId(this.state.contractInfo.id)
    await this.initDataTable();
  }

  getColumns() {
    const { t } = this.props;
    let columns = [
      {
        title: t('contractDetailPage.columnName.no'),
        dataIndex: 'key',
        sorter: (a, b) => a.key - b.key,
        width: '14%',
      },
      {
        title: t('contractDetailPage.columnName.productId'),
        dataIndex: 'code',
        sorter: (a, b) => a.code - b.code,
        width: '14%',
        render: (_, { code, id }) => <p className="itemOrtherInTable product-code text-primary" onClick={() => this.openProductDetail(id)}><a>{code}</a></p>
      },
      {
        title: t('contractDetailPage.columnName.productName'),
        dataIndex: 'itemName',
        sorter: (a, b) => a.itemName - b.itemName,
        width: '14%',
        render: text => <p className="itemOrtherInTable" >{text}</p>
      },
      {
        title: t('contractDetailPage.columnName.category'),
        dataIndex: 'category',
        render: (category) => {
          return <>{t(category)}</>
        },
        sorter: (a, b) => a.category - b.category,
        width: '14%',
      },
      {
        title: t('contractDetailPage.columnName.initial'),
        dataIndex: 'total',
        sorter: (a, b) => a.currentInventory - b.currentInventory,
        width: '15%',
        render: text => <p className="itemOrtherInTable" >{text}</p>
      },
      {
        title: t('contractDetailPage.columnName.currentInventory'),
        dataIndex: 'inventory',
        sorter: (a, b) => a.inventory - b.inventory,
        width: '15%',
        render: text => <p className="itemOrtherInTable" >{text}</p>
      },
      {
        title: t('contractDetailPage.columnName.inWarehouse'),
        dataIndex: 'inWarehouse',
        sorter: (a, b) => a.currentInventory - b.currentInventory,
        width: '15%',
        render: text => <p className="itemOrtherInTable" >{text}</p>
      }

    ];
    return columns;
  }

  getColorContract(contractInfo) {
    switch (contractInfo?.status?.statusName) {
      case EStatusContractLeasing.WaitingForVerify.statusName:
        contractInfo.status.color = EStatusContractLeasing.WaitingForVerify.color
        break;

      case EStatusContractLeasing.WaitingForImport.statusName:
        contractInfo.status.color = EStatusContractLeasing.WaitingForImport.color
        break;

      case EStatusContractLeasing.Renewing.statusName:
        contractInfo.status.color = EStatusContractLeasing.Renewing.color
        break;

      case EStatusContractLeasing.Expired.statusName:
        contractInfo.status.color = EStatusContractLeasing.Expired.color
        break;

      case EStatusContractLeasing.Active.statusName:
        contractInfo.status.color = EStatusContractLeasing.Active.color
        break;

      default:
        break;
    }
  }

  getColorProductOfContract(product, contract) {
    if (!product) return;
    switch (contract?.status?.statusName) {

      case EStatusContractLeasing.WaitingForVerify.statusName:
        product.status = EStatusContractLeasing.WaitingForVerify
        break;
      case EStatusContractLeasing.WaitingForImport.statusName:
        product.status = EStatusContractLeasing.WaitingForImport
        break;
      case EStatusContractLeasing.Renewing.statusName:
        product.status = EStatusContractLeasing.Renewing

        break;
      case EStatusContractLeasing.Expired.statusName:
        product.status = EStatusContractLeasing.Expired

        break;
      case EStatusContractLeasing.Active.statusName:
        if (!product.status) break;
        switch (product?.status?.statusName) {
          case EStatusProductLeasing.WaitingForImport.statusName:
            product.status.color = EStatusProductLeasing.WaitingForImport.color
            break;
          case EStatusProductLeasing.OutOfStock.statusName:
            product.status.color = EStatusProductLeasing.OutOfStock.color
            break;
          case EStatusProductLeasing.ReadyToSell.statusName:
            product.status.color = EStatusProductLeasing.ReadyToSell.color
            break;
          default:
            break;
        }
        break;
      default:
        break;

    }
  }

  async initDataTable() {
    const { id } = this.props.match?.params;
    const { currentUserId, history, justLoggedIn } = this.props;
    let res = await contractDataService.getContractDetailById(id);
    
    if (res?.data?.renterUserId !== currentUserId) {
      if (justLoggedIn) {
        await this.props.resetSession();
        history.push('/page-not-found');
      } else {
        await this.props.resetSession();
        history.push({
          pathname: '/login',
          state: { returnUrl: `/contract/${id}` }
        });
      }
    } else {
      this.getColorContract(res.data);
      let totalInventory = 0;
      let total = 0;
      res.data?.productOfContracts.map((item, index) => {
        item.key = index + 1;
        this.getColorProductOfContract(item, res.data)
        if (item?.status?.statusName === EStatusProductLeasing.ReadyToSell.statusName) {
          totalInventory += item.inventory;
        }
        total += item.inventory;

      });
      let AddMoreShow = true;
      let PlaceOrder = true;
      if (total < res.data?.productOfContracts[0]?.total
        && (res.data?.status?.statusName === EStatusContractLeasing.Active.statusName
          || res.data?.status?.statusName === EStatusContractLeasing.WaitingForImport.statusName)
      ) {
        AddMoreShow = false;
      }
      if (totalInventory > 0
        && res.data?.status?.statusName === EStatusContractLeasing.Active.statusName
        && res.data?.productOfContracts.find(x => x?.status?.statusName === EStatusProductLeasing.ReadyToSell.statusName)) {
        PlaceOrder = false
      }
      this.setState({
        contractInfo: res?.data,
        AddMoreShow: AddMoreShow,
        PlaceOrder: PlaceOrder,
        areaInfo: res?.data?.areas,
        showVerifyButton: res?.data.showVerifyButton
      })
    }
  };

  showModal = () => {
    this.setState({
      ModalConfirmChangeStatus:
      {
        ...this.state.ModalConfirmChangeStatus,
        visible: true,
      }
    })
  };

  handleOk = () => {
    const { t } = this.props;
    this.setState({
      ModalConfirmChangeStatus:
      {
        ...this.state.ModalConfirmChangeStatus,
        confirmLoading: true
      }
    });

    contractDataService.updateStatusContract(this.state.contractInfo.id).then((res) => {

      notification.success({
        message: t('contractDetailPage.contractHasBeenUpdatedSuccessfully'),
      });
      this.setState({
        ModalConfirmChangeStatus:
        {
          ...this.state.ModalConfirmChangeStatus,
          confirmLoading: false,
          visible: false
        }
      })
      this.initDataTable();
    })
  };

  handleCancel = () => {
    this.setState({
      ModalConfirmChangeStatus:
      {
        ...this.state.ModalConfirmChangeStatus,
        visible: false
      }
    })
  };

  showAndHideVerifyContractModal = () => {
    this.setState({
      verifyContractModal: {
        visible: !this.state.verifyContractModal.visible
      }
    });
  }

  handleVerifyContract = () => {

    this.setState({
      verifyContractModal: {
        visible: !this.state.verifyContractModal.visible,
        confirmLoading: true
      }
    });

    contractDataService
      .verifyContract(this.state.contractInfo?.id)
      .then((res) => {
        if (res.success) {
          notification.success({
            message: res.message,
          });
        } else {
          notification.error({
            message: res.errorMessage,
          });
        }

        this.setState({
          verifyContractModal: {
            visible: false,
            confirmLoading: false
          },
          showVerifyButton: false
        });
        this.initDataTable();
      });
  }

  redirectPlaceOrderPage = () => {
    this.props.history.push(`/contract/${this.state.contractInfo?.id}/place-order`)
  }

  redirectAddMorePage = () => {
    const { contractInfo } = this.state;
    if (contractInfo.contractTypeId == 0)
      this.props.history.push(`/contract-rent-area/${this.state.contractInfo?.id}/add-products`)
    else
      this.props.history.push(`/contract/${this.state.contractInfo?.id}/add-products`)
  }

  openProductDetail(id) {
    this.props.history.push(`/products-detail/${id}`)
  }

  redirectToShippingProduct(contractId) {
    if (contractId) {
      this.props.history.push(`/contract/${contractId}/ship-to-warehouse`);
    }
  }

  getTotalLeasingSpaces(areas) {
    let res = 0

    areas.forEach(x => {
      res += x.spaceAreas.length
    })
    return res
  }

  columns
  render() {
    const { contractInfo, ModalConfirmChangeStatus, areaInfo, showVerifyButton, verifyContractModal, isFromPersonalWarehouse } = this.state;
    const total = contractInfo?.productOfContracts?.map(x => x.total).reduce((a, b) => a + b, 0)
    const totalProduct = contractInfo?.productOfContracts?.length
    const totalLeasingSpaces = this.getTotalLeasingSpaces(contractInfo?.areas || []);
    const { t } = this.props;
    const urlCurrent = this.props.location.pathname;
    let columns = this.getColumns();

    const showButton = contractInfo?.status?.statusName !== EStatusContractLeasing?.WaitingForVerify?.statusName && contractInfo?.status?.statusName !== EStatusContractLeasing?.Expired?.statusName
    const showShipToWarehouse = contractInfo?.isShipToWarehouse
    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar title={t("contractDetailPage.title")} />
          <Content className="content">
            <Card bordered={false} className="contract-detail">
              <Row>
                <Col span="6" offset={13}>
                  {!isFromPersonalWarehouse ? (
                    <p className="pull-right">
                      <b>{contractInfo?.code}</b>
                    </p>
                  ) : (
                    ""
                  )}
                </Col>
                <Col span="5" className="textStatusContract">
                  <p style={{ color: `${contractInfo?.status?.color}` }}>
                    {t(
                      contractInfo?.status?.languageKey ||
                      contractInfo?.status?.statusName
                    )}
                  </p>
                  {(contractInfo?.status?.statusName === EStatusContractLeasing?.Active?.statusName ||
                    contractInfo?.status?.statusName === EStatusContractLeasing?.Expired?.statusName)
                    && urlCurrent.search('personal-warehouse-contract') < 0 && (
                      <Link
                        to={`${contractInfo?.id}/extend`}
                        className="btn beecow-btn"
                      >
                        {t('statuses.extend')}
                      </Link>
                    )}
                  {showVerifyButton && isFromPersonalWarehouse && contractInfo.isOwner && (
                    <Button
                      onClick={this.showAndHideVerifyContractModal}
                      type="primary"
                      style={{
                        cursor: "pointer",
                        border: `#52c41a`,
                        background: `#52c41a`,
                      }}
                    >
                      {t("button.verify")}
                    </Button>
                  )}
                  {
                    contractInfo?.status?.statusName === EStatusContractLeasing?.Expired?.statusName
                    && isFromPersonalWarehouse && contractInfo.isOwner && (
                      <Button
                        onClick={this.cleanUpWareHouse}
                        type="primary"
                        className="ant-btn ant-btn-primary"
                      >
                        {t("button.cleanUpWarehouse")}
                      </Button>
                    )
                  }

                  <Modal
                    visible={ModalConfirmChangeStatus.visible}
                    onOk={this.handleOk}
                    confirmLoading={ModalConfirmChangeStatus.confirmLoading}
                    onCancel={this.handleCancel}
                  >
                    <p>{ModalConfirmChangeStatus.modalText}</p>
                  </Modal>

                  <Modal
                    visible={verifyContractModal.visible}
                    onOk={this.handleVerifyContract}
                    confirmLoading={verifyContractModal.confirmLoading}
                    onCancel={this.showAndHideVerifyContractModal}
                  >
                    <p>{t("contractDetailPage.areYouSureWantToVerify")}</p>
                  </Modal>

                  <Modal
                    visible={this.state.ModalConfirmCleanUpWarehouse.visible}
                    onOk={this.cleanUpWarehouseOk}
                    confirmLoading={
                      this.state.ModalConfirmCleanUpWarehouse.confirmLoading
                    }
                    onCancel={this.cleanUpWarehouseCancel}
                  >
                    <p>{t("message.cleanUpWarehouse")}</p>
                  </Modal>
                </Col>
              </Row>
              <Row>
                <Col span="12">
                  <h3 className="headStyle">
                    {t("contractDetailPage.leasingInformation")}
                  </h3>
                  <Row>
                    <Col span={10} className="infor-contract">
                      <ul>
                        <li>
                          <p>
                            {t("contractDetailPage.totalItemsProduct")}:{" "}
                          </p>
                        </li>
                        <li>
                          <p>{t("contractDetailPage.leasingSpaces")}: </p>
                        </li>
                        <li>
                          <p>{t("contractDetailPage.rentalPrice")}: </p>
                        </li>
                        <li>
                          <p>
                            {t("contractDetailPage.contractCreateDate")}:
                          </p>
                        </li>
                        <li>
                          <p>{t("contractDetailPage.expiredDate")}:</p>
                        </li>
                      </ul>
                    </Col>
                    <Col span={14} className="infor-contract content-data">
                      <ul>
                        <li>
                          <p>
                            {total} {t("contractDetailPage.items")} /
                            {totalProduct}{" "}
                            {t("contractDetailPage.products")}{" "}
                          </p>
                        </li>
                        <li>
                          <p>{totalLeasingSpaces} </p>
                        </li>
                        <li>
                          <p>{contractInfo?.price}</p>
                        </li>
                        <li>
                          <p>
                            {moment
                              .utc(contractInfo?.createdTime)
                              .locale(languageService.getLang())
                              .format("DD/MM/YYYY")}
                          </p>
                        </li>
                        <li>
                          <p>
                            {moment
                              .utc(contractInfo?.expiryDate)
                              .locale(languageService.getLang())
                              .format("DD/MM/YYYY")}
                          </p>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
                {contractInfo.isOwner && isFromPersonalWarehouse ? (
                  <Col span={12}>
                    <h3 className="headStyle">
                      {t("warehouse.contract.renterInformation")}
                    </h3>
                    <Row>
                      <Col span={10} className="infor-contract">
                        <ul>
                          <li>
                            <p>{t("warehouse.contract.id")}:</p>
                          </li>
                          <li>
                            <p>{t("warehouse.contract.renterName")}:</p>
                          </li>
                          <li>
                            <p>{t("warehouse.contract.renterPhone")}:</p>
                          </li>
                          <li style={{ height: 57 }}>
                            <p>{t("warehouse.contract.renterAddress")}:</p>
                          </li>
                          {contractInfo?.contractTypeId === 0 && (
                            <>
                              <li>
                                <p>{t("warehouse.contract.areaId")}:</p>
                              </li>
                              <li>
                                <p>{t("warehouse.contract.size")}:</p>
                              </li>
                            </>
                          )}
                        </ul>
                      </Col>
                      <Col
                        span={12}
                        className="infor-contract content-data"
                      >
                        <ul>
                          <li>
                            <p>{contractInfo?.code}</p>
                          </li>
                          <li>
                            <p>{contractInfo?.name}</p>
                          </li>
                          <li>
                            <p>{contractInfo?.phoneNumber}</p>
                          </li>
                          <li>
                            <p>{contractInfo?.address}</p>
                          </li>
                          {contractInfo?.contractTypeId === 0 && (
                            <>
                              <li>
                                <p>{contractInfo?.areas[0].code}</p>
                              </li>
                              <li>
                                <p>
                                  {contractInfo?.lengthWarehouse} x{" "}
                                  {contractInfo?.widthWarehouse} x{" "}
                                  {contractInfo?.heightWarehouse}
                                </p>
                              </li>
                            </>
                          )}
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span="12">
                    <h3 className="headStyle">
                      {t("contractDetailPage.warehouseInformation")}
                    </h3>
                    <Row>
                      <Col span={10} className="infor-contract">
                        <ul>
                          <li>
                            <p>{t("contractDetailPage.warehouseId")}: </p>
                          </li>
                          <li>
                            <p>{t("contractDetailPage.warehouseName")}: </p>
                          </li>
                          <li style={{ height: 55 }}>
                            <p>
                              {t("contractDetailPage.warehouseAddress")}:
                            </p>
                          </li>
                          <li style={{ height: 50 }}>
                            <p>{t("contractDetailPage.provider")}:</p>
                          </li>
                          {contractInfo?.contractTypeId == 0 && (
                            <>
                              <li>
                                <p>{t("contractDetailPage.areaId")}:</p>
                              </li>
                              <li>
                                <p>{t("tableColumn.size")} (m):</p>
                              </li>
                            </>
                          )}
                        </ul>
                      </Col>
                      <Col
                        span={14}
                        className="infor-contract content-data"
                      >
                        <ul>
                          <li>
                            <p>
                              {contractInfo?.wareHouseInformation?.code}
                            </p>
                          </li>
                          <li>
                            <p>
                              {contractInfo?.wareHouseInformation?.name}
                            </p>
                          </li>
                          <li>
                            <p>
                              {contractInfo?.wareHouseInformation?.address}
                            </p>
                          </li>
                          <li style={{ height: 70 }}>
                            <p>
                              <Image
                                fallback={ImageConstants.NO_IMAGE}
                                style={{ height: 45, width: 50 }}
                                src={contractInfo?.company?.logoPath ?? images.warehouseBuilding}
                              />
                            </p>
                          </li>
                          {contractInfo?.contractTypeId == 0 && (
                            <>
                              <li>
                                <p>{areaInfo[0]?.code}</p>
                              </li>
                              <li>
                                <p>
                                  {contractInfo?.lengthWarehouse} x{" "}
                                  {contractInfo?.widthWarehouse} x{" "}
                                  {contractInfo?.heightWarehouse}
                                </p>
                              </li>
                            </>
                          )}
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
              <br />
              <br />
              <Row className="headStyle">
                <Col span="24">
                  {showButton && !isFromPersonalWarehouse && contractInfo?.status?.statusName !== EStatusContractLeasing?.Cleared?.statusName && (
                    <>
                      <Button
                        onClick={this.redirectPlaceOrderPage}
                        className="pull-right"
                        type="primary"
                      >
                        {t("button.placeOrderToSell")}
                      </Button>
                      <Button
                        onClick={this.redirectAddMorePage}
                        className="mr-2 pull-right"
                        type="primary"
                      >
                        {t("button.addMore")}
                      </Button>
                      {showShipToWarehouse && (
                        <Button
                          onClick={() =>
                            this.redirectToShippingProduct(
                              contractInfo?.id
                            )
                          }
                          className="mr-2 pull-right"
                          type="primary"
                        >
                          {t("button.shipToWarehouse")}
                        </Button>
                      )}
                    </>
                  )}
                </Col>
              </Row>
              <br />
              <Table
                dataSource={contractInfo?.productOfContracts}
                columns={columns}
              />
            </Card>
          </Content>
        </div>
      </Layout>
    );

  }

  cleanUpWareHouse = () => {
    this.setState({
      ModalConfirmCleanUpWarehouse: {
        ...this.state.ModalConfirmCleanUpWarehouse,
        visible: true,
      },
    });
  };

  cleanUpWarehouseOk = () => {
    const { t } = this.props;
    this.setState({
      ModalConfirmCleanUpWarehouse: {
        ...this.state.ModalConfirmCleanUpWarehouse,
        confirmLoading: true,
      },
    });

    contractDataService
      .cleanUpWarehouseByContractId(this.state.contractInfo?.id)
      .then((rs) => {

        this.setState({
          ModalConfirmCleanUpWarehouse: {
            ...this.state.ModalConfirmCleanUpWarehouse,
            confirmLoading: false,
            visible: false,
          },
        });
        if (rs.success) {
          message.success(t('message.cleanUpWarhouseSuccess'));
          this.initDataTable();
        }
        else {
          message.success(t('message.cleanUpWarhouseUnSuccess'));
        }
      });

  };

  cleanUpWarehouseCancel = () => {
    this.setState({
      ModalConfirmCleanUpWarehouse: {
        ...this.state.ModalConfirmCleanUpWarehouse,
        visible: false,
      },
    });
  };
}

export default ContractDetailPageClient;