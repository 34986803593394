const actionTypes = {
    GET_CITIES: 'GET_CITIES',
    GET_DISTRICT_BY_CITYID: 'ADDRESS_GET_DISTRICT_BY_CITYID',
    GET_WARDS_BY_DISTRICTID: 'ADDRESS_GET_WARDS_BY_DISTRICTID',
    GET_SENDER_DISTRICT_BY_CITYID: 'ADDRESS_GET_SENDER_DISTRICT_BY_CITYID',
    GET_SENDER_WARDS_BY_DISTRICTID: 'ADDRESS_GET_SENDER_WARDS_BY_DISTRICTID',
    CLEAR_ADDRESS: 'CLEAR_ADDRESS',
    SET_ADDRESS: 'SET_ADDRESS',
    SET_SENDER_ADDRESS: 'SET_SENDER_ADDRESS'
}

export default actionTypes;