import React, { Component } from 'react';
import { Form, Input, Checkbox } from "antd";
import { Content } from 'antd/lib/layout/layout';
import './summarize.scss';
import { add } from 'lodash-es';
import { TEXT_VND } from '../../../../constants/common';
import { formatAmount } from '../../../../utils/order';

export default class SummarizeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            productCategories: props.productCategories ?? [],
            deliveryOptions: props.deliveryOptions ?? [],
            shippingServices: props.shippingServices ?? [],

            shippingInfo: props.shippingInfo ?? {},
            productInfo: props.productInfo ?? {},
            deliveryInfo: props.deliveryInfo ?? {},
            paymentInfo: props.paymentInfo ?? {},
            contractInfo: props.contractInfo ?? {},
        }
    }

    componentDidMount() {
        console.log(this.props.contractInfo)
    }

    render() {
        return (
            <Content className='beecow-summarize-info'>
                {this.renderBookingInfo()}
                {this.renderProductsInfo()}
                <div className="row">
                    <div className="col-sm-4">
                        {this.renderDelivery()}
                        {this.renderRoute()}
                    </div>
                    <div className="col-sm-8">
                        {this.renderPayment()}
                    </div>
                </div>
            </Content>
        )
    }

    renderBookingInfo = () => {
        const { t } = this.props;
        var { shippingInfo, contractInfo } = this.state;
        var { station } = contractInfo;
        return (
            <div className='row'>
                <div className="col-sm-4">
                    <h3>{t('createOrEditOrderPage.summarizeTab.senderInformation')}</h3>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row pl-4 mx-auto">
                                <p className="mr-5">{t('createOrEditOrderPage.summarizeTab.senderPhone')}:</p>
                                <p>{station?.phone}</p>
                            </div>
                            <div className="row pl-4 mx-auto">
                                <p className="mr-5">{t('createOrEditOrderPage.summarizeTab.senderName')}:</p>
                                <p>{station?.name}</p>
                            </div>
                            <div className="row pl-4 mx-auto">
                                <p className="mr-5">{t('createOrEditOrderPage.summarizeTab.senderAddress')}:</p>
                                <p>{this.formattedAddress(station?.address)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 mx-auto">
                    <h3>{t('createOrEditOrderPage.summarizeTab.receiverInformation')}</h3>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row pl-4 mx-auto">
                                <p className="mr-5">{t('createOrEditOrderPage.summarizeTab.receiverPhone')}:</p>
                                <p>{shippingInfo?.receiverInfo?.phoneNumber}</p>
                            </div>
                            <div className="row pl-4 mx-auto">
                                <p className="mr-5">{t('createOrEditOrderPage.summarizeTab.receiverName')}:</p>
                                <p>{shippingInfo?.receiverInfo?.fullName}</p>
                            </div>
                            <div className="row pl-4 mx-auto">
                                <p className="mr-5">{t('createOrEditOrderPage.summarizeTab.receiverAddress')}:</p>
                                <p>{this.formattedAddress(shippingInfo?.receiverInfo?.address)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderProductsInfo = () => {
        const { t } = this.props;
        var { productInfo } = this.state;
        var products = productInfo?.products;
        var productHtml = null;
        var totalItems = 0;
        var totalWeight = 0;
        if (products) {
            totalItems = products.reduce((result, obj) => {
                if (obj) {
                    result += parseInt(obj.quantityToSell);
                    return result;
                }
                else {
                    return result;
                }
            }, 0) ?? 0;

            totalWeight = products?.reduce((result, obj) => {
                if (obj) {
                    result += parseInt(obj.quantityToSell) * parseInt(obj.weight);
                    return result;
                }
                else {
                    return result;
                }
            }, 0) ?? 0;

            productHtml = products?.map((p) => {
                var category = this.state.productCategories.find((i) => i.id == p.categoryId);
                return (
                    <tr>
                        <td className="pl-3">{p?.name}</td>
                        <td className="pl-3">{t(category.languageKey)}</td>
                        <td className="text-center">{p?.length} x {p?.width} x {p?.height}</td>
                        <td className="text-center">{p?.weight} Kg</td>
                        <td className="text-center">{p?.quantityToSell}</td>
                    </tr>
                )
            });
        }
        return <>
            <div className='row mt-4 mb-5'>
                <div className="col-sm-12">
                    <h3>{t('createOrEditOrderPage.summarizeTab.productInformation')}</h3>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>{t('createOrEditOrderPage.summarizeTab.productName')}</th>
                                <th>{t('createOrEditOrderPage.summarizeTab.productCategory')}</th>
                                <th className="text-center">{t('createOrEditOrderPage.summarizeTab.sizeIn')}</th>
                                <th className="text-center">{t('createOrEditOrderPage.summarizeTab.weight')}</th>
                                <th className="text-center">{t('createOrEditOrderPage.summarizeTab.amount')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productHtml}

                            <tr className="line"></tr>

                            <tr>
                                <td className="pl-3"></td>
                                <td className="pl-3"></td>
                                <td className="text-center"></td>
                                <td className="text-left">{t('createOrEditOrderPage.summarizeTab.totalProductsInPackage')}:</td>
                                <td className="text-center">{totalItems}</td>
                            </tr>
                            <tr>
                                <td className="pl-3"></td>
                                <td className="pl-3"></td>
                                <td className="text-center"></td>
                                <td className="text-left">{t('createOrEditOrderPage.summarizeTab.totalWeight')}:</td>
                                <td className="text-center">{totalWeight} Kg</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    }

    renderDelivery = () => {
        const { t } = this.props;
        var { deliveryInfo } = this.state;
        return <>
            <h3>{t('createOrEditOrderPage.summarizeTab.deliveryMethod')}</h3>
            <div className="row">
                <div className="col-sm-12">
                    <p className="pl-4 mr-5">{t(deliveryInfo?.deliveryOption?.languageKey)}</p>
                </div>
                <div className="col-sm-12">
                    <p className="pl-4 mr-5">{t('createOrEditOrderPage.summarizeTab.shippingService')}: <span>{t(deliveryInfo?.shippingService?.languageKey)}</span></p>
                </div>
            </div>
        </>
    }

    renderRoute = () => {
        const { t } = this.props;
        var { deliveryInfo } = this.state;
        return <>
            <div className="mt-3">
                <h3>{t('createOrEditOrderPage.summarizeTab.routing')}</h3>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row mx-auto">
                            <p className="col-sm-4">{t('createOrEditOrderPage.summarizeTab.provider')}:</p>
                            <p className="col-sm-8">{deliveryInfo?.route?.company?.name}</p>
                        </div>
                        <div className="row mx-auto">
                            <p className="col-sm-4">{t('createOrEditOrderPage.summarizeTab.routeId')}:</p>
                            <p className="col-sm-8">{deliveryInfo?.route?.routeId}</p>
                        </div>
                        <div className="row mx-auto">
                            <p className="col-sm-4">{t('createOrEditOrderPage.summarizeTab.routeName')}:</p>
                            <p className="col-sm-8">{deliveryInfo?.route?.routeName}</p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    }

    renderPayment = () => {
        const { t } = this.props;
        var { paymentInfo } = this.state;
        var { paymentDetail } = paymentInfo;

        var codAmountFormatted = formatAmount(paymentInfo.codAmount) + TEXT_VND;
        var codFeeFormatted = formatAmount(paymentInfo?.paymentDetail?.codFee) + TEXT_VND;
        var pickupFromDoorFeeFormatted = formatAmount(paymentInfo?.paymentDetail?.pickupFromDoorFee) + TEXT_VND;
        var deliveryToDoorFeeFormatted = formatAmount(paymentInfo?.paymentDetail?.deliveryToDoorFee) + TEXT_VND;
        var interProvinceFeeFormatted = formatAmount(paymentInfo?.paymentDetail?.interProvinceFee) + TEXT_VND;
        var totalFeeFormatted = formatAmount(paymentInfo?.paymentDetail?.totalFee) + TEXT_VND;

        return <>
            <h3>{t('createOrEditOrderPage.summarizeTab.payment')}</h3>
            <div className="row">
                <div className="col-sm-4 pl-5">
                    <div className="row pl-4 mx-auto">
                        <p className="mr-5">{t(paymentInfo?.paymentType?.languageKey)}</p>
                    </div>
                    <div className="row pl-4 mx-auto">
                        <p className="mr-5">{t(paymentInfo?.payBy?.languageKey)}</p>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="total-card">
                        {
                            paymentInfo.isCod && paymentDetail?.codFee > 0 &&
                            <>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <p className="m-0">{t('createOrEditOrderPage.summarizeTab.codAmount')}</p>
                                        <p className="m-0 pl-4 text-detail font-italic">{t('createOrEditOrderPage.summarizeTab.senderReceivesCodAmountAtTimeOfPickup')}</p>
                                        <p className="m-0 pl-4 text-detail font-italic">{t('createOrEditOrderPage.summarizeTab.recipientPaysForOrderAtTimeOfDelivery')}</p>
                                    </div>

                                    <div className="col-sm-4 m-0 ">
                                        <p className="float-right">{codAmountFormatted}</p>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <p className="col-sm-6">{t('createOrEditOrderPage.summarizeTab.codFee')}</p>
                                    <div className="col-sm-4 m-0 ">
                                        <p className="float-right">{codFeeFormatted}</p>
                                    </div>
                                </div>
                            </>
                        }

                        {
                            paymentDetail?.pickupFromDoorFee > 0 &&
                            <div className="row">
                                <p className="col-sm-6 m-0"><b>{t('createOrEditOrderPage.summarizeTab.pickupFromDoorFee')}:</b></p>
                                <div className="col-sm-4 m-0 ">
                                    <p className="float-right">{pickupFromDoorFeeFormatted}</p>
                                </div>
                            </div>
                        }

                        {
                            paymentDetail?.deliveryToDoorFee > 0 &&
                            <div className="row mt-1">
                                <p className="col-sm-6 m-0"><b>{t('createOrEditOrderPage.summarizeTab.deliveryToDoorFee')}:</b></p>
                                <div className="col-sm-4 m-0 ">
                                    <p className="float-right">{deliveryToDoorFeeFormatted}</p>
                                </div>
                            </div>
                        }

                        {
                            paymentDetail?.interProvinceFee > 0 &&
                            <div className="row mt-1">
                                <p className="col-sm-6 m-0"><b>{t('createOrEditOrderPage.summarizeTab.interProvinceFee')}:</b></p>
                                <div className="col-sm-4 m-0 ">
                                    <p className="float-right">{interProvinceFeeFormatted}</p>
                                </div>
                            </div>
                        }

                        <div className="row mt-1">
                            <p className="col-sm-6 m-0"><b>{t('createOrEditOrderPage.summarizeTab.totalShippingFee')}:</b></p>
                            <div className="col-sm-4 m-0 ">
                                <p className="text-danger float-right"><b>{totalFeeFormatted}</b></p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    }

    formattedAddress = (address) => {
        var formatedAddress = address?.street ?? "";
        formatedAddress += address?.ward?.name ? `, ${address?.ward?.name}` : "";
        formatedAddress += address?.district?.name ? `, ${address?.district?.name}` : "";
        formatedAddress += address?.city?.name ? `, ${address?.city?.name}` : "";

        return formatedAddress;
    }
}