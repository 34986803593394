import _ from 'lodash'
import orderDataService from '../data-services/orders/orders-data.service'
import { formatSubRoutes } from './routing'
import { TEXT_VND } from '../constants/common';
import { Image } from 'antd';
import { specialServices} from '../constants/order-constant';
import fragileIcon from '../assets/icons/icon-fragile.png';
import fastIcon from '../assets/icons/icon-fast.png';

export const getData = (routes, props) => {
    const { selectedRoute } = props
    const { specialServices } = props.initData
    const { packageHeight, packageLength, packageWeight, packageWidth, SpecialOptionId } = props.data || {}
    const additional = specialServices.find((x) => x.id === SpecialOptionId)?.name
    const res = routes.map((x) => {
        const { code, routeName, estimate, subRoutes } = x
        const isSelected = x.id === selectedRoute?.id
        const price = (isSelected && selectedRoute?.price) ? x.shippingFee?.shippingFeeDetails.find((x) => x.height === packageHeight
            && x.weight === packageWeight
            && x.length === packageLength
            && x.width === packageWidth
            && x.additional?.toLowerCase() === additional?.toLowerCase())?.price : 0;
            
        const fromSubs = _.sortBy(subRoutes, (x) => x.position)?.map(x => { return x.fromStationNavigation?.name })
        const toSubs = _.sortBy(subRoutes, (x) => x.position)?.map(x => { return x.toStationNavigation?.name })

        return {
            key: x.id,
            routeId: code,
            routeName: routeName,
            from: getSubRouteHtml(fromSubs),
            to: getSubRouteHtml(toSubs),
            subRoutes: formatSubRoutes(subRoutes),
            eta: estimate,
            price: `${price}${TEXT_VND}`,
            logoPath: x.company?.logoPath
        }
    })
    const result = res?.filter(x => x.price)
    return result
}

const getSubRouteHtml = (subRoutes) => {
    return subRoutes?.map((x) => `<span>${x}</span> <br> `)?.join('') || ''
}

export const formatAmount = (value) => {
    if (isNaN(value) || value == null) {
        return "0";
    }

    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const validatePhoneNumber = (phoneNUmber) => {
    const validatePhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return validatePhone.test(phoneNUmber)
}

export const getPrice = async (props, valueCOD, data) => {
    const { selectedRoute } = props
    const { specialServices, deliveries } = props.initData
    const { packageHeight, packageLength, packageWeight, packageWidth, SpecialOptionId, deliveryId } = data || props.data
    const additional = specialServices.find((x) => x.id === SpecialOptionId)?.name

    let shippingFeeDetails = selectedRoute.shippingFee?.shippingFeeDetails.find((x) => x.height === packageHeight
        && x.weight === packageWeight
        && x.length === packageLength
        && x.width === packageWidth
        && x.additional?.toLowerCase() === additional?.toLowerCase())

    const deliverySelected = deliveries.find(d => d.id === deliveryId)
    const request =
    {
        ShippingFeeDetailId: shippingFeeDetails.id,
        deliverySelected,
        isCOD: valueCOD ? true : false
    }

    var res = await orderDataService.CaclFeeCreateOrderWareHouse(request)
    return res
}

// Added on 2021-11-29
//icon
export const getSpecialServiceIcon = (name) => {
    if (name === specialServices.fragile) {
      return <Image width={30} src={fragileIcon} />
    } else if (name === specialServices.fastService) {
      return <Image width={30} src={fastIcon} />
    }
    return <></>
  }

export default { getData, formatAmount, getSpecialServiceIcon }