export const USER_ID = "5DE4C30E-D277-4989-859A-368245647EDC";
export const STATION_ID = "dff7e52c-3a7f-4ce5-87a9-37cd415a5bc2";
export const FORMAT_DATE = "DD-MM-YYYY";
export const FORMAT_DATETIME = "DD-MM-YYYY HH:mm";
export const GET_MONTH = "month";
export const TEXT_VND = " VND";
export const TEXT_CITY = " City";

export const COMPANY_DETAIL = {
    sytem: "Beecow Logistic",
    company: "Futa Bus Line",
    companyAddress: "42 Trần Hữu Trang, Phường 11, Phú Nhuận, Thành phố Hồ Chí Minh"
};

export const FORMAT_TIME_DATE = "HH:mm DD MMM YYYY";
