import React, { Component } from 'react';
import { Table } from "antd";

export class ProductDetailTableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialData: props.initialData ?? [],
        }
    }

    componentDidMount() {
    }

    render() {
        const { initialData } = this.state;
        return initialData ?
            <Table
                columns={this.columns()}
                dataSource={initialData}
                childrenColumnName="children"
                defaultExpandAllRows={true}
            />
            : <></>
    }

    columns = () => {
        const { t } = this.props;
        return [
            {
                title: t('productDetailPage.columnName.no'),
                dataIndex: 'index',
                key: 'name',
            },
            {
                title: t('productDetailPage.columnName.orderId'),
                dataIndex: 'code',
                key: 'name',
                render: (_, { code, id, isChild }) => {
                    if (isChild) {
                        return <>
                            <div className='text-primary code import-product'
                                onClick={() => this.redirectToImportDetail(id)}><a>{code}</a></div>
                        </>
                    }
                    return <>
                        <div className='text-primary code'
                            onClick={() => this.openOrderDetail(id)}><a>{code}</a></div>
                    </>
                }
            },
            {
                title: t('productDetailPage.columnName.type'),
                dataIndex: 'type',
                key: 'age',
                width: '15%',
            },
            {
                title: t('productDetailPage.columnName.date'),
                dataIndex: 'date',
                width: '25%',
                key: 'address',
            },
            {
                title: t('productDetailPage.columnName.quantity'),
                dataIndex: 'quantity',
                key: 'age',
                width: '12%',
                render: (text, arrProduct) => {
                    const { isChild } = arrProduct
                    if (isChild) {
                        return <>
                            <div className='quantity-child text-primary'>{text}</div>
                        </>
                    }
                    return <>
                        <div className='quantity'>{text}</div>
                    </>
                }
            },
            {
                title: t('productDetailPage.columnName.status'),
                dataIndex: 'status',
                width: '30%',
                key: 'address',
            },
        ];
    }

    updateDataTable = (datatable) => {
        this.setState({
            ...this.state,
            initialData: datatable
        });
    }

    openOrderDetail(id) {
        this.props.history.push(`/detail-order/${id}`)
    }

    redirectToImportDetail(id) {
        this.props.history.push(`/import-detail/${id}`);
    }
}