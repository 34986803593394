import React, { Component } from 'react';
import Layout from 'antd/lib/layout/layout';
import Button from 'antd-button-color';
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import orderDataService from '../../../../data-services/orders/orders-data.service';
import { Space } from 'antd';

export class ConfirmLostPackageScreen extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            result: false,
            message: '',
            showBackButton: false,
        }
    }

    componentDidMount() {
        const { t } = this.props;
        const { orderId, confirmationType } = this.props?.match?.params || {};
        let query = new URLSearchParams(this.props?.location?.search);
        let valueStr = query.get('confirmStatus');
        let orderStepStr = query.get('orderStep');
        let redirectToViewLostOrderDetail = query.get('return-view-lost-order-details');
        let confirmStatus = false;
        if(valueStr && valueStr?.length >0){
            if(valueStr ==='true')
                confirmStatus = true;
        }

        if(redirectToViewLostOrderDetail && redirectToViewLostOrderDetail === 'true'){
            this.setState({ showBackButton: true });
        }

        const requestData = {
            orderId: orderId,
            confirmationType,
            isReturnSender: confirmStatus,
            orderStep: orderStepStr
        }

        orderDataService.confirmLostPackage(requestData).then(res => {
            if (res.isSuccess === true) {
                this.setState({ result: true, message: res.languageCode });
            } else {
                this.setState({ result: false, message: res.languageCode });
            }
        });
    }

    render() {
        const { orderId } = this.props?.match?.params || {};
        const { showBackButton, result, message } = this.state;
        const { t, history } = this.props;
        
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <div className='m-auto centered shadow p-4 pt-5 mb-5 bg-white rounded login-form' style={{ width: '500px', height: '250px' }}>
                        <div className='m-auto centered text-center pt-3'>
                            {
                                result ?
                                    <CheckCircleTwoTone style={{ fontSize: '36px' }} twoToneColor="#52c41a" />
                                    : <CloseCircleTwoTone style={{ fontSize: '36px' }} twoToneColor="#eb2f96" />
                            }
                            <h2>{t(message)}</h2>
                            <Space>
                                <Button
                                    style={{width: '120px'} }
                                    className='mt-5'
                                    onClick={() => this.props.history.push('/')}
                                    type='warning'>
                                    {t('button.home')}
                                </Button>

                                {
                                    showBackButton === true && 
                                    <Button
                                    style={{width: '120px'} }
                                    className='mt-5'
                                    onClick={() => history.push(`/view-lost-order-details/${orderId}`)}
                                    type='warning'>
                                    {t('button.back')}
                                    </Button>
                                }
                            </Space>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}