import { connect } from "react-redux";
import { ListOrdersScreen } from "./list-orders.screen";
import { fetchListOrders } from "../../modules/orders/orders.actions";

import { compose } from "redux";
import { withTranslation, translate, Trans } from "react-i18next";

const mapStateToProps = (state) => {
    return {
        orders: state.order?.list,
        loggedInUserId: state.session?.auth?.user?.userid,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        listOrders: (userId) => dispatch(fetchListOrders(userId))
    }
}

export default compose(
    withTranslation("translations"),
  connect(mapStateToProps, mapDispatchToProps)
)(ListOrdersScreen);
