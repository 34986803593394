import React, { Component } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Checkbox, Divider, Layout } from 'antd';
import Button from 'antd-button-color';

import TopBar from '../../components/topbar';
import importDataService from '../../data-services/import/import-data.service';
import { FORMAT_DATETIME } from '../../common/constants';
import './view-import-list.scss';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { formatDate } from '../../utils/common';

export class ViewImportListScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialData: {},
            editData: {
                isProcessing: false,
                orderId: '',
                productId: '',
                areaId: '',
                areaNewId: '',
                spaceIds: [],
                spaceNewIds: false,
                areas: [],
                pagingSpaces: {
                    page: 0, 
                    records: 0,
                    total: 0,
                    rows: [],
                },
                canLoadMore: false,
                isLoadingMore: false,
            }
        };
    }

    componentDidMount() {
        this.refresh();
    }

    render() {
        const { t } = this.props;
        let { code, createdTime, orders } = this.state.initialData;
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t('title.viewImportList')} />
                    <div className='bg-white clearfix beecow-import-packages'>
                        <Content className='beecow-view-import-list'>
                            <h2 className='text-center'>{t('title.viewImportList')}</h2>
                            <div className='row mt-5'>
                                <div className='col-sm-4'>
                                    <p><b>{t('tableColumn.importId')}: {code}</b></p>
                                </div>
                                <div className='col-sm-4'>
                                    <p><b>{t('tableColumn.totalOrders')}: {orders?.length}</b></p>
                                </div>
                                <div className='col-sm-4'>
                                    <p><b>{t('tableColumn.importDate')}:</b> {formatDate(createdTime, FORMAT_DATETIME)}</p>
                                </div>
                            </div>
                            <Button
                                onClick={() => this.props.history?.goBack()}
                                className='float-right mb-3'
                                type='warning' ghost>
                                { t('button.back')}
                            </Button>
                            <Divider />
                            { orders?.map((order, index) => this.displayOrder(order, index)) }
                        </Content>
                    </div>
                </div>
            </Layout>
        );
    }

    refresh() {
        let importListId = this.props.match?.params?.id;
        if (importListId) {
            importDataService.getById(importListId).then(data => {
                this.setState({ initialData: data });
            });
        }
    }

    displayOrder(order, index) {
        const { t } = this.props;
        return (
            <div className='beecow-products-info' key={index}>
                <div className='row'>
                    <div className='col-sm-6'>
                        <p><b>{t('tableColumn.orderId')}: <Link style={{ textDecoration: 'underline' }} to={`/detail-order/${order?.id}`}>{order?.code}</Link></b></p>
                        <p><b>{t('tableColumn.orderCreatedDate')}:</b> {formatDate(order?.createdTime, FORMAT_DATETIME)}</p>
                        <p><b>{t('tableColumn.totalItemsInOrder')}:</b> {_.sumBy(order?.orderItems, 'importedAmount')}</p>
                        <p><b>{t('tableColumn.totalWeightInOrder')}:</b> {_.sumBy(order?.orderItems, function(i) { return (i.importedAmount || 0)*i.product?.weight })}kg</p>
                    </div>
                    <div className='col-sm-6'>
                        <p><b>{t('tableColumn.pickedListId')}: <Link style={{ textDecoration: 'underline' }} to={`/view-pickup-list/${order?.orderPickup?.id}`}>{order?.orderPickup?.code}</Link></b></p>
                        <p><b>{t('tableColumn.pickedDate')}:</b> {formatDate(order?.pickedDate, FORMAT_DATETIME)}</p>
                        <p><b>{t('tableColumn.assignedVehicle')}:</b> {order?.orderPickup?.vehicle?.code}</p>
                        <p><b>{t('tableColumn.assignedDriver')}:</b> {order?.orderPickup?.driver?.fullName}</p>
                    </div>
                </div>
                <table className='table table-borderless'>
                    <thead>
                        <tr>
                            <th>{t('tableColumn.no')}</th>
                            <th>{t('tableColumn.productCode')}</th>
                            <th>{t('tableColumn.productName')}</th>
                            <th>{t('tableColumn.productCategory')}</th>
                            <th>{t('tableColumn.size')} (m)</th>
                            <th>{t('tableColumn.weight')} (kg)</th>
                            <th>{t('tableColumn.amount')}</th>
                            <th>{t('tableColumn.assignedSpaces')}</th>
                            <th>{t('tableColumn.reason')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        { order?.orderItems?.map((orderItem, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{orderItem?.product?.code}</td>
                                    <td>{orderItem?.product?.name}</td>
                                    <td>{orderItem?.product?.packageType?.name}</td>
                                    <td>{orderItem?.product?.length}m - {orderItem?.product?.width}m - {orderItem?.product?.height}m</td>
                                    <td>{orderItem?.product?.weight}kg</td>
                                    <td>{orderItem?.importedAmount || 0}/{orderItem?.amount}</td>
                                    <td>{this.displaySpaces(order, orderItem?.product)}</td>
                                    <td>{(orderItem?.importedAmount === orderItem?.amount) ? <Checkbox checked={orderItem?.imported} disabled /> : <span>{orderItem?.importFailedReason}</span> }</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <Divider />
            </div>
        )
    }

    displaySpaces(order, product) {
        let spaces = (product?.spaceAreas && product?.spaceAreas?.length > 0) ? product?.spaceAreas : product?.productSpaceShippings?.map(ps => ps.space);
        let areas = [];
        spaces?.forEach(space => {
            let index = areas.findIndex(a => a.id === space.area?.id);
            if (index === -1) {
                let area = space.area;
                area.spaces = [space];
                areas.push(area);
            } else {
                let area = areas[index];
                area.spaces.push(space);
                areas[index] = area;
            }
        });
        return areas.map((area, index) => {
            return (
                <div className='beecow-spaces' key={index}>
                    <span>{area.code + ': ' + _.join(area.spaces?.map(space => space.code), ', ')}</span>
                </div>
            )
        });
    }
}