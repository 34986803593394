import React, { Component } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Form, Select, Radio, Col, Row, Space, Card } from 'antd';
import { Utilities } from '../../../../common/utilities';
import RouteTableComponent from './route-table/route-table.component';
import leasingContractDataService from '../../../../data-services/leasing-contract/leasing-contract-data.service';
import { DeliveryConstants } from './../../../../constants/delivery.constants';
import stationDataService from '../../../../data-services/stations/stations-data.service';

export default class DeliveryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMoreOptions: false,
            deliveryOptions: props.deliveryOptions ?? [],
            shippingServices: props.shippingServices ?? [],
            shippingInfo: props.shippingInfo ?? [],
            productInfo: props.productInfo ?? [],
            locations: [],
            stations: [],
            routes: [],
            cachedRoutes: [],
            initialData: props.initialData ?? [],
            isStationSameWarehouse: false
        }

        this.deliveryFormRef = React.createRef();
        this.routeTableRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        const { initialData, deliveryOptions, shippingServices, locations, routes, showMoreOptions, stations, isStationSameWarehouse } = this.state;
        
        return (
            <Content>
                <Form ref={this.deliveryFormRef}>
                    <div className='row mx-auto'>
                        <div className='col-3 mx-auto'>
                            <label>{t('createOrEditOrderPage.deliveryForm.shippingService')}</label>
                            <Form.Item name={['shippingService', 'id']}
                                rules={[{ required: true, message: t('createOrEditOrderPage.deliveryForm.pleaseSelectShippingService') }]}>
                                <Select
                                    onChange={this.onChangeShippingService}
                                    optionFilterProp='children'
                                    placeholder={t('createOrEditOrderPage.deliveryForm.selectService')}>
                                    {
                                        shippingServices?.map(({ id, name, languageKey }) => <Select.Option key={id} value={id}>{t(languageKey)}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='col-6 offset-2'>
                            <div className='title'>
                                <label> <i className='fa fa-truck' aria-hidden='true'></i> {t('createOrEditOrderPage.deliveryForm.deliveryOptions')}</label>
                            </div>
                            <div className='action'>
                                <Form.Item name={['deliveryOption', 'id']}
                                    rules={[{ required: true, message: t('createOrEditOrderPage.deliveryForm.pleaseSelectDeliveryOption') }]}
                                >
                                    <Space direction='vertical'>
                                        {
                                            deliveryOptions?.map(({ id, name, languageKey }) => {
                                                const selected = initialData?.deliveryOption ?? null;
                                                return (
                                                    <Radio className='row' key={id} value={id} onChange={() => this.onChangeDeliveryOption(id)} checked={id === selected?.id}>
                                                        {t(languageKey)}
                                                    </Radio>
                                                );
                                            })}
                                    </Space>
                                </Form.Item>
                                {
                                    showMoreOptions && <div className='choose-delivery'>
                                        <div className='mt-3 title-sub'>
                                            <span><i className='fa fa-snowflake-o' aria-hidden='true'></i>{t('createOrEditOrderPage.deliveryForm.pleaseSelectLocationAndStation')}</span>
                                        </div>
                                        <Row className='mt-3'>
                                            <Col span={7} className='mr-4'>
                                                <Form.Item rules={[{ required: true, message: t('createOrEditOrderPage.deliveryForm.pleaseSelectLocation') }]}>
                                                    <Select onChange={this.onChangeLocation} placeholder={t('createOrEditOrderPage.deliveryForm.selectLocation')}>
                                                        {
                                                            locations?.map((text, index) => (
                                                                <Select.Option key={index} value={text}>{t(text)}</Select.Option>))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={7}>
                                                <Form.Item
                                                    name={['station', 'id']}
                                                    rules={[{ required: true, message: t('createOrEditOrderPage.deliveryForm.pleaseSelectStation') }]}>
                                                    <Select value={initialData.station?.id} onChange={value => this.onChangeStation(value)} placeholder={t('createOrEditOrderPage.deliveryForm.selectStation')}>
                                                        {
                                                            stations?.map(({ id, name }) => <Select.Option key={id} value={id}>{name}</Select.Option>)
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='title routing'>
                    <label><i className='fa fa-rebel' aria-hidden='true'></i> {t('createOrEditOrderPage.deliveryForm.routing')}</label>
                </div>
                    {isStationSameWarehouse && <>
                        <Card>
                            <span>{t('createOrEditOrderPage.deliveryForm.warningChoosingStation')}</span><br />
                            <span>{t('createOrEditOrderPage.deliveryForm.warningMeansYouWillBringPackage')}</span><br />
                            <span>{t('createOrEditOrderPage.deliveryForm.pleaseGoToPayment')}</span>
                        </Card>
                    </>}
                    {!isStationSameWarehouse && <>
                        <Form.Item>
                            <RouteTableComponent
                                t={t}
                                ref={this.routeTableRef}
                                initialData={routes}
                                onChange={this.onSelectRoute}
                            />
                        </Form.Item>
                    </>}
                </Form>
            </Content>
        )
    }

    componentDidMount() {
        this.refresh();
    }

    getFieldsValue = () => {
        var { deliveryOptions, shippingServices, cachedRoutes } = this.state;
        var deliveryInfo = {};
        if (this.routeTableRef && this.routeTableRef?.current) {
            var route = this.routeTableRef?.current?.getFieldsValue();
            var routeInfo = cachedRoutes?.find((i) => i.key === route.id) ?? null;
            deliveryInfo.route = routeInfo;
            this.setState({ ...this.state, route: route });
        }

        if (this.deliveryFormRef && this.deliveryFormRef?.current) {
            var deliveryMethod = this.deliveryFormRef?.current?.getFieldsValue();
            var delivery = deliveryOptions?.find((i) => i.id === deliveryMethod.deliveryOption?.id) ?? null;
            var shippingService = shippingServices?.find((i) => i.id === deliveryMethod.shippingService?.id) ?? null;
            deliveryInfo.shippingService = shippingService;
            deliveryInfo.deliveryOption = delivery;
            deliveryInfo.toStationId = deliveryMethod.station?.id
            this.setState({ ...this.state, route: route });
        }

        return deliveryInfo;
    }

    setFieldsValue = (values) => {
        if (this.deliveryFormRef && this.deliveryFormRef?.current) {
            return this.deliveryFormRef?.current?.setFieldsValue(values);
        }

        this.setState({ ...this.state, initialData: { ...this.state.initialData, ...values } });
    }

    validateFields = () => {
        if (this.deliveryFormRef && this.deliveryFormRef?.current) {
            return this.deliveryFormRef?.current?.validateFields();
        }
    }

    onChangeShippingService = (value) => {
        const { shippingServices, initialData } = this.state;
        if (shippingServices) {
            const shippingService = shippingServices.find((i) => i.id === value);
            if (shippingService) {
                this.setState({
                    ...this.state,
                    initialData: {
                        ...initialData,
                        shippingService: shippingService
                    },
                    station: []
                }, () => {
                    this.refresh();
                });
            }
        }
    }

    onChangeDeliveryOption = (value) => {
        var { deliveryOptions, initialData } = this.state;
        if (deliveryOptions) {
            const deliveryOption = deliveryOptions?.find((i) => i.id === value);
            if (deliveryOption) {
                var showMoreOptions = false;
                if (this.isShowMoreOption(deliveryOption)) {
                    showMoreOptions = true;
                }
                this.setState({
                    ...this.state,
                    showMoreOptions: showMoreOptions,
                    initialData: {
                        ...initialData,
                        deliveryOption: deliveryOption,
                        station: []
                    },
                }, () => {
                    this.refresh();
                });
            }
        }
    }

    refresh = async () => {
        const { initialData, shippingInfo, productInfo } = this.state;
        var { shippingService, deliveryOption } = initialData;

        if (!shippingService || !deliveryOption || !shippingInfo || !productInfo) {
            this.filterRoutesToReceiverAddress([]);
            return;
        }

        if(!deliveryOption?.name?.toLowerCase().includes(DeliveryConstants.STATION_TO_WAREHOUSE)){
            this.setState({isStationSameWarehouse: false});
        }

        

        var units = productInfo?.products?.map((i, index) => {
            return {
                key: Utilities.randomUuid(),
                length: i.length,
                width: i.width,
                height: i.height,
                weight: i.weight,
                total: i.amount,
            }
        });

        var request = {
            deliveryId: deliveryOption?.id,
            specialOptionId: shippingService?.id,
            products: units ?? [],
            userAddress: this.state.shippingInfo?.senderInfo?.address,
            receiverAddress: this.state.shippingInfo?.receiverInfo?.address,
            toStationId: this.props?.warehouseId ?? null
        }

        if (this.isValidModel(request)) {
            if (this.routeTableRef?.current?.onUpdate) {
                this.routeTableRef?.current?.onUpdate([], true);
                leasingContractDataService.getRoutesByCity(request).then((res) => {
                    var routes = res.map((data) => this.transform(data));
                    this.setState({
                        cachedRoutes: routes
                    });

                    this.filterRoutesToReceiverAddress(routes);
                    var toStationOption = deliveryOption?.name?.toLowerCase().includes(DeliveryConstants.STATION_TO_WAREHOUSE);
                    if (!toStationOption) {
                        this.routeTableRef?.current?.onUpdate(routes, false);
                    }

                }).catch((error) => {
                    this.routeTableRef?.current?.onUpdate([], false);
                    console.error(error);
                });
            }
        }
    }

    isValidModel = (request) => {
        if (!request.deliveryId ||
            !request.specialOptionId ||
            !request.products ||
            !request.userAddress
        ) {
            console.log('Invalid request model')
            return false;
        }
        return true;
    }

    isShowMoreOption = (deliveryOption) => {
        if (deliveryOption) {
            return deliveryOption?.name?.toLowerCase().includes(DeliveryConstants.STATION_TO_WAREHOUSE) ? true : false;
        }

        return false;
    }

    onChangeLocation = (locationId) => {
        let { shippingInfo, cachedRoutes } = this.state;
        let bookingCityId = shippingInfo?.senderInfo?.address?.city?.id;
        let stations = [];

        cachedRoutes?.forEach((r) => {
            if (r.location !== locationId) {
                return;
            }
            if (r.from) {
                r.from.forEach((s) => {
                    if (s.fromCity?.id !== bookingCityId) {
                        return;
                    }
                    let stationObject = {
                        id: s.fromStationId,
                        name: s.from,
                    };
                    stations.push(stationObject);
                });
            }
        });

        // add renting warehouse to station list
        if (shippingInfo?.receiverInfo && shippingInfo?.receiverInfo?.location === locationId) {
            stations.push({
                id: shippingInfo?.receiverInfo?.id,
                name: shippingInfo?.receiverInfo?.name,
            });
        }

        let stationsUniqueById = [...new Map(stations.map(item => [item["id"], item])).values()];
        this.setState({
            stations: stationsUniqueById
        });
    }

    onChangeStation = (stationId) => {
        var { cachedRoutes } = this.state;
        var routes = [];
        cachedRoutes?.forEach((route) => {
            var subrouteDetails = route.subRoutesDetails;
            subrouteDetails.forEach((s) => {
                if (s.fromStationNavigation?.id === stationId) {
                    routes.push(route);
                    this.setState({isStationSameWarehouse: false});
                    return;
                } else if(s.toStationNavigation?.id === stationId){
                    this.setState({isStationSameWarehouse: true});
                }
            });
        });
        if(cachedRoutes && cachedRoutes.length == 0){
            this.setState({isStationSameWarehouse: true});
        }

        this.routeTableRef?.current?.onUpdate(routes, false);
    }

    onSelectRoute = (routeId) => {
        var { cachedRoutes } = this.state;
        if (this.routeTableRef && this.routeTableRef?.current) {
            var routeInfo = cachedRoutes?.find((i) => i.key === routeId) ?? null;
            this.setState({ ...this.state, route: routeInfo });
            if (this.deliveryFormRef && this.deliveryFormRef?.current) {
                var deliveryInfo = this.getFieldsValue();
                deliveryInfo.route = routeInfo;

                this.deliveryFormRef?.current?.setFieldsValue(deliveryInfo);
            }
        }
    }

    filterRoutesToReceiverAddress = (routes) => {
        let { shippingInfo } = this.state;
        let groups = this.groupsByLocation(routes);
        let locations = groups ? Object.keys(groups) : [];

        // add location of renting warehouse
        let locationId = shippingInfo?.senderInfo?.location;
        if (locationId && !locations.includes(locationId)) {
            locations.push(locationId);
        }

        this.setState({
            locations: locations,
        });
    }

    transform = (data) => {
        return {
            key: data?.route?.id,
            id: data?.route?.id,
            routeId: data?.route?.code,
            routeName: data?.route?.routeName,
            subRoutes: this.formatSubRoutes(data?.route?.subRoutes),
            location: data?.route?.fromStationDetail?.location,
            eta: data?.route?.estimate,
            price: this.formatAmount(data?.price) + ' VND',
            company: data?.route?.company,
            subRoutesDetails: data?.route?.subRoutes,
            shippingFeeDetails: data?.shippingFeeDetails,
            from: (this.getSubRoutes(data?.route?.subRoutes))
        }
    }

    formatSubRoutes = (subRoutes) => {
        var toStations = subRoutes?.map((r) => `${r.toStationNavigation?.name}`)?.join(' - ') || '';
        if (toStations.length > 1) {
            return `${subRoutes[0].fromStationNavigation?.name} - ${toStations}`;
        }
        return '';
    }

    formatAmount = (value) => {
        if (isNaN(value) || value == null) {
            return '0';
        }

        return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    getSubRoutes(subRoutes) {
        return subRoutes?.map((value) => {
            return {
                fromStationId: value?.fromStationNavigation?.id,
                from: value?.fromStationNavigation?.name,
                fromCity: value?.fromStationNavigation?.address?.city,
                to: value?.toStationNavigation?.name,
            }
        });
    }

    groupsByLocation(routes) {
        let groups = routes?.reduce((r, a) => {
            r[a.location] = [...r[a.location] || [], a];
            return r;
        }, {});

        return groups;
    }
}
