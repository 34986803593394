import { notification } from 'antd';
const messageType = {
    success: "success",
    warning: "warning",
    error: "error"
}

export const showSuccessMessage = (message, description) => {
    notification[messageType.success]({
        message: message,
        description: description
    });
};

export const showErrorMessage = (message, description) => {
    notification[messageType.error]({
        message: message,
        description: description
    });
};

export const showWarningMessage = (message, description) => {
    notification[messageType.warning]({
        message: message,
        description: description
    });
};
