import React, { Component } from 'react';
import { Form, Button, Select, Input, InputNumber } from 'antd';
import './product-table.scss'
import { data } from 'jquery';
import areaStationDataService from '../../../../../data-services/area-stations/area-stations-data.service';
import { showAlert } from '../../../../../constants/constants';

export default class ProductTableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sizeSpace: props.initialData?.spaces?.length > 0 ? props.initialData.spaces[0] : [],
            products: props.initialData.products,
            categories: props.initialData.categories,
        }
        this.formRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        return (
            <table className='table table-borderless product-table' scroll={{ y: 240 }}>
                <thead>
                    <tr>
                        <th className='col-btn'></th>
                        <th className='col-code'>{t('createOrEditOrderPage.productForm.productId')}</th>
                        <th className='col-info'>{t('createOrEditOrderPage.productForm.productName')}</th>
                        <th className='col-code'>{t('createOrEditOrderPage.productForm.productCategory')}</th>
                        <th className='col-size'>{t('createOrEditOrderPage.productForm.length')}</th>
                        <th className='col-size'>{t('createOrEditOrderPage.productForm.width')}</th>
                        <th className='col-size'>{t('createOrEditOrderPage.productForm.height')}</th>
                        <th className='col-size'>{t('createOrEditOrderPage.productForm.weight')}</th>
                        <th className='col-size'>{t('createOrEditOrderPage.productForm.adding')}</th>
                        <th className='col-hidden'></th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderListProduct()}
                </tbody>
            </table>
        )
    }

    onAdd = (callBack, params) => {
        const { t } = this.props;
        let amountSpaces = this.props.initialData.spaces;
        var listProduct = this.props.getFieldsValue();
        var sumAmount = listProduct.products.reduce((n, {amount}) => n + amount, 0);
        if(amountSpaces.length > sumAmount){
            if (params && params >= 0) {
                callBack(null, params);
            }
            else {
                callBack();
            }
        } else {
            let message = t('confirm.warning');
            let description = t('confirm.canNotAddProducts');
            showAlert(message, description, false);
        }
    }

    onRemove = (callBack, params) => {
        callBack(params);
    }

    onChangeAmount = (value, index) => {
        let maximumAllowed = this.props.initialData.spaces.length;
        var dataForm = this.props.getFieldsValue();
        var sumAmount = dataForm.products.reduce((n, {amount}) => n + amount, 0);
        
        var item = dataForm.products[index];
        item.maximum = (maximumAllowed - (sumAmount - value));
        this.props.setValueAdd(index, item)
    };

    checkItemProducts = (index) => {
        var dataForm = this.props.getFieldsValue();
        var item = dataForm?.products[index];
        let isOldProduct = false;
        if(item?.key){
            isOldProduct = true;
        }
        return isOldProduct;
    }

    renderListProduct = () => {
        const { products, categories, sizeSpace } = this.state;
        const { t } = this.props;
        return <>
            <Form.List name='products' initialValue={products}>
                {(products, { add, remove }) => {
                    return (
                        products.length === 0 ? (
                            <tr>
                                {
                                    <td style={{ display: 'inline-flex' }}>
                                        <Button className = 'btn-icon' type='text' onClick={() => this.onAdd(add, [])}><i className='fa fa-plus text-success' aria-hidden='true' ></i></Button>
                                    </td>
                                }
                            </tr>
                        ) :
                        products?.map((field, index) => {                            
                            return (
                                <tr key={index}>
                                    <td style={{ display: 'inline-flex' }}>
                                        <Button className = 'btn-icon' type='text' onClick={() => this.onAdd(add, index + 1)}><i className='fa fa-plus text-success' aria-hidden='true' ></i></Button>
                                        {
                                            !this.checkItemProducts(index) && <Button className = 'btn-icon' type='text' onClick={() => this.onRemove(remove, field.name)}><i className='fa fa-close text-danger' aria-hidden='true' ></i></Button>
                                        }
                                    </td>
                                    <td>
                                        <label>-</label>
                                    </td>
                                    <td>
                                        <Form.Item
                                            className='mt-0 mr-3'
                                            name={[index, 'name']}
                                            rules={[{ required: true , message: t('createOrEditOrderPage.productForm.pleaseInputProductName')}]} >
                                            <Input disabled={this.checkItemProducts(index)} className='col-info' placeholder={t('createOrEditOrderPage.productForm.productName')} />
                                        </Form.Item>
                                    </td>
                                    <td>
                                        <Form.Item
                                            className='mr-3'
                                            name={[index, 'packageTypeId']}
                                            rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseSelectCategory')}]} >
                                            <Select disabled={this.checkItemProducts(index)} className='col-info' placeholder={t('createOrEditOrderPage.productForm.selectCategory')}>
                                                {categories.map(({ id, languageKey }) => <Select.Option key={id} value={id}>{t(languageKey)}</Select.Option>)}
                                            </Select>
                                        </Form.Item>
                                    </td>
                                    <td>
                                        <Form.Item
                                            name={[index, 'length']}
                                            rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputLength') }]} >
                                            <InputNumber 
                                                disabled={this.checkItemProducts(index)} 
                                                className='col-size'
                                                pattern='^\d+\.\d{0,2}$'
                                                onKeyDown={e => (e.keyCode !== 190 && e.keyCode != 9 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) && e.preventDefault()}
                                                min={0}
                                                max={sizeSpace.length}
                                                placeholder={t('createOrEditOrderPage.productForm.length')} />
                                        </Form.Item>
                                        
                                    </td>
                                    <td>
                                        <Form.Item
                                            name={[index, 'width']}
                                            rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputWidth') }]} >
                                            <InputNumber 
                                                disabled={this.checkItemProducts(index)} 
                                                className='col-size'
                                                pattern='^\d+\.\d{0,2}$'
                                                onKeyDown={e => (e.keyCode !== 190 && e.keyCode != 9 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) && e.preventDefault()}
                                                min={0}
                                                max={sizeSpace.width}
                                                placeholder={t('createOrEditOrderPage.productForm.width')} />
                                        </Form.Item>
                                    </td>
                                    <td>
                                        <Form.Item
                                            name={[index, 'height']}
                                            rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputHeight') }]} >
                                            <InputNumber 
                                                disabled={this.checkItemProducts(index)} 
                                                className='col-size'
                                                pattern='^\d+\.\d{0,2}$'
                                                onKeyDown={e => (e.keyCode !== 190 && e.keyCode != 9 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) && e.preventDefault()}
                                                min={0}
                                                max={sizeSpace.height}
                                                placeholder={t('createOrEditOrderPage.productForm.height')} />
                                        </Form.Item>
                                    </td>
                                    <td>
                                        <Form.Item
                                            name={[index, 'weight']}
                                            rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputWeight') }]} >
                                            <InputNumber 
                                                disabled={this.checkItemProducts(index)} 
                                                className='col-size'
                                                pattern='^\d+\.\d{0,2}$'
                                                onKeyDown={e => (e.keyCode !== 190 && e.keyCode != 9 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) && e.preventDefault()}
                                                min={0}
                                                placeholder={t('createOrEditOrderPage.productForm.weight')} />
                                        </Form.Item>
                                    </td>
                                    <td>
                                        <Form.Item 
                                            hasFeedback
                                            name={[index, 'amount']}
                                            rules={[
                                                {
                                                required: true,
                                                message: t('addMoreProductsPage.form.productInfo.pleaseInputAdding'),
                                                },
                                                ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    let amount = parseInt(getFieldValue(["products", index, "maximum"]))
                                                    if ( amount >= parseInt(value)) {
                                                    return Promise.resolve();
                                                    }
                                    
                                                    return Promise.reject(new Error(`${t('addMoreProductsPage.form.productInfo.youMustInputQuantity')} - ${amount}`));
                                                },
                                                }),
                                            ]} >
                                            <InputNumber
                                                pattern='^\d+$'
                                                onKeyDown={e => (e.keyCode != 9 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) && e.preventDefault()}
                                                min={1}
                                                onChange={(value) => this.onChangeAmount(value, index)}
                                            />
                                        </Form.Item>
                                    </td>
                                    <td className= "col-hidden" >
                                        <Form.Item
                                            name={[index, "maximum"]}>
                                            <Input className= "col-hidden" disabled={true}/>
                                        </Form.Item>
                                    </td>
                                </tr>
                        )})
                    );
                }}
            </Form.List>
        </>
    }
}