import { Button } from 'antd'
import React, { Component } from 'react'

export default class NextPage extends Component {
    render() {
    const { hideBack, hideNext, hideFinish } = this.props
    return (
      <div >
        <div className="next-page pull-right">
          {!hideNext && <Button type="primary" onClick={() => this.props.handleNext()}>Next</Button>}
        </div>
        {
          !hideFinish && <div className="next-page back pull-right">
              <Button type="primary" onClick={() => this.props.handleFinish()}>Finish</Button>
            </div>
        }
        {
          !hideBack && <div className="next-page back pull-right">
            <Button type="primary" onClick={() => this.props.handleBack()}>Back</Button>
          </div>
        }

      </div>
    )
  }
}
