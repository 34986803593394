import { Layout } from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { compose } from "redux";
import LoadingComponent from "./components/loading";
import { ErrorBoundary } from "./errorBoundary";
import PrivateRoute from "./router/auth.router";
import listMenu from "./router/routes";

export class App extends Component {
  render() {
    const { t } = this.props;
    const listRoute = this.getListRoute(listMenu);
    return (
      <ErrorBoundary>
        <Router>
          <div className="container-list">
            <LoadingComponent loading={this.props.loading} />
            <Layout className="ant-layout ant-layout-has-sider" style={{ minHeight: "100vh" }}>
              <Switch>
                {listRoute.map((route, index) =>
                  route.auth ? (
                    <PrivateRoute
                      t={t}
                      routes={listRoute}
                      listMenu={listMenu}
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.main}
                    />
                  ) : (
                    <Route key={index} path={route.path} exact={route.exact} component={route.main} />
                  )
                )}
              </Switch>
            </Layout>
          </div>
        </Router>
      </ErrorBoundary>
    );
  }

  getListRoute = (routes) => {
    let result = [];
    routes.map((route) => {
      result.push(route);
      if (route?.childs?.length > 0) {
        result = result.concat(route.childs);
      }
    });
    return result;
  };
}

const mapStateToProps = (state) => {
  return {
    loading: state.app.isDataServiceProcessing || false,
  };
};

export default compose(withTranslation("translations"), connect(mapStateToProps))(App);

// export default connect(mapStateToProps)(App);
