import React, { Component } from "react";
import Layout, { Header } from "antd/lib/layout/layout";
import { Button, message, Steps } from "antd";
import TopBar from "../../../components/topbar";

import ShippingInfoComponent from "./shipping-info/shipping-info.component";
import ProductInfoComponent from "./product-info/product-info.component";
import DeliveryComponent from "./delivery/delivery.component";
import PaymentMethodComponent from "./payment/payment.component";
import CreateOrderSummaryComponent from "../../../components/create-order-summary/create-order-summary.component";

import { DeliveryConstants, DeliveryTypeConstants } from "./../../../constants/delivery.constants";
import { createOrderTypes } from "./../../../constants/order-constant";
import orderDataService from "./../../../data-services/orders/orders-data.service";
import userDataService from "../../../data-services/user/user-data.service";

const { Step } = Steps;
export class CreateOrderScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      title: "",
      current: 0,
      productCategories: [],
      deliveryOptions: [],
      shippingServices: [],
      locations: [],
      paymentTypes: [],
      paymentByOptions: [],
      initialData: {
        shippingInfo: {
          senderInfo: {
            fullName: null,
            phoneNumber: null,
            address: {
              street: null,
              city: {
                id: null,
                name: null,
              },
              district: {
                id: null,
                name: null,
              },
              ward: {
                id: null,
                name: null,
              },
            },
          },
          receiverInfo: {
            fullName: null,
            phoneNumber: null,
            address: {
              street: null,
              city: {
                id: null,
                name: null,
              },
              district: {
                id: null,
                name: null,
              },
              ward: {
                id: null,
                name: null,
              },
            },
          },
        },
        productInfo: {
          totalProduduct: null,
          description: null,
          products: [],
        },
        deliveryInfo: {
          shippingService: {
            id: null,
            name: null,
          },
          deliveryOption: {
            id: null,
            name: null,
          },
          route: {
            id: null,
            name: null,
            code: null,
            provider: null,
          },
        },
        paymentInfo: {
          payBy: null,
          paymentType: null,
          isCod: null,
          codAmount: null,
        },
      },
    };

    this.shippingInfoForm = React.createRef();
    this.productInfoForm = React.createRef();
    this.deliveryInfoForm = React.createRef();
    this.paymentInfoForm = React.createRef();
  }

  async componentDidMount() {
    orderDataService.getInitData(DeliveryTypeConstants.DELIVERY_ENDUSER).then((res) => {
      var productCategories = res.packageCategory?.map((c) => {
        return {
          id: c.id,
          name: c.name,
          isNew: false,
          languageKey: c.languageKey
        };
      });

      this.setState(
        {
          ...this.state,
          productCategories: productCategories,
          deliveryOptions: res.deliveries,
          shippingServices: res.specialServices,
          locations: res.locations,
          paymentByOptions: res.paymentBy,
          paymentTypes: res.paymentTypes,
        },
        () => {
          userDataService.getUserInfoById(this.props.userId).then((res) => {
            this.getSenderInfo(res);
          });

          this.refresh();
          console.log(this.state);
        }
      );
    });
  }

  render() {
    const { t } = this.props;
    const { current } = this.state;
    const steps = this.createSteps();

    return (
      <Layout className="bg-content">
        <div className="bg-body">
          <TopBar title={t('createOrEditOrderPage.titleForCreate')} />
          <div className="bg-white clearfix beecow-leasing-contract">
            <Steps
              type="navigation"
              current={current}
              className="site-navigation-steps"
            >
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action float-right mr-5 mt-5">
              {current > 0 && (
                <a className="pr-5" onClick={this.prev.bind(this)}>
                  {t("button.back")}
                </a>
              )}
              {current < steps.length - 1 && (
                <Button type="primary" onClick={this.next.bind(this)}>
                  {t("button.next")}
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button type="primary" onClick={this.submit}>
                  {t("button.done")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  getSenderInfo = (res) => {
    var senderInfo = {
      fullName: res.fullName,
      phoneNumber: res.phone,
      address: res.address,
    };

    var { initialData } = this.state;
    var { shippingInfo } = initialData;
    shippingInfo.senderInfo = senderInfo;

    this.setState({
      initialData: {
        shippingInfo: shippingInfo,
      },
    });

    this.shippingInfoForm.current.setFieldsValue(shippingInfo);
  };

  createSteps = () => {
    const { t } = this.props;
    var {
      initialData,
      deliveryOptions,
      shippingServices,
      productCategories,
      paymentByOptions,
      locations,
      paymentTypes,
    } = this.state;
    var { shippingInfo, productInfo, deliveryInfo, paymentInfo } = initialData;
    const steps = [
      {
        title: t("createOrEditOrderPage.steps.shippingInformation"),
        content: (
          <ShippingInfoComponent
            t={t}
            ref={this.shippingInfoForm}
            initialData={shippingInfo}
          />
        ),
      },
      {
        title: t("createOrEditOrderPage.steps.productInformation"),
        content: (
          <ProductInfoComponent
            t={t}
            ref={this.productInfoForm}
            initialData={productInfo}
            productCategories={productCategories}
          />
        ),
      },
      {
        title: t("createOrEditOrderPage.steps.deliveryMethod"),
        content: (
          <DeliveryComponent
            t={t}
            ref={this.deliveryInfoForm}
            initialData={deliveryInfo}
            deliveryOptions={deliveryOptions}
            shippingServices={shippingServices}
            shippingInfo={shippingInfo}
            productInfo={productInfo}
            locations={locations}
          />
        ),
      },
      {
        title: t("createOrEditOrderPage.steps.payment"),
        content: (
          <PaymentMethodComponent
            t={t}
            ref={this.paymentInfoForm}
            initialData={paymentInfo}
            paymentByOptions={paymentByOptions}
            paymentTypes={paymentTypes}
            deliveryInfo={deliveryInfo}
            shippingInfo={shippingInfo}
          />
        ),
      },
      {
        title: t("createOrEditOrderPage.steps.summarize"),
        content: (
          <CreateOrderSummaryComponent
            t={t}
            shippingInfo={shippingInfo}
            productInfo={productInfo}
            deliveryInfo={deliveryInfo}
            paymentInfo={paymentInfo}
            deliveryOptions={deliveryOptions}
            shippingServices={shippingServices}
            productCategories={productCategories}
            isOrderShipToWarehouse={false}
          />
        ),
      },
    ];

    return steps;
  };

  next() {
    const { t } = this.props;
    var currentFormRef;
    switch (this.state.current) {
      case 0:
        currentFormRef = this.shippingInfoForm;
        this.setState({
          ...this.state,
          initialData: {
            ...this.state.initialData,
            shippingInfo: currentFormRef.current.getFieldsValue(),
          },
        });
        break;
      case 1:
        currentFormRef = this.productInfoForm;
        this.setState({
          ...this.state,
          initialData: {
            ...this.state.initialData,
            productInfo: currentFormRef.current.getFieldsValue(),
          },
        });
        break;
      case 2:
        currentFormRef = this.deliveryInfoForm;
        this.setState({
          ...this.state,
          initialData: {
            ...this.state.initialData,
            deliveryInfo: currentFormRef.current.getFieldsValue(),
          },
        });
        break;
      case 3:
        currentFormRef = this.paymentInfoForm;
        this.setState({
          ...this.state,
          initialData: {
            ...this.state.initialData,
            paymentInfo: currentFormRef.current.getFieldsValue(),
          },
        });
        break;
      case 4:
        break;
      default:
        break;
    }

    if (currentFormRef && currentFormRef.current) {
      currentFormRef.current
        .validateFields()
        .then(() => {
          if (
            this.state.current == 1 &&
            currentFormRef.current.props.initialData?.products?.length == 0
          ) {
            message.warning(t('createOrEditOrderPage.pleaseSelectProductItem'));
            return;
          }
          let nextStep = this.state.current + 1;
          if (nextStep < 5) {
            this.setState({ current: nextStep }, () => {
              this.refresh();
            });
          }
        })
        .catch((ex) => console.log(ex));
    }
  }

  prev() {
    this.setState(
      {
        current: this.state.current - 1,
      },
      () => {
        this.refresh();
      }
    );
  }

  refresh() {
    switch (this.state.current) {
      case 0:
        this.shippingInfoForm.current.setFieldsValue(
          this.state.initialData.shippingInfo
        );
        break;
      case 1:
        this.productInfoForm.current.setFieldsValue(
          this.state.initialData.productInfo
        );
        break;
      case 2:
        this.deliveryInfoForm.current.setFieldsValue(
          this.state.initialData.deliveryInfo
        );
        break;
      case 3:
        this.paymentInfoForm.current.setFieldsValue(
          this.state.initialData.deliveryInfo
        );
        break;
      default:
        break;
    }

  }

  submit = () => {
    const { t } = this.props;
    var { initialData } = this.state;
    let toStationId = initialData?.deliveryInfo?.toStationId;
    var createOrderRequest = {
      senderInfo: initialData.shippingInfo.senderInfo,
      receiverInfo: initialData.shippingInfo.receiverInfo,
      productInfo: initialData.productInfo,
      deliveryInfo: {
        shippingServiceId: initialData.deliveryInfo.shippingService.id,
        deliveryTypeId: initialData.deliveryInfo.deliveryOption.id,
        route: {
          routeId: initialData.deliveryInfo.route.id,
          companyId: initialData.deliveryInfo.route.company?.id,
          shippingFeeDetails: initialData.deliveryInfo.route.shippingFeeDetails,
        },
      },
      paymentInfo: {
        payById: initialData.paymentInfo.payBy.id,
        paymentTypeId: initialData.paymentInfo.paymentType.id,
        isCod: initialData.paymentInfo.isCod,
        codAmount: initialData.paymentInfo.codAmount,
      },
      createOrderType: createOrderTypes.END_USER,
      toStationId: toStationId,
    };

    orderDataService.createOrder(createOrderRequest).then((res) => {
      if (res.success) {
        message.success(t('createOrEditOrderPage.orderHasBeenCreatedSuccessfully'));
        this.props.history.push(`/detail-order/${res.data?.id}`);
      } else {
        message.error(t('createOrEditOrderPage.orderCreateRequestFailed'));
      }
    });
  };
}
