const actionTypes = {
    GET_INIT_DATA: 'GET_INIT_DATA',
    GET_STATIONS: 'GET_STATIONS',
    GET_ROUTES: 'GET_ROUTES',
    CLEAR_ORDER_DATA: 'CLEAR_ORDER_DATA',
    GET_STATIONS_FOR_LEASE: 'GET_STATIONS_FOR_LEASE',
    GET_LEASING_FEE_CALCULATOR: 'GET_LEASING_FEE_CALCULATOR',
    SET_CONTRACT_RENEW_ID: 'SET_CONTRACT_RENEW_ID',
    CLEAR_ROUTES: 'CLEAR_ROUTES'
}

export default actionTypes;