import React, { Component } from 'react';
import { Button, message, Steps } from "antd";
import Layout from 'antd/lib/layout/layout';
import TopBar from '../../../components/topbar';
import { BookingInfo } from '../../../components/steps-add-more-products/booking-info/booking-info.component';
import ProductInfo from '../../../components/steps-add-more-products/product-info/product-info.component';
import { LeasingDelivery } from '../../../components/steps-add-more-products/delivery/delivery.component';
import { PaymentMethod } from '../../../components/steps-add-more-products/payment-method/payment-method.component';
import { Summary } from '../../../components/steps-add-more-products/summary/summary.component';
import leasingContractDataService from '../../../data-services/leasing-contract/leasing-contract-data.service';
import "./add-more-products.scss";

import contractDataService from '../../../data-services/contracts/contracts-data.service';

const { Step } = Steps;

export class AddMoreProductsScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Add More Product",
            contractID: props.match?.params?.id,
            contractCode: '',
            stationId: '',
            districts: null,
            wards: null,
            current: 0,
            listProducts: [],
            description: "",
            initialData: null,
            productCategories: [],
            specialServices: [],
            deliveryOptions: [],
            paymentMethods: [],
        }
        this.bookingFormRef = React.createRef();
        this.productFormRef = React.createRef();
        this.deliveryFormRef = React.createRef();
        this.paymentFormRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        let { current, contractCode, initialData, productCategories, specialServices, deliveryOptions, paymentMethods, districts, listProducts, description, title } = this.state;

        if (initialData === null) {
            return <></>;
        } else {
            let { bookingInfo, productInfo, deliveryInfo, warehouseInfo, paymentInfo } = initialData;
            let steps = [
                {
                    title: t('addMoreProductsPage.steps.bookingAndSenderInfo'),
                    content: <BookingInfo
                        t={t}
                        ref={this.bookingFormRef}
                        initialData={bookingInfo}
                        listDistrict={districts} />,
                },
                {
                    title: t('addMoreProductsPage.steps.productInfo'),
                    content: <ProductInfo
                        t={t}
                        ref={this.productFormRef}
                        initialData={productInfo}
                        categories={productCategories}
                        listProducts={listProducts}
                        description={description} />,
                },
                {
                    title: t('addMoreProductsPage.steps.deliveryMethod'),
                    content: (
                        <LeasingDelivery
                            t={t}
                            ref={this.deliveryFormRef}
                            initialData={deliveryInfo}
                            specialServices={specialServices}
                            deliveryOptions={deliveryOptions}
                            bookingInfo={bookingInfo}
                            products={productInfo?.products}
                            warehouseInfo={warehouseInfo} />
                    ),
                },
                {
                    title: t('addMoreProductsPage.steps.payment'),
                    content: (
                        <PaymentMethod t={t} ref={this.paymentFormRef}
                            initialData={paymentInfo}
                            paymentMethods={paymentMethods}
                            bookingInfo={bookingInfo}
                            deliveryInfo={deliveryInfo}
                            warehouseInfo={warehouseInfo} />
                    ),
                },
                {
                    title: t('addMoreProductsPage.steps.summarize'),
                    content: (
                        <Summary
                            t={t}
                            bookingInfo={bookingInfo}
                            productInfo={productInfo}
                            deliveryInfo={deliveryInfo}
                            paymentInfo={paymentInfo}
                            categories={productCategories}
                            specialServices={specialServices}
                            deliveryOptions={deliveryOptions}
                            paymentMethods={paymentMethods} />
                    ),
                },
            ];
            return (
                <Layout className='bg-content'>
                    <div className='bg-body'>
                        <TopBar title={t('addMoreProductsPage.title')} />
                        <div className="bg-white clearfix beecow-add-more-products">
                            <Steps
                                type="navigation"
                                current={current}
                                className="site-navigation-steps"
                            >
                                {steps.map(item => (
                                    <Step key={item.title} title={item.title} />
                                ))}
                            </Steps>
                            <div className="lbl-contract-id">
                                <label>{t('addMoreProductsPage.contractId')}: {contractCode}</label>
                            </div>
                            <div className="steps-content">{steps[current].content}</div>
                            <div className="steps-action">
                                {current > 0 && (
                                    <Button type="primary" onClick={this.prev.bind(this)}>{t('button.back')}</Button>
                                )}
                                {current < steps.length - 1 && (
                                    <Button type="primary" onClick={this.next.bind(this)}>{t('button.next')}</Button>
                                )}
                                {current === steps.length - 1 && (
                                    <Button type="primary" onClick={this.handleFinish.bind(this)}>{t('button.done')}</Button>
                                )}
                            </div>
                        </div>
                    </div>
                </Layout>
            )
        }
    }

    async componentWillMount() {
        const { contractID } = this.state
        let getContractInfoTask = contractDataService.getContractById(contractID);
        let getInitialDataTask = leasingContractDataService.getInitData();
        let [contractInfo, initialData] = await Promise.all([getContractInfoTask, getInitialDataTask]);

        this.setState({ contractCode: contractInfo.data?.contractCode, stationId: contractInfo.data?.stationId });
        let senderInfo = { ...contractInfo?.data.bookingInfo.sender, ...contractInfo };

        if (!initialData) {
            return;
        }

        let description = contractInfo?.data?.productInfo?.description;
        let productList = contractInfo?.data?.productInfo?.products;
        this.setState({
            ...this.state,
            listProducts: productList,
            description: description,
            initialData: {
                productInfo: {
                    description: contractInfo?.data?.productInfo?.description,
                    products: contractInfo?.data?.productInfo?.products,
                },
                bookingInfo: { ...contractInfo?.data.bookingInfo, sender: senderInfo },

                paymentInfo: {
                    ...contractInfo?.data?.paymentInfo,
                    paymentMethodId: initialData?.paymentTypes[0]?.id
                },
                deliveryInfo: { ...contractInfo?.data?.deliveryInfo, warehouseId: contractInfo?.data?.deliveryInfo?.warehouseId, toStation: contractInfo?.data?.deliveryInfo?.arrivalStationId, station: contractInfo.data?.station },
                warehouseInfo: { ...contractInfo?.data?.warehouseInfo, selectedWarehouse: contractInfo.data?.spaces },
            },
            productCategories: initialData.packageCategory,
            specialServices: initialData.specialServices,
            deliveryOptions: contractInfo.data.deliveryOptions.delivery,
            paymentMethods: initialData.paymentTypes,
        }, () => {
            this.refresh();
        });
    }

    next() {
        const { t } = this.props;
        let currentFormRef;
        switch (this.state.current) {
            case 0:
                currentFormRef = this.bookingFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, bookingInfo: currentFormRef.current.getFieldsValue() } })
                break;
            case 1:
                currentFormRef = this.productFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, productInfo: currentFormRef.current.getFieldsValue() } })
                break;
            case 2:
                currentFormRef = this.deliveryFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, deliveryInfo: currentFormRef.current.getFieldsValue() } })
                break;
            case 3:
                currentFormRef = this.paymentFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, paymentInfo: currentFormRef.current.getFieldsValue() } })
                break;
            default:
                break;
        }
        if (currentFormRef && currentFormRef.current) {
            currentFormRef.current.validateFields()
                .then(() => {
                    if (this.state.current === 1 && currentFormRef.current.props.initialData?.products?.length === 0) {
                        message.warning(t('addMoreProductsPage.pleaseSelectProductItem'));
                        return;
                    }
                    let nextStep = this.state.current + 1;
                    if (nextStep < 5) {
                        this.setState({ current: nextStep });
                        this.refresh();
                    }
                })
                .catch(ex => console.log(ex));
        }
    }

    prev() {
        this.setState({
            current: this.state.current - 1
        }, () => {
            this.refresh();
        });
    }

    refresh() {
        switch (this.state.current) {
            case 0:
                this.bookingFormRef.current.setFieldsValue(this.state.initialData.bookingInfo);
                break;
            case 1:
                this.productFormRef.current.setFieldsValue(this.state.initialData.productInfo);
                break;
            case 2:
                this.deliveryFormRef.current.setFieldsValue(this.state.initialData.deliveryInfo);
                break;
            case 3:
                this.paymentFormRef.current.setFieldsValue(this.state.initialData.paymentInfo);
                break;
            default:
                break;
        }
    }

    handleFinish() {
        const { t } = this.props;
        let { contractID, initialData, deliveryOptions } = this.state;
        initialData.contractId = contractID;
        let deliveryOption = deliveryOptions.find(d => d.id === initialData.deliveryInfo.deliveryOptionId);
        initialData.deliveryInfo = {...initialData.deliveryInfo, deliveryTypeId: deliveryOption?.deliveryTypeId}
        contractDataService.addMoreProducts(initialData)
            .then(res => {
                this.props.history?.goBack();
            })
            .catch((err) => {
                console.log(err);
                message.error(t('addMoreProductsPage.failedToAddMoreProducts'));
            });
    }
}