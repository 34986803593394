import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Input, Button, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router'
import userDataService from '../../data-services/user/user-data.service';
import jwt_decode from 'jwt-decode';
import TopBar from './../../components/topbar';
import Layout, { Content } from 'antd/lib/layout/layout';

const CLAIMS_SID = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid';
const CLAIMS_NAME = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name';
const CLAIMS_EMAIL = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress';
const CLAIMS_ROLE = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
const CLAIMS_CURRENT_STATION_ID = 'currentStationId';
const CLAIMS_COMPANY_IDENTITY = 'COMPANY_IDENTITY';
const CLAIMS_CHANGED_PASSWORD = 'CHANGED_PASSWORD';

export class ResetPassWordScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userId: props?.match?.params?.userId,
            userCurrent: {},
        }
    }

    componentDidMount() {
        userDataService.getUserId(this.state.userId).then((data) => {
            this.setState({
                userCurrent: data
            });
        })
    }

    render() {
        var { t } = this.props;
        if (this.props?.changedPassWord) {
            return <Redirect to='/home' />;
        }
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    {(this.props.isAuthenticated) && (<TopBar />)}


                    <Form
                        name="normal_login"
                        className="login-form col-2 m-auto centered"
                        initialValues={{ rememberMe: true }}
                        onFinish={this.onFinish}
                    >
                        <div className="mb-5 text-center"><h1>{t('menuBar.resetPassWord')}</h1></div>

                        {this.state.userCurrent && (<p><b>{t('topBar.hi')} {this.state.userCurrent?.email}</b></p>)}

                        <Form.Item
                            name="oldPassWord"
                            rules={[{ required: true, message: 'Please input your old password!' }
                            ]}
                        >

                            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Old Password" />
                        </Form.Item>
                        <Form.Item
                            name="newPassWord"
                            rules={[{ required: true, message: 'Please input your new password!' },
                            { min: 8, message: `${t('message.passwordMini')}` }]}
                        >
                            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="New Password" />
                        </Form.Item>
                        <Form.Item
                            name="confirmNewPassWord"
                            rules={[{ required: true, message: 'Please input your confirm new password!' },
                            { min: 8, message: `${t('message.passwordMini')}` }]}
                        >
                            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Confirm Password" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button col-12">
                                {t('button.reset')}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Layout>
        )
    }

    onFinish = (values) => {
        const { t } = this.props;
        if (values.newPassWord != values.confirmNewPassWord) {
            message.error(`${t('message.alertNewPassword')}`);
            return;
        }
        let self = this;
        let request =
        {
            Id: self.state.userId,
            NewPassWord: values?.newPassWord,
            OldPassWord: values?.oldPassWord
        }
        userDataService.resetPasswordByUser(request)
            .then((data) => {

                if (data.success) {
                    let token = data.token || '';
                    let refreshToken = data.refreshToken || '';
                    let exprire = data.exprire;
                    let userInfo = self.getUserInfo(token);
                    let workspaceId = self.getWorkspaceId(token);
                    self.setupWorkspace(token, refreshToken, exprire, userInfo, workspaceId);
                    this.setState({ ...self.state, userCurrent: { ...self.state.userCurrent, changedPassWord: true } });
                }
                else {
                    message.error(data.message);
                }

            })
            .catch((err) => {
                message.error(`${t('message.resetFail')}`);
            });

    };
    getUserInfo(token) {
        let claims = jwt_decode(token);
        let user = {
            'userid': claims[CLAIMS_SID],
            'username': claims[CLAIMS_NAME],
            'emailaddress': claims[CLAIMS_EMAIL],
            'role': claims[CLAIMS_ROLE],
            'currentStationId': claims[CLAIMS_CURRENT_STATION_ID],
            'changedPassWord': claims[CLAIMS_CHANGED_PASSWORD]

        };
        return user;
    }

    getWorkspaceId(token) {
        let claims = jwt_decode(token);
        return claims[CLAIMS_COMPANY_IDENTITY];
    }

    setupWorkspace(token, refreshToken, exprire, userInfo, workspaceId) {
        const { t } = this.props;
        if (userInfo.role == "USER") {
            let auth = { token: token, refreshToken: refreshToken, exprire: exprire, user: userInfo };
            this.props.setAuth(auth);
            this.props.setWorkspace(workspaceId);
            message.success(`${t('loginPage.youHaveBeenLoggedInSuccessfully')}`);
            this.setState({ isLoggedIn: true });
        } else {
            message.error(`${t('loginPage.thisPageOnlyAllowsToAcceptByUser')}`);
        }
    }

}