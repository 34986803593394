import { withRouter } from "react-router";
import {ViewOrderHistoryScreen} from "./view-order-history.screen";

import { compose } from "redux";
import { withTranslation } from "react-i18next";

export default compose(
    withTranslation("translations"),
    withRouter
)(ViewOrderHistoryScreen);
