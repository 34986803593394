import ViewDetailScreen from "./view-detail-order.screen";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOrderDetails, getInitData, clearData } from "../../modules/orders/orders.actions"
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { resetSession } from "../../modules/session/session.actions";

const mapStateToProps = ({ app, order, session }) => {
    return {
        order: order.orderDetails || {},
        initData: order.create.initData || {},
        amount: order.create?.amount || 1,
        deliverySelected: order.create?.deliverySelected,
        currentUserId: session?.auth?.user?.userid,
        justLoggedIn: app?.justLoggedIn
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getOrderDetails,
        getInitData,
        clearData,
        resetSession
    }, dispatch)
}

export default compose(
    withTranslation("translations"),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(ViewDetailScreen);
