
import { withRouter } from 'react-router-dom';
import ViewImportFromVehicleDetailScreen from './view-detail.screen';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';


export default compose(
    withTranslation('translations'),
    withRouter
)(ViewImportFromVehicleDetailScreen)
