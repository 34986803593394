import React, { Component } from 'react';
import { Form, Button, Select, Input } from "antd";
import './product-table.scss'

export default class ProductTableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productsAdded: [],
            listProducts: props.listProducts,
            listProductExcluded: props.listProducts,
            products: props.initialData,
            productCategories: props.productCategories,
        }
    }

    componentDidMount() {
    }

    render() {
        const { t } = this.props;
        return (
            <table className="table table-borderless product-table ml-0" scroll={{ y: 240 }}>
                <thead>
                    <tr>
                        <th className="action-button-area"></th>
                        <th>{t('createOrEditOrderPage.productForm.productId')}</th>
                        <th className="table-product-name">{t('createOrEditOrderPage.productForm.productName')}</th>
                        <th className="table-product-category">{t('createOrEditOrderPage.productForm.productCategory')}</th>
                        <th className="table-product-size">{t('createOrEditOrderPage.productForm.length')}</th>
                        <th className="table-product-size">{t('createOrEditOrderPage.productForm.width')}</th>
                        <th className="table-product-size">{t('createOrEditOrderPage.productForm.height')}</th>
                        <th className="table-product-size">{t('createOrEditOrderPage.productForm.weight')}</th>
                        <th className="table-product-size">{t('createOrEditOrderPage.productForm.currentInventory')}</th>
                        <th style={{width: "160px"}}>{t('createOrEditOrderPage.productForm.quantityToSell')}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderListProduct()}
                </tbody>
            </table>
        )
    }

    onAdd = (callBack, params) => {
        if (params && params >= 0) {
            callBack(null, params);
        }
        else {
            callBack();
        }

        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    onRemove = (callBack, params) => {
        callBack(params);
        if (this.props.onChange) {
            this.props.onChange();
        }

        this.onUpdateListExcluded(params);
    }

    renderListProduct = () => {
        const { t } = this.props;
        const { products, productCategories, listProductExcluded } = this.state;
        return <>
            <Form.List name="products" initialValue={products}>
                {(fields, { add, remove }) => {
                    return (
                        fields.length == 0 ? (
                            <tr>
                                <td>
                                    <Button type="text" onClick={() => this.onAdd(add, [])}><i className="fa fa-plus text-success" aria-hidden="true"></i></Button>
                                </td>
                            </tr>
                        ) :
                            fields.map((field, index) => (
                                <tr key={field.key}>
                                    <td style={{ display: 'inline-flex' }}>
                                        <Button type="text" onClick={() => this.onAdd(add, index + 1)}><i className="fa fa-plus text-success" aria-hidden="true" ></i></Button>
                                        <Button type="text" onClick={() => this.onRemove(remove, field.name)}><i className="fa fa-close text-danger" aria-hidden="true" ></i></Button>
                                    </td>
                                    <td className="mt-0 table-product-code pl-0">
                                        <Form.Item
                                            className="mt-0"
                                            name={[index, "code"]}
                                        >
                                            <Input disabled={true} />
                                        </Form.Item>
                                    </td>
                                    <td className="mt-0">
                                        <Form.Item
                                            className="mt-0"
                                            name={[index, "name"]}
                                            rules={[{ required: true , message: t('createOrEditOrderPage.productForm.pleaseInputProductName')}]}
                                        >
                                            <Select placeholder={t('createOrEditOrderPage.productForm.productName')}
                                                onChange={(e) => this.onChangeProduct(e, index)}
                                            >
                                                <Select.Option style={{ cursor: "default"}} value="disabled" disabled>
                                                    <span className="float-left">Product Name</span>
                                                    <span className="float-right">Inventory</span>
                                                </Select.Option>

                                                {listProductExcluded?.map(({ id, name, inWarehouse }) =>
                                                <Select.Option key={id} value={id}>
                                                        <span className="float-left">{name}</span>
                                                        <span className="float-right">{inWarehouse}</span>
                                                </Select.Option>)}

                                            </Select>
                                        </Form.Item>
                                    </td>
                                    <td className="mt-0">
                                        <Form.Item
                                            name={[index, "categoryId"]}
                                        >
                                            <Select disabled={true} placeholder={t('createOrEditOrderPage.productForm.selectCategory')}>
                                                {productCategories.map(({ id, name }) => <Select.Option key={id} value={id}>{name}</Select.Option>)}
                                            </Select>
                                        </Form.Item>
                                    </td>
                                    <td className="pr-0">
                                        <Form.Item
                                            name={[index, "length"]}
                                        >
                                            <Input disabled={true} />
                                        </Form.Item>
                                    </td>
                                    <td className="pr-0">
                                        <Form.Item
                                            name={[index, "width"]}
                                        >
                                            <Input disabled={true} />
                                        </Form.Item>
                                    </td>
                                    <td className="pr-0">
                                        <Form.Item
                                            name={[index, "height"]}
                                        >
                                            <Input disabled={true} />
                                        </Form.Item>
                                    </td>
                                    <td className="pr-0">
                                        <Form.Item
                                            name={[index, "weight"]}
                                        >
                                            <Input disabled={true} />
                                        </Form.Item>
                                    </td>
                                    <td className="pr-0">
                                        <Form.Item
                                            name={[index, "inWarehouse"]}
                                        >
                                            <Input disabled={true} />
                                        </Form.Item>
                                    </td>
                                    <td className="pr-0">
                                        <Form.Item
                                            hasFeedback
                                            className="mr-3"
                                            name={[index, "quantityToSell"]}
                                            rules={[
                                                ({ getFieldValue }) => ({
                                                    validator(_, quantity) {
                                                        let availableAmount = parseInt(getFieldValue(["products", index, "inWarehouse"]))

                                                        if (availableAmount === 0) {
                                                            return Promise.reject(new Error(`${t('productDetailPage.placeOrderPage.form.productInfo.amountInventoryNotEnough')}`));
                                                        } else
                                                        if (isNaN(quantity)) {
                                                            return Promise.reject(new Error(`${t('productDetailPage.placeOrderPage.form.productInfo.pleaseInputQuantityToSell')}`));
                                                        }

                                                        if (parseInt(quantity) <= availableAmount && parseInt(quantity) > 0) {
                                                            return Promise.resolve();
                                                        }

                                                        return Promise.reject(new Error(`${t('productDetailPage.placeOrderPage.form.productInfo.pleaseEnterAmount')} - ${availableAmount}`));
                                                    },
                                                }),
                                            ]} >

                                            <Input
                                                onChange={this.onChangeAmount}
                                                placeholder={t('createOrEditOrderPage.productForm.quantityToSell')}
                                                type="number"
                                                min="1"
                                                onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                                            />
                                        </Form.Item>

                                    </td>
                                </tr>
                            ))
                    );
                }}
            </Form.List>
        </>
    }

    onChangeProduct = (id, index) => {
        var { listProducts } = this.state;
        var product = listProducts?.find((x) => x.id === id);
        this.onUpdateListExcluded(index, product);
        this.props.onChangeProduct(product, index);
    }

    onUpdateListExcluded = (index, product) => {
        var { listProducts, productsAdded, listProductExcluded } = this.state;

        if (product) {
            productsAdded.splice(index, 1, product);
        } else {
            productsAdded.splice(index, 1);
        }

        listProductExcluded = listProducts.filter((p) => !productsAdded.some(selected => selected.code === p.code));
        this.setState({
            ...this.state,
            productsAdded: productsAdded,
            listProductExcluded: listProductExcluded
        });
    }


    onChangeAmount = () => {
        if (this.props.onChange) {
            this.props.onChange();
        }
    }
}
