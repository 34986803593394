import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import PersonalWarehouseScreen from "./personal-warehouse.screen";
import { compose } from "redux";
import { withTranslation } from "react-i18next";


const mapStateToProps = (state) => ({
  userId: state.session?.auth?.user?.userid
})

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, null),
  withRouter
)(PersonalWarehouseScreen);
