import { Steps, message } from 'antd';
import Button from 'antd-button-color';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Layout from 'antd/lib/layout/layout';
import TopBar from '../../components/topbar';
import Summarize from './summarize/summarize.component';
import WarehouseInfo from './warehouse-info/warehouse-info.component';
import DefineAreaSpace from './define-area-space/define-area-space.component';
import warehouseDataService from '../../data-services/warehouse/warehouse-data.service';
const { Step } = Steps;
const screens = {
    warehouseInfo: 0,
    defineAreaSpace: 1,
    summarize: 2,
}

export default class WarehouseRegistrationScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            current: screens.warehouseInfo,
            warehouseCategories: [],
            warehouseLocations: [],
            initialData: {},
            firstRequest: true,
            showRegistrationPage: false,
            warehouseId: null
        }
        this.warehouseInfoRef = React.createRef();
        this.defineAreaSpaceRef = React.createRef();
        this.summarizeRef = React.createRef();
    }

    async componentDidMount() {
        var warehouseCategories = await warehouseDataService.getWarehouseCategories();
        var warehouseLocations = await warehouseDataService.getWarehouseLocations();
        if (warehouseCategories && warehouseLocations) {
            this.setState({
                warehouseCategories: warehouseCategories ?? [],
                warehouseLocations: warehouseLocations ?? [],
            });
        }
    }

    componentWillMount() {
        const { userId } = this.props;
        if (userId) {
            // this http request to get data.
            warehouseDataService.getListOfWarehouseByUserId(userId).then((res) => {
                let showRegistrationPage = false;
                if (res.redirectToWarehouseRegistration) {
                    showRegistrationPage = true;
                }
                this.setState({ firstRequest: false, showRegistrationPage, warehouseId: res.warehouseId });
            });
        }
    }

    render() {
        const { t } = this.props;
        const { current, warehouseCategories, warehouseLocations, initialData, firstRequest, showRegistrationPage, warehouseId } = this.state;
        if (firstRequest === false) {
            if (showRegistrationPage === false) {
                return <Redirect to={`/personal-warehouse/${warehouseId}`} />
            }
        }
        const steps = [
            {
                title: t('warehouseRegistration.warehouseInfo'),
                content: <>
                    <div className='col-lg-5 col-md-6'>
                        <WarehouseInfo
                            t={t}
                            ref={this.warehouseInfoRef}
                            locations={warehouseLocations}
                            initialData={initialData?.warehouse}
                        />
                    </div>
                </>,
            },
            {
                title: t('warehouseRegistration.defineAreaSpace'),
                content: <DefineAreaSpace
                    t={t}
                    ref={this.defineAreaSpaceRef}
                    categories={warehouseCategories}
                    initialData={this.state.initialData?.areas}
                />,
            },
            {
                title: t('warehouseRegistration.summarize'),
                content: <Summarize
                    t={t}
                    ref={this.summarizeRef}
                    initialData={initialData}
                    categories={warehouseCategories}
                />,
            },
        ];

        return <>
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t('title.warehouseRegistration')} />
                    <div className='bg-white clearfix beecow-leasing-contract'>
                        <Steps
                            type='navigation'
                            current={current}
                            className='site-navigation-steps'>
                            {steps.map((item) => { return <Step key={item.title} title={item.title} /> })}
                        </Steps>
                        <div className='steps-content'>{steps[current].content}</div>
                        <div className='steps-action clearfix'>
                            {current > 0 && (<Button type='primary' onClick={this.prev}>{t('button.back')}</Button>)}
                            {current < (steps.length - 1) && (<Button type='primary' onClick={this.next}>{t('button.next')}</Button>)}
                            {current === (steps.length - 1) && (<Button type='primary' onClick={this.finish}>{t('button.done')}</Button>)}
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    }

    next = () => {
        let { initialData, current } = this.state;
        let currentFormRef;
        switch (this.state.current) {
            case screens.warehouseInfo:
                currentFormRef = this.warehouseInfoRef;
                this.setState({
                    ...this.state,
                    initialData: {
                        ...initialData,
                        warehouse: currentFormRef.current.getFieldsValue()
                    }
                });
                break;
            case screens.defineAreaSpace:
                currentFormRef = this.defineAreaSpaceRef;
                this.setState({
                    ...this.state,
                    initialData: {
                        ...initialData,
                        areas: currentFormRef.current.getFieldsValue()
                    }
                });
                break;
            default:
                break;
        }

        if (currentFormRef && currentFormRef.current) {
            currentFormRef.current.validateFields()
                .then(() => {
                    let nextStep = current + 1;
                    this.setState({ current: nextStep });
                })
                .catch(ex => console.log(ex));
        }
    }

    prev = () => {
        let { current } = this.state;
        let preStep = current - 1 <= 0 ? 0 : current - 1;
        this.setState({ current: preStep });
    }

    finish = () => {
        const { t } = this.props;
        const { initialData } = this.state;
        let request = {
            warehouse: initialData?.warehouse,
            areas: initialData?.areas
        }
        warehouseDataService.warehouseRegistration(request).then(res => {
            if (res === true) {
                message.success(t('warehouseRegistration.createSuccessMessage'));
                //redirect to home page
                this.props.history.push('/home');
            }
            else {
                message.error('warehouseRegistration.createErrorMessage');
            }
            console.log(res);
        });
    }
}