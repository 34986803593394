import React, { Component } from "react";
import warehouseDataServices from "./../../data-services/warehouse/warehouse-data.service";
import { Redirect } from 'react-router';

export default class PersonalWarehouseScreen extends Component {
  constructor(props) {
      super(props);
    // This line init values.
    this.state = {
      firstRequest: true,
      showRegistrationPage: false,
      warehouseId: null
    };
  }

  componentWillMount() {
    const { userId } = this.props;
    if (userId) {
      // this http request to get data.
      warehouseDataServices.getListOfWarehouseByUserId(userId).then((res) => {
        let showRegistrationPage = false;
        if (res.redirectToWarehouseRegistration) {
          showRegistrationPage = true;
        }
        this.setState({ firstRequest: false, showRegistrationPage, warehouseId: res.warehouseId });
      });
    }
  }

  render() {
    const { firstRequest, showRegistrationPage, warehouseId } = this.state;
    if (firstRequest === false) {
      if (showRegistrationPage) {
        return <Redirect to="/warehouse-registration"/>;
      } else {
        return  <Redirect to={`/personal-warehouse/${warehouseId}`}/>;
      }
    } else return <></>;
  }
}
