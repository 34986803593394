import { Col, Input, Row, Form, Image } from 'antd';
import React, { Component } from 'react';
import moment from "moment";
import { formatAmount } from '../../../utils/order';
import { TEXT_VND } from '../../../constants/common';
import contractDataService from '../../../data-services/contracts/contracts-data.service';
import {images} from '../../../constants/imagesConstant';
class ContentExtendContractComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      month: '',
      rentalPrice: 0
    };
    this.formRef = React.createRef()
  }

  componentDidMount() {
    const { id } = this.props?.match?.params || {}
    this.props.getExtendContract(id)
    if (this.props.months) this.initValues(this.props.months)
  }

  onChangeMonth(e) {
    const month = e.target.value
    this.setState({ month })
    if (month) {
      this.calcRenewFee(month)
    } else {
      this.setState({ rentalPrice: 0 })
    }
  }

  calcRenewFee(months) {
    const data = {
      contractId: this.props.extendContract?.id,
      months
    }
    contractDataService.calcRenewFee(data).then(({ rentalPrice }) => this.setState({ rentalPrice }))
  }

  initValues(months) {
    this.setState({ month:  months})
    this.formRef.current?.setFieldsValue({ months })
    this.calcRenewFee(months)
  }

  render() {
    const { month, rentalPrice } = this.state
    const { extendContract, t } = this.props
    
    const currentMonth = moment.utc(extendContract.expiryDate).local().format('DD/MM/YYYY')
    const addMonth = moment(extendContract.expiryDate).add(month, 'M').local().format('DD/MM/YYYY')
    const oldPrice = formatAmount(extendContract.priceLeasing) + TEXT_VND
    const priceExtend = formatAmount(rentalPrice) + TEXT_VND
    return (
      <div className="extend-page">
        <Form ref={this.formRef}>
          <br />
          <Row>
            <Col span={11} offset={1}>
              <h3>{t('extendContractPage.form.extendContract.wareHouseInformation')}</h3>
              <div className="warehouse-info">
                <Row>
                  <Col span={6} offset={2}>
                    <p className="content-left">{t('extendContractPage.form.extendContract.area')}: </p>
                    <p className="content-left ">{t('extendContractPage.form.extendContract.provider')}:</p>
                    <p className="content-left clearfix">{t('extendContractPage.form.extendContract.stationId')}:</p>
                    <p className="content-left clearfix">{t('extendContractPage.form.extendContract.stationName')}:</p>
                    <p className="clearfix">{t('extendContractPage.form.extendContract.stationAddress')}: </p>
                  </Col>
                  <Col span={10}>
                    <p>{t(extendContract?.area)}</p>
                    <p className="name-company">{extendContract?.station?.company?.name} <span><Image src={extendContract?.station?.company != null ? extendContract?.station?.company.logoPath : images.warehouseBuilding} style={{ height: 30, width: 40 }} /></span> </p>
                    <p className="clearfix">{extendContract.station?.code}</p>
                    <p className="clearfix">{extendContract.station?.name}</p>
                    <p className="clearfix">{extendContract?.station?.address?.street}, {extendContract?.station?.address?.ward?.name}, {extendContract?.station?.address?.district?.name},{extendContract?.station?.address?.city?.name}</p>
                  </Col>
                </Row>

              </div>
              <br />
              <h3>{t('extendContractPage.form.extendContract.howManyMonthsYouWantToExtend')}</h3>
              <Row>
                <Col span={5} offset={1}>
                  <Form.Item name="months" rules={[{ required: true, message: t('extendContractPage.form.extendContract.pleaseInputMonths') }]} >
                    <Input type="number" min={0} onChange={this.onChangeMonth.bind(this)} />
                  </Form.Item>
                </Col>
              </Row>

            </Col>
            <Col span={9}>
              <h3>{t('extendContractPage.form.extendContract.currentLeasingTimeOfThisContract')}:</h3>
              <p className="time">{moment.utc(extendContract.startDate).local().format('DD/MM/YYYY')} - {moment.utc(extendContract.expiryDate).local().format('DD/MM/YYYY')}</p>
              <br />
              <br />
              <h3>{t('extendContractPage.form.extendContract.currentRentalPrice')}: <span className="feild-time text-price">{oldPrice}</span></h3>
              <br />
              <br />
              <br />
              <br />
              <br />
              <h3>{t('extendContractPage.form.extendContract.extendTime')}:</h3>
              {
                month && <p className="feild-time text-red">{currentMonth} -  {addMonth}</p>
              }
              <br />
              <h3>{t('extendContractPage.form.extendContract.extendRentalPrice')}: <span className="feild-time text-red text-price">{priceExtend || '0'}</span></h3>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default ContentExtendContractComponent