import actionTypes from "./profile.types";

const usersInitialState = {
    create: {
        initUser: []
    },
    userId:{}
};

const profileReducer = (state = usersInitialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INIT_USER:
            return { ...state, create: { ...state.create, initUser: action.payload } };
            case actionTypes.GET_USER_ID:
                return { ...state, userId: action.payload };
        default:
            return state;
    }
}

export default profileReducer;