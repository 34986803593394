import React from 'react'
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BeecowMenu from "../components/menu";

const PrivateRoute = (props) => {
    const { isAuthenticated, changedPassWord, userId } = props;
    let routeRender;
    const currentUrl = props?.computedMatch?.url;

    if (isAuthenticated) {
        if (changedPassWord === false && props.location !== "/reset-password/:userId") {
            routeRender = (<Redirect
                to={{
                    pathname: `/reset-password/${userId}`,
                    state: { from: props.location }
                }} />)
        }
        else {
            routeRender = (React.createElement(props.component, props))
        }
    }
    else {
        if (props.location === `/reset-password/:userId` || props.location === `/forgot-pasword/:refreshTokenKey`) {
            routeRender = (React.createElement(props.component, props))
        }
        else if (props.location === `/view-order-history` ||
            props.location === `/view-order-history/:orderCode` ||
            props.location === `/home` ||
            props.location === `/login/:uid` ||
            props.location === `/login`) {
            routeRender = (React.createElement(props.component, props))
        }
        else {
            routeRender = (<Redirect
                to={{
                    pathname: "/login",
                    state: { from: props.location, returnUrl: currentUrl }
                }}
            />)
        }
    }

    return (
        <>
            <BeecowMenu currentPath={props.routeProps} t={props.t} listMenu={props.listMenu} routes={props.routes} />
            <Route {...props.routeProps} render={() => routeRender} />
        </>
    );
}

PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        isAuthenticated: (state.session?.auth?.token && (state.session?.auth?.user?.role === "USER")) ? true : false,
        changedPassWord: state.session?.auth?.user?.changedPassWord === "True" || state.session?.auth?.user?.changedPassWord === true ? true : false,
        userId: state.session?.auth?.user?.userid,
        location: ownProps.path,
        routeProps: {
            exact: ownProps.exact,
            path: ownProps.path,
        }
    };
}

export default withRouter(connect(mapStateToProps, null, null, { pure: false })(PrivateRoute));