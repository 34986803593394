import actionTypes from "./orders.types";
import orderDataService from "../../data-services/orders/orders-data.service";
import contractDataService from "../../data-services/contracts/contracts-data.service";


export function setListOrders(orders) {
  return { type: actionTypes.SET_LIST_ORDERS, orders };
}

export function fetchListOrders(userId) {
  return async (dispatch) => {
    let data = await orderDataService.listUserOrders(userId);
    dispatch(setListOrders(data.orders));
  }
}
export function SetDeliveryTypeSelected(dilivery) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_DELIVERY_SELECTED,
      payload: dilivery
    })
  }
}
export const getInitData = (deliveryType) => {
  return async (dispatch) => {
    const res = await orderDataService.getInitData(deliveryType)
    dispatch({
      type: actionTypes.GET_ORDER_INIT_DATA,
      payload: res
    })
  }
}

export const getStation = (receiverCity) => {
  return async (dispatch) => {
    const res = await orderDataService.getStation(receiverCity)
    dispatch({
      type: actionTypes.GET_STATIONS,
      payload: res?.stations
    })
  }
}

export const getRouting = (req) => {
  return async (dispatch) => {
    const { toStation, fromStation } = req || {}
    const res = await orderDataService.getRouting(toStation, fromStation || '')
    dispatch({
      type: actionTypes.GET_ROUTING,
      payload: res?.data || []
    })
  }
}

export const getRoutingToDoor = (receiverCity, fromStation, senderCity) => {
  return async (dispatch) => {
    const res = await orderDataService.getRoutingToDoor(receiverCity, fromStation, senderCity)
    dispatch({
      type: actionTypes.GET_ROUTING,
      payload: res?.data || []
    })
  }
}

export const getRoutingToDoorWareHouse = (contractId,  receiverCity) => {
  return async (dispatch) => {
    const res = await orderDataService.getRoutingToDoorWareHouse(contractId, receiverCity)
    dispatch({
      type: actionTypes.GET_ROUTING_WAREHOUSE,
      payload: res?.data || []
    })
  }
}

export const clearData = () => {
  return {
    type: actionTypes.CLEAR_ORDER_DATA,
    payload: []
  }
}

export const getDistrictByCityId = (cityId, isSender) => {
  return async (dispatch) => {
    const res = await orderDataService.getDistrictByCityId(cityId)
    dispatch({
      type: actionTypes.GET_DISTRICT_BY_CITYID,
      payload: { data: res?.districts || [], isSender }
    })
  }
}

export const getWardsByDistrictId = (districtId, isSender) => {
  return async (dispatch) => {
    const res = await orderDataService.getWardsByDistrictId(districtId)
    dispatch({
      type: actionTypes.GET_WARDS_BY_DISTRICTID,
      payload: { data: res?.wards || [], isSender }
    })
  }
}

export const getOrderDetails = (orderId) => {
  return async (dispatch) => {
    const res = await orderDataService.getOrderDetails(orderId)
    dispatch({
      type: actionTypes.GET_ORDER_DETAILS,
      payload: res.order || {}
    })
  }
 
}
export const getContractDetail = (contractId) => {
  
  return async (dispatch) => {
    const res = await contractDataService.getContractDetailById(contractId)
    dispatch({
      type: actionTypes.GET_CONTRACT_DETAIL,
      payload: res?.data || {}
    })
  }
 
}
export const SetCurrentInventory = (currentInventory) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_CURRENT_INVENTORY,
      payload: currentInventory
    })
  }
}
export const SetAmount = (amount) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_AMOUNT,
      payload: amount
    })
  }
  
}
export const SetPayment = (payment) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_PAYMENT,
      payload: payment
    })
  }
  
}

export const SetContractDetail = (contract) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_CONTRACT_DETAIL,
      payload: contract
    })
  }
  
}

export const SetListProductPlaceOrder = (products) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_LIST_PRODUCT_PLACE_ORDER,
      payload: products
    })
  }
  
}

// Added on 2021-11-29
export function getPickupListById(id) {
  return async (dispatch) => {
      let data = await orderDataService.getPickupListById(id);
      dispatch({
          type: actionTypes.GET_PICKUP_LIST_BY_ID,
          payload: data?.orderPickup
      })
  }
}

export function getDeliveryListDetailById(id) {
  return async (dispatch) => {
      let data = await orderDataService.getDeliveryListDetailById(id);
      dispatch({
          type: actionTypes.GET_DELIVERY_LIST_DETAIL_BY_ID,
          payload: data
      })
  }
}