import { ExclamationCircleOutlined, FileOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Image, Modal, Row } from "antd";
import { Component } from "react";
import { Link } from "react-router-dom";
import deliveryIcon from "../../../assets/icons/icon-delivery.png";
import fastIcon from "../../../assets/icons/icon-fast.png";
import fragileIcon from "../../../assets/icons/icon-fragile.png";
import deliveryIconLeasing from "../../../assets/icons/warehouse.png";
import { deliveryMethods, deliveryMethodsRenting, specialServices } from "../../../constants/order-constant";
import { formatAddress } from "../../../utils/common";
import { getDateTime } from "../../../utils/datetime";
import { formatAmount } from "../../../utils/order";
import "./card-order.component.scss";

export class CardOrder extends Component {
  constructor(props) {
    super(props);
  }
  getSpecialServiceIcon = (name) => {
    if (name === specialServices.fragile) {
      return <Image width={30} src={fragileIcon} />;
    } else if (name === specialServices.fastService) {
      return <Image width={30} src={fastIcon} />;
    }
    return <></>;
  };
  renderShippingColumn(order) {
    const { t } = this.props;
    let href = `/contract/${order?.contractId}`;
    if (order.orderContractTypeId == null) {
      let renderPaidToContract = "";
      if (order.contractId != null) {
        renderPaidToContract = (
          <>
            <br />
            <div className="beecow-margin-top5 text-center">
              <a className="beecow-text-underline" href={href}>
                {order?.contract?.code}
              </a>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="text-center">
            <b>{t("orderListPage.shippingFee")}</b>
          </div>
          <div className="beecow-margin-top5 text-center">
            <span className="beecow-currency-fee">
              <b>{formatAmount(order.totalFee)} &nbsp;VND</b>
            </span>
          </div>
          <div className="beecow-margin-top5 text-center">
            <span className="beecow-text-italic">{t(order.payByLanguageKey)}</span> &nbsp;
            <span className="beecow-badge-cash">{order.paymentType?.name}</span>
          </div>
          {order.isCod && (
            <div className="beecow-margin-top5 text-center">
              <Checkbox checked={true} />
              <span>&nbsp; COD &nbsp;</span>
              <span className="beecow-currency-cod">{formatAmount(order.codamount)} &nbsp;VND</span>
            </div>
          )}
          {renderPaidToContract}
          {order?.showEditButton && (
            <div style={{ display: "flex", justifyContent: "center", marginTop: 5 }}>
              <div className="text-center" style={{ marginRight: 10 }}>
                <Link to={`/edit-order/${order?.id}`} className="ant-btn ant-btn-primary">
                  {t("button.edit")}
                </Link>
              </div>
              <div className="text-center">
                <Button
                  onClick={() => this.clickButtonDelete(order?.id)}
                  className="ant-btn ant-btn-primary ant-btn-dangerous"
                >
                  {t("button.delete")}
                </Button>
              </div>
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          <div className="text-center">
            <b>{t("orderListPage.paidInRentingContract")}</b>
          </div>
          <div className="beecow-margin-top5 text-center">
            <a className="beecow-text-underline" href={href}>
              {order?.contract?.code}
            </a>
          </div>
        </>
      );
    }
  }
  render() {
    const { t } = this.props;
    let order = this.props.dataSource || {};
    let fullNameStatusOrder;
    let shippingRender = this.renderShippingColumn(order);
    if (order.status?.subStatusName != null) {
      fullNameStatusOrder = order.status?.languageKey || `${order.status?.statusName}-${order.status?.subStatusName}`;
    } else {
      fullNameStatusOrder = order.status?.languageKey || order.status?.statusName;
    }
    const isShowDeliveryIcon = deliveryMethods.includes(order.delivery?.name.toLowerCase());
    const isShowDeliveryIconLeasing = deliveryMethodsRenting.includes(order.delivery?.name.toLowerCase());
    return (
      <Card className="beecow-card-order">
        <Row gutter={24}>
          <Col className="content gutter-row" span={8}>
            <Row gutter={24}>
              <Col span={10} className="beecow-center-vertical">
                <FileOutlined />
                &nbsp;
                <Link target={"_blank"} to={`/detail-order/${order.id}`} className="beecow-text-underline">
                  {order.code}
                </Link>
              </Col>
              <Col span={14}>
                <b className="clearfix">
                  {t("orderListPage.to")} : {order.receiverName}
                </b>
                <b className="clearfix"> {order.receiverPhone}</b>
                <div className="clearfix">
                  <span>{this.getReceiverAddress(order)}</span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="content gutter-row" span={5}>
            <Row gutter={24}>
              <Col>
                <div className="pull-left">
                  <small>
                    <b>{order.delivery?.name}</b>
                  </small>
                </div>
                <div className="pull-right clearfix">
                  {isShowDeliveryIcon && <Image src={deliveryIcon} width={30} />}
                  {isShowDeliveryIconLeasing && <Image src={deliveryIconLeasing} width={30} />}
                </div>
              </Col>
              <Col className="becow-center-baseline">
                <p className="pull-left">
                  <small>
                    <b>{t(order.specialOption?.languageKey)}</b>
                  </small>{" "}
                  &nbsp;
                </p>
                <p className="pull-right">{this.getSpecialServiceIcon(order.specialOption?.name)}</p>
              </Col>
              <div className="date-time text-center">
                <small>
                  <b>{t("orderListPage.createdDate")} </b>
                  {getDateTime(order.createdTime, "DD/MM/YYYY, hh:mm a")}
                </small>
              </div>
            </Row>
          </Col>
          <Col className="content gutter-row" span={5}>
            <span className="beecow-badge-status">{t(fullNameStatusOrder)}</span>
            <Link
              to={`/view-order-history/${order.orderTrackingCode?.toLowerCase()}`}
              className="beecow-float-right beecow-text-underline clearfix"
            >
              {t("orderListPage.viewTrackingOrderPage")}
            </Link>
            <br />
            <div className="logo-company clearfix">
              <Image src={order.route?.company?.logoPath} width={100} />
            </div>
          </Col>
          <Col className="gutter-row shipping" span={6}>
            {shippingRender}
          </Col>
        </Row>
      </Card>
    );
  }

  clickButtonDelete(orderId) {
    const currentComponent = this;
    const { t } = this.props;
    Modal.confirm({
      title: `${t("confirm.removeOrder")}`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        currentComponent.props.handleDeleteOrder(orderId);
      },
      okType: "danger",
      okText: `${t("button.ok")}`,
      cancelText: `${t("button.cancel")}`,
    });
  }

  getReceiverAddress(order) {
    const { t } = this.props;
    return formatAddress(order?.addressRecipient, t);
  }
}
