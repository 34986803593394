import './view-detail-order.scss';
import Barcode from 'react-barcode';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import TopBar from '../../components/topbar';
import { formatAmount } from '../../utils/order';
import { TEXT_VND } from '../../constants/common';
import iconMomo from '../../assets/icons/momo.png';
import iconBank from '../../assets/icons/bank.jpg';
import { formatAddress } from '../../utils/common';
import iconMoney from '../../assets/icons/money.jpg';
import fastIcon from "../../assets/icons/icon-fast.png";
import Layout, { Content } from 'antd/lib/layout/layout';
import fragileIcon from "../../assets/icons/icon-fragile.png";
import deliveryIcon from "../../assets/icons/icon-delivery.png";
import { DeliveryTypeConstants } from '../../constants/delivery.constants';
import PrintModal from "../../components/modal-print/modal-print.component"
import { StationToWarehouser } from '../../constants/leasing-contract-constant';
import { deliveryMethods, doorToStation, iconPayment, specialServices } from '../../constants/order-constant';

export default class ViewDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sumAmount: 0,
      totalWeight: 0,
      showBarcode: false,
      onlyPrintBarcode: false
    }
    this.choosePrintRef = React.createRef();
  }

  async componentDidMount() {
    const { orderId } = this.props?.match?.params || {}
    await this.props.getOrderDetails(orderId);
    const { currentUserId, history, justLoggedIn, order } = this.props;
    if (order?.userId !== currentUserId) {
      if (justLoggedIn) {
        this.props.resetSession(currentUserId);
        history.push('/page-not-found');
      } else {
        this.props.resetSession(currentUserId);
        history.push({
          pathname: '/login',
          state: { returnUrl: `/contract/${orderId}` }
        });
      }
    }
    else {
      this.props.getInitData(DeliveryTypeConstants.DELIVERY_STAFF)
      window.onafterprint = () => {
        this.setState({ showBarcode: false, onlyPrintBarcode: false })
      }
    }
  }

  render() {
    const { t } = this.props;
    const { showBarcode, onlyPrintBarcode } = this.state
    const { code } = this.props.order
    return (
      <Layout className='bg-content'>
        <div className='bg-body'>
          <TopBar title={t('orderDetailPage.title')} />
          <div className="bg-white clearfix">
            <Content className='beecow-summarize-info'>
              {
                showBarcode && <div className="row">
                  <Barcode
                    def
                    value={code}
                    format="code128"
                  />
                </div>
              }
              {
                !onlyPrintBarcode && <>
                  {this.renderBookingInfo()}
                  {this.renderProductsInfo()}
                  <div className="row">
                    <div className="col-sm-6">
                      {this.renderDelivery()}
                      {this.renderRoute()}
                    </div>
                    <div className="col-sm-6">
                      {this.renderPayment()}
                    </div>
                  </div>
                  {this.renderAction()}
                </>
              }
            </Content>
          </div>
        </div>
      </Layout>
    )
  }

  renderBookingInfo = () => {
    const { t } = this.props;
    const { code, status, senderPhone, senderName, receiverPhone, receiverName, addressRecipient, addressSender, orderTrackingCode } = this.props.order;
    const { showBarcode } = this.state
    return (
      <div className="detail-page">
        <div className='row'>
          <div className="col-sm-6">
            <h4>{t('createOrEditOrderPage.orderId')}: {code}</h4>
            <h4>{t('orderDetailPage.trackingOrderId')}: {orderTrackingCode}</h4>
          </div>
          <div className="col-sm-4">
            <h3>{t('orderDetailPage.status')}: {t(status?.languageKey || status?.statusName)}</h3>
            <div><Link target="_blank" to={`/view-order-history/${orderTrackingCode?.toLowerCase()}`}>{t('orderDetailPage.viewTrackingOrder')}</Link></div>
          </div>
        </div>
        <br />
        <div className='row'>
          <div className="col-sm-6">
            <h3>{t('createOrEditOrderPage.summarizeTab.senderInformation')}</h3>
            <div className="row">
              <div className="col-sm-12">
                <div className="row pl-4 mx-auto">
                  <p className="mr-5">{t('createOrEditOrderPage.summarizeTab.senderPhone')}: </p>
                  <p> {senderPhone}</p>
                </div>
                <div className="row pl-4 mx-auto">
                  <p className="mr-5">{t('createOrEditOrderPage.summarizeTab.senderName')}:</p>
                  <p> {senderName}</p>
                </div>
                <div className="row pl-4 mx-auto">
                  <p className="mr-5">{t('createOrEditOrderPage.summarizeTab.senderAddress')}: <span>{formatAddress(addressSender, t)}</span></p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <h3>{t('createOrEditOrderPage.summarizeTab.receiverInformation')}</h3>
            <div className="row">
              <div className="col-sm-12">
                <div className="row pl-4 mx-auto">
                  <p className="mr-5">{t('createOrEditOrderPage.summarizeTab.receiverPhone')}:</p>
                  <p> {receiverPhone}</p>
                </div>
                <div className="row pl-4 mx-auto">
                  <p className="mr-5">{t('createOrEditOrderPage.summarizeTab.receiverName')}:</p>
                  <p> {receiverName}</p>
                </div>
                <div className="row pl-4 mx-auto">
                  <p className="mr-5">{t('createOrEditOrderPage.summarizeTab.receiverAddress')}: <span>{formatAddress(addressRecipient, t)}</span> </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderProductsInfo = () => {
    const { t } = this.props;
    const { orderItems } = this.props.order || {}
    const sumAmount = orderItems?.map(x => x.amount).reduce((a, b) => a + b, 0)
    const totalWeight = orderItems?.map(x => x.amount * x.product?.weight).reduce((a, b) => a + b, 0)

    return <>
      <div className='row mt-4 mb-5'>
        <div className="col-sm-12">
          <h3>{t('createOrEditOrderPage.summarizeTab.productInformation')}</h3>
          <table className="table">
            <thead>
              <tr>
                <th>No.</th>
                <th>{t('createOrEditOrderPage.summarizeTab.productId')}</th>
                <th>{t('createOrEditOrderPage.summarizeTab.productName')}</th>
                <th>{t('createOrEditOrderPage.summarizeTab.productCategory')}</th>
                <th className="text-center">{t('createOrEditOrderPage.summarizeTab.sizeIn')}</th>
                <th className="text-center">{t('createOrEditOrderPage.summarizeTab.weight')}</th>
                <th className="text-center">{t('createOrEditOrderPage.summarizeTab.amount')}</th>
              </tr>
            </thead>
            <tbody>
              {
                orderItems?.map((p, index) => {
                  const { key, code, name, length, height, width, weight, total, packageType } = p.product || {};
                  return <tr key={p.id}>
                    <td className="pl-3">{index + 1}</td>
                    <td className="pl-3">{code}</td>
                    <td className="pl-3">{name}</td>
                    <td className="pl-3">{t(packageType?.languageKey)}</td>
                    <td className="text-center">{length} x {width} x {height}</td>
                    <td className="text-center">{weight} Kg</td>
                    <td className="text-center">{p.amount}</td>
                  </tr>
                })
              }
            </tbody>
          </table>
          <hr className="line" />
          <div className="total-card float-right">
            <div className="row mx-auto">
              <p className="col-sm-8">{t('createOrEditOrderPage.summarizeTab.totalProductsInPackage')}:</p>
              <p className="col-sm-3 text-right">{sumAmount}</p>
            </div>
            <div className="row mx-auto">
              <p className="col-sm-8">{t('createOrEditOrderPage.summarizeTab.totalWeight')}:</p>
              <p className="col-sm-3 text-right">{totalWeight} Kg</p>
            </div>
          </div>
        </div>
      </div>
    </>
  }

  renderDelivery = () => {
    const { t } = this.props;
    const { deliveryType, contract, station, toStation, specialOption } = this.props.order || {};
    const isShowDeliveryIcon = deliveryMethods.includes(deliveryType?.name)
    const { name, code } = toStation || {}
    const receiverStation = name && `${code} (${name})` || ''
    return <>
      <h3>{t('createOrEditOrderPage.summarizeTab.deliveryMethod')}</h3>
      <div className="row">
        <div className="col-sm-12">
          <p className="pl-4 mr-5">{t(deliveryType?.languageKey)} {isShowDeliveryIcon && <img src={deliveryIcon} width={30} />}</p>
          <p className="pl-4 mr-5">{t('createOrEditOrderPage.summarizeTab.shippingService')}: <span>{t(specialOption?.languageKey)}  {this.getSpecialServiceIcon(specialOption?.name)}</span></p>
          <p className="pl-4 mr-5">{t('createOrEditOrderPage.summarizeTab.contractId')}:  {contract && <span className="click-contract" onClick={this.openContractDetail.bind(this)}><a>{contract?.code}</a></span>}</p>

          <p className="pl-4 mr-5">{t('orderDetailPage.assignedStation')}: <span>{`${station?.code} (${station?.name})`}</span></p>
        </div>
        <div className="row pl-4">
          <div className="col-sm-7">
            <p className="pl-4 mr-5">{t('orderDetailPage.stationWhereSenderWillDelivery')}:</p>
          </div>
          <div className="col-sm-4">
            {deliveryType?.name?.toLowerCase() === StationToWarehouser?.toLowerCase() && <span>{receiverStation}</span>}
          </div>
        </div>
        <div className="row pl-4">
          <div className="col-sm-7">
            <p className="pl-4 mr-5">{t('orderDetailPage.stationWhereReceiverPickUpPackage')}: </p>
          </div>
          <div className="col-sm-4">
            {deliveryType?.name?.toLowerCase() === doorToStation?.toLowerCase() && <span>{receiverStation}</span>}
          </div>
        </div>
      </div>
    </>
  }

  renderRoute = () => {
    const { t } = this.props;
    const { route } = this.props.order || {}
    return <>
      <div className="mt-3">
        <h3>{t('createOrEditOrderPage.summarizeTab.routing')}</h3>
        <div className="row">
          <div className="col-sm-12">
            <div className="row mx-auto">
              <p className="col-sm-4">{t('createOrEditOrderPage.summarizeTab.provider')}:</p>
              <p className="col-sm-8">{route?.company?.name} <span><img src={route?.company?.logoPath} width={111} /></span></p>
            </div>
            <div className="row mx-auto">
              <p className="col-sm-4">{t('createOrEditOrderPage.summarizeTab.routeId')}:</p>
              <p className="col-sm-8">{route?.code}</p>
            </div>
            <div className="row mx-auto">
              <p className="col-sm-4">{t('createOrEditOrderPage.summarizeTab.routeName')}:</p>
              <p className="col-sm-8">{route?.routeName}</p>
            </div>
          </div>
        </div>

      </div>
    </>
  }

  renderPayment = () => {
    const { t } = this.props;
    const { paymentType, payByLanguageKey, codamount, shippingFee, totalFee, deliveryToDoorFee, pickupFromDoorFee, codFee, delivery } = this.props.order || {};
    return <>
      <h3>{t('createOrEditOrderPage.summarizeTab.payment')}</h3>
      <div className="row">
        <div className="col-sm-4 pl-5">
          <p className="mr-5">  {this.getPaymentTypeIcon(paymentType?.name)} {t(paymentType?.languageKey)}</p>
        </div>
        <div className="col-sm-4 pl-5">
          <p className="mr-5">{t(payByLanguageKey)}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-8 pl-5">
          <div className="total-card">
            {codamount > 0 && <div className="row">
              <div className="col-sm-6">
                <p className="m-0">{t('createOrEditOrderPage.summarizeTab.codAmount')}:</p>
                <p className="m-0 pl-4 text-detail font-italic">{t('createOrEditOrderPage.summarizeTab.senderReceivesCodAmountAtTimeOfPickup')}</p>
                <p className="m-0 pl-4 text-detail font-italic">{t('createOrEditOrderPage.summarizeTab.recipientPaysForOrderAtTimeOfDelivery')}</p>
              </div>
              <p className="col-sm-6 text-right">{formatAmount(codamount) + TEXT_VND}</p>
            </div>}
            {
              codFee > 0 && <div className="row mt-2">
                <p className="col-sm-6">{t('createOrEditOrderPage.summarizeTab.codFee')}:</p>
                <p className="col-sm-6 text-right">{formatAmount(codFee) + TEXT_VND}</p>
              </div>
            }
            {
              pickupFromDoorFee > 0 && <div className="row mt-2">
                <p className="col-sm-6">{t('createOrEditOrderPage.summarizeTab.pickupFromDoorFee')}:</p>
                <p className="col-sm-6 text-right">{formatAmount(pickupFromDoorFee) + TEXT_VND}</p>
              </div>
            }
            <div className="row">
              <p className="col-sm-6">{t('createOrEditOrderPage.summarizeTab.shippingFee')}:</p>
              <p className="col-sm-6 text-right">{formatAmount(shippingFee) + TEXT_VND}</p>
            </div>
            {
              delivery?.name.toLowerCase() !== StationToWarehouser.toLowerCase() || doorToStation.toLowerCase() &&
              <div className="row">
                <p className="col-sm-6">{t('orderDetailPage.additionalDoorServiceFee')}:</p>
                <p className="col-sm-6 text-right">{formatAmount(deliveryToDoorFee) + TEXT_VND}</p>
              </div>
            }
            <div className="row">
              <p className="col-sm-6">{t('createOrEditOrderPage.summarizeTab.total')}:</p>
              <p className="text-danger col-sm-6 text-right">{formatAmount(totalFee) + TEXT_VND}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  }

  renderAction = () => {
    const { t } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-sm-12 offset-sm-8 action-detail">
            <div className="click-close no-print" onClick={this.onBackHome.bind(this)}>{t('button.close')}</div>
            <div className="content-print">
              <PrintModal
                t={t}
                handlePrintContent={this.handlePrintContent}
                handlePrintBarcode={this.handlePrintBarcode}
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  handlePrintContent = () => {
    this.setState({ showBarcode: true }, () => {
      window.print()
    })
  }

  handlePrintBarcode = () => {
    this.setState({ showBarcode: true, onlyPrintBarcode: true }, () => {
      window.print()
    })
  }

  getSpecialServiceIcon = (name) => {
    if (name === specialServices.fragile) {
      return <img width={30} src={fragileIcon} />
    } else if (name === specialServices.fastService) {
      return <img width={30} src={fastIcon} />
    }
    return <></>
  }

  getPaymentTypeIcon(name) {
    if (name === iconPayment.cash) {
      return <img width={30} src={iconMoney} />
    }
    else if (name === iconPayment.bank) {
      return <img width={30} src={iconBank} />
    }
    return <img width={30} src={iconMomo} />
  }

  onBackHome() {
    this.props.history.push(`/list-orders`);
  }

  openContractDetail() {
    const { contract } = this.props.order
    this.props.history.push(`/contract/${contract?.id}`)
  }

}

