import React, { Component } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Form, Input } from "antd";
import ProductTableComponent from "./product-table/product-table.component";

export default class ProductInfoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            total: props.initialData?.totalProduduct ?? 0,
            description: props.initialData?.description ?? "",
            productCategories: props.productCategories ?? [],
            listProducts: props.listProducts ?? [],
            initialData: {
                products: props.initialData?.products ?? {},
            }
        }

        this.formRef = React.createRef();
    }

    componentDidMount() {
    }

    render() {
        const { t } = this.props;
        var { initialData } = this.state;
        return (
            <Content className='beecow-shipping-info'>
                <Form ref={this.formRef }>
                    <div className="row">
                        <div className="col-4 mx-auto">
                            <p>{t('createOrEditOrderPage.productForm.packageDescription')}</p>
                            <Form.Item
                                name="description"
                                rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputPackageDescription') }]}
                            >
                                <Input placeholder={t('createOrEditOrderPage.productForm.packageDescription')} />
                            </Form.Item>
                        </div>
                        <div className="row col-4 mt-5 mx-auto">
                            <p className="mr-5">{t('createOrEditOrderPage.productForm.totalItemsInPackage')}:</p> <label>{this.state.total ?? 0}</label>
                        </div>
                    </div>
                    <div className="mt-3">
                        <ProductTableComponent
                            t={t}
                            onChangeProduct={this.onChangeProduct}
                            onChange={this.onChange}
                            listProducts={this.state.listProducts }
                            initialData={initialData?.products}
                            productCategories={this.state.productCategories}/>
                    </div>
                </Form>
            </Content>
        )
    }

    getFieldsValue = () => {
        if (this.formRef && this.formRef.current) {
            var productInfo = this.formRef?.current?.getFieldsValue();
            productInfo.totalProduduct = productInfo?.products?.reduce((result, obj) => {
                if (obj) {
                    result += parseInt(obj.amount);
                    return result;
                }
                else {
                    return result;
                }
            }, 0) ?? 0;

            return productInfo;
        }
    }

    setFieldsValue = (values) => {
        var { initialData } = this.state;
        this.formRef.current?.setFieldsValue(values);
        this.setState({
            ...this.state,
            initialData: {
                ...initialData,
                products: values
            }
        }, () => {
            this.onChange();
        });
    }

    onChangeProduct = (values, index) => {
        var { initialData, listProducts } = this.state;
        var productsAdded = this.formRef.current.getFieldsValue().products ?? [];
        productsAdded.splice(index, 1, values);

        this.setState({
            ...this.state,
            listProducts: listProducts,
            initialData: {
                ...initialData,
                products: productsAdded
            }
        }, () => {
            this.formRef.current.setFieldsValue(this.state.initialData);
        });
    }

    validateFields = async () => {
        return this.formRef.current?.validateFields();
    }

    onChange = () => {
        var values = this.formRef?.current?.getFieldsValue();
        var total = values?.products?.reduce((result, obj) => {
            if (obj) {
                result += parseInt(obj.quantityToSell);
                return result;
            }
            else {
                return result;
            }
        }, 0) ?? 0;

        this.setState({total : total})
    }
}