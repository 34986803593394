
import actionTypes from "./orders.types";

const ordersInitialState = {
    list: [],
    create: {
        initData: {},
        stations: [],
        routes: [],
        selectedRoute: {},
        senderWards: [],
        senderDistricts: [],
        receiverWards: [],
        receiverDistricts: [],
        contractDetail: {},
        currentInventory: 0,
        amount: 1,
        deliverySelected: {},
        additionalFee: 0,
        payment: {},
        listProductPlaceOrder: [{
            code: "-",
            id: "-",
            packageType: "-",
            length: "-",
            width: "-",
            height: "-",
            weight: "-",
            inventory: "-",
            maximumAllowed: "-",
            total: "-",
            key: 0

        }]
    },
    orderDetails: {},
};

const ordersReducer = (state = ordersInitialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LIST_ORDERS:
            return { ...state, list: action.orders };
        case actionTypes.GET_ORDER_INIT_DATA:
            return { ...state, create: { ...state.create, initData: action.payload } };
        case actionTypes.GET_STATIONS:
            return { ...state, create: { ...state.create, stations: action.payload } };
        case actionTypes.GET_ROUTING:
            return { ...state, create: { ...state.create, routes: action.payload } };
        case actionTypes.GET_ROUTING_WAREHOUSE:
            return { ...state, create: { ...state.create, routes: action.payload } };
        case actionTypes.SELECT_ROUTING:

            return { ...state, create: { ...state.create, selectedRoute: action.payload } };
        case actionTypes.SET_ROUTE:
            return { ...state, create: { ...state.create, routes: [] } };
        case actionTypes.CLEAR_ORDER_DATA:
            return { ...state, create: { ...state.create, selectedRoute: {}, routes: [], districts: [], wards: [] }, orderDetails: {} };
        case actionTypes.GET_DISTRICT_BY_CITYID:
            if (action.payload?.isSender) {
                return { ...state, create: { ...state.create, senderDistricts: action.payload?.data, senderWards: [] } };
            }
            return { ...state, create: { ...state.create, receiverDistricts: action.payload?.data, receiverWards: [] } };
        case actionTypes.GET_WARDS_BY_DISTRICTID:
            if (action.payload?.isSender) {
                return { ...state, create: { ...state.create, senderWards: action.payload?.data } };
            }
            return { ...state, create: { ...state.create, receiverWards: action.payload?.data } };
        case actionTypes.GET_ORDER_DETAILS:
            return { ...state, orderDetails: action.payload };
        case actionTypes.GET_CONTRACT_DETAIL:
            return { ...state, create: { ...state.create, contractDetail: action.payload } };
        case actionTypes.SET_CURRENT_INVENTORY:
            return { ...state, create: { ...state.create, currentInventory: action.payload } };
        case actionTypes.SET_AMOUNT:
            return { ...state, create: { ...state.create, amount: action.payload } };
        case actionTypes.SET_DELIVERY_SELECTED:
            return { ...state, create: { ...state.create, deliverySelected: action.payload } };
        case actionTypes.SET_PAYMENT:
            return { ...state, create: { ...state.create, payment: action.payload } };
        case actionTypes.SET_CONTRACT_DETAIL:
            return { ...state, create: { ...state.create, contractDetail: action.payload } };
        case actionTypes.SET_LIST_PRODUCT_PLACE_ORDER:
            return { ...state, create: { ...state.create, listProductPlaceOrder: action.payload } };
        case actionTypes.GET_PICKUP_LIST_BY_ID:
                return { ...state, pickupList: action.payload };
        case actionTypes.GET_DELIVERY_LIST_DETAIL_BY_ID:
            return {
                ...state,
                dataForDeliveryListDetail: action.payload,
            };
        default:
            return state;
    }
};

export default ordersReducer;