import React, { Component } from 'react';
import { Form, Input, Select, Col, Row, Button, InputNumber } from "antd";
import { ContractMessage } from '../../common/contract.message';
import './product-info.scss';
import _ from 'lodash';
import { Utilities } from '../../common/utilities';

export class ProductInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            initialData: this.props.initialData,
        }
        this.formRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        let { initialData } = this.state;
        let { categories } = this.props;
        
        return (
            <Form 
                ref={this.formRef}
                initialValues={initialData}
                onValuesChange={this.onValuesChange.bind(this)}
                className="beecow-product-info">
                <Row gutter={24}>
                    <Col span={12}>
                        <label>{t('createOrEditOrderPage.productForm.packageDescription')}</label>
                        <Form.Item name="description" rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputPackageDescription') }]}>
                            <Input placeholder={t('createOrEditOrderPage.productForm.packageDescription')} />
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
                        <label>{t('createOrEditOrderPage.productForm.totalProductsInPackage')}: {_.sumBy(initialData?.products, "total")}</label>
                    </Col>
                </Row>
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t('createOrEditOrderPage.productForm.productName')}</th>
                            <th>{t('createOrEditOrderPage.productForm.productCategory')}</th>
                            <th colSpan="3">{t('createOrEditOrderPage.productForm.sizeIn')}</th>
                            <th>{t('createOrEditOrderPage.productForm.weight')}</th>
                            <th>{t('createOrEditOrderPage.productForm.amount')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        { initialData?.products.map((product, index) => {
                            return (
                                <tr key={index}>
                                    <th style={{ display: 'inline-flex' }}>
                                        <Button type="text" onClick={() => this.addNewProductAtIndex(index)}><i className="fa fa-plus text-success" aria-hidden="true"></i></Button>
                                        <Button type="text" onClick={() => this.removeProductAtIndex(index)}><i className="fa fa-minus text-danger" aria-hidden="true"></i></Button>
                                        <Form.Item 
                                            name={["products", index, "key"]} 
                                            hidden={true}>
                                            <Input />
                                        </Form.Item>

                                        <Form.Item hidden={true} name={["products", index, "isNewProduct"]}>
                                            <Input defaultValue={false} />
                                        </Form.Item>
                                    </th>
                                    <th>
                                        <Form.Item 
                                            name={["products", index, "name"]} 
                                            rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputProductName') }]}>
                                            <Input placeholder={t('createOrEditOrderPage.productForm.productName')} />
                                        </Form.Item>
                                    </th>
                                    <th>
                                        <Form.Item 
                                            name={["products", index, "packageTypeId"]}
                                            rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseSelectCategory') }]}>
                                            <Select 
                                                optionFilterProp="children" 
                                                placeholder={t('createOrEditOrderPage.productForm.selectCategory')}
                                                rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseSelectCategory') }]}>
                                                {
                                                    categories?.map(({ id, name, languageKey }) => <Select.Option key={id} value={id}>{t(languageKey)}</Select.Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </th>
                                    <th>
                                        <Form.Item 
                                            name={["products", index, "length"]}
                                            rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputProductSize') }]}>
                                            <InputNumber
                                                pattern="^\d+\.\d{0,2}$"
                                                onKeyDown={e => (e.keyCode !== 190 && e.keyCode != 9 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) && e.preventDefault()}
                                                min={0}
                                                placeholder={t('createOrEditOrderPage.productForm.length')}
                                            />
                                        </Form.Item>
                                    </th>
                                    <th>
                                        <Form.Item 
                                            name={["products", index, "width"]}
                                            rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputProductSize') }]}>
                                            <InputNumber
                                                pattern="^\d+\.\d{0,2}$"
                                                onKeyDown={e => (e.keyCode !== 190 && e.keyCode != 9 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) && e.preventDefault()}
                                                min={0}
                                                placeholder={t('createOrEditOrderPage.productForm.width')}
                                            />
                                        </Form.Item>
                                    </th>
                                    <th>
                                        <Form.Item 
                                            name={["products", index, "height"]}
                                            rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputProductSize') }]}>
                                            <InputNumber
                                                pattern="^\d+\.\d{0,2}$"
                                                onKeyDown={e => (e.keyCode !== 190 && e.keyCode != 9 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) && e.preventDefault()}
                                                min={0}
                                                placeholder={t('createOrEditOrderPage.productForm.height')}
                                            />
                                        </Form.Item>
                                    </th>
                                    <th>
                                        <Form.Item 
                                            name={["products", index, "weight"]}
                                            rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputWeight') }]}>
                                            <InputNumber
                                                pattern="^\d+\.\d{0,2}$"
                                                onKeyDown={e => (e.keyCode !== 190 && e.keyCode != 9 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) && e.preventDefault()}
                                                min={0}
                                                placeholder={t('createOrEditOrderPage.productForm.weight')}
                                            />
                                        </Form.Item>
                                    </th>
                                    <th>
                                        <Form.Item 
                                            name={["products", index, "total"]}
                                            rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputAmount') }]}>
                                            <InputNumber
                                                pattern="^\d+$"
                                                onKeyDown={e => (e.keyCode != 9 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) && e.preventDefault()}
                                                onChange={this.onChangeProductAmount}
                                                min={1}
                                                placeholder={t('createOrEditOrderPage.productForm.amount')}
                                            />
                                        </Form.Item>
                                    </th>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Form>
        )
    }

    setFieldsValue(values) {
        this.formRef.current.setFieldsValue(values);
        this.setState({ initialData: values });
    }

    onValuesChange(changedValues, allValues) {
        let mergedValues = { ...this.state.initialData, ...allValues};
        this.setState({ initialData: mergedValues });
    }

    addNewProductAtIndex(index) {
        let products = this.state.initialData.products;
        let newProduct = {
            key: Utilities.randomUuid(),
            name: "",
            packageTypeId: "",
            othersCategory: "",
            length: "",
            width: "",
            height: "",
            weight: "",
            inventory: "",
            total: "",
            isNewProduct: true
        };
        products.splice(index+1, 0, newProduct);
        this.setState({ 
            initialData: { ...this.state.initialData, products: products } 
        }, function() {
            this.formRef.current.setFieldsValue(this.state.initialData);
        });
    }

    removeProductAtIndex(index) {
        let products = this.state.initialData.products;
        if (products.length > 1) {
            products.splice(index, 1);
            this.setState({ 
                initialData: { ...this.state.initialData, products: products } 
            }, function() {
                this.formRef.current.setFieldsValue(this.state.initialData);
            });
        }
    }

    validateFields(){
        return this.formRef.current.validateFields();
    }

    getFieldsValue() {
        let fieldsValue = this.formRef.current.getFieldsValue();
        let mergedValues = { ...this.state.initialData, ...fieldsValue};
        return mergedValues;
    }
}