import React, { Component } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Form, Input, Select, Divider, Button } from "antd";
import ProductTableComponent from "./product-table/product-table.component";
import { ContractMessage } from '../../../common/contract.message';

export default class ProductInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            description: props.initialData?.description,
            total: props.initialData?.products?.length ?? 0,
            listProducts: props.listProducts ?? [],
            productTable: {
                products: props.initialData?.products ?? [],
                categories: props.categories ?? [],
                listProducts: props.listProducts ?? []
            }
        }

        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.formRef.current?.setFieldsValue(this.state);
    }

    render() {
        const { t } = this.props;
        return (
            <Content className='beecow-shipping-info'>
                <Form ref={this.formRef }>
                    <div className="row">
                        <div className="col-4 mx-auto">
                            <p>{t('createOrEditOrderPage.productForm.packageDescription')}</p>
                            <Form.Item className="size"
                                rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputPackageDescription') }]}
                                name="description">
                                <Input placeholder={t('createOrEditOrderPage.productForm.packageDescription')}/>
                            </Form.Item>
                        </div>
                        <div className="row col-4 mt-5 mx-auto">
                        </div>
                    </div>
                    <div className="mt-3">
                        <ProductTableComponent
                            t={t}
                            setValueAdd = { this.setValueAdd }
                            onChangeProduct={this.onChangeProduct}
                            setFieldsValue = { this.setFieldsValue } 
                            getFieldsValue = { this.getFieldsValue } 
                            onChange={this.onChange} 
                            initialData={this.state.productTable} />
                    </div>
                </Form>
            </Content>
        )
    }

    getFieldsValue = () => {
        var values = this.formRef?.current?.getFieldsValue();
        return values;
    }

    setFieldsValue = (dataToApplyToForm) => {
        if(dataToApplyToForm){
            this.formRef.current.setFieldsValue(dataToApplyToForm);
        }
    }

    setValueAdd = (index, item) => {
        var data = this.formRef.current.getFieldsValue();
        data.products.splice(index, 1, item);
        this.formRef.current.setFieldsValue(data)
    }

    onChangeProduct = (values, index) => {
        var { initialData, listProducts} = this.state;
        var productsAdded = this.formRef.current.getFieldsValue().products ?? [];
        productsAdded.splice(index, 1, values);

        this.setState({
            ...this.state,
            listProducts: listProducts,
            initialData: {
                ...initialData,
                products: productsAdded
            }
        }, () => {
            this.formRef.current?.setFieldsValue(this.state.initialData);
        });
    }

    validateFields(){
        return this.formRef.current.validateFields();
    }
}