import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCities } from "../../../modules/address/address.actions";
import { getInitData } from "../../../modules/leasing-contract/leasing-contract.actions";
import { RenewContractScreen } from "./renew-contract.screen";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => {
  return {
    userId: state.session?.auth?.user?.userid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInitData: () => dispatch(getInitData()),
    getCities: () => dispatch(getCities()),
  };
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(RenewContractScreen);
