import { Form, Input } from "antd";
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import _ from 'lodash';
import AddressComponent from '../address/address-component';
import { ContractMessage } from '../../common/contract.message';

export default class FormInfoComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: props.title ?? "",
            initialData: props.initialData ?? {
                fullName: null,
                phoneNumber: null,
                address: {
                    street: null,
                    city: {
                        id: null,
                        name: null
                    },
                    district: {
                        id: null,
                        name: null
                    },
                    ward: {
                        id: null,
                        name: null
                    }
                }
            }
        }

        this.formRef = React.createRef();
        this.addressRef = React.createRef();
    }

    componentDidMount() {
        this.formRef.current.setFieldsValue(this.state.initialData);
    }

    render() {
        const { t } = this.props;
        var { initialData } = this.state;
        return (
            <div>
                <p><b>{t(this.props.title)}</b></p>
                <Form ref={this.formRef}>
                    <div className="beecow-input-icon">
                        <i className="fa fa-mobile" aria-hidden="true"></i>
                        <Form.Item
                            name={["phoneNumber"]}
                            rules={[
                                { required: true, message: t('createOrEditOrderPage.form.pleaseInputValidPhone') },
                                { pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, message: t('createOrEditOrderPage.form.pleaseInputValidPhone') }
                            ]}>
                            <Input onChange={this.onChange} placeholder={t('createOrEditOrderPage.form.enterPhoneNumber')} />
                        </Form.Item>
                    </div>
                    <div className="beecow-input-icon">
                        <i className="fa fa-user-o" aria-hidden="true"></i>
                        <Form.Item
                            name={["fullName"]}
                            rules={[{ required: true, message: t('createOrEditOrderPage.form.pleaseInputRenterName') }]}>
                            <Input placeholder={t('createOrEditOrderPage.form.enterFullName')} />
                        </Form.Item>
                    </div>
                    <Form.Item
                        className="col-12"
                        name={["address"]}
                        rules={[{
                            required: true
                        }]}>
                        <AddressComponent t={t} ref={this.addressRef} initialData={initialData?.address} />
                    </Form.Item>
                </Form>

            </div>
        )
    }

    onChange = () => {
        if (this.props.onChange) {
            var values = this.formRef.current?.getFieldsValue();
            this.props.onChange(values);
        }
    }

    getFieldsValue = () => {
        let fieldsValue = this.formRef.current?.getFieldsValue();
        let mergedValues = { ...this.state.initialData, ...fieldsValue };
        return mergedValues;
    }

    setFieldsValue(values) {
        this.formRef.current.setFieldsValue(values);
        this.setState({ initialData: values });
        if (this.addressRef && this.addressRef.current) {
            this.addressRef.current.setFieldsValue(values?.address);
        }
    }

    validateFields = () => {
        return this.formRef.current?.validateFields();
    }
}

const propTypesAddressDetail = {
    id: PropTypes.string,
    name: PropTypes.string,
}

const propTypesAddress = {
    street: PropTypes.string,
    ward: PropTypes.shape(propTypesAddressDetail),
    district: PropTypes.shape(propTypesAddressDetail),
    city: PropTypes.shape(propTypesAddressDetail),
}

const propTypesInfo = {
    fullName: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.shape(propTypesAddress),
}

const propTypesFormInfo = {
    title: PropTypes.string,
    data: PropTypes.shape(propTypesInfo),
}

FormInfoComponent.propTypes = {
    initialData: PropTypes.shape(propTypesFormInfo),
}