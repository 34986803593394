import React, { Component } from 'react';
import { Form, Input, Modal, Checkbox, message } from 'antd';
import './product-table.scss';

export default class ProductTableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: props.initialData,
            productCategories: props.productCategories,
            modalVisible: false,
            newProductCategory: {
                id: null,
                name: null,
                isNew: true
            },
            changeAtIndex: null,
        }
    }

    render() {
        const { t } = this.props;

        return (
            <table className='table table-borderless product-table ml-0' scroll={{ y: 240 }}>
                <thead style={{ textAlign: 'center' }}>
                    <tr>
                        <th style={{ width: '20px' }}></th>
                        <th style={{ width: '20px' }}>{t('tableColumn.no')}</th>
                        <th className='table-product-code'>{t('tableColumn.productCode')}</th>
                        <th className='table-product-name'>{t('createOrEditOrderPage.productForm.productName')}</th>
                        <th className='table-product-category'>{t('createOrEditOrderPage.productForm.productCategory')}</th>
                        <th className='table-product-size'>{t('createOrEditOrderPage.productForm.length')}</th>
                        <th className='table-product-size'>{t('createOrEditOrderPage.productForm.width')}</th>
                        <th className='table-product-size'>{t('createOrEditOrderPage.productForm.height')}</th>
                        <th className='table-product-size'>{t('createOrEditOrderPage.productForm.weight')}</th>
                        <th className='table-product-size'>{t('createOrEditOrderPage.productForm.amount')}</th>
                    </tr>
                </thead>
                <tbody style={{ textAlign: 'center' }}>
                    {this.renderListProduct()}
                </tbody>
            </table>
        )
    }

    renderListProduct = () => {
        const { t } = this.props;
        const { products, newProductCategory } = this.state;
        return <>
            <Form.List name='products' initialValue={products}>
                {(fields, { add, remove }) => {
                    return (
                        fields.map((field, index) => {
                            var product = products[index];
                            var min = 0;
                            var maxShipping = product?.maximumAmountCanShipping ?? 0;
                            if (maxShipping > 0) {
                                return (
                                    <tr key={index}>
                                        <td style={{ display: 'inline-flex' }}>

                                            <Form.Item
                                                name={[index, 'selected']}
                                                valuePropName='checked'
                                            >
                                                <Checkbox onChange={(event) => this.onSelectProduct(product, event)}></Checkbox>
                                            </Form.Item>
                                        </td>
                                        <td className='mt-0 pl-0'>
                                            <p>{index + 1}</p>
                                        </td>
                                        <td className='pr-0'>
                                            <p>{product?.code}</p>
                                        </td>
                                        <td className='mt-0 table-product-name pl-0'>
                                            <p>{product?.name}</p>
                                        </td>
                                        <td className='mt-0 table-product-category'>
                                            <p>{t(product?.packageType?.languageKey)}</p>
                                        </td>
                                        <td className='pr-0'>
                                            <p>{product?.length}</p>
                                        </td>
                                        <td className='pr-0'>
                                            <p>{product?.width}</p>
                                        </td>
                                        <td className='pr-0'>
                                            <p>{product?.height}</p>
                                        </td>
                                        <td className='pr-0'>
                                            <p>{product?.weight}</p>
                                        </td>
                                        <td className='pr-0'>
                                            <Form.Item
                                                name={[index, 'amount']}
                                                rules={[
                                                    {
                                                        required: product?.selected ? true : false,
                                                        message: t('createOrEditOrderPage.productForm.pleaseInputAmount'),
                                                    },
                                                    () => ({
                                                        validator(_, amount) {
                                                            if (parseInt(amount) <= min) {
                                                                return Promise.reject(new Error(t('createOrEditOrderPage.productForm.pleaseInputAmountLargerThan')));
                                                            }

                                                            if (parseInt(amount) > maxShipping) {
                                                                return Promise.reject(new Error(`${t('text.maximumAmount')} ${maxShipping}`));
                                                            }

                                                            return Promise.resolve();
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input
                                                    onChange={(event) => this.onChangeAmount(product, event)}
                                                    placeholder={t('createOrEditOrderPage.productForm.amount')}
                                                    type='number'
                                                    min={min}
                                                    max={maxShipping}
                                                    defaultValue={'0'}
                                                    onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                                                    disabled={product?.selected ? false : true}
                                                />
                                            </Form.Item>
                                        </td>
                                    </tr>
                                )
                            } else return (<></>)

                        }
                        )
                    );
                }}
            </Form.List>

            <Modal
                title={t('createOrEditOrderPage.productForm.addProductCategory')}
                centered
                visible={this.state.modalVisible}
                maskClosable={false}
                onOk={this.onOk}
                onCancel={() => {
                    this.setState({
                        modalVisible: false,
                    });
                }}
                okText={t('button.done')}
                cancelText={t('button.cancel')}
                width={300}
            >
                <div className='row col-12 m-0'>
                    <Input placeholder={t('createOrEditOrderPage.productForm.pleaseInputProductCategory')} value={newProductCategory?.name} onChange={this.onChangeNewCategory} />
                </div>
            </Modal>
        </>
    }

    onChangeAmount = (product, event) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    onSelectProduct = (product, event) => {
        const { t } = this.props;
        var { products } = this.state;
        var updateProduct = products?.find(p => p.id === product.id);
        var isSelected = event.target?.checked;

        if (isSelected) {
            updateProduct.selected = true;
        } else {
            updateProduct.selected = false;
        }
        var index = products.findIndex(p => p.id === product.id);
        products.splice(index, 1, updateProduct);
        var productSelected = products?.find(p => p?.selected === true);
        if (!productSelected) {
            message.error(t('text.selectAtLeastOneRow'));
            return false;
        }
        this.setState({
            products: products,
        })

        if (this.props.onChange) {
            this.props.onChange();
        }
    }
}
