import { Button } from "antd";
import React, { Component } from "react";
import "./not-found.screen.scss";

export class NotFoundScreen extends Component {
  constructor(props) {
    super(props);
  }

  randomNum = () => {
    return Math.floor(Math.random() * 9) + 1;
  };

  componentDidMount() {
    var loop1,
      loop2,
      loop3,
      time = 30,
      i = 0,
      number,
      selector3 = document.querySelector(".thirdDigit"),
      selector2 = document.querySelector(".secondDigit"),
      selector1 = document.querySelector(".firstDigit");
    var obj = this;
    loop3 = setInterval(function () {
      if (i > 40) {
        clearInterval(loop3);
        selector3.textContent = 4;
      } else {
        selector3.textContent = obj.randomNum();
        i++;
      }
    }, time);
    loop2 = setInterval(function () {
      if (i > 80) {
        clearInterval(loop2);
        selector2.textContent = 0;
      } else {
        selector2.textContent = obj.randomNum();
        i++;
      }
    }, time);
    loop1 = setInterval(function () {
      if (i > 100) {
        clearInterval(loop1);
        selector1.textContent = 4;
      } else {
        selector1.textContent = obj.randomNum();
        i++;
      }
    }, time);
  }

  redirectToHomePage(){
    const { history } = this.props;
    history.push('/');
  }

  render() {
    const { t } = this.props;
    return (
      <div className="pageNotFoundContainer">
        <link
          href="https://fonts.googleapis.com/css?family=Anton|Passion+One|PT+Sans+Caption"
          rel="stylesheet"
          type="text/css"
        ></link>
        <div className="error">
          <div className="container-floud">
            <div className="col-xs-12 ground-color text-center">
              <div className="container-error-404">
                <div className="clip">
                  <div className="shadow">
                    <span className="digit thirdDigit"></span>
                  </div>
                </div>
                <div className="clip">
                  <div className="shadow">
                    <span className="digit secondDigit"></span>
                  </div>
                </div>
                <div className="clip">
                  <div className="shadow">
                    <span className="digit firstDigit"></span>
                  </div>
                </div>
                <div className="msg">
                  OH!<span className="triangle"></span>
                </div>
              </div>
              <h2 className="h2">Sorry! Page not found</h2>
              <h2 className="h2">Xin lỗi! Không tìm thấy trang</h2>
              <Button onClick={()=> this.redirectToHomePage()} className="ant-btn ant-btn-primary">{t('button.home')}</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
