import axios from "axios";
import { env } from "../../env";
import { startDataServiceProcessing, stopDataServiceProcessing } from "../../modules/app/app.actions";
import actionTypes from "../../modules/session/session.types";
import { logService } from "../../services/base/log/log.service";
import { store } from "../../store";

export class DataService {
  constructor() {
    const defaultOptions = {
      baseURL: `${env.REACT_APP_API_DOMAIN}/api`,
      withCredentials: true,
      timeout: 300000,
      headers: { Accept: "application/json", "Content-Type": "application/json" },
    };

    this.httpClient = axios.create(defaultOptions);
    this._configHttpRequest();
    this._configHttpResponse();
    this.isRefreshing = false;
    this.requestQueue = [];
    this.options = { prefix: "DATASERVICE", color: "blue", enableLog: false };
  }

  async get(url) {
    try {
      store.dispatch(startDataServiceProcessing());
      logService.trackTrace(`GET ${url}`, "", this.options);
      const response = await this.httpClient.get(url);
      logService.trackTrace(`GET ${url} >>> SUCCESS: `, response, this.options);
      return response ? response.data : "";
    } catch (err) {
      logService.trackException(`GET ${url} >>> ERROR: `, err, this.options);
      return Promise.reject(err);
    } finally {
      store.dispatch(stopDataServiceProcessing());
    }
  }

  async post(url, data) {
    try {
      store.dispatch(startDataServiceProcessing());
      logService.trackTrace(`POST ${url}`, data, this.options);
      const response = await this.httpClient.post(url, data);
      logService.trackTrace(`POST ${url} >>> SUCCESS: `, response, this.options);
      return response ? response.data : "";
    } catch (err) {
      logService.trackException(`POST ${url} >>> ERROR: `, err, this.options);
      return Promise.reject(err);
    } finally {
      store.dispatch(stopDataServiceProcessing());
    }
  }

  async put(url, data) {
    try {
      store.dispatch(startDataServiceProcessing());
      logService.trackTrace(`PUT ${url}`, data, this.options);
      const response = await this.httpClient.put(url, data);
      logService.trackTrace(`PUT ${url} >>> SUCCESS: `, response, this.options);
      return response ? response.data : "";
    } catch (err) {
      logService.trackException(`PUT ${url} >>> ERROR: `, err, this.options);
      return Promise.reject(err);
    } finally {
      store.dispatch(stopDataServiceProcessing());
    }
  }

  async delete(url) {
    try {
      store.dispatch(startDataServiceProcessing());
      logService.trackTrace(`DELETE ${url}`, "", this.options);
      const response = await this.httpClient.delete(url);
      logService.trackTrace(`DELETE ${url} >>> SUCCESS: `, response, this.options);
      return response ? response.data : "";
    } catch (err) {
      logService.trackException(`DELETE ${url} >>> ERROR: `, err, this.options);
      return Promise.reject(err);
    } finally {
      store.dispatch(stopDataServiceProcessing());
    }
  }

  _configHttpRequest() {
    this.httpClient.interceptors.request.use(
      async (config) => {
        const accessToken = this._getAccessToken();
        if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
        const workspaceId = this._getWorkspaceId();
        if (workspaceId) config.headers["workspace-id"] = workspaceId;
        return config;
      },
      (error) => {
        return Promise.reject(error.response);
      }
    );
  }

  _configHttpResponse() {
    this.httpClient.interceptors.response.use(
      (response) => {
        return response;
      },
      async (err) => {
        const originalConfig = err?.config;
        // Access Token was expired
        if (err?.response?.status === 401 && !originalConfig?._retry) {
          originalConfig._retry = true;
          try {
            // var auth = this._getAuth();
            // const rs = await this.post("/login/refreshToken", {
            //     refreshToken: auth?.refreshToken
            // });
            // if (rs?.success) {
            //     store.dispatch({type:actionTypes.SET_AUTH_TOKEN, rs});
            // }
            // else {
            store.dispatch({ type: actionTypes.RESET_SESSION });
            //message.error('Session expired.');
            // }
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
    );
  }

  _getAccessToken() {
    let state = store.getState();
    let accessToken = state.session?.auth?.token || "";
    return accessToken;
  }

  _getWorkspaceId() {
    let state = store.getState();
    let workspaceId = state.session?.workspace?.workspaceId || "";
    return workspaceId;
  }
}
