import React, { Component } from 'react';
import Layout from 'antd/lib/layout/layout';
import { Button, message, Steps } from 'antd';
import TopBar from '../../../components/topbar';
import ShippingInfoComponent from './shipping-info/shipping-info.component';
import ProductInfoComponent from './product-info/product-info.component';
import DeliveryComponent from './delivery/delivery.component';
import PaymentMethodComponent from './payment/payment.component';
import CreateOrderSummaryComponent from '../../../components/create-order-summary/create-order-summary.component';
import { createOrderTypes } from '../../../constants/order-constant';
import orderDataService from '../../../data-services/orders/orders-data.service';
import userDataService from '../../../data-services/user/user-data.service';
import contractDataService from '../../../data-services/contracts/contracts-data.service';
import leasingContractDataService from '../../../data-services/leasing-contract/leasing-contract-data.service';
import stationDataService from '../../../data-services/stations/stations-data.service';
import './ship-to-warehouse.screen.scss';

const { Step } = Steps;
export class ShipToWarehouseScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      title: '',
      current: 0,
      productCategories: [],
      deliveryOptions: [],
      shippingServices: [],
      locations: [],
      paymentTypes: [],
      paymentByOptions: [],
      totalAmountCanShipping: 0,
      initialData: {
        contractId: null,
        warehouseId: null,
        shippingInfo: {
          senderInfo: {
            fullName: null,
            phoneNumber: null,
            address: {
              street: null,
              city: {
                id: null,
                name: null,
              },
              district: {
                id: null,
                name: null,
              },
              ward: {
                id: null,
                name: null,
              },
            },
          },
          receiverInfo: {
            name: null,
            phone: null,
            address: {
              street: null,
              city: {
                id: null,
                name: null,
              },
              district: {
                id: null,
                name: null,
              },
              ward: {
                id: null,
                name: null,
              },
            },
          },
        },
        productInfo: {
          totalProduct: null,
          description: null,
          products: [],
        },
        deliveryInfo: {
          shippingService: {
            id: '',
            name: '',
          },
          deliveryOption: {
            id: '',
            name: '',
          },
          route: {
            id: '',
            name: '',
            code: '',
            provider: '',
          },
        },
        paymentInfo: {
          payBy: null,
          paymentType: null,
          isCod: null,
          codAmount: null,
        },
      },
    };

    this.shippingInfoForm = React.createRef();
    this.productInfoForm = React.createRef();
    this.deliveryInfoForm = React.createRef();
    this.paymentInfoForm = React.createRef();
  }

  async componentDidMount() {
    await this.init();
  }

  render() {
    const { t } = this.props;
    const { current } = this.state;
    const steps = this.createSteps();

    return (
      <Layout className='bg-content'>
        <div className='bg-body'>
          <TopBar title={t('createOrEditOrderPage.titleForCreate')} />
          <div className='bg-white clearfix beecow-leasing-contract'>
            <Steps
              type='navigation'
              current={current}
              className='site-navigation-steps'
            >
              {steps.map((item) => (
                <Step key={item?.title} title={item?.title} />
              ))}
            </Steps>
            <div className='steps-content'>{steps[current].content}</div>
            <div className='steps-action'>
              {current > 0 && (
                <Button type='primary' onClick={this.prev.bind(this)}>
                  {t('button.back')}
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button type='primary' onClick={this.next.bind(this)}>
                  {t('button.next')}
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button type='primary' onClick={this.submit}>
                  {t('button.done')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }


  init = async () => {
    const { id } = this.props?.match?.params || {}
    // static data initialization
    let initData = await leasingContractDataService.getInitData();
    // fill user data as sender info
    const userData = await userDataService.getUserInfoById(this.props.userId);
    const senderInfo = {
      fullName: userData?.fullName,
      phoneNumber: userData?.phone,
      address: userData?.address,
    };

    // state data initialization
    const contractData = await contractDataService.getProducts(id);
    const products = contractData?.products;
    const warehouse = await stationDataService.getStationId(contractData.warehouseId);
    const receiverInfo = {
      id: warehouse?.id,
      name: warehouse?.name,
      phone: warehouse?.phone,
      address: warehouse?.address,
      location: warehouse.location
    };
    
    let formData = {
      ...this.state,
      productCategories: initData?.packageCategory,
      deliveryOptions: contractData?.deliveryOptions?.delivery,
      shippingServices: initData?.specialServices,
      locations: initData?.locations,
      paymentTypes: initData?.paymentTypes,
      totalAmountCanShipping: contractData.totalAmountCanShipping,
      initialData: {
        contractId: contractData?.contractId,
        warehouseId: contractData?.warehouseId,
        productInfo: {
          products: products,
          description: contractData?.packageDescriptions
        },
        shippingInfo: {
          senderInfo: senderInfo,
          receiverInfo: receiverInfo
        }
      }
    };

    this.setState(formData, () => {
        this.refresh();
      });
  }

  createSteps = () => {
    const { t } = this.props;
    const {
      initialData,
      deliveryOptions,
      shippingServices,
      productCategories,
      paymentByOptions,
      locations,
      paymentTypes,
      totalAmountCanShipping
    } = this.state;
    
    var { shippingInfo, productInfo, deliveryInfo, paymentInfo, warehouseId } = initialData;
    var selectedProduct = productInfo?.products?.filter(p => p?.selected === true);
    const productSelectedInfo = {
      description: productInfo?.description,
      products: selectedProduct
    }

    const steps = [
      {
        title: t('createOrEditOrderPage.steps.shippingInformation'),
        content: (
          <ShippingInfoComponent
            t={t}
            ref={this.shippingInfoForm}
            initialData={shippingInfo}
          />
        ),
      },
      {
        title: t('createOrEditOrderPage.steps.productInformation'),
        content: (
          <ProductInfoComponent
            t={t}
            ref={this.productInfoForm}
            initialData={productInfo}
            productCategories={productCategories}
            totalAmountCanShipping={totalAmountCanShipping}
          />
        ),
      },
      {
        title: t('createOrEditOrderPage.steps.deliveryMethod'),
        content: (
          <DeliveryComponent
            t={t}
            ref={this.deliveryInfoForm}
            initialData={deliveryInfo}
            deliveryOptions={deliveryOptions}
            shippingServices={shippingServices}
            shippingInfo={shippingInfo}
            productInfo={productInfo}
            locations={locations}
            warehouseId={warehouseId}
          />
        ),
      },
      {
        title: t('createOrEditOrderPage.steps.payment'),
        content: (
          <PaymentMethodComponent
            t={t}
            ref={this.paymentInfoForm}
            initialData={paymentInfo}
            paymentByOptions={paymentByOptions}
            paymentTypes={paymentTypes}
            deliveryInfo={deliveryInfo}
            shippingInfo={shippingInfo}
          />
        ),
      },
      {
        title: t('createOrEditOrderPage.steps.summarize'),
        content: (
          <CreateOrderSummaryComponent
            t={t}
            shippingInfo={shippingInfo}
            productInfo={productSelectedInfo}
            deliveryInfo={deliveryInfo}
            paymentInfo={paymentInfo}
            deliveryOptions={deliveryOptions}
            shippingServices={shippingServices}
            productCategories={productCategories}
            isOrderShipToWarehouse={true}
          />
        ),
      },
    ];

    return steps;
  };

  next() {
    const { t } = this.props;
    var currentFormRef;
    switch (this.state.current) {
      case 0:
        currentFormRef = this.shippingInfoForm;
        this.setState({
          ...this.state,
          initialData: {
            ...this.state.initialData,
            shippingInfo: currentFormRef.current.getFieldsValue(),
          },
        });
        break;
      case 1:
        currentFormRef = this.productInfoForm;
        this.setState({
          ...this.state,
          initialData: {
            ...this.state.initialData,
            productInfo: currentFormRef.current.getFieldsValue(),
          },
        });
        break;
      case 2:
        currentFormRef = this.deliveryInfoForm;
        this.setState({
          ...this.state,
          initialData: {
            ...this.state.initialData,
            deliveryInfo: currentFormRef.current.getFieldsValue(),
          },
        });
        break;
      case 3:
        currentFormRef = this.paymentInfoForm;
        this.setState({
          ...this.state,
          initialData: {
            ...this.state.initialData,
            paymentInfo: currentFormRef.current.getFieldsValue(),
          },
        });
        break;
      case 4:
        break;
      default:
        break;
    }

    if (currentFormRef && currentFormRef.current) {
      currentFormRef.current
        .validateFields()
        .then(() => {
          if (
            this.state.current == 1 &&
            currentFormRef.current.props.initialData?.products?.length == 0
          ) {
            message.warning(t('createOrEditOrderPage.pleaseSelectProductItem'));
            return;
          }
          let nextStep = this.state.current + 1;
          if (nextStep < 5) {
            this.setState({ current: nextStep }, () => {
              this.refresh();
            });
          }
        })
        .catch((ex) => console.log(ex));
    }

  }

  prev() {
    this.setState(
      {
        current: this.state.current - 1,
      },
      () => {
        this.refresh();
      }
    );
  }

  refresh() {
    switch (this.state.current) {
      case 0:
        this.shippingInfoForm.current.setFieldsValue(
          this.state.initialData.shippingInfo
        );
        break;
      case 1:
        this.productInfoForm.current.setFieldsValue(
          this.state.initialData.productInfo
        );
        break;
      case 2:
        this.deliveryInfoForm.current.setFieldsValue(
          this.state.initialData.deliveryInfo
        );
        break;
      case 3:
        this.paymentInfoForm.current.setFieldsValue(
          this.state.initialData.deliveryInfo
        );
        break;
      default:
        break;
    }

  }

  submit = () => {
    const { t } = this.props;
    var { initialData } = this.state;
    var listShippingFee = this.tranformShippingFee(initialData?.deliveryInfo);
    var selectedProduct = initialData?.productInfo?.products?.filter(p => p?.selected === true);
    const productInfo = {
      description: initialData?.productInfo?.description,
      products: selectedProduct
    }
    const isStationSameWarehouse = !initialData?.deliveryInfo?.route?.id;

    var createOrderRequest = {
      senderInfo: initialData?.shippingInfo?.senderInfo,
      productInfo: productInfo,
      deliveryInfo: {
        shippingServiceId: initialData?.deliveryInfo?.shippingService?.id,
        deliveryTypeId: initialData?.deliveryInfo?.deliveryOption?.deliveryTypeId,
        route: {
          routeId: initialData?.deliveryInfo?.route?.id,
          companyId: initialData?.deliveryInfo?.route?.company?.id,
          shippingFeeDetails: listShippingFee,
        },
      },
      paymentInfo: {
        payById: 0,
        paymentTypeId: initialData?.paymentInfo?.paymentType?.id,
        isCod: initialData?.paymentInfo?.isCod,
        codAmount: initialData?.paymentInfo?.codAmount,
      },
      createOrderType: createOrderTypes.END_USER,
      toStationId: initialData?.warehouseId,
      contractId: initialData?.contractId,
      isStationSameWarehouse: isStationSameWarehouse
    };
    
    orderDataService.createOrderShipToWarehouse(createOrderRequest).then((res) => {
      if (res?.success) {
        message.success(t('createOrEditOrderPage.orderHasBeenCreatedSuccessfully'));
        this.props.history.push(`/detail-order/${res?.data?.id}`);
      } else {
        message.error(t('createOrEditOrderPage.orderCreateRequestFailed'));
      }
    });

  };

  tranformShippingFee = (deliveryInfo) => {
    var listShippingFee = [];
    if (deliveryInfo?.route?.shippingFeeDetails && deliveryInfo?.route?.shippingFeeDetails?.length > 0) {
      deliveryInfo?.route?.shippingFeeDetails.map((fee) => {
        var shippingFee = {
          ShippingFeeDetailId: fee?.id,
          AmountOfProduct: fee?.numberOfUnit
        }
        listShippingFee.push(shippingFee);
      })
    }

    return listShippingFee;
  }
}
