import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LeaseSpaceScreen } from "./lease-space.screen";
import {
  clearData,
  getInitData,
} from "../../modules/leasing-contract/leasing-contract.actions";
import { withRouter } from "react-router-dom";

import { compose } from "redux";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => {
  return {
    idWarehouse: state.warehouse,
    userid: state.session?.auth?.user?.userid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearData,
    },
    dispatch
  );
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(LeaseSpaceScreen);
