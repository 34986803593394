import actionTypes from "./leasing-contract.types";
import leasingContractDataService from "../../data-services/leasing-contract/leasing-contract-data.service";
import contractDataService from "../../data-services/contracts/contracts-data.service";

export const getInitData = () => {
  return async (dispatch) => {
    const res = await leasingContractDataService.getInitData()
    dispatch({
      type: actionTypes.GET_INIT_DATA,
      payload: res
    })
  }
}

export const getStation = (stationLocation) => {
  return async (dispatch) => {
    const res = await leasingContractDataService.getStation(stationLocation)
    dispatch({
      type: actionTypes.GET_STATIONS,
      payload: res.stations
    })
  }
}


export const getRoutesByCity = (data) => {
  return async (dispatch) => {
    const res = await leasingContractDataService.getRoutesByCity(data)
    dispatch({
      type: actionTypes.GET_ROUTES,
      payload: res
    })
  }
}

export const clearRoute = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.CLEAR_ROUTES,
      payload: []
    })
  }
}

export const clearData = () => {
  return {
    type: actionTypes.CLEAR_ORDER_DATA,
    payload: []
  }
}

export function fetchStationsForLease(productInfo) {
  return async (dispatch) => {
    const data = await leasingContractDataService.stationsForLease(productInfo)
    dispatch({
      type: actionTypes.GET_STATIONS_FOR_LEASE,
      payload: data || []
    })
  }
}

export function setStationsForLease(data) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_STATIONS_FOR_LEASE,
      payload: data || []
    })
  }
}

export function calcNewRentingSpace(data) {
  return async (dispatch) => {
    const res = await leasingContractDataService.leasingFeeCalculator(data)
    dispatch({
      type: actionTypes.GET_LEASING_FEE_CALCULATOR,
      payload: res || []
    })
  }
}

export function calcRenewFee(data) {
  return async (dispatch) => {
    const res = await contractDataService.calcRenewFee(data);
    dispatch({
      type: actionTypes.GET_LEASING_FEE_CALCULATOR,
      payload: res || []
    })
  }
}

export function calcAddMoreProductFee(data) {
  return async (dispatch) => {
    const res = await contractDataService.calcAddMoreProductFee(data);
    dispatch({
      type: actionTypes.GET_LEASING_FEE_CALCULATOR,
      payload: res || []
    })
  }
}

export function addNewContract(data) {
  return async (dispatch) => {
      return await leasingContractDataService.addNewContract(data);
  }
}

export const setContractRenewId = (id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_CONTRACT_RENEW_ID,
      payload: id
    })
  }
}
