import React, { Component } from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import AddressComponent from '../../../components/address/address-component';
import './sender-info.scss';

export class SenderInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            initialData: this.props.initialData,
            address: props.initialData.address ?? {
                street: '',
                city: {
                    id: '',  
                    name: ''
                },
                district: {
                    id: '',
                    name: ''
                },
                ward: {
                    id: '',
                    name: ''
                }
            }
        }
        this.formRef = React.createRef();
        this.addressFormRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        let { initialData, address } = this.state;
        return (
            <Form 
                ref={this.formRef}
                initialValues={initialData}
                className='beecow-sender-info'>
                <div className='mt-5'>
                    <label>{t('addMoreProductsPage.form.bookingInfo.senderName')}</label>
                    <div className='beecow-input-icon'>
                        <i className='fa fa-user-o' aria-hidden='true'></i>
                        <Form.Item 
                            name='fullName'
                            className='col-9 pr-2' 
                            rules={[{ required: true, message: t('addMoreProductsPage.form.bookingInfo.pleaseInputRenterName') }]}>
                            <Input 
                                disabled={initialData?.isSender} 
                                placeholder={t('addMoreProductsPage.form.bookingInfo.senderName')}
                            />
                        </Form.Item>
                    </div>
                    <label>{t('addMoreProductsPage.form.bookingInfo.senderPhone')}</label>
                    <div className='beecow-input-icon'>
                        <i className='fa fa-mobile' aria-hidden='true'></i>
                        <Form.Item
                            name='phone'
                            className='col-9 pr-2'
                            rules={[
                                { required: true, message: t('addMoreProductsPage.form.bookingInfo.pleaseInputValidPhone') },
                                { pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, message: t('addMoreProductsPage.form.bookingInfo.pleaseInputValidPhone') }
                            ]}>
                            <Input 
                                disabled={initialData?.isSender} 
                                placeholder={t('addMoreProductsPage.form.bookingInfo.senderPhone')}
                            />
                        </Form.Item>
                    </div>
                    <label>{t('addMoreProductsPage.form.bookingInfo.senderAddress')}</label>
                    <Form.Item
                        className='col-9 pr-2'
                        name='address'>
                        <AddressComponent t={t} ref={this.addressFormRef} disabled={initialData.isSender}  initialData={address} />
                    </Form.Item>
                </div>
            </Form>
        )
    }

    setFieldsValue(values) {
        this.formRef.current.setFieldsValue(values);
        if(this.addressFormRef && this.addressFormRef.current){
            this.addressFormRef.current.setFieldsValue(this.state.address);
        }
        this.setState({ initialData: values });
    }

    validateFields(){
        return this.formRef.current.validateFields();
    }

    getFieldsValue() {
        let allValues = this.formRef.current.getFieldsValue();
        let mergedValues = { ...this.state.initialData, ...allValues};
        return mergedValues;
    }
}

const propTypesAddressDetail = {
    id: PropTypes.string,
    name: PropTypes.string,
}

const propTypesAddress = {
    street: PropTypes.string,
    ward: PropTypes.shape(propTypesAddressDetail),
    district: PropTypes.shape(propTypesAddressDetail),
    city: PropTypes.shape(propTypesAddressDetail),
}

const propTypesSender = {
    fullName: PropTypes.string, 
    phone: PropTypes.string,
    address: PropTypes.shape(propTypesAddress),
}

SenderInfo.propTypes = {
    initialData: PropTypes.shape(propTypesSender),
};