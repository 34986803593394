import { DataService } from "../base/base-data.service";

class ContractDataService extends DataService {
    apiUrl = '/contract';

    async loadContracts() {
        return await this.get(`${this.apiUrl}/loadData`);
    }

    async getContractById(id) {
        return await this.get(`${this.apiUrl}/${id}`);
    }

    async getContractDetailById(id) {
        return await this.get(`${this.apiUrl}/detail/${id}`);
    }

    async getLeaseSpaceByUserId(id) {
        return await this.get(`${this.apiUrl}/leaseSpace/${id}`);
    }

    async calcRenewFee(data) {
        return await this.post(`${this.apiUrl}/renew/fee-calculator`, data);
    }

    async renewContract(data) {
        return await this.post(`${this.apiUrl}/renew`, data);
    }

    async calcAddMoreProductFee(data) {
        return await this.post(`${this.apiUrl}/add-more/fee-calculator`, data);
    }

    async addMoreProducts(data) {
        return await this.post(`${this.apiUrl}/add-more`, data);
    }

    async getExtendContract(id) {
        return await this.get(`${this.apiUrl}/${id}/extend-contract`);
    }

    async updateStatusContract(id) {
        return await this.put(`${this.apiUrl}/StatusContract/${id}`);
    }

    async GetDataContractPlaceOrder(id) {
        return await this.get(`${this.apiUrl}/get-data-contract-place-order/${id}`);
    }

    async updateContract(data) {
        return await this.put(`${this.apiUrl}/update`, data);
    }

    async deleteContractById(contractId) {
        return await this.delete(`${this.apiUrl}/delete/${contractId}`);
    }

    async createContractForRentArea(data) {
        return await this.post(`${this.apiUrl}/create-for-rent-area`, data)
    }

    async updateContractForRentArea(data) {
        return await this.put(`${this.apiUrl}/update-contract-for-rent-area`, data);
    }

    async addProductsForContractRentArea(data) {
        return await this.post(`${this.apiUrl}/add-products-for-contract-rent-area`, data);
    }

    async getProducts(contractId) {
        return await this.get(`${this.apiUrl}/get-product-list-by-contract-id/${contractId}`);
    }

    async getContractsByStationId(warehouseId, page, rows, keySearch) {
        return await this.get(`${this.apiUrl}/get-contracts-by-station-id?warehouseId=${warehouseId}&page=${page}&rows=${rows}&keySearch=${keySearch}`);
    }

    async verifyContract(contractId) {
        return await this.put(`${this.apiUrl}/verify/${contractId}`);
    }

    async cleanUpWarehouseByContractId(contractId) {
        return await this.delete(`${this.apiUrl}/clean-up-warehouse-by-contract-id/${contractId}`);
    }
    
}

let contractDataService = new ContractDataService();

export default contractDataService;