import React, { Component } from 'react';
import { Form, Button, Select, Input, Modal, InputNumber } from "antd";
import './product-table.scss'
import { data } from 'jquery';
import areaStationDataService from '../../../../data-services/area-stations/area-stations-data.service';

const { confirm } = Modal;

export default class ProductTableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productsAdded:[],
            products: props.initialData.products,
            listProducts: props.initialData.listProducts,
            listProductExcluded: props.initialData.listProducts,
            categories: props.initialData.categories,
            isShowModal: false,
            productId: ''
        }
        this.formRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        return (
            <table className="table table-borderless product-table" scroll={{ y: 240 }}>
                <thead>
                    <tr>
                        <th className="col-btn"></th>
                        <th className="col-code">{t('createOrEditOrderPage.productForm.productId')}</th>
                        <th className="col-info">{t('createOrEditOrderPage.productForm.productName')}</th>
                        <th className="col-code">{t('createOrEditOrderPage.productForm.productCategory')}</th>
                        <th className="col-size">{t('createOrEditOrderPage.productForm.length')}</th>
                        <th className="col-size">{t('createOrEditOrderPage.productForm.width')}</th>
                        <th className="col-size">{t('createOrEditOrderPage.productForm.height')}</th>
                        <th className="col-size">{t('createOrEditOrderPage.productForm.weight')}</th>
                        <th className="col-size">{t('createOrEditOrderPage.productForm.currentInventory')}</th>
                        <th className="col-size">{t('createOrEditOrderPage.productForm.adding')}</th>
                        <th className="col-hidden"></th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderListProduct()}
                </tbody>
            </table>
        )
    }

    onAdd = (callBack, params) => {
        var listProduct = this.props.getFieldsValue();
        if(listProduct.products.length + 1 <= this.state.listProducts.length){
            if (params && params >= 0) {
                callBack(null, params);
            }
            else {
                callBack();
            }
    
            if (this.props.onChange) {
                this.props.onChange();
            }
        }
    }

    onRemove = (callBack, params) => {
        callBack(params);
        if (this.props.onChange) {
            this.props.onChange();
        }
        this.onUpdateListExcluded(params);
    }

    getProductName = (data) => {
        let text = []
        data.map( (p) => {
            let item = p.name + " - " + p.inventory + "/" +p.total
            let obj = {
                id: p.id,
                name: item
            }
            text.push(obj)
        })
        return text
    }

    onSelectProductName = (key, index) => {
        const { listProducts } = this.state;
        const item = listProducts?.find((x) => x.key === key)
        if(item.inventory === 0){
            this.setState({ isShowModal: true, productId: key })
        }else{
            const { listProducts } = this.state;
            let item = listProducts.find(p => p.key === key)
            this.props.setFieldsValue(key, item, true)
        }

        this.onUpdateListExcluded(index, item);
        this.props.onChangeProduct(item, index);
    }

    onChangeProduct = (id, index) => {
        const { listProducts } = this.state;
        const product = listProducts?.find((x) => x.id === id);
        this.onUpdateListExcluded(index, product);
        this.props.onChangeProduct(product, index);
    }

    onUpdateListExcluded = (index, product) => {
        const { listProducts, productsAdded, listProductExcluded } = this.state;
        if (product) {
            productsAdded.splice(index, 1, product);
        } else {
            productsAdded.splice(index, 1);
        }
        let item = listProducts.filter((p) => !productsAdded.some(selected => selected.code === p.code));
        this.setState({
        productsAdded: productsAdded,
        listProductExcluded: item
        });
    }

    openModal = () => {
        const { t } = this.props;
        const { isShowModal } = this.state
        return (
            <>
              <Modal visible={isShowModal} onOk={this.handleOk} onCancel={this.handleCancel} 
                footer={[
                    <Button onClick={this.handleCancel}>{t('button.no')}</Button>,
                    <Button type="primary" onClick={this.handleOk}>{t('button.yes')}</Button>
              ]}>
                <h3>{t('addMoreProductsPage.doYouWantToChange')}</h3>
              </Modal>
            </>
          );
    }

    handleOk = () => {
        const { listProducts, productId } = this.state;
        
        this.setState({isShowModal: false})
        let item = listProducts.find(p => p.id == productId)
        this.props.setFieldsValue(productId, item, false)
    };
    
    handleCancel = () => {
        const { listProducts, productId } = this.state;
        
        this.setState({isShowModal: false})
        let item = listProducts.find(p => p.id == productId)
        this.props.setFieldsValue(productId, item, true)
        let index = listProducts.findIndex(p => p.id == productId)
    };

    checkItemProduct = (index) =>{
        let isNewProduct = true;
        if(index != null){
            var oldValues = this.props.getFieldsValue();
            if(oldValues != undefined){
                let item = oldValues.products[index]
                if(item?.isOldProduct == undefined){
                    isNewProduct = true
                }
                else{
                    isNewProduct = item?.isOldProduct
                }
            }
        }
        else{
            isNewProduct = true
        }
        return isNewProduct
    }

    onChangeSize = (e, index) => {
        const { listProducts, productId } = this.state;

        var dataForm = this.props.getFieldsValue();
        var item = dataForm.products[index]
        if(item.length > 0 && item.width > 0 && item.height > 0 && item.weight > 0){
            let unit = {
                length: item.length,
                width: item.width,
                height: item.height,
                weight: item.weight,
            };
            let request = {
                productId: item.id,
                replaceUnit: unit
            }
            areaStationDataService.getSpaceByUnit(request)
                .then(res => {
                    item.maximum = res.maximumAllowed
                    this.props.setValueAdd(index, item)
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    renderListProduct = () => {
        const { products, categories, listProducts, isShowModal, listProductExcluded } = this.state;
        const listProductName = this.getProductName(listProducts)
        const { t } = this.props;
        
        return <>
            <Form.List name="products" initialValue={products}>
                {(products, { add, remove }) => {
                    return (
                        products.length === 0 ? (
                            <tr>
                                {
                                    listProductName.length > 0 && <>
                                        <td style={{ display: 'inline-flex' }}>
                                            <Button className = "btn-icon" type="text" onClick={() => this.onAdd(add, [])}><i className="fa fa-plus text-success" aria-hidden="true" ></i></Button>
                                        </td>
                                    </>
                                }
                            </tr>
                        ) :
                        products?.map((field, index) => {
                            var isNewProduct = this.checkItemProduct(index)
                            
                            return (
                                <tr key={index}>
                                    <td style={{ display: 'inline-flex' }}>
                                        <Button className = "btn-icon" type="text" onClick={() => this.onAdd(add, index + 1)}><i className="fa fa-plus text-success" aria-hidden="true" ></i></Button>
                                        <Button className = "btn-icon" type="text" onClick={() => this.onRemove(remove, field.name)}><i className="fa fa-close text-danger" aria-hidden="true" ></i></Button>
                                    </td>
                                    {
                                        isNewProduct ? <>
                                            <td className="col-code">
                                                <Form.Item
                                                    name={[index, "code"]}
                                                    rules={[{ required: true , message: t('addMoreProductsPage.form.productInfo.pleaseInputProductCode')}]} >
                                                    <Input className="col-code" disabled={true} />
                                                </Form.Item>
                                            </td>
                                            <td className="col-info">
                                                <Form.Item
                                                    name={[index, "name"]}
                                                    rules={[{ required: true , message: t('createOrEditOrderPage.productForm.pleaseInputProductName')}]} >
                                                    <Select 
                                                    className="col-info" 
                                                    placeholder={t('createOrEditOrderPage.productForm.productName')} 
                                                    onChange={(e) => this.onSelectProductName(e, index)} >
                                                        <Select.Option style={{ cursor: "default"}} value="disabled" disabled>
                                                            <span className="float-left">{t('addMoreProductsPage.form.productInfo.name')}</span>
                                                            <span className="float-right">{t('addMoreProductsPage.form.productInfo.inventory')}</span>
                                                        </Select.Option>

                                                        {listProductExcluded?.map(({ key, name, inventory, total }) =>
                                                            <Select.Option key={key} value={key}>
                                                                <span className="float-left">{name}</span>
                                                                <span className="float-right">{inventory}/{total}</span>
                                                            </Select.Option>)}
                                                    </Select>
                                                </Form.Item>
                                                {
                                                    isShowModal && this.openModal()
                                                }
                                            </td>
                                            <td className="col-code">
                                                <Form.Item
                                                    name={[index, "packageTypeId"]}
                                                    rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseSelectCategory')}]} >
                                                    <Select className="col-info" disabled={true} placeholder={t('createOrEditOrderPage.productForm.selectCategory')}>
                                                        {categories.map(({ id, name }) => <Select.Option key={id} value={id}>{name}</Select.Option>)}
                                                    </Select>
                                                </Form.Item>
                                            </td>
                                            <td className="col-size">
                                                <Form.Item
                                                    name={[index, "length"]}
                                                    rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputLength') }]} >
                                                    <InputNumber className="col-size" disabled={true} />
                                                </Form.Item>    
                                            </td>
                                            <td className="col-size">
                                                <Form.Item
                                                    name={[index, "width"]}
                                                    rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputWidth') }]} >
                                                    <InputNumber className="col-size" disabled={true} />
                                                </Form.Item>
                                            </td>
                                            <td className="col-size">
                                                <Form.Item
                                                    name={[index, "height"]}
                                                    rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputHeight') }]} >
                                                    <InputNumber className="col-size" disabled={true} />
                                                </Form.Item>
                                            </td>
                                            <td className="col-size">
                                                <Form.Item
                                                    name={[index, "weight"]}
                                                    rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputWeight') }]} >
                                                    <InputNumber className="col-size" disabled={true} />
                                                </Form.Item>
                                            </td>
                                            <td className="col-size">
                                                <Form.Item
                                                    name={[index, "maximum"]}>
                                                    <InputNumber className="col-size" disabled={true} />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item 
                                                    hasFeedback
                                                    name={[index, "amount"]}
                                                    rules={[
                                                        {
                                                        required: true,
                                                        message: t('addMoreProductsPage.form.productInfo.pleaseInputAdding'),
                                                        },
                                                        ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            let amount = parseInt(getFieldValue(["products", index, "maximum"]))
                                                            if ( amount >= parseInt(value)) {
                                                            return Promise.resolve();
                                                            }
                                            
                                                            return Promise.reject(new Error(`${t('addMoreProductsPage.form.productInfo.pleaseEnterAmount')} ${amount}`));
                                                        },
                                                        }),
                                                    ]} >
                                                    <InputNumber
                                                        className="col-size"
                                                        pattern="^\d+$"
                                                        onKeyDown={e => (e.keyCode != 9 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) && e.preventDefault()}
                                                        min={1}
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td className= "col-hidden">
                                                <Form.Item
                                                    name={[index, "maximum"]}>
                                                    <Input className= "col-hidden" disabled={true}/>
                                                </Form.Item>
                                            </td>
                                        </>
                                        : <> 
                                            <td>
                                                <label>-</label>
                                            </td>
                                            <td>
                                                <Form.Item
                                                    className="mt-0 mr-3"
                                                    name={[index, "name"]}
                                                    rules={[{ required: true , message: t('createOrEditOrderPage.productForm.pleaseInputProductName')}]} >
                                                    <Input className="col-info" placeholder={t('createOrEditOrderPage.productForm.productName')} />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item
                                                    className="mr-3"
                                                    name={[index, "packageTypeId"]}
                                                    rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseSelectCategory')}]} >
                                                    <Select className="col-info" placeholder={t('createOrEditOrderPage.productForm.selectCategory')}>
                                                        {categories.map(({ id, name }) => <Select.Option key={id} value={id}>{name}</Select.Option>)}
                                                    </Select>
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item
                                                    name={[index, "length"]}
                                                    rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputLength') }]} >
                                                    <InputNumber 
                                                        className="col-size"
                                                        onChange={(e) => this.onChangeSize(e, index)}
                                                        pattern="^\d+\.\d{0,2}$"
                                                        onKeyDown={e => (e.keyCode !== 190 && e.keyCode != 9 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) && e.preventDefault()}
                                                        min={0}
                                                        placeholder={t('createOrEditOrderPage.productForm.length')} />
                                                </Form.Item>
                                                
                                            </td>
                                            <td>
                                                <Form.Item
                                                    name={[index, "width"]}
                                                    rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputWidth') }]} >
                                                    <InputNumber 
                                                        className="col-size"
                                                        onChange={(e) => this.onChangeSize(e, index)}
                                                        pattern="^\d+\.\d{0,2}$"
                                                        onKeyDown={e => (e.keyCode !== 190 && e.keyCode != 9 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) && e.preventDefault()}
                                                        min={0}
                                                        placeholder={t('createOrEditOrderPage.productForm.width')} />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item
                                                    name={[index, "height"]}
                                                    rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputHeight') }]} >
                                                    <InputNumber 
                                                        className="col-size"
                                                        onChange={(e) => this.onChangeSize(e, index)}
                                                        pattern="^\d+\.\d{0,2}$"
                                                        onKeyDown={e => (e.keyCode !== 190 && e.keyCode != 9 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) && e.preventDefault()}
                                                        min={0}
                                                        placeholder={t('createOrEditOrderPage.productForm.height')} />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <Form.Item
                                                    name={[index, "weight"]}
                                                    rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputWeight') }]} >
                                                    <InputNumber 
                                                        className="col-size"
                                                        onChange={(e) => this.onChangeSize(e, index)}
                                                        pattern="^\d+\.\d{0,2}$"
                                                        onKeyDown={e => (e.keyCode !== 190 && e.keyCode != 9 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) && e.preventDefault()}
                                                        min={0}
                                                        placeholder={t('createOrEditOrderPage.productForm.weight')} />
                                                </Form.Item>
                                            </td>
                                            <td>
                                                <label>-</label>
                                            </td>
                                            <td>
                                                <Form.Item 
                                                    hasFeedback
                                                    name={[index, "amount"]}
                                                    rules={[
                                                        {
                                                        required: true,
                                                        message: t('addMoreProductsPage.form.productInfo.pleaseInputAdding'),
                                                        },
                                                        ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            let amount = parseInt(getFieldValue(["products", index, "maximum"]))
                                                            if ( amount >= parseInt(value)) {
                                                            return Promise.resolve();
                                                            }
                                            
                                                            return Promise.reject(new Error(`${t('addMoreProductsPage.form.productInfo.youMustInputQuantity')} - ${amount}`));
                                                        },
                                                        }),
                                                    ]} >
                                                    <InputNumber
                                                        pattern="^\d+$"
                                                        onKeyDown={e => (e.keyCode != 9 && e.keyCode !== 8 && (e.keyCode < 48 || e.keyCode > 57)) && e.preventDefault()}
                                                        min={1}
                                                    />
                                                </Form.Item>
                                            </td>
                                            <td className= "col-hidden" >
                                                <Form.Item
                                                    name={[index, "maximum"]}>
                                                    <Input className= "col-hidden" disabled={true}/>
                                                </Form.Item>
                                            </td>
                                        </>
                                    }
                                </tr>
                        )})
                    );
                }}
            </Form.List>
        </>
    }
}