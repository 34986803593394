
export const leftMenu = [];
export const FORMAT_DATE = "DD-MM-YYYY";
export const FORMAT_DATETIME = "DD-MM-YYYY HH:mm";
export const FORMAT_TIME_DATE = "HH:mm DD MMM YYYY";

export const EStatusContractLeasing = {
    Active: {
        id: 1,
        name: "Active",
        color: "#52c41a"
    },
    WaitingForVerify: {
        id: 2,
        name: " Waiting for Verify",
        color: "#faad14"
    },
    Renewing: {
        id: 3,
        name: "Renewing",
        color: "#52c41a"
    },
    Expired: {
        id: 4,
        name: "Expired",
        color: "#ff4d4f"
    }
}
export const EStatusProductLeasing = {
    WaitingForImport: {
        id: 1,
        name: "Waiting for Import",
        color: "#faad14"
    },
    OutOfStock: {
        id: 2,
        name: "Out of Stock",
        color: "#ff4d4f"
    },
    ReadyToSell: {
        id: 3,
        name: "Ready to Sell",
        color: "#52c41a"
    }
};

export const formatAddress = (address) => {
    let arr = [];
    if (address && address.street) {
        arr.push(address.street);
    }
    if (address && address.ward) {
        let ward = address.ward.prefix + ' ' + address.ward.name;
        arr.push(ward);
    }
    if (address && address.district) {
        let district = address.district.prefix + ' ' + address.district.name;
        arr.push(district);
    }
    if (address && address.city) {
        arr.push(address.city.name);
    }
    return arr.join(', ');
};

export const page = {
    pageDefault: 1,
    pageSizeDefault: 20
}

export const thumbColors = {
    rentedOut: "thumb-rented-out",
    available: "thumb-normal",
    warning: "thumb-available",
    full: "thumb-full",
}