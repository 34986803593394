import React, { Component } from 'react';
import { Button, message, Steps } from 'antd';
import Layout from 'antd/lib/layout/layout';
import TopBar from '../../../components/topbar';
import { BookingInfo } from '../../../components/booking-info/booking-info.component';
import { WarehouseSelection } from '../../../components/steps-contract-rent-area/warehouse-selection-component/warehouse-selection.component';
import { PaymentMethod } from '../../../components/steps-contract-rent-area/payment-method/payment-method.component';
import { Summary } from '../../../components/steps-contract-rent-area/summary/summary.component';
import leasingContractDataService from '../../../data-services/leasing-contract/leasing-contract-data.service';
import contractDataService from '../../../data-services/contracts/contracts-data.service';
import './edit-contract-rent-area.scss';
import { formatAddress } from '../../../common/constants';
import moment from 'moment';

const { Step } = Steps;

export class EditContractRentAreaScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Edit Contract',
            current: 0,
            initialData: {
                contractId: null,
                contractCode: null,
                bookingInfo: {
                    renter: {
                        id: this.props.userId,
                        fullName: '',
                        phone: '',
                        email: '',
                        userTypeId: '',
                        paymentTypeId: '',
                        address: {
                            street: null,
                            city: {
                                id: null,
                                name: null
                            },
                            district: {
                                id: null,
                                name: null
                            },
                            ward: {
                                id: null,
                                name: null
                            }
                        }
                    },
                },
                warehouseInfo: {
                    startTime: '',
                    length: 1,
                    width: 1,
                    height: 1,
                    selectedStationAreaId: '',
                    months: 1,
                    leasingFee: 0,
                    selectedAreaId: '',
                    stationArea: {},
                },
                paymentInfo: {
                    paymentMethodId: null
                }
            },
            leasingAreas: [],
            specialServices: [],
            paymentMethods: [],
            selectedStationId: '',
            selectedAreaId: ''
        }

        this.bookingFormRef = React.createRef();
        this.warehouseFormRef = React.createRef();
        this.paymentFormRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        let { current, initialData, leasingAreas, paymentMethods, selectedStationId, selectedAreaId } = this.state;
        let { bookingInfo, warehouseInfo, paymentInfo } = initialData;
        let steps = [
            {
                title: t('createOrEditNewSpacePage.steps.bookingInformation'),
                content: <BookingInfo
                    t={t}
                    ref={this.bookingFormRef}
                    initialData={bookingInfo} isRentArea={true} />,
            },
            {
                title: t('createOrEditNewSpacePage.steps.selectWarehouse'),
                content: <WarehouseSelection
                    t={t}
                    ref={this.warehouseFormRef}
                    initialData={warehouseInfo}
                    areas={leasingAreas}
                    selectedStationId={selectedStationId}
                    selectedAreaId={selectedAreaId} />,
            },
            {
                title: t('createOrEditNewSpacePage.steps.payment'),
                content: (
                    <PaymentMethod 
                        t={t}
                        ref={this.paymentFormRef} 
                        initialData={paymentInfo} 
                        paymentMethods={paymentMethods}
                        bookingInfo={bookingInfo}
                        warehouseInfo={warehouseInfo} />
                ),
            },
            {
                title: t('createOrEditNewSpacePage.steps.summarize'),
                content: (
                    <Summary 
                        t={t}
                        bookingInfo={bookingInfo} 
                        warehouseInfo={warehouseInfo} 
                        paymentInfo={paymentInfo}
                        areas={leasingAreas} 
                        paymentMethods={paymentMethods}
                        isRentArea={true} />
                ),
            },
        ];
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={ this.state.title} />
                    <div className='bg-white clearfix beecow-leasing-contract'>
                        <Steps
                            type='navigation'
                            current={current}
                            className='site-navigation-steps'
                            >
                            {steps.map(item => (
                                <Step key={item.title} title={item.title} />
                            ))}
                        </Steps>
                        <div className='steps-content'>
                            <h2 className='float-right mr-5'>{initialData?.contractCode  }</h2>
                            {steps[current].content}
                        </div>
                        <div className='steps-action'>
                            {current > 0 && (
                                <Button type='primary' onClick={this.prev.bind(this)}>{t('button.back')}</Button>
                            )}
                            {current < steps.length - 1 && (
                                <Button type='primary' onClick={this.next.bind(this)}>{t('button.next')}</Button>
                            )}
                            {current === steps.length - 1 && (
                                <Button type='primary' onClick={this.handleFinish.bind(this)}>{t('button.done')}</Button>
                            )}
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    async componentDidMount() {
        const { id } = this.props?.match?.params || {};
        if (!id) return;
        let leasingContractTask = contractDataService.getContractById(id);
        let getInitialDataTask = leasingContractDataService.getInitData();
        let [leasingContract, initialData] = await Promise.all([leasingContractTask, getInitialDataTask]);
        if(leasingContract){
            var { data } = leasingContract;
            var leaseDate = moment.utc(data.warehouseInfo.leaseDate).local()
            let areaSize = {
                length: data.lengthWarehouses,
                width: data.widthWarehouses,
                height: data.heightWarehouses
            };
            let stations = await leasingContractDataService.stationsForRentArea({ size: areaSize, area: data.warehouseInfo.selectedWarehouse?.address?.city?.id});
            let stationArea = {};
            stations.map((s) => {
                let area = s.areaStation.find((i) => i.areas.id === data.areaStationId);
                if(area){
                    let data = {
                        id: s.id,
                        stationCode: s.code,
                        stationName: s.name,
                        stationAddress: formatAddress(s.address),
                        companyName: s.company?.name,
                        companyLogoUrl: s.companyLogoUrl,
                        area: area
                    }
                    stationArea = data;
                }
            });
            this.setState({
                ...this.state,
                initialData: {
                    ...this.state.initialData,
                    contractId: data.contractId,
                    contractCode: data.contractCode,
                    bookingInfo: data.bookingInfo,
                    warehouseInfo: {
                        ...data.warehouseInfo,
                        length: data.lengthWarehouse,
                        width: data.widthWarehouse,
                        height: data.heightWarehouse,
                        selectedAreaId: data.warehouseInfo.selectedWarehouse?.address?.city?.id,
                        selectedStationAreaId: data.areaStationId,
                        selectedWarehouseTypeId: data.areaCategoryId,
                        startTime: leaseDate,
                        months: data.warehouseInfo.months,
                        stationArea: stationArea,
                        stationTypes: initialData.stationTypeList
                    },
                    paymentInfo: {
                        ...data.paymentInfo,
                        paymentMethodId: data.paymentTypeId,
                    },
                },
                leasingAreas: initialData.areas,
                paymentMethods: initialData.paymentTypes,
                selectedStationId: data.warehouseInfo.selectedWarehouse.id,
                selectedAreaId: data.areaStationId
            }, () => {
                this.refresh();
            });
        }
    };

    next() {
        const { t } = this.props;
        let currentFormRef;
        switch (this.state.current) {
            case 0:
                currentFormRef = this.bookingFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, bookingInfo: currentFormRef.current.getFieldsValue()} })
                break;
            case 1:
                currentFormRef = this.warehouseFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, warehouseInfo: currentFormRef.current.getFieldsValue()} })
                break;
            case 2:
                currentFormRef = this.paymentFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, paymentInfo: currentFormRef.current.getFieldsValue()} })
                break;
            default:
                break;
        }
        if (currentFormRef && currentFormRef.current) {
            currentFormRef.current.validateFields()
                .then(() => {
                    if(!this.state.initialData.warehouseInfo?.selectedStationAreaId && this.state.current === 1){
                        message.warning(t('message.pleaseSelectArea'));
                    } else{
                        let nextStep = this.state.current + 1;
                        if (nextStep < 6) {
                            this.setState({ current: nextStep });
                            this.refresh();
                        }
                    }
                })
                .catch(ex => console.log(ex));
        }
    };

    prev() {
        this.setState({
            current: this.state.current - 1
        }, () => {
            this.refresh();
        });
    };

    refresh() {
        switch(this.state.current) {
            case 0:
                this.bookingFormRef.current.setFieldsValue(this.state.initialData.bookingInfo);
                break;
            case 1:
                this.warehouseFormRef.current.setFieldsValue(this.state.initialData.warehouseInfo);
                break;
            case 2:
                this.paymentFormRef.current.setFieldsValue(this.state.initialData.paymentInfo);
                break;
            default:
                break;
        }
    };

    handleFinish() {
        const { initialData } = this.state;
        let bookingInfo = {
            renter: initialData.bookingInfo.renter
        };
        let areaSize = {
            length: initialData.warehouseInfo.length,
            width: initialData.warehouseInfo.width,
            height: initialData.warehouseInfo.height
        };
        let warehouseInfo = {
            areaSize: areaSize,
            leaseDate: initialData.warehouseInfo.startTime,
            months: initialData.warehouseInfo.months,
            stationId: initialData.warehouseInfo.stationArea.id,
            areaStationId: initialData.warehouseInfo.selectedStationAreaId,
            priceLeasing: initialData.warehouseInfo.stationArea.area.leasingFee,
        };
        let request = {
            contractId: initialData.contractId,
            bookingInfo: bookingInfo,
            warehouseInfo: warehouseInfo,
            paymentInfo: initialData.paymentInfo
        };
        contractDataService.updateContractForRentArea(request).then((res) => {
            if (res) {
                this.props.history.push(`/leasing-contract`);
            }
        });
    };
}