import actionTypes from "./extend-contract.type";

const InitialState = {
  extendContract: {}
};
const extendContractReducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EXTEND:
      return { ...state, extendContract: action.payload };
    default:
      return state;
  }
};

export default extendContractReducer;