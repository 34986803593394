import React, { Component } from 'react';
import Layout from 'antd/lib/layout/layout';
import Button from 'antd-button-color';
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import orderDataService from '../../../../data-services/orders/orders-data.service';

export class ReturnLostPackageScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            result: false,
            message: ''
        }
    }

    componentDidMount() {
        const { t } = this.props;
        const { orderId, confirmationType } = this.props?.match?.params || {};
        const requestData = {
            orderId: orderId,
            confirmationType,
            isReturnSender: true
        }

        orderDataService.confirmLostPackage(requestData).then(res => {
            if (res.isSuccess === true) {
                this.setState({ result: true, message: res.languageCode });
            } else {
                this.setState({ result: false, message: res.languageCode });
            }
        });

        //beecow-sider-menu
    }

    render() {
        const { t } = this.props;
        const {result, message} = this.state;
        
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <div className='m-auto centered shadow p-4 pt-5 mb-5 bg-white rounded login-form' style={{ width: '500px', height: '250px' }}>
                        <div className='m-auto centered text-center pt-3'>
                            {
                                result ?
                                    <CheckCircleTwoTone style={{ fontSize: '36px' }} twoToneColor="#52c41a" />
                                    : <CloseCircleTwoTone style={{ fontSize: '36px' }} twoToneColor="#eb2f96" />
                            }
                            <h2>{t(message)}</h2>
                            <Button
                                style={{ width: '120px' }}
                                className='mt-5'
                                onClick={() => this.props.history.push('/')}
                                type='warning'>
                                {t('button.home')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}