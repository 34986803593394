import { DataService } from "../base/base-data.service";

class WarehouseDataService extends DataService {
    apiUrl = '/warehouse';

    async getWarehouseCategories() {
        return this.get(`${this.apiUrl}/categories`);
    }

    async getWarehouseLocations() {
        return this.get(`${this.apiUrl}/locations`);
    }

    async getPersonalWarehouse(uid) {
        return this.get(`${this.apiUrl}/personal-warehouse/${uid}`);
    }


    async warehouseRegistration(data) {
        return this.post(`${this.apiUrl}/registration`, data);
    }

    async getListOfWarehouseByUserId(userId) {
        return this.get(`${this.apiUrl}/get-list-of-warehouse-by-userid/${userId}`);
    }

    async getWarehouseById(id) {
        return this.get(`${this.apiUrl}/get-warehouse-by-id/${id}`);
    }

    async updatePersonalWarehouse(data) {
        return await this.put(`${this.apiUrl}/update-personal-warehouse`, data);
    }

}

let warehouseDataService = new WarehouseDataService();
export default warehouseDataService;