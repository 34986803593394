import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { setContractRenewId } from "../../modules/leasing-contract/leasing-contract.actions";
import { ContractDetailPageClient } from "./contract-detail.screen";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { resetSession } from "../../modules/session/session.actions";

const mapStateToProps = (state) => {
  return {
    currentUserId: state.session?.auth?.user?.userid,
    justLoggedIn: state.app?.justLoggedIn
  };
};

const mapDispatchToProps = (dispatch) => {
  
  return bindActionCreators(
    {
      setContractRenewId,
      resetSession
    },
    dispatch
  );
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ContractDetailPageClient);
