import React, { Component } from "react";
import { Form, Input, Button, message, DatePicker } from "antd";
import { UserOutlined, PhoneOutlined, MailOutlined } from "@ant-design/icons";

import userDataService from "../../data-services/user/user-data.service";
import AddressComponent from "../address/address-component";
import languageService from "../../services/language.service";

export default class UserRegisterForm extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <Form
          name="normal_login"
          className="m-auto centered shadow p-4 pt-5 mb-5 bg-white rounded mt-50px"
          initialValues={{ rememberMe: true }}
          onFinish={this.onFinish}
        >
          <div className="mb-3 text-center">
            <div className="logo">
              <div className="name d-flex justify-content-center">
                <h1>{t("loginPage.registerAccount")}</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <Form.Item
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: `${t("profilePage.pleaseInputName")}`,
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={t("profilePage.fullName")}
                />
              </Form.Item>

              <Form.Item
                name="doB"
                rules={[
                  {
                    required: true,
                    message: `${t("profilePage.inputDob")}`,
                  },
                ]}
              >
                <DatePicker
                  className="w-100"
                  locale={languageService.getLocale()}
                  placeholder={t("profilePage.inputDob")}
                />
              </Form.Item>
            </div>

            <div className="col-6">
              <Form.Item
                name="phone"
                name={["phoneNumber"]}
                rules={[
                  {
                    required: true,
                    message: t(
                      "createOrEditOrderPage.form.pleaseInputValidPhone"
                    ),
                  },
                  {
                    pattern:
                      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                    message: t(
                      "createOrEditOrderPage.form.pleaseInputValidPhone"
                    ),
                  },
                ]}
              >
                <Input
                  prefix={<PhoneOutlined className="site-form-item-icon" />}
                  placeholder={t("profilePage.phone")}
                />
              </Form.Item>

              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: `${t("profilePage.inputIsNotValidEmailAddress")}`,
                  },
                  {
                    required: true,
                    message: `${t("profilePage.pleaseInputEmail")}`,
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder={t("profilePage.email")}
                />
              </Form.Item>
            </div>

            <div className="col-12">
              <Form.Item
                className="col-12"
                name={["address"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <AddressComponent t={t} ref={this.addressRef} />
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button col-12"
            >
              {t("button.register")}
            </Button>

            <span>{t("loginPage.haveAccount")}</span>
            <a
              className="ml-2"
              href="javascript:void(0)"
              onClick={() => {
                if (this.props.callBack) this.props.callBack();
              }}
            >
              {t("loginPage.loginHere")}
            </a>
          </Form.Item>
        </Form>
      </>
    );
  }

  onFinish = values => {
    const { t } = this.props;
    userDataService.register(values).then(res => {
      if (res === true) {
        message.success(t("message.registerUserAccountSuccess"));
        if (this.props.callBack) this.props.callBack();
      } else {
        message.error(t("message.registerUserAccountFailed"));
      }
    });
  };
}
