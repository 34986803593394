
export const DeliveryConstants = {

    CREATE_ORDER: 1,

    PLACE_ORDER_FROM_WAREHOUSE: 2,

    TO_STATION: "to station",

    STATION_TO_WAREHOUSE: "station to warehouse"
}

export const DeliveryTypeConstants = {
    DELIVERY_STAFF: 0, // create order from station
    DELIVERY_ENDUSER: 1, // create order from end user
    DELIVERY_ENDUSER_WAREHOUSE: 2, // place an order from warehouse on end user
    DELIVERY_WAREHOUSE_ENDUSER: 3, // Renting warehouse on end user
    DELIVERY_PERSONAL_WAREHOUSE: 4 // Renting warehouse from personal warehouse on end user
}
