export const ContractMessage = {
    WARNING_SELECT_ITEM: "Please select an item in the list!",
    WARNING_AMOUNT_MAX: "Please enter the amount less than or equal ",
    SUCCESS_RENTED: "You have successfully rented the new space!",

    WARNING_PACKAGE_DESCRIPTION: "Please input package description",
    WARNING_PRODUCT_CODE: "Please input product code!",
    WARNING_PRODUCT_NAME: "Please input product name!",
    WARNING_PRODUCT_CATEGORY: "Please input product category!",
    WARNING_SIZE: "Please input product size!",
    WARNING_WEIGHT: "Please input product weight!",
    WARNING_SPECIAL_SERVICE: "Please input product service!",
    WARNING_AMOUNT: "Please input product amount!",
    WARNING_LEASING_DATE: "Please input leasing date!",
    WARNING_NUMBER_MONTH: "Please input number month!",
    WARNING_SELECT_AREA: "Please select area!",
    WARNING_SELECT_WAREHOUSE: "Please select warehouse!",
    WARNING_SELECT_ROUTE: "Please select route!",
    WARNING_NAME: "Please input renter name!",
    WARNING_PHONE: "Please input valid phone!",
    WARNING_MAIL: "Please input renter email!",
    WARNING_ADDRESS: "Please input Address Detail and Street Name!",
    WARNING_QUANTITY_TO_SELL:"Please input product quantity to sell!",

    WARNING_SELECT_LOCATION: "Please select location!",
    WARNING_SELECT_STATION: "Please select station!",
}