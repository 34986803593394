import { withRouter } from "react-router-dom";
import { BeecowMenu } from "./menu.component";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';


const mapStateToProps = ({ session }) => {
    return {
        userId: session?.auth?.user?.userid
    };
};

export default compose(
    withTranslation("translations"),
    withRouter,
    connect(mapStateToProps, null),
)(BeecowMenu)