import productsDataService from "../../data-services/product/products-data.service"
import actionTypes from "./products.types"

export const getProductDetail = (productId) => {
    return async (dispatch) => {
        const res = await productsDataService.getProductDetail(productId)
        dispatch({
            type: actionTypes.GET_PRODUCT_DETAILS,
            payload: res || {}
        })
    }

}