import { connect } from "react-redux";
import { TopBar } from "./topbar.component";
import { resetSession } from "../../modules/session/session.actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { setJustLoggedIn } from "../../modules/app/app.actions";

const mapStateToProps = (state) => {
    return {
        signedInUser: state.session?.auth?.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: (userId) => dispatch(resetSession(userId)),
        setJustLoggedIn: (value) => dispatch(setJustLoggedIn(value))
    }
}

export default compose(
    withTranslation("translations"),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
  )(TopBar);
  