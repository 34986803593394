import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { Table, Form, Card, Image, Modal } from 'antd';
import Button from 'antd-button-color';
import { ExclamationCircleOutlined  } from '@ant-design/icons';
import Layout, { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import { FORMAT_DATE } from '../../constants/common';
import TopBar from '../../components/topbar';
import contractDataService from '../../data-services/contracts/contracts-data.service';
import { EStatusContractLeasing, showAlert } from '../../constants/constants';
import { ImageConstants } from '../../constants/system.constants';

export class LeaseSpaceScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
        };
    }

    componentWillMount() {
        this.InitDataTable()
    }

    InitDataTable() {
        const userId = this.props.userid
        contractDataService.getLeaseSpaceByUserId(userId).then((res) => {
            if (res) {
                const data = this.getData(res);
                this.setState({
                    dataSource: data,
                });
            }
        })
    }

    getColumns(){
        const { t } = this.props;
        let columns =[
            {
                title: t('leasingContractPage.provider'),
                dataIndex: 'companyLogoUrl',
                width: '8%',
                sorter: (a, b) => a.companyLogoUrl.localeCompare(b.companyLogoUrl),
                render: (text, record) => {
                    return <Image
                        style={{ objectFit: 'contain', height: 60 }}
                        src={record.companyLogoUrl}
                        fallback={ImageConstants.NO_IMAGE}
                    />
                }
            },
            {
                title: t('leasingContractPage.contractId'),
                dataIndex: 'contractId',
                width: '7%',
                sorter: (a, b) => a.contractId.localeCompare(b.contractId),
                render: (text, data) => <Link to={`/contract/${data.id}`}>{text}</Link>
            },
            {
                title: t('leasingContractPage.warehouseLocation'),
                dataIndex: 'location',
                width: '23%',
                ellipsis: true,
                render: (text) => {
                    return <div style={{ display: 'grid' }}>
                        {
                            text.map((value) => <a className={'white-space-pre-wrap'}>{value}</a>)
                        }
                    </div>
                }
            },
            {
                title: t('leasingContractPage.leasingTime'),
                dataIndex: 'leasingTime',
                width: '20%',
                sorter: (a, b) => a.leasingTime.localeCompare(b.leasingTime),
                ellipsis: true,
            },
            {
                title: t('leasingContractPage.rentalPrice'),
                dataIndex: 'price',
                width: '11%',
                ellipsis: true,
                sorter: (a, b) => a.price.localeCompare(b.price),
                render: (text) => {
                    return <p className={'white-space-pre-wrap'}>{ text }</p>
                }
            },
            {
                title: t('leasingContractPage.status'),
                dataIndex: 'status',
                width: '10%',
                sorter: (a, b) => a.status?.statusName.localeCompare(b.status?.statusName),
                render: (status, record) => {
                    var isWaitingForVerify = EStatusContractLeasing.WaitingForVerify.statusName.toLowerCase().includes(status?.statusName.toLowerCase());
                    if (isWaitingForVerify) {
                        return (
                           <div>
                                <p className='status'>{t(status?.languageKey || status?.statusName)}</p>
                                <div style={{ display: 'flex', justifyContent: 'center'}}>
                                    <div className='text-center' style={{ marginRight: 10}}>
                                        {
                                            record?.contractTypeId == 0 ? <Link className='ant-btn ant-btn-primary' to={`/contract/${record.id}/edit-contract-rent-area`} >{t('button.edit')}</Link>
                                            : <Link className='ant-btn ant-btn-primary' to={`/contract/${record.id}/edit`} >{t('button.edit')}</Link>
                                        }
                                    </div>
                                    <div className='text-center'>
                                        <Button className='ant-btn ant-btn-primary ant-btn-dangerous' onClick={() => this.clickButtonDelete(record?.id)} >{t('button.delete')}</Button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    return (<p className='status'>{t(status?.languageKey || status?.statusName)}</p>)
                }
            }
        ];

        let mergedColumns = columns.map((col) => {
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    inputType: 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                }),
            };
        });

        return mergedColumns;
    };

    clickButtonDelete(contractId){
        const currentComponent = this;
        const { t } = this.props;
        Modal.confirm({
          title: `${t('confirm.removeOrder')}`,
          icon: <ExclamationCircleOutlined />,
          onOk() {
            currentComponent.handleDeleteContract(contractId);
          },
          okType: 'danger',
          okText: `${t('button.ok')}`,
          cancelText: `${t('button.cancel')}`,
        });
    };

    handleDeleteContract = (contractId) => {
        const { t } = this.props;
        let message = '';
        let description = '';
        contractDataService.deleteContractById(contractId).then((res) =>{
            if(res){
                message = t('confirm.success');
                description = t('confirm.deleteSuccess');
                showAlert(message, description, true);
                this.InitDataTable();
            }else{
                message = t('confirm.warning');
                description = t('confirm.deleteFail');
                showAlert(message, description, false);
            }
        });
    };

    getData = (data) => {
        return data?.map((x, i) => ({
            id: x.id,
            key: x.id,
            contractId: x.code,
            location: this.formatLocation(x.stationCode, x.stationName, x.location),
            leasingTime: moment.utc(x.applyDate).local().format(FORMAT_DATE) + ' - ' + moment.utc(x.expiryDate).local().format(FORMAT_DATE),
            price: x.priceLeasing,
            status: x.status,
            companyLogoUrl: x.logoPath,
            totalFee: x.totalFee,
            contractTypeId: x.contractTypeId
        }));
    }

    formatLocation = (strCode, strName, strLocation) => {
        var infoLocation = [strCode, strName, strLocation]
        return infoLocation
    }

    clickBtnAdd = () => {
        this.props.history.push('/leasing-contract/create')
    };

    clickBtnAddNewArea = () => {
        this.props.history.push('/leasing-contract/rent-new-area')
    };

    render() {
        const { t } = this.props;
        const { dataSource } = this.state;
        let columns = this.getColumns();
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t('leasingContractPage.title')} />
                    <span className='leasing-subtitle'>{t('leasingContractPage.subTitle')} </span>
                    <Content className='content'>
                        <Card>
                            <div className='form-btn'>
                                <Button className='btn-add' onClick={() => this.clickBtnAddNewArea()}>{t('leasingContractPage.rentNewArea')}<span className="btn-label">
                                    <i className='fa fa-plus-circle' aria-hidden='true'></i></span>
                                </Button>
                                <Button className='btn-add' onClick={() => this.clickBtnAdd()}>{t('leasingContractPage.rentNewSpace')}<span className="btn-label">
                                    <i className='fa fa-plus-circle' aria-hidden='true'></i></span>
                                </Button>
                            </div>
                            <Form ref={this.formRef}>
                                <Table
                                    dataSource={dataSource}
                                    columns={columns}
                                    bordered
                                />
                            </Form>
                        </Card>
                    </Content>
                </div>
            </Layout>
        );
    }
}