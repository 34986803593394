import React, { Component } from 'react';
import { Button, message, Steps } from 'antd';
import Layout from 'antd/lib/layout/layout';
import TopBar from '../../../components/topbar';
import { BookingInfo } from '../../../components/booking-info/booking-info.component';
import { ProductInfo } from '../../../components/product-info/product-info.component';
import { WarehouseSelection } from '../../../components/warehouse-selection/warehouse-selection.component';
import { LeasingDelivery } from '../../../components/delivery/delivery.component';
import { PaymentMethod } from '../../../components/payment-method/payment-method.component';
import { Summary } from '../../../components/summary/summary.component';
import userDataService from '../../../data-services/user/user-data.service';
import leasingContractDataService from '../../../data-services/leasing-contract/leasing-contract-data.service';
import { Utilities } from '../../../common/utilities';
import './create-leasing-contract.scss';
import deliveryDataService from '../../../data-services/delivery/delivery-data.service';
import { DeliveryTypeConstants } from '../../../constants/delivery.constants';

const { Step } = Steps;

const screens = {
    bookingInfo: 0,
    productInfo: 1,
    warehouseSelection: 2,
    leasingDelivery: 3,
    paymentMethod: 4,
    summary: 5
}

export class CreateLeasingContractScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            initialData: {
                bookingInfo: {
                    renter: {
                        id: this.props.userId,
                        fullName: '',
                        phone: '',
                        email: '',
                        userTypeId: '',
                        paymentTypeId: '',
                        address: {
                            street: null,
                            city: {
                                id: null,
                                name: null
                            },
                            district: {
                                id: null,
                                name: null
                            },
                            ward: {
                                id: null,
                                name: null
                            }
                        }
                    },
                    isSender: true,
                    sender: {
                        fullName: '',
                        phone: '',
                        address: {
                            street: null,
                            city: {
                                id: null,
                                name: null
                            },
                            district: {
                                id: null,
                                name: null
                            },
                            ward: {
                                id: null,
                                name: null
                            }
                        }
                    },
                },
                productInfo: {
                    description: '',
                    products: [{
                        key: Utilities.randomUuid(),
                        name: '',
                        packageTypeId: '',
                        othersCategory: '',
                        length: '',
                        width: '',
                        height: '',
                        weight: '',
                        inventory: '',
                        total: '',
                    }],
                },
                warehouseInfo: {
                    leaseDate: '',
                    months: 1,
                    selectedAreaId: '',
                    selectedWarehouseId: '',
                    selectedWarehouse: {},
                },
                deliveryInfo: {
                    isShipping: true,
                    specialServiceId: '',
                    deliveryOptionId: '',
                    deliveryLocationId: '',
                    arrivalStationId: '',
                    selectedRouteId: '',
                    selectedRoute: {},
                },
                paymentInfo: {
                    paymentMethodId: '',
                    leasingFeeCalculator: {},
                }
            },
            warehouseDeliveryType: DeliveryTypeConstants.DELIVERY_WAREHOUSE_ENDUSER,
            personalWarehouseDeliveryType: DeliveryTypeConstants.DELIVERY_PERSONAL_WAREHOUSE,
            productCategories: [],
            leasingAreas: [],
            stationTypeList: [],
            specialServices: [],
            deliveryOptions: [],
            warehouseDeliveryOptions: [],
            paymentMethods: [],
        }
        this.bookingFormRef = React.createRef();
        this.productFormRef = React.createRef();
        this.warehouseFormRef = React.createRef();
        this.deliveryFormRef = React.createRef();
        this.paymentFormRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        let { current, initialData, productCategories, leasingAreas, stationTypeList, specialServices, deliveryOptions, paymentMethods } = this.state;
        let { bookingInfo, productInfo, warehouseInfo, deliveryInfo, paymentInfo } = initialData;
        let steps = [
            {
                title: t('createOrEditNewSpacePage.steps.bookingInformation'),
                content: <BookingInfo
                    t={t}
                    ref={this.bookingFormRef}
                    initialData={bookingInfo} />,
            },
            {
                title: t('createOrEditNewSpacePage.steps.productInfo'),
                content: <ProductInfo
                    t={t}
                    ref={this.productFormRef}
                    initialData={productInfo}
                    categories={productCategories} />,
            },
            {
                title: t('createOrEditNewSpacePage.steps.selectWarehouse'),
                content: <WarehouseSelection
                    t={t}
                    ref={this.warehouseFormRef}
                    initialData={warehouseInfo}
                    products={productInfo?.products}
                    areas={leasingAreas}
                    stationTypeList={stationTypeList}
                />,
            },
            {
                title: t('createOrEditNewSpacePage.steps.deliveryMethod'),
                content: (
                    <LeasingDelivery
                        t={t}
                        ref={this.deliveryFormRef}
                        initialData={deliveryInfo}
                        specialServices={specialServices}
                        deliveryOptions={deliveryOptions}
                        bookingInfo={bookingInfo}
                        products={productInfo?.products}
                        warehouseInfo={warehouseInfo} />
                ),
            },
            {
                title: t('createOrEditNewSpacePage.steps.payment'),
                content: (
                    <PaymentMethod
                        t={t}
                        ref={this.paymentFormRef}
                        initialData={paymentInfo}
                        paymentMethods={paymentMethods}
                        bookingInfo={bookingInfo}
                        warehouseInfo={warehouseInfo}
                        deliveryInfo={deliveryInfo} />
                ),
            },
            {
                title: t('createOrEditNewSpacePage.steps.summarize'),
                content: (
                    <Summary t={t}
                        bookingInfo={bookingInfo}
                        productInfo={productInfo}
                        warehouseInfo={warehouseInfo}
                        deliveryInfo={deliveryInfo}
                        paymentInfo={paymentInfo}
                        categories={productCategories}
                        areas={leasingAreas}
                        specialServices={specialServices}
                        deliveryOptions={deliveryOptions}
                        paymentMethods={paymentMethods} />
                ),
            },
        ];
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t('createOrEditNewSpacePage.title')} />
                    <div className='bg-white clearfix beecow-leasing-contract'>
                        <Steps
                            type='navigation'
                            current={current}
                            className='site-navigation-steps'
                        >
                            {steps.map((item) => {
                                return <Step key={item.title} title={item.title} />
                            })}
                        </Steps>
                        <div className='steps-content'>{steps[current].content}</div>
                        <div className='steps-action'>
                            {current > 0 && (
                                <Button type='primary' onClick={this.prev.bind(this)}>{t('button.back')}</Button>
                            )}
                            {current < steps.length - 1 && (
                                <Button type='primary' onClick={this.next.bind(this)}>{t('button.next')}</Button>
                            )}
                            {current === steps.length - 1 && (
                                <Button type='primary' onClick={this.handleFinish.bind(this)}>{t('button.done')}</Button>
                            )}
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    async componentWillMount() {
        let getUserProfileTask = userDataService.getUserProfileById(this.props.userId);
        let getInitialDataTask = leasingContractDataService.getInitData();
        let [userProfile, initialData] = await Promise.all([getUserProfileTask, getInitialDataTask]);

        let renterInfo = { ...this.state.initialData.bookingInfo.renter, ...userProfile };
        renterInfo['address']['city']['id'] = userProfile.address?.city?.id;
        renterInfo['address']['city']['name'] = userProfile.address?.city?.name;
        renterInfo['address']['district']['id'] = userProfile.address?.district?.id;
        renterInfo['address']['district']['name'] = userProfile.address?.district?.name;
        renterInfo['address']['ward']['id'] = userProfile.address?.ward?.id;
        renterInfo['address']['ward']['name'] = userProfile.address?.ward?.name;

        this.setState({
            ...this.state,
            initialData: {
                ...this.state.initialData,
                bookingInfo: {
                    ...this.state.initialData.bookingInfo,
                    renter: renterInfo
                },
                paymentInfo: {
                    ...this.state.initialData.paymentInfo,
                    paymentMethodId: initialData.paymentTypes[0].id
                },
            },
            productCategories: initialData.packageCategory,
            leasingAreas: initialData.areas,
            stationTypeList: initialData.stationTypeList,
            specialServices: initialData.specialServices,
            warehouseDeliveryOptions: initialData.deliveries,
            paymentMethods: initialData.paymentTypes
        }, () => {
            this.refresh();
        });
    }

    next() {
        let currentFormRef;
        switch (this.state.current) {
            case screens.bookingInfo:
                currentFormRef = this.bookingFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, bookingInfo: currentFormRef.current.getFieldsValue() } })
                break;
            case screens.productInfo:
                currentFormRef = this.productFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, productInfo: currentFormRef.current.getFieldsValue() } })
                break;
            case screens.warehouseSelection:
                currentFormRef = this.warehouseFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, warehouseInfo: currentFormRef.current.getFieldsValue() } }, () => {
                    let selectedWarehouse = this.state.initialData?.warehouseInfo?.selectedWarehouse;
                    this.getDeliveries(selectedWarehouse.isPersonalWarehouse);
                })
                break;
            case screens.leasingDelivery:
                currentFormRef = this.deliveryFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, deliveryInfo: currentFormRef.current.getFieldsValue() } })
                break;
            case screens.paymentMethod:
                currentFormRef = this.paymentFormRef;
                this.setState({ ...this.state, initialData: { ...this.state.initialData, paymentInfo: currentFormRef.current.getFieldsValue() } })
                break;
            default:
                break;
        }
        if (currentFormRef && currentFormRef.current) {
            currentFormRef.current.validateFields()
                .then(() => {
                    let nextStep = this.state.current + 1;
                    this.setState({ current: nextStep }, () => {
                        this.refresh();
                    });
                })
                .catch(ex => console.log(ex));
        }
    }

    prev() {
        var previousStep = this.state.current - 1;
        this.setState({
            current: previousStep
        }, () => {
            this.refresh();
        });
    }

    refresh() {
        switch (this.state.current) {
            case screens.bookingInfo:
                this.bookingFormRef.current.setFieldsValue(this.state.initialData.bookingInfo);
                break;
            case screens.productInfo:
                this.productFormRef.current.setFieldsValue(this.state.initialData.productInfo);
                break;
            case screens.warehouseSelection:
                this.warehouseFormRef.current.setFieldsValue(this.state.initialData.warehouseInfo);
                break;
            case screens.leasingDelivery:
                this.deliveryFormRef.current.setFieldsValue(this.state.initialData.deliveryInfo);
                break;
            case screens.paymentMethod:
                this.paymentFormRef.current.setFieldsValue(this.state.initialData.paymentInfo);
                break;
            default:
                break;
        }
    }

    handleFinish() {
        const { t } = this.props;
        let { initialData } = this.state;

        this.props.addNewContract(initialData).then(res => {
            if (res === true) {
                message.success(t('createOrEditNewSpacePage.youHaveSuccessfullyRentedNewSpace'));
                this.props.history.push(`/leasing-contract`);
            }
        });
    }

    getDeliveries = (isPersonalWarehouse) => {
        var deliveryType = isPersonalWarehouse ? this.state.personalWarehouseDeliveryType : this.state.warehouseDeliveryType
        deliveryDataService.getDeliveryByDeliveryType(deliveryType).then(res => {
            if (res.deliveries.length > 0) {
                this.setState({ deliveryOptions: res.deliveries });
            }
        })
    }

}