import { Table, Button, Image } from 'antd';
import moment from 'moment';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import Layout, { Content } from 'antd/lib/layout/layout';
import TopBar from '../../components/topbar';
import { getSpecialServiceIcon } from '../../utils/order';
import { deliveryHouseMethods } from '../../constants/order-constant';
import iconWareHouse from '../../assets/icons/warehouse.png';
import './view-delivery-list-detail.screen.scss';

const unitWeight = 'Kg';
export class ViewDeliveryListDetailScreen extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.state = {
            searchText: '',
            searchedColumn: '',
        };
    }

    componentDidMount() {
        const { id } = this.props?.match?.params || {};
        if (this.props && this.props.getDeliveryListDetailById) {
            this.props.getDeliveryListDetailById(id);
        } else {
            console.log('Cannot get delivery list detail');
        }
    }

    getTableColumns(orders) {
        const { t } = this.props;
        let deliveries = orders?.map((d) => {
            return { text: d.deliveryMethod, value: d.deliveryMethod };
        });
        let delivieryOptions = deliveries?.filter(
            (d, i) => deliveries?.findIndex((o) => o.value === d.value) === i
        );
        let packages = orders?.map((d) => {
            return { text: d.packageInfo, value: d.packageInfo };
        });
        let packageInfoOptions = packages?.filter(
            (d, i) => packages?.findIndex((o) => o.value === d.value) === i
        );
        let columns = [
            {
                className: 'w-120px',
                title: t('tableColumn.orderId'),
                dataIndex: 'orderId',
                sorter: (a, b) => a.orderId.localeCompare(b.orderId),
                render: (text, data) => (
                    <Link target='_blank' to={`/detail-order/${data.id}`}>
                        {text}
                    </Link>
                ),
                fixed:'left'
            },
            {
                className: 'w-270px',
                title: t('tableColumn.receiverAddress'),
                dataIndex: 'toRecipient',
                render: (text) => <span dangerouslySetInnerHTML={{ __html: text }} />,
            },
            {
                title: t('tableColumn.packageInfo'),
                dataIndex: 'packageInfo',
                sorter: (a, b) => a.packageInfo.localeCompare(b.packageInfo),
                render: (name) => (
                    <span>
                        {name}
                        {getSpecialServiceIcon(name)}
                    </span>
                ),
                filters: packageInfoOptions,
                onFilter: (value, record) => record.packageInfo.indexOf(value) === 0,
            },
            {
                title: t('tableColumn.deliveryMethod'),
                dataIndex: 'deliveryMethod',
                sorter: (a, b) => a.deliveryMethod.localeCompare(b.deliveryMethod),
                render: (text) => (
                    <span>
                        {text}
                        <br />
                        {deliveryHouseMethods.includes(text?.toLowerCase()) && (
                            <Image src={iconWareHouse} width={30} />
                        )}
                    </span>
                ),
                filters: delivieryOptions,
                onFilter: (value, record) => record.deliveryMethod.indexOf(value) === 0,
            },
            {
                className: 'w-140px',
                title: t('tableColumn.totalWeight'),
                dataIndex: 'totalWeight',
                sorter: (a, b) => a.totalWeight - b.totalWeight,
                render: (text) => (
                    <span>
                        {text} {unitWeight}
                    </span>
                ),
            },
            {
                className: 'w-140px',
                title: t('tableColumn.totalProductsInPackage'),
                dataIndex: 'totalProductsInPackage',
                sorter: (a, b) => a.totalProductsInPackage - b.totalProductsInPackage,
            },
            {
                className: 'w-140px',
                title: t('tableColumn.totalItems'),
                dataIndex: 'totalItemsInPackage',
                sorter: (a, b) => a.totalItemsInPackage - b.totalItemsInPackage,
            },
            {
                className: 'w-120px',
                title: t('tableColumn.createdDate'),
                dataIndex: 'createdDate',
                sorter: (a, b) => moment(a.createdDate) - moment(b.createdDate),
                render: (text) => (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: moment
                                .utc(text)
                                .local()
                                .format('hh:mm A <br> DD/MM/YYYY'),
                        }}
                    />
                ),
            },
        ];
        return columns;
    }

    getRecipientInfo(order) {
        let formattedAddress =
            this.getFormattedAddress(order?.addressRecipient) || this.props.t('text.noAddress');
        let receiverInfo = [
            order.receiverName,
            order.receiverPhone,
            formattedAddress,
        ];
        return receiverInfo.join('<br/>');
    }

    getFormattedAddress(address) {
        let arr = [];
        if (address && address.street) {
            arr.push(address.street);
        }
        if (address && address.ward) {
            let ward = address.ward.prefix + ' ' + address.ward.name;
            arr.push(ward);
        }
        if (address && address.district) {
            let district = address.district.prefix + ' ' + address.district.name;
            arr.push(district);
        }
        if (address && address.city) {
            arr.push(address.city.name);
        }
        return arr.join(', ');
    }

    onChange(pagination, filters, sorter, extra) {
        console.log('params', pagination, filters, sorter, extra);
    }

    handleClosePage() {
        this.props.history.push('/home');
    }

    transform = (order) => {
        var result = {
            key: order.id,
            id: order.id,
            orderId: order.code,
            toRecipient: this.getRecipientInfo(order),
            deliveryMethod: order.delivery?.name,
            packageInfo: order.specialOption?.name,
            createdDate: order.createdTime,
            amount: order.amount,
            packageSizeWeight: order.packageSizeWeight,
            totalWeight: order.totalWeight,
            totalProductsInPackage: order.totalProductsInPackage,
            totalItemsInPackage: order.totalItemsInPackage,
        };

        if (order.status.subStatusName != null) {
            result.status = `${order.status.statusName}-${order.status.subStatusName}`;
        } else {
            result.status = order.status.statusName;
        }

        return result;
    };

    render() {
        const { t } = this.props;
        const { vehicle, deliveryCode, createdTime, orders } = this.props;
        let rows = orders?.map((o) => this.transform(o)) || [];
        let columns = this.getTableColumns(rows);

        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t('title.viewDeliveryListDetail')}/>
                    <div className='bg-white clearfix'>
                        <Content className='content'>
                            <div className='list-pickup'>
                                <div className='review-vehicle'>
                                    <div className='pull-left'>
                                        <p>
                                            <b>{t('tableColumn.deliveryListId')}: </b>
                                            {deliveryCode}
                                        </p>
                                        <p>
                                            <b>{t('tableColumn.createdDate')}: </b>
                                            {moment
                                                .utc(createdTime)
                                                .local()
                                                .format('hh:mm A DD/MM/YYYY')}
                                        </p>
                                    </div>

                                    <div className='pull-right'>
                                        <p>
                                            <b>{t('text.selectedVehicle')}: </b>
                                            {vehicle?.code}
                                        </p>
                                        <p>
                                            <b>{t('text.vehicleType')}: </b>
                                            {vehicle?.type?.name}
                                        </p>
                                    </div>
                                </div>
                                <Table
                                    bordered
                                    columns={columns}
                                    dataSource={rows}
                                    onchange={this.onChange}
                                    scroll={{x:'100vw'}}
                                />
                            </div>

                            <div className='redirect-page pull-right'>
                            <Button
                                className='btn-action'
                                onClick={this.handleClosePage.bind(this)}
                            >
                                { t('button.close')}
                            </Button>
                            <Button className='btn-action btn-next'> {t('button.print')}</Button>
                        </div>
                        </Content>
                    </div>
                </div>
            </Layout>
        );
    }
}
