import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './menu.component.scss';

const { Sider } = Layout;
const { SubMenu } = Menu;

export class BeecowMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuCollapsed: false,
            current: 'app.dashboard',
            currentSubMenu: [],
            subMenuCollapsed: false,
        };
    }

    render() {
        const { listMenu, routes } = this.props;
        const { menuCollapsed } = this.state;
        const currentPath = this.props?.currentPath?.path;
        const selectedItem = routes?.find(i => i.path === currentPath);
        let selectedKey = (selectedItem && selectedItem.isMenu) ? selectedItem.key : this.state.current;
        if (selectedItem && !selectedItem.isMenu && selectedItem.focus) {
            selectedKey = selectedItem.focus;
        }
        const menu = listMenu?.filter(i => i.key && i.isMenu === true);
        return (
            <Sider
                style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    height: '100vh',
                    position: 'sticky',
                    top: 0,
                    left: 0
                }}
                className='beecow-sider-menu no-print scrollbar-warning'
                collapsible
                collapsed={menuCollapsed}
                onCollapse={() => this.toggle()}
            >
                <div className={'beecow-toggle-btn ' + (!menuCollapsed ? 'beecow-flex-right mt-1' : 'beecow-flex-center mt-4 mb-4')} onClick={this.toggle}>
                    {this.state.menuCollapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined className='mt-0 mr-1' />}
                </div>
                <Menu
                    mode='inline'
                    theme='light'
                    selectedKeys={[selectedKey]}
                    openKeys={this.state.currentSubMenu}
                    onOpenChange={this.onOpenChange}
                    onClick={this.handleClick}>
                    {!menuCollapsed &&
                        <div className='beecow-logo mb-4 mt-3'>
                            <div className='logo'>
                                <div className='suffix d-flex justify-content-end'>
                                    <span className='mb-1'>END USER</span>
                                </div>
                                <div className='name d-flex justify-content-center'>
                                    <h1>BEECOW</h1>
                                </div>
                            </div>
                        </div>
                    }
                    {this.renderMenu(menu, selectedKey)}
                </Menu>
            </Sider>
        );
    }

    renderMenu(menu, selectedKey) {
        return menu?.map((item) => {
            if (item?.childs && item?.childs.length > 0) {
                const subMenu = item?.childs?.filter(i => i.key && i.isMenu === true);
                return this.renderSubMenu(item, subMenu, selectedKey);
            } else {
                return this.renderMenuItem(item);
            }
        });
    }

    renderMenuItem(item) {
        const { t } = this.props;
        return (
            <Menu.Item
                key={item.key}
                icon={item.icon && <><span className='ant-menu-item-icon'><i className={item.icon}></i></span></>}>
                {item.path && <Link to={item.path} />}
                {t(item.name)}
            </Menu.Item>
        );
    }

    renderSubMenu(item, menu, selectedKey) {
        const { t } = this.props;
        if (this.state.currentSubMenu.length == 0) {
            let matched = false;
            menu?.forEach(element => {
                if (element.key === selectedKey) {
                    matched = true;
                }
            });

            if (matched && !this.state.subMenuCollapsed) {
                this.setState({ currentSubMenu: [item.key] });
            }
        }

        return (
            <SubMenu
                key={item.key}
                icon={<><span className='ant-menu-item-icon'><i className={item.icon}></i></span></>}
                title={t(item.name)}>
                {this.renderMenu(menu, selectedKey)}
            </SubMenu>
        );
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    };

    handleClick = e => {
        this.setState({
            current: e.key,
        });
    };

    onOpenChange = items => {
        this.setState({ currentSubMenu: items, subMenuCollapsed: !this.state.subMenuCollapsed });
    };
}