import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Input, Button, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import userDataService from '../../data-services/user/user-data.service';
import './reset-password-by-email.screen.scss';
import TopBar from '../../components/topbar';

export class ResetPasswordByEmailScreen extends Component {
  constructor(props) {
        super(props);
        this.state = {
            refreshTokenKey: props?.match?.params?.refreshTokenKey
        };
    }

    componentDidMount() {
        const { refreshTokenKey } = this.state;
        const { uid } = this.props.match?.params;
        if(this.props.isAuthenticated) {
            this.props.history.push(`/home`)
        } else {
            userDataService.checkRefeshToken(refreshTokenKey).then( res => {
                if(!res.success) {
                  this.props.history.push(`/login`)
                }
            });
        }
    }

    onFinish = values => {
        let self = this;
        const { t } = this.props;
        if (values.newPassWord != values.confirmNewPassWord) {
            message.error(`${t('message.alertNewPassword')}`);
            return;
        }
        let request =
        {
            RefreshTokenKey: self.state.refreshTokenKey,
            NewPassWord: values?.newPassWord
        };
        userDataService.updatePasswordByEmail(request).then( res => {
            if(res.success) {
                message.success(`${t('confirm.updateSuccess')}`);
                this.props.history.push(`/login`)
            }
        });
    };

    render() {
        const { t } = this.props;
        return (
        <div className='w-100'>
            <TopBar />
            <div className='m-auto beecow-login-bg'>
            <Form
                ref={this.formRef}
                name='normal_login'
                className='m-auto centered shadow p-4 pt-5 mb-5 bg-white rounded login-form'
                onFinish={this.onFinish}
            >
                <div className='mb-5 text-center'>
                <div className='logo'>
                    <div className='name d-flex justify-content-center'>
                    <h1>BEECOW</h1>
                    </div>
                </div>
                </div>
                <div>
                <Form.Item
                    className='mb-4'
                    name='newPassWord'
                    rules={[
                        {
                            required: true,
                            message: t('loginPage.pleaseInputYourPassword')
                        }, { min: 8, message: `${t('message.passwordMini')}` }
                    ]}
                >
                    <Input
                    prefix={<LockOutlined className='site-form-item-icon' />}
                    type='password'
                    placeholder={t('placeholder.newPassword')}
                    />
                </Form.Item>
                
                <Form.Item 
                    className='mb-5'
                    name='confirmNewPassWord'
                    rules={[
                        {
                            required: true,
                            message: t('loginPage.pleaseInputYourPassword')
                        }, { min: 8, message: `${t('message.passwordMini')}` }
                    ]}
                >
                    <Input
                    prefix={<LockOutlined className='site-form-item-icon' />}
                    type='password'
                    placeholder={t('placeholder.confirmPassword')}
                    />
                </Form.Item>
                </div>
                <Form.Item>
                <Button
                    type='primary'
                    htmlType='submit'
                    className='login-form-button col-12'
                >
                    {t('button.update')}
                </Button>
                </Form.Item>
            </Form>
            </div>
        </div>
        );
    }
}
