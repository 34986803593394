import React, { Component } from 'react';
import { Form, Input } from "antd";
import { ContractMessage } from '../../common/contract.message';
import PropTypes from 'prop-types';
import AddressComponent from '../address/address-component';
import './renter-info.scss';

export class RenterInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            initialData: this.props.initialData,
        }
        this.formRef = React.createRef();
        this.formAddressRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        let { initialData } = this.state;
        return (
            <Form
                className="beecow-renter-info"
                ref={this.formRef}
                initialValues={initialData}>
                <h2 className="text-center">{t('createOrEditNewSpacePage.bookingForm.bookingInformation')}</h2>
                <div className="mt-5">
                    <label>{t('createOrEditNewSpacePage.bookingForm.nameOfRenterInContact')}</label>
                    <div className="beecow-input-icon">
                        <i className="fa fa-user-o" aria-hidden="true"></i>
                        <Form.Item 
                            name="fullName"
                            className="col-12 pr-2" 
                            rules={[{ required: true, message:t('createOrEditNewSpacePage.bookingForm.pleaseInputRenterName') }]}>
                            <Input placeholder={t('createOrEditNewSpacePage.bookingForm.nameOfRenterInContact')} />
                        </Form.Item>
                    </div>
                    <label>{t('createOrEditNewSpacePage.bookingForm.phoneOfRenterInContract')}</label>
                    <div className="beecow-input-icon">
                        <i className="fa fa-mobile" aria-hidden="true"></i>
                        <Form.Item
                            name="phone"
                            className="col-12 pr-2"
                            rules={[
                                { required: true, message: t('createOrEditNewSpacePage.bookingForm.pleaseInputValidPhone') },
                                { pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, message: ContractMessage.WARNING_PHONE }
                            ]}>
                            <Input placeholder={t('createOrEditNewSpacePage.bookingForm.phoneOfRenterInContract')} />
                        </Form.Item>
                    </div>
                    <label>{t('createOrEditNewSpacePage.bookingForm.emailOfRenterInContract')}</label>
                    <div className="beecow-input-icon">
                        <i className="fa fa-envelope-o" aria-hidden="true"></i>
                        <Form.Item 
                            name="email"
                            className="col-12 pr-2" 
                            rules={[{ required: true, message: ContractMessage.WARNING_MAIL }]}>
                            <Input placeholder={t('createOrEditNewSpacePage.bookingForm.phoneOfRenterInContract')} />
                        </Form.Item>
                    </div>
                    <label>{t('createOrEditNewSpacePage.bookingForm.addressOfRenterInContract')}</label>
                    <Form.Item
                        className="col-12 pr-2"
                        name="address">
                        <AddressComponent
                            t={t}
                            ref={this.formAddressRef}
                            initialData={initialData?.address} />
                    </Form.Item>
                </div>
            </Form>
        )
    }

    setFieldsValue(values) {
        this.formRef.current.setFieldsValue(values);
        this.formAddressRef.current.setFieldsValue(values?.address);
        this.setState({ initialData: values });
    }

    validateFields(){
        return this.formRef.current.validateFields();
    }

    getFieldsValue() {
        let fieldsValue = this.formRef.current.getFieldsValue();
        let mergedValues = { ...this.state.initialData, ...fieldsValue};
        return mergedValues;
    }
}

const propTypesAddress = {
    street: PropTypes.string,
    wardId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    districtId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

const propTypesRenter = {
    fullName: PropTypes.string, 
    phone: PropTypes.string, 
    email: PropTypes.string, 
    userTypeId: PropTypes.string, 
    paymentTypeId: PropTypes.string,
    address: PropTypes.shape(propTypesAddress),
}

RenterInfo.propTypes = {
    initialData: PropTypes.shape(propTypesRenter)
};