import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import EditWarehouseRegistrationScreen from "./edit-warehouse-registration.screen";
import { compose } from "redux";
import { withTranslation } from "react-i18next";


export default compose(
  withTranslation("translations"),
  withRouter
)(EditWarehouseRegistrationScreen);
