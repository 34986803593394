import React, { Component } from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import { Card, Col, Row, Table } from 'antd';
import TopBar from '../../components/topbar';
import "./view-product-detail.scss"
import moment from 'moment';
import { FORMAT_DATETIME } from '../../constants/common';

import { ProductDetailTableComponent } from "./product-detail-table/product-detail-table.component";

class ProductDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checkStrictly: false,
        }

        this.productDetailTableRef = React.createRef();
    }

    componentDidMount() {
        const { productId } = this.props?.match?.params
        this.props.getProductDetail(productId)
    }

    render() {
        const { t } = this.props;
        const { code, inventory, name, orders, packageType, total, inWarehouse, areas } = this.props.productDetails;
        var dataTable = this.getOrdersDataTable(orders);
        if (this.productDetailTableRef && this.productDetailTableRef.current) {
            this.productDetailTableRef.current.updateDataTable(dataTable);
        }

        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t('productDetailPage.title')} />
                    <Content className='content'>
                        <Card bordered={false} className="product-detail">
                            <div className="row">
                                <div className="col-sm-6 col-xl-6 col-lg-6">
                                    <div className="row">
                                        <div className="col-sm-4 col-md-4 infor-contract">
                                            <ul>
                                                <li><p><b>{t('productDetailPage.productId')}: </b></p></li>
                                                <li><p><b>{t('productDetailPage.productName')}: </b></p></li>
                                                <li><p><b>{t('productDetailPage.location')}: </b></p></li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-6  col-md-6 infor-contract content-data">
                                            <ul>
                                                <li><p>{code}</p></li>
                                                <li><p>{name}</p></li>
                                                <li>
                                                    {
                                                        areas?.map(({ code, spaceAreas }) => <p key="">{code}: <span>{spaceAreas.map(x => x.code).join(',')}</span></p>)
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-xl-6 col-lg-6">
                                    <div className="row">
                                        <div className="col-sm-4  col-md-4 infor-contract">
                                            <ul>
                                                <li><p><b>{t('productDetailPage.initial')}: </b></p></li>
                                                <li><p><b>{t('productDetailPage.current')}: </b></p></li>
                                                <li><p><b>{t('productDetailPage.inWarehouse')}: </b></p></li>
                                                <li><p><b>{t('productDetailPage.category')}: </b></p></li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-6  col-md-6 infor-contract content-data">
                                            <ul>
                                                <li><p>{total}</p></li>
                                                <li><p>{inventory}</p></li>
                                                <li><p>{inWarehouse}</p></li>
                                                <li><p>{t(packageType?.languageKey)}</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <ProductDetailTableComponent
                                    t={t}
                                    history={this.props.history}
                                    ref={this.productDetailTableRef}
                                    initialData={dataTable} />
                            </div>
                        </Card>
                    </Content>
                </div>
            </Layout>
        )
    }

    getOrdersDataTable = (orders) => {
        const { t } = this.props;
        return orders?.map((order, index) => {
            var { id, code, type, createdTime, quantity, status, orderItems } = order;
            return {
                key: id,
                index: index + 1,
                id,
                code,
                type,
                date: moment.utc(createdTime).local().format(FORMAT_DATETIME),
                quantity,
                status: t(status?.languageKey) || status?.statusName,
                children: orderItems?.map((i) => this.importInfoTransfer(i))?.filter((i) => i.quantity > 0),
            };
        });
    }

    importInfoTransfer = (importInfo) => {
        return {
            id: importInfo?.importList?.id,
            code: importInfo?.importList?.code,
            date: moment.utc(importInfo?.importList?.createdTime).local().format('DD/MM/YYYY'),
            quantity: importInfo?.importedAmount ?? 0,
            isChild: true
        }
    }
}

export default ProductDetail;