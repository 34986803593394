import React, { Component } from 'react';
import orderDataService from '../../data-services/orders/orders-data.service';
import Layout, { Content } from 'antd/lib/layout/layout';
import TopBar from '../../components/topbar';
import "./list-product.scss";
import Barcode from 'react-barcode';
import ReactDOM from 'react-dom';
import { Col, Row } from "antd";

export default class ListProductComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      order: {},
      showBarcode: false,
      displayValue: false
    }
  }

  componentDidMount() {
    const { orderId } = this.props?.match?.params || {}
    this.props.getOrderDetails(orderId)
    orderDataService.getOrderDetails(orderId).then(({ order }) => this.setState({ order }))

    window.onafterprint = () => {
      this.setState({ showBarcode: false })
    }
  }

  handleCheckAll(isCheckAll) {
    const { order } = this.state
    order.orderItems.forEach(o => { o.checked = !isCheckAll })
    this.setState({ order })
  }

  handleCheckItem(id) {
    const { order } = this.state
    var item = order.orderItems.find((i) => i.id === id)
    item.checked = !item.checked
    this.setState({ order })
  }

  handlePrintBarcodeProducts(listCheckeds) {
    this.setState({ showBarcode: true }, () => {
      listCheckeds.forEach((x) => {
        const elm = document.getElementsByClassName(x.product?.code)
        const textElm = elm[0].getElementsByTagName('text')[0]
        if (textElm) textElm.innerHTML = x.product?.code
      })
      window.print()
    })
  }

  backToOrderDetail() {
    const { orderId } = this.props?.match?.params || {}
    this.props.history.push(`/detail-order/${orderId}`)
  }

  render() {
    const { order, showBarcode, displayValue } = this.state || {}
    const isCheckAll = order.orderItems?.every((x) => x.checked)
    const listCheckeds = order.orderItems?.filter((i) => i.checked)
    return (
      <Layout className='bg-content'>
        <div className='bg-body'>
          <TopBar />
          <div className="bg-white clearfix">
            <Content className='beecow-summarize-info'>
              {
                showBarcode && listCheckeds?.map((x) => {
                  const code = `${order?.code},${x.product?.code},${x.amount}`
                  return <div className={x.product?.code}>
                    <Barcode
                      value={code}
                      format="code128"
                    />
                  </div>
                })
              }
              <div className="row no-print">
                <div className="col-md-6">
                  <div className="pull-left"><h3>Please select Products you want to print barocde:</h3></div>
                </div>
                <div className="col-md-4">
                  <div className="pull-right"> <h3>Order code: {order.code}</h3></div>
                </div>
              </div>
              <div className='row mt-4 mb-5 no-print'>
                <div className="col-sm-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            onChange={() => this.handleCheckAll(isCheckAll)}
                            checked={isCheckAll}
                          />
                        </th>
                        <th>No.</th>
                        <th>Product code</th>
                        <th>Product Name</th>
                        <th>Product Category</th>
                        <th className="text-center">Size in Length - Width - Height (m)</th>
                        <th className="text-center">Weight (Kg)</th>
                        <th className="text-center">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        order.orderItems?.map((p, index) => {
                          const { id, code, name, length, height, width, weight, total, packageType } = p.product || {}
                          return <tr key={p.id}>
                            <td><input type="checkbox" onChange={() => this.handleCheckItem(p.id)} checked={p.checked} /></td>
                            <td>{index + 1}</td>
                            <td>{code}</td>
                            <td>{name}</td>
                            <td>{packageType?.name}</td>
                            <td className="text-center">{length} x {width} x {height}</td>
                            <td className="text-center">{weight} Kg</td>
                            <td className="text-center">{total}</td>
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="print-products no-print">
                <Row>
                  <Col span={4} offset={20}>
                    <a className="pull-left text-primary" onClick={() => this.backToOrderDetail()}>Close</a>
                    <button className="pull-right" onClick={() => this.handlePrintBarcodeProducts(listCheckeds)}>Print</button>
                  </Col>
                </Row>
              </div>
            </Content>
          </div>
        </div>
      </Layout>
    )
  }
}
