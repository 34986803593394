import { DataService } from "../base/base-data.service";

class AreaStationDataService extends DataService {
    apiUrl = '/AreaStation';

    async getListAreaStation(id) {
        return await this.get(`${this.apiUrl}/${id}`);
    }

    async getAreaByWarehouseId(id) {
        return await this.get(`${this.apiUrl}/get-area-by-warehouse-id?warehouseId=${id}`);
    }

    async addAreaStation(data) {
        return await this.post(`${this.apiUrl}/addAreaStation`, data);
    }

    async deleteAreaStation(data) {
        return await this.post(`${this.apiUrl}/delete`, data);
    }

    async getAreaDetailSpace(areaId) {
        return await this.get(`${this.apiUrl}/getAreaById?areaId=${areaId}`);
    }

    async getAreaById(areaId) {
        return await this.get(`${this.apiUrl}/get-area-by-id?areaId=${areaId}`);
    }

    async updateAreaStation(data) {
        return await this.post(`${this.apiUrl}/update`, data);
    }

    async getSpaceDetail(data) {
        return await this.get(`${this.apiUrl}/spaceDetail?spaceId=${data}`)
    }

    async getSpaceByUnit(data) {
        return await this.post(`${this.apiUrl}/calc-maximum-allowed`, data)
    }

    async getSpacesByAreaIdWithFilter(areaId, page, pageSize, filter, isDropdownList) {
        return await this.get(`${this.apiUrl}/get-spaces-by-areaid?areaId=${areaId}&page=${page}&pageSize=${pageSize}&filter=${filter}&isDropdownList=${isDropdownList}`);
    }

    async getSpacesByAreaIdWithSearch(data) {
        return await this.post(`${this.apiUrl}/query-spaces-by-areaid`, data);
    }
}

let areaStationDataService = new AreaStationDataService();

export default areaStationDataService;