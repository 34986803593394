import React, { Component, createRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Input, Button, message, Switch } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import jwt_decode from 'jwt-decode';
import { Redirect } from 'react-router';
import loginDataService from '../../data-services/login/login-data.service';
import userDataService from '../../data-services/user/user-data.service';
import './login.screen.scss';
import TopBar from './../../components/topbar';
import UserRegisterForm from '../../components/user-register-form';
import ForgotPasswordForm from '../../components/forgot-password/forgot-password.component';

const CLAIMS_SID = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid';
const CLAIMS_NAME =
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name';
const CLAIMS_EMAIL =
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress';
const CLAIMS_ROLE =
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
const CLAIMS_CURRENT_STATION_ID = 'currentStationId';
const CLAIMS_COMPANY_IDENTITY = 'COMPANY_IDENTITY';
const CLAIMS_CHANGED_PASSWORD = 'CHANGED_PASSWORD';

export class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUsername: '',
      disabledLoginByPhone: true,
      isLoggedIn: false,
      loginByPhone: false,
      visibleRegisterForm: false,
      forgotPasswordVisible: false,
    };
    this.formRef = createRef();
  }

  componentDidMount() {
    const { uid } = this.props.match?.params;
    if (uid) {
      this.props?.signOut(uid);
      userDataService.getUserId(uid).then(res => {
        this.setState({ currentUsername: res?.email }, () => { this.refresh() });
      });
    }
    this.setState({
      isLoggedIn: this.props.isAuthenticated,
    });
  }

  render() {
    const { t } = this.props;
    const { uid } = this.props.match?.params;
    if (this.state.isLoggedIn && !uid) {
      return <Redirect to='/home' />;
    }

    return (
      <div className='w-100'>
        <TopBar />
        <div className='m-auto beecow-login-bg'>

          {
            !this.state.forgotPasswordVisible ? <>
              {this.state.visibleRegisterForm ? (
                <UserRegisterForm
                  t={t}
                  callBack={() => this.setState({ visibleRegisterForm: false })}
                />
              ) : (
                <Form
                  ref={this.formRef}
                  name='normal_login'
                  className='m-auto centered shadow p-4 pt-5 mb-5 bg-white rounded login-form'
                  initialValues={{ rememberMe: true, userName: this.state.currentUsername }}
                  onFinish={this.onFinish}
                >
                  <div className='mb-5 text-center'>
                    <div className='logo'>
                      <div className='name d-flex justify-content-center'>
                        <h1>BEECOW</h1>
                      </div>
                      <span className='mb-1'>END USER</span>
                    </div>
                  </div>
                  <Form.Item name='loginByPhone'>
                    <Switch
                      onChange={this.onChange}
                      disabled={this.state.disabledLoginByPhone}
                    />
                    <span className='ml-3' id='loginByPhone'>
                      {t('loginPage.loginByPhoneNumber')}
                    </span>
                  </Form.Item>
                  {!this.state.loginByPhone ? (
                    <div>
                      <Form.Item
                        name='userName'
                        rules={[
                          {
                            required: true,
                            message: `${t('loginPage.pleaseInputYourUsername')}`,
                          },
                        ]}
                      >
                        <Input
                          prefix={<UserOutlined className='site-form-item-icon' />}
                          placeholder={t('loginPage.username')}
                        />
                      </Form.Item>
                      <Form.Item
                        className='mb-0'
                        name='password'
                        rules={[
                          {
                            required: true,
                            message: t('loginPage.pleaseInputYourPassword')
                          }
                        ]}
                      >
                        <Input
                          prefix={<LockOutlined className='site-form-item-icon' />}
                          type='password'
                          placeholder={t('loginPage.password')}
                        />
                      </Form.Item>
                      <Form.Item>
                        <a
                          className='login-form-forgot float-right'
                          onClick={() => this.setState({ forgotPasswordVisible: true })}
                        >
                          {t('loginPage.forgotPassword')}
                        </a>
                      </Form.Item>
                    </div>
                  ) : (
                    <Form.Item
                      name='phoneNumber'
                      rules={[
                        {
                          required: true,
                          message: t('loginPage.pleaseInputYourPhoneNumber'),
                        },
                      ]}
                    >
                      <Input
                        prefix={<UserOutlined className='site-form-item-icon' />}
                        placeholder={t('loginPage.phoneNumber')}
                      />
                    </Form.Item>
                  )}

                  <Form.Item>
                    <Button
                      type='primary'
                      htmlType='submit'
                      className='login-form-button col-12'
                    >
                      {t('loginPage.logIn')}
                    </Button>
                    <span>{t('loginPage.dontHaveAccount')}</span>
                    <a
                      className='ml-2'
                      href='javascript:void(0)'
                      onClick={() => this.setState({ visibleRegisterForm: true })}
                    >
                      {t('loginPage.registerHere')}
                    </a>
                  </Form.Item>
                </Form>
              )}
            </>
              : <>
                <ForgotPasswordForm
                  t={t}
                  callBack={() => this.setState({ forgotPasswordVisible: false })}
                />
              </>
          }
        </div>
      </div>
    );
  }

  refresh = () => {
    if (this.formRef) {
      this.formRef.current.setFieldsValue({ userName: this.state.currentUsername })
    }
  }

  onChange = checked => {
    this.setState({
      loginByPhone: !this.state.loginByPhone,
    });
  };

  onFinish = values => {
    let self = this;
    const { t, location } = this.props;
    loginDataService
      .authenticate(values)
      .then(data => {
        let token = data.token || '';
        let refreshToken = data.refreshToken || '';
        let exprire = data.exprire;
        let userInfo = self.getUserInfo(token);
        let workspaceId = self.getWorkspaceId(token);
        self.setupWorkspace(
          token,
          refreshToken,
          exprire,
          userInfo,
          workspaceId
        );
      })
      .catch(err => {
        message.error(t('loginPage.loginFail'));
      });
  };

  getUserInfo(token) {
    let claims = jwt_decode(token);
    let user = {
      userid: claims[CLAIMS_SID],
      username: claims[CLAIMS_NAME],
      emailaddress: claims[CLAIMS_EMAIL],
      role: claims[CLAIMS_ROLE],
      currentStationId: claims[CLAIMS_CURRENT_STATION_ID],
      changedPassWord: claims[CLAIMS_CHANGED_PASSWORD],
    };
    return user;
  }

  getWorkspaceId(token) {
    let claims = jwt_decode(token);
    return claims[CLAIMS_COMPANY_IDENTITY];
  }

  setupWorkspace(token, refreshToken, exprire, userInfo, workspaceId) {
    const { t, location } = this.props;
    if (userInfo.role === 'USER') {
      let auth = {
        token: token,
        refreshToken: refreshToken,
        exprire: exprire,
        user: userInfo,
      };
      this.props.setAuth(auth);
      this.props.setWorkspace(workspaceId);
      this.props.setJustLoggedIn(true);
      message.success(t('loginPage.youHaveBeenLoggedInSuccessfully'));
      this.setState({ isLoggedIn: true });

      if (location.state && location.state.returnUrl) {
        this.props.history.push(location.state.returnUrl);
      }else{
        this.props.history.push("/home");
      }
      
    } else {
      message.error(t('loginPage.thisPageOnlyAllowsToAcceptByUser'));
    }
  }
}
