export const doorToWarehouse = 'door to warehouse';
export const StationToWarehouser = 'station to warehouse';
export const codFee = 30000
export const cash = 'Cash'
export const othersCategory = 'Other'
export const deliveryMethods = [
  {
    id: 0,
    name: "Door to Warehouse"
  },
  {
    id: 1,
    name: "Station to Warehouse"
  }
]

export const specialServices = {
  "fastService": "Fast Service",
  "normalService": "Normal Service",
  "fragile": "Fragile"
}

export const addMoreProductSteps = {
  infor: 0,
  delivery: 1,
  payment: 2,
  summary: 3
}

export const renewContractSteps = {
  infor: 0,
  payment: 1,
  summary: 2
}