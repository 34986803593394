import React, { Component } from "react";
import Layout from "antd/lib/layout/layout";
import { Steps, message, Space } from "antd";
import Button from "antd-button-color";
import TopBar from "../../components/topbar";
import WarehouseInfo from "./warehouse-info/warehouse-info.component";
import DefineAreaSpace from "./define-area-space/define-area-space.component";
import Summarize from "./summarize/summarize.component";
import warehouseDataService from "../../data-services/warehouse/warehouse-data.service";
const { Step } = Steps;
const screens = {
  warehouseInfo: 0,
  defineAreaSpace: 1,
  summarize: 2,
};

export default class UpdateWarehouseRegistrationScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: screens.warehouseInfo,
      warehouseCategories: [],
      warehouseLocations: [],
      initialData: {},
    };
    this.warehouseInfoRef = React.createRef();
    this.defineAreaSpaceRef = React.createRef();
    this.summarizeRef = React.createRef();
  }

  async componentDidMount() {
    const { warehouseId } = this.props?.match?.params || {};
    var warehouseInfo = await warehouseDataService.getWarehouseById(
      warehouseId
    );

    if (warehouseInfo.data.isUpdate) {
      var warehouseCategories =
        await warehouseDataService.getWarehouseCategories();
      var warehouseLocations =
        await warehouseDataService.getWarehouseLocations();
        if (warehouseCategories && warehouseLocations && warehouseInfo) {
          let dataToInit = {
            warehouse: {
              ...warehouseInfo.data,
              category: {
                id: warehouseInfo.data.warehouseTypeId,
              },
              address: {
                street: warehouseInfo.data.address.street,
                city: {
                  id: warehouseInfo.data.address.city.id,
                  name: warehouseInfo.data.address.city.name
                },
                district: {
                  id: warehouseInfo.data.address.district.id,
                  name: warehouseInfo.data.address.district.name,
                  prefix: warehouseInfo.data.address.district.prefix
                },
                ward: {
                  id: warehouseInfo.data.address.ward.id,
                  name: warehouseInfo.data.address.ward.name,
                  prefix: warehouseInfo.data.address.ward.prefix
                },
              },
            },
            areas: warehouseInfo.data.areaStations?.map((i, index) =>  ({...i, space: i?.spaceArea, price: i?.spaceArea?.price, totalSpace: i.totalNumberOfSpaces, category:{ id: i.stationTypeId } }))
          };
        this.setState({
          warehouseCategories: warehouseCategories ?? [],
          warehouseLocations: warehouseLocations ?? [],
          initialData: dataToInit,
        });
      }
    } else {
      this.props.history.push(`/personal-warehouse/${warehouseId}`);
    }
  }

  render() {
    const { t } = this.props;
    const { current, warehouseCategories, warehouseLocations, initialData } =
      this.state;

    if (initialData?.warehouse === undefined) {
      return <></>;
    }

    const steps = [
      {
        title: t("warehouseRegistration.warehouseInfo"),
        content: (
          <>
            <div className="col-lg-5 col-md-6">
              <WarehouseInfo
                t={t}
                ref={this.warehouseInfoRef}
                categories={warehouseCategories}
                locations={warehouseLocations}
                initialData={initialData?.warehouse}
              />
            </div>
          </>
        ),
      },
      {
        title: t("warehouseRegistration.defineAreaSpace"),
        content: (
          <DefineAreaSpace
            t={t}
            ref={this.defineAreaSpaceRef}
            categories={warehouseCategories}
            initialData={this.state.initialData?.areas}
          />
        ),
      },
      {
        title: t("warehouseRegistration.summarize"),
        content: (
          <Summarize 
            t={t} 
            ref={this.summarizeRef} 
            initialData={initialData} 
            categories={warehouseCategories}
          />
        ),
      },
    ];

    return (
      <>
        <Layout className="bg-content">
          <div className="bg-body">
            <TopBar title={t("title.warehouseRegistration")} />
            <div className="bg-white clearfix beecow-leasing-contract">
              <Steps
                type="navigation"
                current={current}
                className="site-navigation-steps"
              >
                {steps.map((item) => {
                  return <Step key={item.title} title={item.title} />;
                })}
              </Steps>
              <div className="steps-content">{steps[current].content}</div>
              <div className="steps-action clearfix">
                {current > 0 && (
                  <Button type="primary" onClick={this.prev}>
                    {t("button.back")}
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button type="primary" onClick={this.next}>
                    {t("button.next")}
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button type="primary" onClick={this.finish}>
                    {t("button.done")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }

  next = () => {
    let { initialData, current } = this.state;
    let currentFormRef;
    switch (this.state.current) {
      case screens.warehouseInfo:
        currentFormRef = this.warehouseInfoRef;
        let dataToSet = currentFormRef.current.getFieldsValue();
        this.setState({
          ...this.state,
          initialData: {
            ...initialData,
            warehouse: dataToSet,
          },
        });
        break;
      case screens.defineAreaSpace:
        currentFormRef = this.defineAreaSpaceRef;
        this.setState({
          ...this.state,
          initialData: {
            ...initialData,
            areas: currentFormRef.current.getFieldsValue(),
          },
        });
        break;
      default:
        break;
    }

    if (currentFormRef && currentFormRef.current) {
      currentFormRef.current
        .validateFields()
        .then(() => {
          let nextStep = current + 1;
          this.setState({ current: nextStep });
        })
        .catch();
    }
  };

  prev = () => {
    let { current } = this.state;
    let preStep = current - 1 <= 0 ? 0 : current - 1;
    this.setState({ current: preStep });
  };

  finish = () => {
    const { warehouseId } = this.props?.match?.params || {};
    const { t } = this.props;
    const { initialData } = this.state;
    let request = {
      warehouse: {...initialData?.warehouse, warehouseId},
      areas: initialData?.areas,
    };
    warehouseDataService.updatePersonalWarehouse(request).then((res) => {
        if (res.isSuccessful === true) {
          message.success(t("warehouseRegistration.createSuccessMessage"));
          //redirect to personal ware house
          this.props.history.push("/personal-warehouse");
        } else {
          message.error(t(res.languageCode));
        }
    });
  };
}
