import { Col, Input, Row, Form, Tabs } from 'antd';
import { Radio } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { Component } from 'react'
import { formatAmount, getPrice } from '../../../../utils/order';
import contractDataService from '../../../../data-services/contracts/contracts-data.service';
import { TEXT_VND } from '../../../../constants/common';
import { images } from '../../../../constants/imagesConstant';

const { TabPane } = Tabs;

export class PaymentExtendContract extends Component {

  constructor(props) {
    super(props);
    this.handleChangeTab = this.handleChangeTab.bind(this)
    this.state = {
      data: [],
      lang: 'en',
      value: 3,
      selectedRowKeys: props.selectedRoute ? [props.selectedRoute.id] : [],
      additionalFee: true
    }
  }

  render() {
    const { t } = this.props;
    const { value } = this.state;
    const { paymentTypes } = this.props.extendContract
    const { paymentTypeId } = this.props

    return (
      <Content className='content'>
        <Form.Item hidden={true}>
        </Form.Item>
        <div className="create-order clearfix">
          <div className="title">
            <i className="fa fa-credit-card" aria-hidden="true"></i>
            <p>{t('createOrEditOrderPage.paymentForm.paymentMethod')}</p>
          </div>
          <Row>
            <Col span={6}>
            </Col>
            <Col span={10}>
              <div className="tab-payment">
                <Tabs defaultActiveKey={paymentTypeId} onChange={this.handleChangeTab}>
                  {
                    paymentTypes?.map(({ id, name }, index) => {
                      if (name === 'Cash') return <TabPane tab={
                        <div className="title-payment"><p><img src={images.iconMoney} /></p>{name}</div>} key={id} className="cash">
                        {
                          this.renderPayment()
                        }
                      </TabPane>
                      if (name === 'Bank') return <TabPane tab={
                        <div className="title-payment"><p><img src={images.iconBank} /></p>{name}</div>} key={id}>
                        <div className="bank-payment">
                          <p>{t('createOrEditOrderPage.paymentForm.pleaseTransferToThisBankAccount')}:</p>
                          <div className="info-bank">
                            <p>{t('createOrEditOrderPage.paymentForm.bankName')}: <span>Vietcombank HCM</span></p>
                            <p>{t('createOrEditOrderPage.paymentForm.accountNumber')}: <span>0987654312</span></p>
                            <p>{t('createOrEditOrderPage.paymentForm.accountName')}: <span>CTY TNHH BEECOW VN</span></p>
                          </div>
                          <div className="des-bank">
                            <p dangerouslySetInnerHTML={{ __html: t('createOrEditOrderPage.paymentForm.afterTheReceiveThisPayment') }}></p>
                          </div>
                          {
                            this.renderPayment()
                          }
                        </div>
                      </TabPane>
                      return <TabPane tab={
                        <div className="title-payment"><p><img src={images.iconMomo} /></p>{name}</div>} key={id} className="e-wallet">
                        <div className="img-qr-code">
                          <img src={images.qrCode} />
                        </div>
                        {
                          this.renderPayment()
                        }
                      </TabPane>
                    })
                  }
                </Tabs>
              </div>
            </Col>
          </Row>
        </div>
      </Content>
    )
  }

  componentWillMount() {
    const req = {
      contractId: this.props.extendContract?.id,
      months: this.props.months
    }
    contractDataService.calcRenewFee(req).then(({ rentalPrice, totalFee }) => this.setState({ rentalPrice, totalFee }))
  }

  handleChangeTab(key) {
    this.props.onChangeTab(key)
  }

  renderPayment() {
    const { extendContract, t } = this.props
    const { rentalPrice, totalFee } = this.state
    const paymentRentalPrice = formatAmount(rentalPrice) + TEXT_VND
    const paymentTotal = formatAmount(totalFee) + TEXT_VND
    return (
      <>
        <div className="shipping-fee">
          <ul>
            <li className="pull-left">{t('extendContractPage.form.extendContract.extendRentalPrice')}: </li>
            <li className="total-price pull-right">{paymentRentalPrice}</li>
            <br />
            <li className="pull-left">{t('createOrEditOrderPage.paymentForm.total')}: </li>
            <li className="total-price pull-right">{paymentTotal}</li>

          </ul>
        </div>
      </>
    )
  }
}