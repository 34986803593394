import { connect } from "react-redux"
import { PaymentExtendContract } from "./payment-extend-contract.screen"
import { bindActionCreators } from "redux"
import { calcNewRentingSpace, calcRenewFee, calcAddMoreProductFee } from '../../../../modules/leasing-contract/leasing-contract.actions'
import { getExtendContract } from "../../../../modules/extend-contract/extend-contract.action"
const mapStateToProps = (state, { leasingContract }) => {
  return {
    extendContract: state.extend?.extendContract || {},
    // leasingFeeCalculator: leasingContract.leasingFeeCalculator || {},
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    calcNewRentingSpace,
    calcRenewFee,
    calcAddMoreProductFee,
    getExtendContract
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentExtendContract)