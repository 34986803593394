const actionTypes = {
  SET_LIST_ORDERS: "SET_LIST_ORDERS",
  GET_ORDER_INIT_DATA: "GET_ORDER_INIT_DATA",
  GET_STATIONS: "GET_STATIONS",
  GET_ROUTING: "GET_ROUTING",
  GET_ROUTING_WAREHOUSE: "GET_ROUTING-WAREHOUSE",
  SELECT_ROUTING: "SELECT_ROUTING",
  SET_ROUTE: "SET_ROUTE",
  CLEAR_ORDER_DATA: "CLEAR_ORDER_DATA",
  GET_DISTRICT_BY_CITYID: "GET_DISTRICT_BY_CITYID",
  GET_WARDS_BY_DISTRICTID: "GET_WARDS_BY_DISTRICTID",
  GET_ORDER_DETAILS: "GET_ORDER_DETAILS",
  GET_CONTRACT_DETAIL: "GET_CONTRACT_DETAIL",
  SET_CURRENT_INVENTORY: "SET_CURRENT_INVENTORY",
  SET_AMOUNT: "SET_AMOUNT",
  SET_DELIVERY_SELECTED: "SET_DELIVERY_SELECTED",
  SET_PAYMENT: "SET_PAYMENT",
  SET_CONTRACT_DETAIL: "SET_CONTRACT_DETAIL",
  SET_LIST_PRODUCT_PLACE_ORDER: "SET_LIST_PRODUCT_PLACE_ORDER",
  GET_PICKUP_LIST_BY_ID: "GET_PICKUP_LIST_BY_ID",
  GET_DELIVERY_LIST_DETAIL_BY_ID: "GET_DELIVERY_LIST_DETAIL_BY_ID",
};

export default actionTypes;
