import React, { Component } from 'react';
import { Card } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { thumbColors } from '../../common/constants';
import './index.scss'

export default class WarehouseThumbnail extends Component {
    state = {
        showActionButtons: false
    }

    render() {
        const color = this.props?.color;
        return (
            <>
                <Card
                    style={{ width: this.props?.width, height: this.props?.height }}
                    className={`thumb ${this.props.className} ${color}`}
                    onMouseEnter={() => this.setState({ showActionButtons: true })}
                    onMouseLeave={() => this.setState({ showActionButtons: false })}
                >
                    {
                        color === thumbColors.rentedOut && <CloseOutlined className='thumb-icon' style={{ fontSize: this.props?.width }} />
                    }
                    {
                        this.state.showActionButtons ?
                            this.props?.renderActionButton
                            :
                            this.props?.content
                    }
                </Card>
            </>
        );
    }
}