import { DataService } from "../base/base-data.service";

class ImportDataService extends DataService {
    apiUrl = '/import';

    async getById(id) {
        return await this.get(`${this.apiUrl}/${id}`);
    }
    
    async executeImport(data) {
        return await this.post(`${this.apiUrl}/execute`, data);
    }

    async updateProductSpaceShipping(data) {
        return await this.post(`${this.apiUrl}/update-product-space-shipping`, data);
    }

    async getImportListFromVehicleById(id) {
        return await this.get(`${this.apiUrl}/view-import-from-vehicle?importId=${id}`);
    }
}

let importDataService = new ImportDataService();

export default importDataService;