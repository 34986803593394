import React from 'react'
import { Modal, Button, Radio, Form, Space } from 'antd';
import { connect } from 'react-redux'
import { withRouterInnerRef } from '../../components/forward-ref/router-foward-ref'
import { listOptionPrint } from '../../constants/constants';

class PrintModal extends React.Component {
  constructor(props) {
    super(props)
    this.showModal = this.showModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this)
    this.backToPage = this.backToPage.bind(this)
    this.state = {
      open: false,
      business: null,
      textError: '',
      isModalVisible: false,
      selectedValue: 0
    }
  }


  handleChange = (e, { value }) => {
    this.setState({ businessId: value })
    this.props.setBusiness(value)
  }

  backToPage() {
    this.handleClose()
  }

  showModal = () => {
    this.setState({ isModalVisible: true })
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false })
  };

  onChangeOpption(e) {
    const option = e.target.value
    if (option === 2) {
      const { orderId } = this.props?.match?.params || {}
      this.props.history.push(`/print-products/${orderId}`)
    }
    this.setState({ selectedValue: option })
  }

  onOK = () => {
    const { selectedValue } = this.state
    switch (selectedValue) {
      case 1:
        this.props.handlePrintBarcode()
        break;
      default:
        this.props.handlePrintContent()
        break;
    }
  }

  render() {
    const { t } = this.props;
    const { isModalVisible, selectedValue } = this.state
    return (
      <div className='modal-print no-print'>
        <Button type="primary" onClick={this.showModal}>
          {t('button.print')}
        </Button>
        <Modal className="no-print option-print" visible={isModalVisible} onOk={this.onOK} onCancel={this.handleCancel} okText="Print">
          <Form className='form-print' layout="vertical">
            <Radio.Group onChange={this.onChangeOpption.bind(this)} value={selectedValue}>
              {
                listOptionPrint?.map(({ id, name }) => (
                  <Space direction="vertical">
                    <Radio value={id}>{name}</Radio>
                  </Space>

                ))
              }
            </Radio.Group>
          </Form>
        </Modal>
      </div>
    )
  }
}

export default withRouterInnerRef(connect(null, null, null, { forwardRef: true })(PrintModal))
