import React, { Component } from 'react';
import { Form, Input } from "antd";
import { ContractMessage } from '../../common/contract.message';
import PropTypes from 'prop-types';
import AddressComponent from '../address/address-component';
import './sender-info.scss';

export class SenderInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            initialData: props.initialData,
            visible: props.visible
        }
        this.formRef = React.createRef();
        this.formAddressRef = React.createRef();
    }

    render() {
        const { t } = this.props;
        let { initialData, visible } = this.state;
        var display = visible === true ? "d-block" : "d-none";
        return (
            <Form 
                ref={this.formRef}
                initialValues={initialData}
                className={`beecow-sender-info ${display}`}>
                <h2 className="text-center">{t('createOrEditNewSpacePage.bookingForm.senderInformation')}</h2>
                <div className="mt-5">
                    <label>{t('createOrEditNewSpacePage.bookingForm.senderName')}</label>
                    <div className="beecow-input-icon">
                        <i className="fa fa-user-o" aria-hidden="true"></i>
                        <Form.Item 
                            name="fullName"
                            className="col-12 pr-2" 
                            rules={[{ required: true, message: t('createOrEditNewSpacePage.bookingForm.pleaseInputRenterName') }]}>
                            <Input placeholder={t('createOrEditNewSpacePage.bookingForm.senderName')} />
                        </Form.Item>
                    </div>
                    <label>{t('createOrEditNewSpacePage.bookingForm.senderPhone')}</label>
                    <div className="beecow-input-icon">
                        <i className="fa fa-mobile" aria-hidden="true"></i>
                        <Form.Item
                            name="phone"
                            className="col-12 pr-2"
                            rules={[
                                { required: true, message: t('createOrEditNewSpacePage.bookingForm.pleaseInputValidPhone') },
                                { pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, message: t('createOrEditNewSpacePage.bookingForm.pleaseInputValidPhone') }
                            ]}>
                            <Input placeholder={t('createOrEditNewSpacePage.bookingForm.senderPhone')} />
                        </Form.Item>
                    </div>
                    <label>{t('createOrEditNewSpacePage.bookingForm.senderAddress')}</label>
                    <Form.Item
                        className="col-12 pr-2"
                        name="address">
                        <AddressComponent
                            t={t}
                            ref={this.formAddressRef}
                            initialData={initialData?.address} />
                    </Form.Item>
                </div>
            </Form>
        )
    }

    setVisible = (value) => {
        this.setState({ visible: value });
    }

    setFieldsValue(values) {
        this.formRef.current.setFieldsValue(values);
        this.formAddressRef.current.setFieldsValue(values?.address);
        this.setState({ initialData: values });
    }

    getFieldsValue() {
        let allValues = this.formRef.current.getFieldsValue();
        let mergedValues = { ...this.state.initialData, ...allValues};
        return mergedValues;
    }

    validateFields() {
        return this.formRef.current.validateFields();
    }
}

const propTypesAddress = {
    street: PropTypes.string,
    wardId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    districtId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

const propTypesSender = {
    fullName: PropTypes.string, 
    phone: PropTypes.string,
    address: PropTypes.shape(propTypesAddress),
}

SenderInfo.propTypes = {
    initialData: PropTypes.shape(propTypesSender),
};