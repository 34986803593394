import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ContentExtendContractComponent from "./content-extend-contract.screen"
import { getExtendContract } from "../../../modules/extend-contract/extend-contract.action"
import { bindActionCreators } from "redux";
import {  calcRenewFee } from '../../../modules/leasing-contract/leasing-contract.actions'
import { withRouterInnerRef } from "../../../components/forward-ref/router-foward-ref";

const mapStateToProps = (state) => {
  return {
    extendContract: state.extend?.extendContract || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getExtendContract,
    calcRenewFee,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withRouterInnerRef(ContentExtendContractComponent))