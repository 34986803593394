import React, { Component } from 'react';
import { Form, Button, Select, Input, Modal } from "antd";
import './product-table.scss';
import { othersCategoryId } from '../../../../../constants/order-constant';
import { Utilities } from "../../../../../common/utilities";

export default class ProductTableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: props.initialData,
            productCategories: props.productCategories,
            modalVisible: false,
            newProductCategory: {
                id: null,
                name: null,
                isNew: true
            },
            changeAtIndex: null
        }
    }

    render() {
        const { t } = this.props;
        return (
            <table className="table table-borderless product-table ml-0" scroll={{ y: 240 }}>
                <thead>
                    <tr>
                        <th className="action-button-area"></th>
                        <th className="table-product-name">{t('createOrEditOrderPage.productForm.productName')}</th>
                        <th className="table-product-category">{t('createOrEditOrderPage.productForm.productCategory')}</th>
                        <th className="table-product-size">{t('createOrEditOrderPage.productForm.length')}</th>
                        <th className="table-product-size">{t('createOrEditOrderPage.productForm.width')}</th>
                        <th className="table-product-size">{t('createOrEditOrderPage.productForm.height')}</th>
                        <th className="table-product-size">{t('createOrEditOrderPage.productForm.weight')}</th>
                        <th className="table-product-size">{t('createOrEditOrderPage.productForm.amount')}</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderListProduct()}
                </tbody>
            </table>
        )
    }

    onAdd = (callBack, params) => {
        if (params && params >= 0) {
            callBack(null, params);
        }
        else {
            callBack();
        }

        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    onRemove = (callBack, params) => {
        callBack(params);
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    onChangeAmount = () => {
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    onChangeCategory = (obj, index) => {
        var { productCategories, products } = this.state;
        if (othersCategoryId == obj.value) {
            this.setState({
                newProductCategory: {
                    id: null,
                    name: null,
                    isNew: true
                },
                changeAtIndex: index,
                modalVisible: true
            });
        }
    }

    onChangeNewCategory = (event) => {
        var { newProductCategory } = this.state;
        newProductCategory.name = event.target.value;
        this.setState({
            newProductCategory: newProductCategory
        });
    }

    onOk = (values) => {
        var { newProductCategory, productCategories, changeAtIndex, products } = this.state;
        newProductCategory.id = Utilities.randomUuid();
        productCategories.push(newProductCategory);
        this.setState({
            modalVisible: false,
            newProductCategory: null,
            productCategories: productCategories
        });
    };

    renderListProduct = () => {
        const { t } = this.props;
        const { products, productCategories, newProductCategory } = this.state;
        return <>
            <Form.List name="products" initialValue={products}>
                {(fields, { add, remove }) => {
                    return (
                        fields.length === 0 ? (
                            <tr>
                                <td>
                                    <Button onClick={() => this.onAdd(add, [])} type="text"><i className="fa fa-plus text-success" aria-hidden="true"></i></Button>
                                </td>
                            </tr>
                        ) :
                            fields.map((field, index) => (
                                <tr key={field.key}>
                                    <td style={{ display: 'inline-flex' }} className="action-button-area pl-0">
                                        <Button type="text" onClick={() => this.onAdd(add, index + 1)}><i className="fa fa-plus text-success" aria-hidden="true" ></i></Button>
                                        <Button type="text" onClick={() => this.onRemove(remove, field.name)}><i className="fa fa-close text-danger" aria-hidden="true" ></i></Button>
                                    </td>
                                    <td className="mt-0 table-product-name pl-0">
                                        <Form.Item
                                            className="mt-0"
                                            name={[index, "name"]}
                                            rules={[{ required: true , message: t('createOrEditOrderPage.productForm.pleaseInputProductName')}]}
                                        >
                                            <Input placeholder={t('createOrEditOrderPage.productForm.productName')} />
                                        </Form.Item>
                                    </td>
                                    <td className="mt-0 table-product-category">
                                        <Form.Item
                                            name={[index, "categoryId"]}
                                            rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseSelectCategory')}]}
                                        >
                                            <Select placeholder={t('createOrEditOrderPage.productForm.selectCategory')}>
                                                {productCategories.map(({ id, name, languageKey }) => <Select.Option key={id} value={id}>{t(languageKey)}</Select.Option>)}
                                            </Select>
                                        </Form.Item>
                                    </td>
                                    <td className="pr-0">
                                        <Form.Item
                                            name={[index, "length"]}
                                            rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputLength') }]}
                                        >
                                            <Input placeholder={t('createOrEditOrderPage.productForm.length')}
                                                type="number"
                                                min="0"
                                                onKeyDown={e => /[\+\-\,]$/.test(e.key) && e.preventDefault()}
                                            />
                                        </Form.Item>
                                    </td>
                                    <td className="pr-0">
                                        <Form.Item
                                            name={[index, "width"]}
                                            rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputWidth') }]}
                                        >
                                            <Input placeholder={t('createOrEditOrderPage.productForm.width')} type="number"
                                                min="0"
                                                onKeyDown={e => /[\+\-\,]$/.test(e.key) && e.preventDefault()}
                                            />
                                        </Form.Item>
                                    </td>
                                    <td className="pr-0">
                                        <Form.Item
                                            name={[index, "height"]}
                                            rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputHeight') }]}
                                        >
                                            <Input placeholder={t('createOrEditOrderPage.productForm.height')} type="number"
                                                min="0"
                                                onKeyDown={e => /[\+\-\,]$/.test(e.key) && e.preventDefault()}
                                            />
                                        </Form.Item>
                                    </td>
                                    <td className="pr-0">
                                        <Form.Item
                                            name={[index, "weight"]}
                                            rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputWeight') }]}
                                        >
                                            <Input placeholder={t('createOrEditOrderPage.productForm.weight')} type="number"
                                                min="0"
                                                onKeyDown={e => /[\+\-\,]$/.test(e.key) && e.preventDefault()}
                                            />
                                        </Form.Item>
                                    </td>
                                    <td className="pr-0">
                                        <Form.Item
                                            name={[index, "amount"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('createOrEditOrderPage.productForm.pleaseInputAmount'),
                                                },
                                                () => ({
                                                    validator(_, amount) {
                                                        if (parseInt(amount) <= 0) {
                                                            return Promise.reject(new Error(t('createOrEditOrderPage.productForm.pleaseInputAmountLargerThan')));
                                                        }

                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input
                                                onChange={this.onChangeAmount}
                                                placeholder={t('createOrEditOrderPage.productForm.amount')}
                                                type="number"
                                                min="1"
                                                onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                                            />
                                        </Form.Item>
                                    </td>
                                </tr>
                            ))
                    );
                }}
            </Form.List>

            <Modal
                title={t('createOrEditOrderPage.productForm.addProductCategory')}
                centered
                visible={this.state.modalVisible}
                maskClosable={false}
                onOk={this.onOk}
                onCancel={() => {
                    this.setState({
                        modalVisible: false,
                    });
                }}
                okText={t('button.done')}
                cancelText={t('button.cancel')}
                width={300}
            >
                <div className='row col-12 m-0'>
                    <Input placeholder={t('createOrEditOrderPage.productForm.pleaseInputProductCategory')} value={newProductCategory?.name } onChange={ this.onChangeNewCategory }/>
                </div>
            </Modal>
        </>
    }
}
