import React, { Component } from 'react';
import { Content } from 'antd/lib/layout/layout';
import PropTypes from 'prop-types';
import { RenterInfo } from '../renter-info/renter-info.component';
import { SenderInfo } from '../sender-info/sender-info.component';
import Checkbox from 'antd/lib/checkbox/Checkbox';

export class BookingInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            initialData: this.props.initialData,
            isRentArea: this.props.isRentArea,
        }
        this.renterInfoForm = React.createRef();
        this.senderInfoForm = React.createRef();
    }

    render() {
        const { t } = this.props;
        let { initialData, isRentArea } = this.state;
        return (
            <Content className='beecow-booking-info'>
                <div className='row'>
                    <div className='col-sm-5'>
                        <RenterInfo t={t} ref={this.renterInfoForm} initialData={initialData?.renter} />
                        {
                            !isRentArea && <div>
                                <Checkbox 
                                    value={initialData?.isSender} 
                                    checked={initialData?.isSender}
                                    onChange={this.handleChange.bind(this)}>{t('createOrEditNewSpacePage.bookingForm.useRenterInformationAsSender')}
                                </Checkbox><br/>
                                <small>{t('createOrEditNewSpacePage.bookingForm.senderInformationWillBeUsed')}</small>
                            </div>
                        }
                    </div>
                    <div className='col-sm-1'></div>
                    {
                        !isRentArea && <div className='col-sm-5'>
                            <SenderInfo
                                t={t}
                                ref={this.senderInfoForm}
                                initialData={initialData?.sender}
                                visible={!initialData?.isSender}
                            />
                        </div>
                    }
                </div>
            </Content>
        )
    }

    validateFields() {
        let promises = [];

        if (this.renterInfoForm && this.renterInfoForm.current) {
            let renterInfoValidation = this.renterInfoForm.current.validateFields();
            promises.push(renterInfoValidation);
        }
        if (!this.state.initialData?.isSender && this.senderInfoForm && this.senderInfoForm.current) {
            let senderInfoValidation = this.senderInfoForm.current.validateFields();
            promises.push(senderInfoValidation);
        }
        
        return Promise.all(promises);
    }

    setFieldsValue(values) {
        if (this.renterInfoForm && this.renterInfoForm.current) {
            this.renterInfoForm.current.setFieldsValue(values.renter);
        }

        if (!values.isSender && this.senderInfoForm && this.senderInfoForm.current) {
            var isSender = values?.isSender;
            var visible = isSender == true ? false : true;
            this.senderInfoForm.current.setFieldsValue(values.sender);
            this.senderInfoForm.current.setVisible(visible);
        }

        this.setState({ ...this.state, initialData: { ...this.state.initialData, ...values } });
    }

    getFieldsValue() {
        let bookingInfo = {};

        if (this.renterInfoForm && this.renterInfoForm.current) {
            let renterInfo = this.renterInfoForm.current.getFieldsValue();
            bookingInfo["renter"] = renterInfo;
        }
        if (!this.state.initialData?.isSender && this.senderInfoForm && this.senderInfoForm.current) {
            let senderInfo = this.senderInfoForm.current.getFieldsValue();
            bookingInfo["sender"] = senderInfo;
        }
        bookingInfo["isSender"] = this.state.initialData?.isSender;

        return bookingInfo;
    }

    handleChange(e) {
        var checked = e.target.checked;
        this.setState({ ...this.state, initialData: { ...this.state.initialData, isSender: checked } });
        if (this.senderInfoForm && this.senderInfoForm.current && this.senderInfoForm.current.setVisible) {
            var isSender = checked;
            this.senderInfoForm.current.setVisible(!isSender);
        }
    }
}

const propTypesAddress = {
    street: PropTypes.string,
    wardId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    districtId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

const propTypesRenter = {
    fullName: PropTypes.string, 
    phone: PropTypes.string, 
    email: PropTypes.string, 
    userTypeId: PropTypes.string, 
    paymentTypeId: PropTypes.string,
    address: PropTypes.shape(propTypesAddress),
}

const propTypesSender = {
    fullName: PropTypes.string, 
    phone: PropTypes.string,
    address: PropTypes.shape(propTypesAddress),
}

const propTypesBookingInfo = {
    renter: PropTypes.shape(propTypesRenter),
    isSender: PropTypes.bool,
    sender: PropTypes.shape(propTypesSender),
}

BookingInfo.propTypes = {
    initialData: PropTypes.shape(propTypesBookingInfo),
}