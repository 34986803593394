import actionTypes from "./leasing-contract.types";

const InitialState = {
    create: {
        initData: {},
        stations: [],
        routes: [],
    },
    stationsForLease: [],
    leasingFeeCalculator: {},
    contractRenewId: ''
};

const leasingContractReducer = (state = InitialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INIT_DATA:
            return { ...state, create: { ...state.create, initData: action.payload } };
        case actionTypes.GET_STATIONS:
            return { ...state, create: { ...state.create, stations: action.payload } };
        case actionTypes.GET_ROUTES:
            return { ...state, create: { ...state.create, routes: action.payload } };
        case actionTypes.CLEAR_ROUTES:
            return { ...state, create: { ...state.create, routes: action.payload } };
        case actionTypes.CLEAR_ORDER_DATA:
            return { ...state, create: { ...state.create, stations: [], routes: [] }, stationsForLease: [] };
        case actionTypes.GET_STATIONS_FOR_LEASE:
            return { ...state, stationsForLease: action.payload };
        case actionTypes.GET_LEASING_FEE_CALCULATOR:
            return { ...state, leasingFeeCalculator: action.payload };
        case actionTypes.SET_CONTRACT_RENEW_ID:
            return { ...state, contractRenewId: action.payload };
        default:
            return state;
    }
};

export default leasingContractReducer;