import { combineReducers } from "redux";
import sessionReducer from "./session/session.reducers";
import ordersReducer from "./orders/orders.reducers";
import profileReducer from "./profile/profile.reducers";
import leasingContractReducer from "./leasing-contract/leasing-contract.reducers";
import addressReducer from "./address/address.reducers";
import extendContractReducer from "./extend-contract/extend-contract.reducers";
import appReducer from "./app/app.reducers";
import productsReducer from "./products/products.reducers";

const rootReducer = combineReducers({
    app: appReducer,
    session: sessionReducer,
    order: ordersReducer,
    profile: profileReducer,
    leasingContract: leasingContractReducer,
    address: addressReducer,
    extend: extendContractReducer, 
    product: productsReducer
});

export default rootReducer;