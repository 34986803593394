import React, { Component } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Form, Input } from 'antd';
import ProductTableComponent from './product-table/product-table.component';

export default class ProductInfoComponent extends Component {
    constructor(props) {
        super(props);

        let initializeData = this.getDefaultData(props);
        this.state = initializeData;

        this.formRef = React.createRef();
    }

    getDefaultData(props) {

        return {
            loading: false,
            total: props.initialData?.totalProduct ?? 0,
            description: props.initialData?.description ?? '',
            productCategories: props.productCategories ?? [],
            initialData: props.initialData?.products ?? [],
            totalAmountCanShipping: props.totalAmountCanShipping ?? 0
        };
    }

    render() {
        const { t } = this.props;
        const { initialData, totalAmountCanShipping } = this.state;
        return (
            <Content className='beecow-shipping-info'>
                <Form ref={this.formRef}>
                    <div className='row'>
                        <div className='col-4 mx-auto'>
                            <label>{t('createOrEditOrderPage.productForm.packageDescription')}</label>
                            <Form.Item
                                name='description'
                                rules={[{ required: true, message: t('createOrEditOrderPage.productForm.pleaseInputPackageDescription') }]}
                            >
                                <Input placeholder={t('createOrEditOrderPage.productForm.packageDescription')} disabled={totalAmountCanShipping == 0} />
                            </Form.Item>
                        </div>
                        <div className='row col-4 mt-5 mx-auto'>
                            <label className='mr-5'>{t('createOrEditOrderPage.productForm.totalProductsInPackage')}:</label> <label>{this.state.total}</label>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <ProductTableComponent
                            t={t}
                            onChange={this.onChange}
                            initialData={initialData}
                            productCategories={this.state.productCategories} />
                    </div>
                </Form>
            </Content>
        )
    }

    getFieldsValue = () => {
        if (this.formRef && this.formRef.current) {
            var productInfo = this.formRef?.current?.getFieldsValue();
            var selectedProduct = productInfo?.products?.filter(p => p?.selected === true);
            productInfo.totalProduduct = selectedProduct?.reduce((result, obj) => {
                if (obj) {
                    result += parseInt(obj?.amount);
                    return result;
                }
                else {
                    return result;
                }
            }, 0) ?? 0;

            return productInfo;
        }
    }

    setFieldsValue = (values) => {
        var { initialData } = this.state;
        this.formRef.current?.setFieldsValue(values);

        this.setState({
            ...this.state,
            initialData: {
                ...initialData,
                products: values
            }
        }, () => {
            this.onChange();
        });
    }

    validateFields = async () => {
        return this.formRef.current?.validateFields();
    }

    // change product
    onChange = () => {
        var values = this.formRef?.current?.getFieldsValue();
        var total = values?.products?.reduce((result, obj) => {
            if (obj && obj?.selected == true) {
                result += parseInt(obj?.amount);
                return result;
            }
            else {
                return result;
            }
        }, 0) ?? 0;

        this.setState({ total: total });
    }
}