import { Col, Input, Row, Form, Image } from 'antd';
import React, { Component } from 'react';
import iconMomo from '../../../../assets/icons/momo.png';
import iconMoney from '../../../../assets/icons/money.jpg';
import iconBank from '../../../../assets/icons/bank.jpg';
import { iconPayment } from '../../../../constants/order-constant';
import contractDataService from '../../../../data-services/contracts/contracts-data.service';
import moment from 'moment';
import { formatAmount } from '../../../../utils/order';
import { TEXT_VND } from '../../../../constants/common';
import {images} from '../../../../constants/imagesConstant';

class SummaryExtendContractComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalFee: 0,
      rentalPrice: 0,
      months: 0
    }
    this.formRef = React.createRef()
  }

  getPaymentTypeIcon(name) {
    if (name === iconPayment.cash) {
      return <Image width={30} src={iconMoney} />
    }
    else if (name === iconPayment.bank) {
      return <Image width={30} src={iconBank} />
    }
    return <Image width={30} src={iconMomo} />
  }

  componentDidMount() {
    const req = {
      contractId: this.props.extendContract?.id,
      months: this.props.months
    }
    contractDataService.calcRenewFee(req).then(({ rentalPrice, totalFee }) => this.setState({ rentalPrice, totalFee }))
  }

  render() {
    const { rentalPrice, totalFee, month, expiryDate } = this.state
    const { extendContract, paymentTypeId, t } = this.props
    const months = this.props.months
    const startTime = moment.utc(extendContract.startDate).local().format('DD/MM/YYYY')
    const endTime = moment.utc(extendContract.expiryDate).local().format('DD/MM/YYYY')
    const currentMonth = moment.utc(extendContract.expiryDate).local().format('DD/MM/YYYY')
    const addMonth = moment(extendContract.expiryDate).add(months, 'M').local().format('DD/MM/YYYY')
    const paymentType = extendContract.paymentTypes.find(x => x.id === paymentTypeId)?.name
    const totalPrice = formatAmount(totalFee) + TEXT_VND
    const rentalFee = formatAmount(rentalPrice) + TEXT_VND
    const oldPrice = formatAmount(extendContract.priceLeasing) + TEXT_VND
    return (
      <div className="extend-page">
        <br />
        <Row>
          <Col span={11} offset={1}>
            <p><b>{t('extendContractPage.form.extendContract.wareHouseInformation')}</b></p>
            <div className="warehouse-info">
              <Row>
                <Col span={6} offset={2}>
                  <p className="content-left">{t('extendContractPage.form.extendContract.area')}: </p>
                  <p className="content-left ">{t('extendContractPage.form.extendContract.provider')}:</p>
                  <p className="content-left clearfix">{t('extendContractPage.form.extendContract.stationId')}:</p>
                  <p className="content-left clearfix">{t('extendContractPage.form.extendContract.stationName')}:</p>
                  <p className="clearfix">{t('extendContractPage.form.extendContract.stationAddress')}: </p>
                </Col>
                <Col span={10}>
                  <p>{extendContract?.area}</p>
                  <p className="name-company">{extendContract?.station?.company?.name} <span><Image src={extendContract?.station?.company != null ? extendContract?.station?.company.logoPath : images.warehouseBuilding} style={{ height: 30, width: 40 }} /></span></p>
                  <p className="clearfix">{extendContract.station?.code}</p>
                  <p className="clearfix">{extendContract.station?.name}</p>
                  <p className="clearfix">{extendContract?.station?.address?.street}, {extendContract?.station?.address?.ward?.name}, {extendContract?.station?.address?.district?.name},{extendContract?.station?.address?.city?.name}</p>
                </Col>
              </Row>

            </div>
            <h3>{t('extendContractPage.form.extendContract.howManyMonthsYouWantToExtend')}</h3>
            <Row>
              <Col span={5} offset={1}>
                <p className="months">{months}</p>
              </Col>
            </Row>

          </Col>
          <Col span={9}>
            <h3>{t('extendContractPage.form.extendContract.currentLeasingTimeOfThisContract')}:</h3>
            <p className="time">{startTime} - {endTime}</p>
            <br />
            <br />
            <h3>{t('extendContractPage.form.extendContract.currentRentalPrice')}: <span className="feild-time text-price">{oldPrice} </span></h3>
            <br />
            <br />
            <br />
            <br />
            <br />
            <h3>{t('extendContractPage.form.extendContract.extendTime')}:</h3><p className="feild-time text-red">{currentMonth} -  {addMonth}</p>
            <br />
            <h3>{t('extendContractPage.form.extendContract.extendRentalPrice')}: <span className="feild-time text-red text-price">{rentalFee || '0'}</span></h3>
          </Col>
        </Row>
        <br />
        <div className="payment">
          <h3 className="title">{t('createOrEditOrderPage.paymentForm.paymentMethod')}</h3>
          <Row>
            <Col span={9} offset={2}>
              <div className="payment">
                <p className="methods">
                  {this.getPaymentTypeIcon(paymentType)}
                  <br />
                  {paymentType}
                </p>
              </div>
            </Col>
            <Col span={10}>
              <div className="shipping-fee">
                <ul>
                  {
                    <>
                      <li className="pull-left">{t('extendContractPage.form.extendContract.extendRentalPrice')}:</li>
                      <li className="pull-right">{rentalFee}</li>
                      <br />
                      <li className="pull-left">{t('createOrEditOrderPage.paymentForm.total')}: </li>
                      <li className="total-price pull-right">{totalPrice}</li>
                    </>
                  }
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default SummaryExtendContractComponent