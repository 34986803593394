import { Component } from 'react';
import { Row, Carousel, Image } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './slider.component.scss'

export default class SliderComponent extends Component {

    render() {
        const settings = {
            nextArrow: this.RenderNextArrow(),
            prevArrow: this.RenderPrevArrow()
        }

        return (
            <div className='content'>
                <Row>
                    <Carousel
                        autoplay {...settings}
                        arrows={true}
                        swipeToSlide draggable>
                        {this.renderImages()}
                    </Carousel>
                </Row>
            </div>
        );
    }

    renderImages = () => {
        let images = this.props.imageSource;
        return images?.map((i) => <Image src={i} preview={false} />);
    }

    RenderNextArrow = () => {
        const { className, onClick } = this.props
        return (
            <div className={className} onClick={onClick}>
                <RightOutlined />
            </div>
        )
    }

    RenderPrevArrow = () => {
        const { className, onClick } = this.props
        return (
            <div className={className} onClick={onClick}>
                <LeftOutlined />
            </div>
        )
    }
}