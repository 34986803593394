import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getInitUser, getUserId } from "../../modules/profile/profile.actions";
import { withRouter } from "react-router-dom";
import {
  getDistrictByCityId,
  getWardsByDistrictId,
  getCities,
} from "../../modules/address/address.actions";
import { ProfileScreen } from "./profile.screen";
import {
  updateUserName,
  resetSession,
} from "../../modules/session/session.actions";

import { compose } from "redux";
import { withTranslation } from "react-i18next";

const mapStateToProps = ({ order, profile, address }) => {
  return {
    initData: order.create?.initData || {},
    initUser: profile.create?.initUser || [],
    users: profile.userId || {},
    address,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCities,
      getInitUser,
      getUserId,
      getDistrictByCityId,
      getWardsByDistrictId,
      updateUserName: (userName) => dispatch(updateUserName(userName)),
      signOut: (userInfo) => dispatch(resetSession(userInfo)),
    },
    dispatch
  );
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ProfileScreen);
