import React, { Component } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { Col, Row, Form, Tabs, Checkbox, InputNumber, Tooltip, Card } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { formatAmount } from '../../../../utils/order';
import { paymentConstants } from '../../../../constants/order-constant';
import { TEXT_VND } from '../../../../constants/common';
import './payment.scss';
import orderDataService from '../../../../data-services/orders/orders-data.service'
import { DeliveryConstants } from './../../../../constants/delivery.constants'
import { images } from '../../../../constants/imagesConstant';

const { TabPane } = Tabs;

export default class PaymentMethodComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCODCondition: paymentConstants.SHOW_COD_CONDITION,
            loading: false,
            showShippingFeeDetail: false,
            paymentTypes: props.paymentTypes ?? [],
            paymentByOptions: props.paymentByOptions ?? [],
            deliveryInfo: props.deliveryInfo ?? {},
            shippingInfo: props.shippingInfo ?? {},
            initialData: props.initialData ?? {},
            paymentDetail: {},
            tooltipDetail: props.t('createOrEditOrderPage.paymentForm.whatIsCod')
        }

        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.formRef?.current?.setFieldsValue(this.state.initialData);
        this.getPaymentCalculator();
    }

    render() {
        var { paymentByOptions, paymentTypes, initialData, tooltipDetail } = this.state;
        const { t } = this.props;
        return (
            <Content>
                <Form ref={this.formRef}>
                    <div className='create-order clearfix mx-auto'>
                        <div className='title'>
                            <i className='fa fa-credit-card' aria-hidden='true'></i>
                            <p>{t('createOrEditOrderPage.paymentForm.paymentMethod')}</p>
                        </div>
                        <Row>
                            <Col span={8}>
                                <div className='action'>
                                    {
                                        (initialData?.payById === this.state.showCODCondition) && <div className='check-cod'>
                                            <Form.Item name='isCod' valuePropName='checked'>
                                                <Checkbox onChange={this.onChangeCODCheckBox}>COD
                                                    <Tooltip title={t('createOrEditOrderPage.paymentForm.whatIsCod')} overlayStyle={{ whiteSpace: 'pre-line' }} placement='bottomLeft' color={'gold'}>
                                                        <QuestionCircleOutlined style={{ position: 'absolute', paddingLeft: '5px' }} />
                                                    </Tooltip>
                                                </Checkbox>
                                            </Form.Item>
                                            {
                                                initialData?.isCod && <div>
                                                    <Form.Item className='cod-amount'
                                                        name='codAmount'
                                                        rules={[{ required: true, message: t('createOrEditOrderPage.paymentForm.pleaseInputCodAmount') }]}>
                                                        <InputNumber
                                                            onChange={this.onChangeCODAmount}
                                                            className='cod-amount'
                                                            min={0}
                                                            formatter={value => formatAmount(value)}
                                                            placeholder={t('createOrEditOrderPage.paymentForm.inputCodAmount')}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </Col>
                            <Col>
                                <div className='tab-payment'>
                                    <Form.Item name={['paymentType', 'id']}>
                                        <Tabs defaultActiveKey={initialData?.paymentType?.id} onChange={this.handleChangeTab}>
                                            {
                                                paymentTypes?.map(({ id, name, languageKey }, index) => {
                                                    if (name === 'Cash') return <TabPane tab={t(languageKey)} key={id} className='cash'>
                                                        {
                                                            this.renderPayment()
                                                        }
                                                    </TabPane>
                                                    if (name === 'Bank') return <TabPane tab={t(languageKey)} key={id}>
                                                        <div className='bank-payment'>
                                                            <p>{t('createOrEditOrderPage.paymentForm.pleaseTransferToThisBankAccount')}:</p>
                                                            <div className='info-bank'>
                                                                <p>{t('createOrEditOrderPage.paymentForm.bankName')}: <span>Vietcombank HCM</span></p>
                                                                <p>{t('createOrEditOrderPage.paymentForm.accountNumber')}: <span>0987654312</span></p>
                                                                <p>{t('createOrEditOrderPage.paymentForm.accountName')}: <span>CTY TNHH BEECOW VN</span></p>
                                                            </div>
                                                            <div className='des-bank'>
                                                                <p dangerouslySetInnerHTML={{ __html: t('createOrEditOrderPage.paymentForm.afterTheReceiveThisPayment') }}></p>
                                                            </div>
                                                            {
                                                                this.renderPayment()
                                                            }
                                                        </div>
                                                    </TabPane>
                                                    return <TabPane tab={t(languageKey)} key={id} className='e-wallet'>
                                                        <div className='img-qr-code'>
                                                            <img src={images.qrCode} />
                                                        </div>
                                                        {
                                                            this.renderPayment()
                                                        }
                                                    </TabPane>
                                                })
                                            }
                                        </Tabs>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Content>
        )
    }

    isDeliveryToStation = (deliveryOption) => {
        if (deliveryOption) {
            return deliveryOption?.name.toLowerCase().includes(DeliveryConstants.TO_STATION) ? true : false;
        }

        return false;
    }

    renderPayment() {
        const { t } = this.props;
        var { initialData, paymentDetail } = this.state;
        var codAmountFormatted = formatAmount(initialData.codAmount) + TEXT_VND;
        var CODFeeFormatted = formatAmount(paymentDetail?.codFee) + TEXT_VND;
        var pickupFromDoorFeeFormatted = formatAmount(paymentDetail?.pickupFromDoorFee) + TEXT_VND;
        var deliveryToDoorFeeFormatted = formatAmount(paymentDetail?.deliveryToDoorFee) + TEXT_VND;
        var interProvinceFeeFormatted = formatAmount(paymentDetail?.interProvinceFee) + TEXT_VND;
        var totalFeeFormatted = formatAmount(paymentDetail?.totalFee) + TEXT_VND;
        return (
            <>
                {
                    initialData.isCod && paymentDetail?.codFee > 0 &&
                    <>
                        <div className='row'>
                            <div className='col-sm-8'>
                                <p className='m-0'><b>{t('createOrEditOrderPage.paymentForm.codAmount')}</b></p>
                                <p className='m-0 pl-4 text-detail font-italic'>{t('createOrEditOrderPage.paymentForm.senderReceiveCodAmountAtTimeOfPickup')}</p>
                                <p className='m-0 pl-4 text-detail font-italic'>{t('createOrEditOrderPage.paymentForm.recipientPaysForOrderAtTimeOfDelivery')}</p>
                            </div>
                            <div className='col-sm-4'>
                                <p className='float-right'>{codAmountFormatted}</p>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <p className='col-sm-8'><b>{t('createOrEditOrderPage.paymentForm.codFee')}</b></p>
                            <div className='col-sm-4'>
                                <p className='float-right'>{CODFeeFormatted}</p>
                            </div>
                        </div>
                    </>
                }

                <div>
                    <div className='row'>
                        <p className='col-sm-8 m-0'><b>{t('createOrEditOrderPage.paymentForm.pickupFromDoorFee')}:</b></p>
                        <div className='col-sm-4 m-0 '>
                            <p className='float-right'>{pickupFromDoorFeeFormatted}</p>
                        </div>
                    </div>

                    {
                        this.isDeliveryToStation(initialData?.deliveryOption) ||
                        <div className='row mt-1'>
                            <p className='col-sm-8 m-0'><b>{t('createOrEditOrderPage.paymentForm.deliveryToDoorFee')}:</b></p>
                            <div className='col-sm-4 m-0 '>
                                <p className='float-right'>{deliveryToDoorFeeFormatted}</p>
                            </div>
                        </div>
                    }

                    {
                        paymentDetail?.interProvinceFee > 0 &&
                        <div className='row mt-1'>
                            <p className='col-sm-8 m-0'><b>{t('createOrEditOrderPage.paymentForm.interProvinceFee')}:</b></p>
                            <div className='col-sm-4 m-0 '>
                                <p className='float-right'>{interProvinceFeeFormatted}</p>
                            </div>
                        </div>
                    }
                    {this.isStationSameWarehouse() && <>
                        <Card>
                            <span>{t('createOrEditOrderPage.deliveryForm.warningChoosingStation')}</span> {" "}
                            <span>{t('createOrEditOrderPage.deliveryForm.warningMeansYouWillBringPackage')}</span>
                        </Card>
                    </>}

                    <div className='row mt-1'>
                        <p className='col-sm-8 m-0'><b>{t('createOrEditOrderPage.paymentForm.totalShippingFee')}:</b></p>
                        <div className='col-sm-4 m-0 '>
                            <p className='text-danger float-right'><b>{totalFeeFormatted}</b></p>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    toggleCollapse() {
        this.setState({
            showShippingFeeDetail: !this.state.showShippingFeeDetail,
        });
    }

    paymentByOptions = e => {
        var initialData = this.state.initialData;
        initialData.payById = e.target?.value;
        this.setState({
            initialData: initialData
        }, () => {
            this.getPaymentCalculator();
        });
    }

    onChangeCODCheckBox = (e) => {
        var initialData = this.state.initialData;
        initialData.isCod = e.target?.checked;
        this.setState({
            initialData: initialData
        }, () => {
            this.getPaymentCalculator();
        });
    }

    onChangeCODAmount = (value) => {
        var { initialData } = this.state;
        this.setState({
            ...this.state,
            initialData: {
                ...initialData,
                codAmount: value
            }
        });
    }

    getPaymentCalculator = () => {
        var { shippingInfo, deliveryInfo } = this.state;
        var paymentInfo = this.getFieldsValue();
        var listShippingFee = this.tranformShippingFee(deliveryInfo);
        
        var paymentCalculatorRequest = {
            senderCityId: shippingInfo?.senderInfo?.address?.city?.id,
            receiverCityId: shippingInfo?.receiverInfo?.address?.city?.id,
            isCOD: paymentInfo.isCod ?? false,
            companyId: deliveryInfo?.route?.company?.id,
            shippingFeeDetails: listShippingFee,
            deliveryTypeId: deliveryInfo?.deliveryOption?.deliveryTypeId,
        }
        
        if (this.isValidRequest(paymentCalculatorRequest) && !this.isStationSameWarehouse()) {
            orderDataService.paymentCalculator(paymentCalculatorRequest).then((res) => {
                this.setState({
                    ...this.state,
                    paymentDetail: res
                });
            });
        } else {
            this.setState({
                ...this.state,
                paymentDetail: {
                    CODFee: 0,
                    PickupFromDoorFee: 0,
                    DeliveryToDoorFee: 0,
                    InterProvinceFee: 0,
                    TotalFee: 0
                }
            });
        }

    }

    getFieldsValue = () => {
        var { paymentByOptions, paymentTypes, paymentDetail } = this.state;
        var paymentInfo = this.formRef.current.getFieldsValue() ?? {};

        if (!paymentInfo.isCod) {
            paymentInfo.isCod = false;
        }
        if (!paymentInfo.codAmount) {
            paymentInfo.codAmount = null;
        }
        if (!paymentInfo.paymentType.id) {
            paymentInfo.paymentType = paymentTypes ? paymentTypes[0] : null;
        }

        var paymentType = paymentTypes?.find((i) => i.id === paymentInfo?.paymentType?.id) ?? null;
        paymentInfo.paymentType = paymentType;

        var payBy = paymentByOptions?.find((i) => i.id === paymentInfo?.payBy?.id) ?? null;
        paymentInfo.payBy = payBy;
        paymentInfo.paymentDetail = paymentDetail;

        return paymentInfo;
    }

    validateFields = () => {
        return this.formRef.current.validateFields();
    }

    setFieldsValue = (values) => {
        this.setState({
            ...this.state,
            initialData: values
        });

        this.formRef?.current?.setFieldsValue(values);
        this.getPaymentCalculator();
    }

    tranformShippingFee = (deliveryInfo) => {
        var listShippingFee = [];
        if (deliveryInfo?.route?.shippingFeeDetails && deliveryInfo?.route?.shippingFeeDetails?.length > 0) {
            deliveryInfo?.route?.shippingFeeDetails.map((fee) => {
                var shippingFee = {
                    ShippingFeeDetailId: fee?.id,
                    AmountOfProduct: fee?.numberOfUnit
                }
                listShippingFee.push(shippingFee);
            })
        }

        return listShippingFee;
    }

    isStationSameWarehouse() {
        var { deliveryInfo } = this.state;
        return !deliveryInfo?.route?.id ? true : false;
    }

    isValidRequest = (request) => {
        if (!request.senderCityId || !request.receiverCityId ||
            !request.deliveryTypeId || !request.companyId || request.shippingFeeDetails?.length == 0
        ) {
            console.log('Invalid request model')
            return false;
        }
        return true;
    }
}