import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { DashboardScreen } from './dashboard.screen';
import { withRouter } from 'react-router-dom';

const mapStateToProps = ({ session }) => {
    return {
      userId: session?.auth?.user?.userid,
      isAuthenticated: (session?.auth?.token && session?.auth?.user?.role == 'USER') ? true : false
    };
  };

  export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, null),
    withRouter
  )(DashboardScreen);