export const doorToStation = "door to station";
export const doorToDoor = "door to door";
export const doorToWarehouse = "Door To Warehouse";

export const stationToStation = "Station to Station";
export const stationToDoor = "Station To Door";
export const warehouseToDoor = "warehouse to door";
export const warehouseToStation = "warehouse to station";
export const deliveryEndUser = "DeliveryEndUser";
export const deliveryEndUserWareHouse = "deliveryEndUserWareHouse";
export const deliveryStaff = "DeliveryStaff";

export const othersCategory = "Other";
export const othersCategoryId = "4e30ab02-a084-4784-86d4-1aa77a0ed419";

export const orderStep = {
  infor: 0,
  delivery: 1,
  payment: 2,
  pakageDetails: 3,
};
export const codFee = 30000;
export const cash = "Cash";
export const deliveryMethods = [
  "door to station",
  "station to station",
  "warehouse to station",
];

export const deliveryMethodsRenting = [
  "door to warehouse",
  "station to warehouse",
  "warehouse to door",
];

export const specialServices = {
  fastService: "Fast Service",
  normalService: "Normal Service",
  fragile: "Fragile",
};

export const contractStep = {
  infor: 0,
  delivery: 1,
  payment: 2,
  summary: 3,
};

export const iconPayment = {
  cash: "Cash",
  bank: "Bank",
  ewallet: "E-Wallet",
};

export const payBySender = "Pay By Sender";
export const payByRecipient = "Pay by Recipient";

export const paymentByConstants = {
  payBySender: 0,
  payByRecipient: 1,
};

export const paymentConstants = {
  SHOW_COD_CONDITION: 1,
};

export const createOrderTypes = {
  END_USER: "END USER",
  STATION: "STATION",
  WAREHOUSE: "WAREHOUSE",
};

export const deliveryHouseMethods = [
  "door to warehouse",
  "station to warehouse",
  "warehouse to door",
];

export const selectDate = {
  all: "all",
  thisMonth: "this-month",
  fromTo: "from-to",
};

export const orderSteps = {
  import: 0,
  export: 1,
  importFromVehicle: 2,
};
