import React, { Component } from "react";
import Layout, { Content } from "antd/lib/layout/layout";
import Form from "antd/lib/form/Form";
import { Redirect, Route } from "react-router-dom";
import { Card, message } from "antd";
import TopBar from "../../../components/topbar";
import NextPage from "../../../components/next-page";
import { renewContractSteps } from "../../../constants/leasing-contract-constant";
import { BookingInfo } from '../../../components/booking-info/booking-info.component';
import { LeasingDelivery } from "../../../components/delivery/delivery.component";
import { PaymentMethod } from "../../../components/payment-method/payment-method.component";
import { Summary } from "../../../components/summary/summary.component";
import contractDataService from "../../../data-services/contracts/contracts-data.service";
import "./renew-contract.scss";

export class RenewContractScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentForm: renewContractSteps.infor,
            data: {
                statusRenew: true,
                contractId: props.match?.params?.id,
            },
        }
        this.formRef = React.createRef()
    }

    componentWillMount() {
        this.props.getInitData()
        this.props.getCities()
    }

    render() {
        const { currentForm } = this.state
        return (
            <Layout className='bg-content beecow-contract-renew'>
                <div className='bg-body'>
                    <TopBar title="Renew Contract" />
                    <Content className='content'>
                        <Card className='beecow-card'>
                            <Form ref={this.formRef} >
                                {this.renderForm()}
                                <Route render={({ history }) => (
                                    <NextPage
                                        handleNext={this.handleNextPage.bind(this)}
                                        handleBack={this.handleBack.bind(this)}
                                        handleFinish={this.handleFinish.bind(this)}
                                        hideBack={currentForm === renewContractSteps.infor}
                                        hideNext={currentForm === renewContractSteps.summary}
                                        hideFinish={currentForm !== renewContractSteps.summary}
                                    />
                                )} />
                            </Form>
                        </Card>
                    </Content>
                </div>
            </Layout>
        );
    }

    renderForm() {
        const { t } = this.props;
        const { currentForm, data } = this.state
        switch (currentForm) {
            case renewContractSteps.payment:
                return <PaymentMethod t={t} data={data} onChangeTab={this.onChangePaymentMenthod.bind(this)} />
            case renewContractSteps.summary:
                return <Summary t={t} data={data} />
            default:
                return <BookingInfo t={t} data={data} setFieldToEmpty={this.setFieldToEmpty.bind(this)} />
        }
    }

    setFieldToEmpty(data) {
        this.formRef.current.setFieldsValue(data)
    }

    onChangePaymentMenthod(value) {
        this.formRef.current.setFieldsValue({ paymentTypeId: value })
    }

    handleNextPage() {
        const { currentForm, data } = this.state
        this.formRef?.current?.validateFields().then(() => {
            const values = this.formRef.current.getFieldsValue()
            this.setState({ currentForm: currentForm + 1, data: { ...data, ...values } })
        })
    }

    handleBack() {
        const { currentForm } = this.state
        this.setState({ currentForm: currentForm - 1 })
    }

    handleFinish() {
        const createdBy = this.props.userId;
        const {
            contractId, expiryDate, numberMonth, nameRenter, phoneRenter, emailRenter, paymentTypeId,
            wardRenter, districtRenter, cityRenter, addressRenter,
            isSender, nameSender, phoneSender, addressSender, citySender, districtSender, wardSender
        } = this.state.data;

        let renewContractRequest =
        {
            id: contractId,
            createdBy: createdBy,
            expiryDate: expiryDate,
            months: numberMonth,
            isSender: isSender,
            user:
            {
                id: this.props.userId,
                fullName: nameRenter,
                phone: phoneRenter,
                email: emailRenter,
                paymentTypeId: paymentTypeId,
                isSender: isSender,
                address:
                {
                    wardId: wardRenter,
                    districtId: districtRenter,
                    cityId: cityRenter,
                    street: addressRenter,
                }
            },
            sender: {
                fullName: isSender ? nameRenter : nameSender,
                phone: isSender ? phoneRenter : phoneSender,
                address: {
                    wardId: isSender ? wardRenter : wardSender,
                    districtId: isSender ? districtRenter : districtSender,
                    cityId: isSender ? cityRenter : citySender,
                    street: isSender ? addressRenter : addressSender
                }
            },
        };

        contractDataService.renewContract(renewContractRequest)
            .then(res => {
                console.log(res);
                this.props.history?.goBack();
            })
            .catch((err) => {
                console.log(err);
                message.error('Failed to renew contract.');
            });
    }
}