import React from 'react';
import NotFound from '../screens/not-found';
import Login from '../screens/login';
import ResetPasswordByEmail from '../screens/reset-password-by-email';

import DashboardScreen from '../screens/dashboard';
import CreateOrderScreen from '../screens/order/create-order/index';
import EditOrderScreen from '../screens/order/edit-order';
import PlaceOrderScreen from '../screens/order/place-order/index';

import ContractDetailPageClientScreen from '../screens/contract-detail';
import ListOrdersScreen from '../screens/list-orders';
import ViewOrderScreen from '../screens/shared/components/create-order/detail-package';
import ViewOrderHistoryScreen from '../screens/view-order-history';
import LeaseSpaceScreen from '../screens/leasing-contract';
import CreateLeasingContractScreen from '../screens/leasing-contract/create-leasing-contract';
import RentNewAreaScreen from '../screens/leasing-contract/rent-new-area';
import EditLeasingContractScreen from '../screens/leasing-contract/edit-leasing-contract';
import ProfileScreen from '../screens/profile';
import RenewContractScreen from '../screens/leasing-contract/renew-contract';
import AddMoreProductsScreen from '../screens/leasing-contract/add-more-products';
import ResetPasssWord from '../screens/resetpassword/reset-password.container';
import ExtendContractScreen from '../screens/extend-contract';
import ViewDetailScreen from '../screens/view-detail-order';
import ListProductComponent from '../components/list-product';
import ProductDetail from '../screens/view-product-detail';
import ViewExportListDetailScreen from '../screens/view-export-list-detail';
import ViewPickupListContainer from '../screens/view-pickup-list/view-pickup-list.container';
import ViewDeliveryListDetailContainer from '../screens/view-delivery-list-detail';
import ViewImportListScreen from '../screens/view-import-list';
import ViewImportFromVehicleDetailContainer from '../screens/view-import-from-vehicle';

import ConfirmLostPackageContainer from '../screens/order/lost-package/confirm';
import ReturnLostPackageContainer from '../screens/order/lost-package/return';
import EditContractRentAreaContainer from '../screens/leasing-contract/edit-contract-rent-area';
import AddProductsRentAreaScreen from '../screens/leasing-contract/add-product-contract-rent-area'

// Added on: 2021-12-09
import LostOrderListContainer from '../screens/lost-order-list';
import ViewLostOrderDetailsContainer from '../screens/view-lost-order-details';
import WarehouseRegistration from '../screens/warehouse-registration';
import EditWarehouseRegistrationScreen from '../screens/edit-warehouse-registration';
import ShipToWarehouseContainer from '../screens/order/ship-to-warehouse';
import PersonalWarehouseManagementContainer from '../screens/personal-warehouse-management';
import PersonalWarehouseContainer from './../screens/personal-warehouse/personal-warehouse.container';
import AddAreaContainer from './../screens/personal-warehouse-management/add-area';

// DESCRIPTION:
// {
//     key: ''// key of this menu
//     focus: '', // focus on this menu when open this menu
//     path: '', // path of this menu
//     name: '', // name of this menu
//     isMenu: false, // this is a menu or a route
//     auth: true, // this menu need to auth or not
//     exact: true, // this route needs to be exact or not
//     childs: [] // child routes
//     main: () => <Component />, // component of this menu
// }

const routes = [ // list of routes
    {
        key: 'app.login',
        path: '/login/:uid',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <Login />
    },
    {
        key: 'app.login',
        path: '/login',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <Login />
    },
    {
        key: 'app.reset.password.by.email',
        path: '/forgot-pasword/:refreshTokenKey',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <ResetPasswordByEmail />
    },
    {
        key: 'app.dashboard',
        path: '/home',
        icon: 'fa fa-tachometer',
        name: 'menuBar.home',
        isMenu: true,
        auth: true,
        exact: true,
        main: () => <DashboardScreen />
    },
    {
        key: 'app.dashboard.02',
        path: '/',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <DashboardScreen />
    },

    /// Order router
    {
        key: 'app.order.create',
        path: '/create-order',
        icon: 'fa fa-plus-square',
        name: 'menuBar.createOrder',
        isMenu: true,
        auth: true,
        exact: false,
        main: () => <CreateOrderScreen />
    },
    {
        key: 'app.orders.editOrder',
        path: '/edit-order/:id',
        icon: 'fa fa-plus-square',
        name: 'menuBar.editOrder',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <EditOrderScreen />
    },
    {
        key: 'app.contracts.orders.new',
        focus: 'app.contract.list',
        path: '/contract/:id/place-order',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <PlaceOrderScreen />
    },
    {
        key: 'app.contracts.orders.shipingProducts',
        path: '/contract/:id/ship-to-warehouse',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <ShipToWarehouseContainer />
    },
    {
        key: 'app.orders.list',
        path: '/list-orders',
        icon: 'fa fa-list',
        name: 'menuBar.orderList',
        isMenu: true,
        auth: true,
        exact: false,
        main: () => <ListOrdersScreen />
    },
    {
        key: 'app.order.detail',
        path: '/view-order/:orderId',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <ViewOrderScreen />
    },
    {
        key: 'app.order.history.orderCode',
        path: '/view-order-history/:orderCode',
        icon: 'fa fa-search-plus',
        name: 'menuBar.orderTracking',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <ViewOrderHistoryScreen />
    },
    {
        key: 'app.order.history',
        path: '/view-order-history',
        icon: 'fa fa-search-plus',
        name: 'menuBar.orderTracking',
        isMenu: true,
        auth: true,
        exact: true,
        main: () => <ViewOrderHistoryScreen />
    },
    {
        key: 'app.order.detail',
        path: '/detail-order/:orderId',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <ViewDetailScreen />
    },

    /// Contract router
    {
        key: 'app.contract.new',
        focus: 'app.contract.list',
        path: '/leasing-contract/create',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <CreateLeasingContractScreen />
    },

    {
        key: 'app.contract.rentArea.new',
        focus: 'app.contract.list',
        path: '/leasing-contract/rent-new-area',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <RentNewAreaScreen />
    },
    {
        key: 'app.contract.detail',
        focus: 'app.contract.list',
        path: '/contract/:id',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <ContractDetailPageClientScreen />
    },
    {
        key: 'app.personalWarehouse.detail',
        focus: 'app.personalWarehouse',
        path: '/personal-warehouse-contract/:id',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <ContractDetailPageClientScreen />
    },
    {
        key: 'app.contract.edit',
        path: '/contract/:id/edit',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <EditLeasingContractScreen />
    },
    {
        key: 'app.contract.edit',
        path: '/contract/:id/edit-contract-rent-area',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <EditContractRentAreaContainer />
    },
    {
        key: 'app.contract.renew',
        path: '/contract/:id/renew',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <RenewContractScreen />
    },
    {
        key: 'app.contract.extend',
        path: '/contract/:id/extend',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <ExtendContractScreen />
    },
    {
        key: 'app.contract.placeOrder',
        path: '/contract/:id/place-order',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <PlaceOrderScreen />
    },
    {
        key: 'app.contract.addProducts',
        path: '/contract/:id/add-products',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <AddMoreProductsScreen />
    },
    {
        key: 'app.contract.rentArea.addProducts',
        path: '/contract-rent-area/:id/add-products',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <AddProductsRentAreaScreen />
    },
    {
        key: 'app.users.edit',
        path: '/profile/:userId',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <ProfileScreen />
    },
    {
        path: '/reset-password/:userId',
        icon: '',
        name: 'Reset PassWord',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <ResetPasssWord />
    },

    /// Product router
    {
        key: 'app.product.list',
        path: '/print-products/:orderId',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <ListProductComponent />
    },
    {
        key: 'app.product.detail',
        path: '/products-detail/:productId',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <ProductDetail />
    },

    /// Import router
    {
        key: 'app.packages.import.detail',
        path: '/packages/view/:id',
        icon: 'fa fa-list',
        name: 'menu.importDetail',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <ViewImportListScreen />
    },

    /// Export router
    {
        key: 'app.packages.export.detail',
        path: '/view-export-list/:id',
        name: 'menu.exportPackagesDetail',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <ViewExportListDetailScreen />
    },
    {
        key: 'app.pickup.detail',
        path: '/view-pickup-list/:id',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <ViewPickupListContainer />
    },
    {
        key: 'app.deliveryOrders.reviewOrderListDelivery',
        path: '/view-delivery-list-detail/:id',
        name: 'menu.viewDeliveryListDetail',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <ViewDeliveryListDetailContainer />
    },
    {
        key: 'app.packages.import2station.detail',
        path: '/view-import-from-vehicle/:id',
        name: 'menuBar.viewImportListFromVehicle',
        isMenu: false,
        auth: true,
        exact: false,
        main: () => <ViewImportFromVehicleDetailContainer />
    },
    {
        key: 'app.lost-order-list',
        path: '/lost-order-list',
        icon: 'fa fa-exclamation-triangle',
        name: 'menu.lostOrders',
        isMenu: true,
        auth: true,
        exact: true,
        main: () => <LostOrderListContainer />
    },
    {
        key: 'app.lost-order-list.details',
        path: '/view-lost-order-details/:orderId',
        icon: 'fa fa-exclamation-triangle',
        name: 'menu.lostOrders',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <ViewLostOrderDetailsContainer />
    },
    {
        key: 'app.packages.lostPackage.return',
        path: '/return-lost-packages/:orderId/:confirmationType',
        name: 'lostPackage.return',
        isMenu: false,
        auth: false,
        exact: false,
        main: () => <ReturnLostPackageContainer />
    },
    {
        key: 'app.packages.lostPackage.confirm',
        path: '/confirm-lost-packages/:orderId/:confirmationType?',
        name: 'lostPackage.confirm',
        isMenu: false,
        auth: false,
        exact: false,
        main: () => <ConfirmLostPackageContainer />
    },
    {
        key: 'app.contract.list',
        path: '/leasing-contract',
        icon: 'fa fa-database',
        name: 'menuBar.warehouseLeasing',
        isMenu: true,
        auth: true,
        exact: false,
        main: () => <LeaseSpaceScreen />
    },
    {
        key: 'app.personalWarehouse',
        path: '/personal-warehouse',
        name: 'menu.personalWarehouse',
        isMenu: true,
        icon: 'fa fa-university',
        auth: true,
        exact: true,
        main: () => <PersonalWarehouseContainer />
    },
    {
        key: 'app.personalWarehouse.registration',
        focus: 'app.personalWarehouse',
        path: '/warehouse-registration',
        name: 'menu.warehouseRegistration',
        isMenu: false,
        icon: 'fa fa-registered',
        auth: true,
        exact: true,
        main: () => <WarehouseRegistration />
    },
    {
        key: 'app.personalWarehouse.area.add',
        focus: 'app.personalWarehouse',
        path: '/personal-warehouse/:id',
        name: 'menu.warehouseArea',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <PersonalWarehouseManagementContainer />
    },
    {
        key: 'app.personalWarehouse.area.edit',
        focus: 'app.personalWarehouse',
        path: '/personal-warehouse/:warehouseId/edit-area/:areaId',
        name: 'menu.warehouseArea',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <AddAreaContainer />
    },
    {
        key: 'app.personalWarehouse.areas.new',
        focus: 'app.personalWarehouse',
        path: '/warehouse/:warehouseId/add-area',
        name: 'menu.warehouseArea',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <AddAreaContainer />
    },
    {
        key: 'app.warehouse.registration.edit',
        path: '/edit-warehouse-registration/:warehouseId',
        name: 'menu.warehouseRegistration.edit',
        isMenu: false,
        auth: true,
        exact: true,
        main: () => <EditWarehouseRegistrationScreen />
    },
    {
        key: 'app.page-not-found',
        path: '/page-not-found',
        auth: false,
        exact: false,
        main: () => <NotFound />
    },
    {
        key: 'app.notfound',
        path: '',
        auth: false,
        exact: false,
        main: () => <NotFound />
    }
];

export default routes;