import React, { Component } from 'react';
import { Form } from "antd";
import { Content } from 'antd/lib/layout/layout';
import './shipping-info.scss';

import FormInfoComponent from "../../../../components/form-info/form-info.component";

export default class ShippingInfoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isValidSender: false,
            isValidReceiver: false,
            initialData: props.initialData ?? {
                receiverInfo: {
                    fullName: null,
                    phoneNumber: null,
                    address: {
                        street: null,
                        city: {
                            id: null,
                            name: null
                        },
                        district: {
                            id: null,
                            name: null
                        },
                        ward: {
                            id: null,
                            name: null
                        }
                    },
                }
            }
        }

        this.senderFormRef = React.createRef();
        this.receiverFormRef = React.createRef();
    }

    componentDidMount() {
    }

    render() {
        const { t } = this.props;
        var { initialData } = this.state;
        return (
            <Content className='beecow-shipping-info'>
                <div className="row">
                    <Form.Item name="receiverInfo" className='col-sm-6 col-lg-6'>
                        <FormInfoComponent t={t} initialData={initialData.receiverInfo} ref={this.receiverFormRef} title="createOrEditOrderPage.form.receiverInformation" />
                    </Form.Item>
                </div>
            </Content>
        )
    }

    validateFields = () => {
        let promises = [];

        if (this.senderFormRef && this.senderFormRef.current) {
            let senderFormValidation = this.senderFormRef.current.validateFields();
            promises.push(senderFormValidation);
        }

        if (this.receiverFormRef && this.receiverFormRef.current) {
            let receiverFormValidation = this.receiverFormRef.current.validateFields();
            promises.push(receiverFormValidation);
        }

        return Promise.all(promises);
    }

    getFieldsValue = () => {
        let shippingInfo = {};

        if (this.senderFormRef && this.senderFormRef.current) {
            let senderInfo = this.senderFormRef.current.getFieldsValue();
            shippingInfo["senderInfo"] = senderInfo;
        }

        if (this.receiverFormRef && this.receiverFormRef.current) {
            let receiverInfo = this.receiverFormRef.current.getFieldsValue();
            shippingInfo["receiverInfo"] = receiverInfo;
        }

        return shippingInfo;
    }

    setFieldsValue = (values) => {
        if (this.senderFormRef && this.senderFormRef.current) {
            this.senderFormRef.current.setFieldsValue(values.senderInfo);
        }
        if (this.receiverFormRef && this.receiverFormRef.current) {
            this.receiverFormRef.current.setFieldsValue(values.receiverInfo);
        }

        this.setState({ ...this.state, initialData: { ...this.state.initialData, ...values } });
    }
}