import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { PersonalWarehouseManagementScreen } from "./personal-warehouse-management.screen";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = (state) => ({
    userId: state.session?.auth?.user?.userid
  })

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, null), withRouter
)(PersonalWarehouseManagementScreen);