import { DataService } from "../base/base-data.service";

class UserDataService extends DataService {
  apiUrl = "/user";

  async getUserByPhone(phone) {
    return await this.get(`${this.apiUrl}/getByPhone?phone=${phone}`);
  }

  async getUserById(id) {
    return await this.get(`${this.apiUrl}/${id}`);
  }

  async getInitUser() {
    return await this.get(`${this.apiUrl}/get-init-user`);
  }

  async getUserId(id) {
    return await this.get(`${this.apiUrl}/get-user-by-Id?id=${id}`);
  }

  async getUserProfileById(id) {
    return await this.get(`${this.apiUrl}/get-user-profile-by-id?id=${id}`);
  }

  async resetPasswordByUser(req) {
    return await this.put(`${this.apiUrl}/reset-password-by-user`, req);
  }

  async resetPassword(req) {
    return await this.put(`${this.apiUrl}/reset-password`, req);
  }

  async update(req) {
    return await this.put(`${this.apiUrl}/update`, req);
  }
  async logout(req) {
    return await this.post(`${this.apiUrl}/logout`, req);
  }

  async getUserInfoById(userId) {
    return await this.get(
      `${this.apiUrl}/get-user-info-by-id?userId=${userId}`
    );
  }

  async register(data) {
    return await this.post(`${this.apiUrl}/register`, data);
  }

  async checkExistEmailByType(data) {
    return await this.post(`${this.apiUrl}/create-refresh-token-by-email`, data);
  }

  async checkRefeshToken(refreshTokenKey) {
    return await this.get(`${this.apiUrl}/check-refresh-token/${refreshTokenKey}`);
  }

  async updatePasswordByEmail(req) {
    return await this.put(`${this.apiUrl}/update-password-by-email`, req);
  }
}

let userDataService = new UserDataService();

export default userDataService;
