import localeEN from 'antd/es/date-picker/locale/en_US';
import localeVI from 'antd/es/date-picker/locale/vi_VN';
class LanguageService {
    setLang = (langCode) => {
        localStorage.setItem('i18nextLng', langCode);
    }

    getLang = () => {
        var langCode = localStorage.getItem('i18nextLng');
        return langCode ?? null;
    }

    getLocale = () => {
        var langCode = localStorage.getItem('i18nextLng');
        switch (langCode) {
            case 'vi':
                return localeVI;
            default:
                return localeEN;
        }
        
    }

}

const languageService = new LanguageService();
export default languageService;