import contractDataService from "../../data-services/contracts/contracts-data.service"
import actionTypes from "./extend-contract.type"

export const getExtendContract = (id) => {
  return async (dispatch) => {
    const res = await contractDataService.getExtendContract(id)
    dispatch({
      type: actionTypes.GET_EXTEND,
      payload: res.data
    })
  }
}