import { Input, Select } from 'antd'
import { Form } from 'antd';
import React, { Component } from 'react';
import addressDataService from '../../data-services/address/address-data.service';
import "./address-component.scss"

export default class AddressComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cities: null,
            districts: null,
            wards: null,
            address: props.initialData ?? {
                street: null,
                city: {
                    id: null,
                    name: null
                },
                district: {
                    id: null,
                    name: null,
                    prefix: null
                },
                ward: {
                    id: null,
                    name: null,
                    prefix: null
                }
            }
        }
    }

    componentDidMount() {
        this.initCities();
        
        if(this.props.initialData?.city?.id){
            this.onChangeCity(this.props.initialData?.city?.id);
        }
        
        if(this.props.initialData?.district?.id){
            this.onChangeDistrict(this.props.initialData?.district?.id);
        }
        
        if(this.props.initialData?.ward?.id){
            this.onChangeWard(this.props.initialData?.ward?.id);
        }
    }

    render() {
        const { cities, districts, wards } = this.state;
        const { disabled, t } = this.props;
        return (
            <div className="select">
                <div className="beecow-input-icon">
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <Form.Item
                        name={["address", "street"]}
                        rules={[{ required: true, message: t('createOrEditOrderPage.addressForm.pleaseEnterAddress') }]}>
                        <Input disabled={disabled} onChange={this.onChangeStreet} placeholder={t('createOrEditOrderPage.addressForm.enterAddress')} />
                    </Form.Item>
                    <div className='row'>
                        <div className='col-sm-6 col-lg-6'>
                            <Form.Item
                                name={["address", "city", "id"]}
                                rules={[{ required: true, message: t('createOrEditOrderPage.addressForm.pleaseSelectCity') }]}>
                                <Select
                                    disabled={disabled}
                                    showSearch
                                    placeholder={t('createOrEditOrderPage.addressForm.selectCity')}
                                    optionFilterProp="children"
                                    onChange={this.onChangeCity}
                                    onFocus={this.onFocus}
                                    onBlur={this.onBlur}
                                    onSearch={this.onSearch}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: '100%' }}>
                                    {
                                        cities?.map(({ id, name }) => <Select.Option value={id} key={id}>{name}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name={["address", "city", "name"]} hidden><Input hidden readOnly="true" /></Form.Item>
                        </div>
                        <div className='col-sm-6 col-lg-6'>
                            <Form.Item
                                name={["address", "district", "id"]}
                                rules={[{ required: true, message: t('createOrEditOrderPage.addressForm.pleaseSelectDistrict') }]}>
                                <Select
                                    disabled={disabled}
                                    showSearch
                                    placeholder={t('createOrEditOrderPage.addressForm.selectDistrict')}
                                    optionFilterProp="children"
                                    onChange={this.onChangeDistrict}
                                    onFocus={this.onFocus}
                                    onBlur={this.onBlur}
                                    onSearch={this.onSearch}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: '100%' }}>
                                    {
                                        districts?.map(({ id, name, prefix }) => <Select.Option value={id} key={id}>{`${prefix} ${name}`}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name={["address", "district", "name"]} hidden><Input hidden readOnly="true" /></Form.Item>

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6 col-lg-6'>
                            <Form.Item
                                name={["address", "ward", "id"]}
                                rules={[{ required: true, message: t('createOrEditOrderPage.addressForm.pleaseSelectWard') }]}>
                                <Select
                                    disabled={disabled}
                                    showSearch
                                    placeholder={t('createOrEditOrderPage.addressForm.selectWard')}
                                    optionFilterProp="children"
                                    onChange={this.onChangeWard}
                                    onFocus={this.onFocus}
                                    onBlur={this.onBlur}
                                    onSearch={this.onSearch}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {
                                        wards?.map(({ id, name, prefix }) => <Select.Option value={id} key={id}>{`${prefix} ${name}`}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name={["address", "ward", "name"]} hidden><Input hidden readOnly="true" /></Form.Item>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    initCities = () => {
        if (!this.state.cities) {
            addressDataService.getInitData().then((res) => {
                this.setState({ cities: res.cities });
            });
        }
    }

    onChangeCity = async (cityId) => {
        var { address } = this.state;
        var name = this.state.cities?.find(i => i.id === cityId)?.name;
        this.setState({
            districts: null,
            wards: null,
            address: {
                ...address,
                city: {
                    id: cityId,
                    name: name
                },
                district: {
                    id: null,
                    name: null,
                    prefix: null
                },
                ward: {
                    id: null,
                    name: null,
                    prefix: null
                }
            }

        }, () => {
            this.onChange();
        });

        addressDataService.getDistrictByCityId(cityId).then((res) => {
            this.setState({ districts: res.districts })
        });
    }

    onChangeDistrict = async (districtId) => {
        var { address } = this.state;
        var name = this.state.districts?.find(i => i.id === districtId)?.name;
        var prefix = this.state.districts?.find(i => i.id === districtId)?.prefix;
        this.setState({
            wards: null,
            address: {
                ...address,
                district: {
                    id: districtId,
                    name: name,
                    prefix: prefix
                },
                ward: {
                    id: null,
                    name: null,
                    prefix: null
                }
            }
        }, () => {

            this.onChange();
        });

        addressDataService.getWardsByDistrictId(districtId).then((res) => {
            this.setState({ wards: res.wards })
        });
    }

    onChangeWard = async (wardId) => {
        var { address } = this.state;
        var name = this.state.wards?.find(i => i.id === wardId)?.name;
        var prefix = this.state.wards?.find(i => i.id === wardId)?.prefix;
        if(name === undefined || name === null){
            name = address.ward.name;
        }
        if (prefix === undefined || prefix === null) {
            prefix = address.ward.prefix;
        }
        address.ward.id = wardId;
        address.ward.name = name;
        address.ward.prefix = prefix;
        this.setState({
            address: address
        });

        this.onChange();
    }

    onChangeStreet = (event) => {
        this.setState({
            address: {
                ...this.state.address,
                street: event.target.value
            }
        }, () => { this.onChange() });


    }

    onRefresh = () => {
        var { cities, districts, wards, address } = this.state;
        var city = cities?.find((c) => c.id === address?.city?.id) ?? address.city;
        var district = districts?.find((c) => c.id === address?.district?.id) ?? address.district;
        var ward = wards?.find((c) => c.id === address?.ward?.id) ?? address.ward;

        this.setState({
            ...this.state,
            address: {
                ...address,
                city: city,
                district: district,
                ward: ward
            }
        });

        this.onChange();
    }

    onChange = () => {
        if (this.props.onChange) {
            this.props.onChange(this.state.address);
        }
    }

    setFieldsValue = (address) => {
        this.setState({
            ...this.state,
            address: address
        }, () => {
            var { address } = this.state;
            if (address?.city?.id) {
                addressDataService.getDistrictByCityId(address?.city?.id).then((res) => {
                    this.setState({ districts: res.districts })
                }).then(() => {
                    if (address?.district?.id) {
                        addressDataService.getWardsByDistrictId(address?.district?.id).then((res) => {
                            this.setState({ wards: res.wards })
                        });
                    }
                })
            }
        });

    }
}
