import { Component } from "react";
import Layout, { Content, Header } from "antd/lib/layout/layout";
import { Tabs, Space, Button, Input, Dropdown, Menu, Card } from "antd";
import CardOrder from "./card-order";
import { DownOutlined } from "@ant-design/icons";
import _ from "lodash";
import TopBar from "../../components/topbar";
import orderDataService from "../../data-services/orders/orders-data.service";
import { showAlert } from "../../constants/constants";

const { TabPane } = Tabs;
const { Search } = Input;
export class ListOrdersScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            filterBy: '',
            sortBy: this.getSortOptions()[0],
        };
    }

    componentDidMount() {
        this.InitDataTable();
    }

    InitDataTable() {
        if (this.props.loggedInUserId) {
            this.props.listOrders(this.props.loggedInUserId).then(() => {
                this.getOrder();
            });
        }
    }

    getSortOptions() {
        const { t } = this.props;
        const sortOptions = [
            { value: 'createdate.desc', text: t('orderListPage.newest') },
            { value: 'createdate.asc', text: t('orderListPage.oldest') },
        ];
        return sortOptions;
    }

    handleDeleteOrder = (orderId) => {
        const { t } = this.props;
        let message = '';
        let description = '';
        orderDataService.deleteOrderById(orderId).then((res) => {
            if (res) {
                message = t('confirm.success');
                description = t('confirm.deleteSuccess');
                showAlert(message, description, true);
                this.InitDataTable();
            } else {
                message = t('confirm.warning');
                description = t('confirm.deleteFail');
                showAlert(message, description, false);
            }
        });
    };

    render() {
        const { t } = this.props;
        const { orders } = this.state;

        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t('orderListPage.title')} />
                    <Content className='content'>
                        <Card>
                            <Tabs defaultActiveKey="active" tabBarExtraContent={this.renderSearchSortOptions()}>
                                <TabPane tab={t('orderListPage.all')} key="all">
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                        {orders.map((order, index) => <CardOrder t={t} key={index} dataSource={order} handleDeleteOrder={this.handleDeleteOrder} />)}
                                    </Space>
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Content>
                </div>
            </Layout>
        );
    }

    getOrder = () => {
        let orders = this.props.orders || [];
        if (this.state.filterBy) {
            let value = this.state.filterBy.toLowerCase();
            orders = orders.filter(o => o.code?.toLowerCase().indexOf(value) >= 0);
        }
        switch (this.state.sortBy.value) {
            case 'createdate.desc':
                orders = _.sortBy(orders, 'createdTime').reverse();
                break;
            case 'createdate.asc':
                orders = _.sortBy(orders, 'createdTime');
                break;
            default:
                orders = _.sortBy(orders, 'createdTime').reverse();
                break;
        }
        this.setState({ orders: orders });
    }

    renderSearchSortOptions() {
        const { t } = this.props;
        return (
            <div style={{ display: "flex", alignItems: "center", marginBottom: "-6px" }}>
                <Dropdown overlay={this.renderSortOptionMenu()}>
                    <Button size="large">
                        {t('orderListPage.sortedBy')}: {t('orderListPage.newest')} <DownOutlined />
                    </Button>
                </Dropdown>
                <Search
                    placeholder={t('orderListPage.searchBy')}
                    allowClear
                    enterButton={t('button.search')}
                    size="large"
                    onSearch={this.handleTextChanged} />
            </div>
        );
    }

    renderSortOptionMenu() {
        return (
            <Menu onClick={this.handleMenuClicked}>
                {this.getSortOptions().map(option => <Menu.Item key={option.value}>{option.text}</Menu.Item>)}
            </Menu>
        );
    }

    handleMenuClicked = (e) => {
        let sortBy = this.getSortOptions().filter(option => option.value === e.key)[0];
        this.setState({
            sortBy: sortBy,
        }, () => {
            this.getOrder();
        });
    }

    handleTextChanged = (value) => {
        this.setState({
            filterBy: value,
        }, () => {
            this.getOrder();
        });
    }
}