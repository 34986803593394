import { DataService } from "../base/base-data.service";

class OrderDataService extends DataService {
    apiUrl = '/order'

    async getInitData(deliveryType) {
        return await this.get(`${this.apiUrl}/loadData?deliveryType=${deliveryType}`);
    }

    async listStationOrders(stationId) {
        return await this.get(`${this.apiUrl}/list-station-orders?stationId=${stationId}`);
    }

    async listUserOrders(userId) {
        return await this.get(`${this.apiUrl}/list-user-orders?userId=${userId}`);
    }

    async getStation(receiverCity) {
        return await this.get(`${this.apiUrl}/get-station?cityId=${receiverCity}`);
    }

    async getRouting(toStation, fromStation) {
        return await this.get(`${this.apiUrl}/get-routing?toStation=${toStation}&fromStation=${fromStation || ''}`);
    }

    async getRoutingToDoor(receiverCity, fromStation, senderCity) {
        return await this.get(`${this.apiUrl}/get-routing-to-door?receiverCity=${receiverCity}&fromStation=${fromStation || ''}&senderCity=${senderCity || ''}`);
    }
    async getRoutingToDoorWareHouse(contractId, receiverCity) {

        return await this.get(`${this.apiUrl}/get-routing-to-door-warehouse?contractId=${contractId || ''}&cityId=${receiverCity || ''}`);
    }
    async submit(data) {
        return await this.post(`${this.apiUrl}/submit`, data);
    }

    async getWardsByDistrictId(districtId) {
        return await this.get(`${this.apiUrl}/get-wards-by-districtId?districtId=${districtId}`);
    }

    async getDistrictByCityId(cityId) {
        return await this.get(`${this.apiUrl}/get-district-by-cityId?cityId=${cityId}`);
    }

    async getOrderDetails(orderId) {
        return await this.get(`${this.apiUrl}/get-order-details?id=${orderId}`);
    }

    async CaclFeeCreateOrderWareHouse(data) {
        return await this.post(`${this.apiUrl}/caclfee-create-order-warehouse`, data);
    }

    // async getDeliveryTypes(deliveryType) {
    //     return await this.get(`${this.apiUrl}/loadData?deliveryType=${deliveryType}`);
    // }

    async createOrder(data) {
        return await this.post(`${this.apiUrl}/create-order`, data);
    }

    async paymentCalculator(data) {
        return await this.post(`${this.apiUrl}/payment-calculator`, data);
    }

    async getOrderTrackingByOrderCode(orderCode) {
        return await this.get(`${this.apiUrl}/get-order-tracking-by-order-code/${orderCode}`);
    }

    async updateOrder(data) {
        return await this.post(`${this.apiUrl}/update-order`, data);
    }

    async getPickupListById(id) {
        return await this.get(`${this.apiUrl}/get-pickup-list-by-id?id=${id}`);
    }

    async getExportListById(id) {
        return await this.get(`${this.apiUrl}/get-export-list-by-id?id=${id}`);
    }

    async getDeliveryListDetailById(id) {
        return await this.get(`${this.apiUrl}/get-delivery-list-detail/${id}`);
    }

    async deleteOrderById(orderId) {
        return await this.delete(`${this.apiUrl}/delete/${orderId}`);
    }

    async confirmLostPackage(data) {
        return await this.post(`${this.apiUrl}/confirm-lost-package`, data);
    }

    async getLostOrderList(fromDate, toDate, stationId, userId) {
        return await this.post(`${this.apiUrl}/get-lost-order-list`, { fromDate, toDate, stationId, userId });
    }

    async getLostOrderDetails(orderId, viewOnEndUserWeb) {
        return await this.post(`${this.apiUrl}/get-lost-order-details`, { orderId, viewOnEndUserWeb });
    }

    async createOrderShipToWarehouse(data) {
        return await this.post(`${this.apiUrl}/create-order-ship-to-warehouse`, data);
    }
}

let orderDataService = new OrderDataService();

export default orderDataService;