import React, { Component } from 'react';
import { Content } from 'antd/lib/layout/layout';
import PropTypes from 'prop-types';
import { SenderInfo } from '../sender-info/sender-info.component';
import Checkbox from 'antd/lib/checkbox/Checkbox';

export class BookingInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            initialData: props.initialData,
        }
        this.senderInfoForm = React.createRef();
    }

    render() {
        const { t, isRentArea } = this.props;
        let { initialData } = this.state;
        return (
            <Content className='beecow-booking-info'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div>
                            {
                                !isRentArea && <>
                                    <Checkbox 
                                        value={initialData?.isSender} 
                                        checked={initialData?.isSender}
                                        onChange={this.handleChange}>Use last Sender information
                                    </Checkbox><br/>
                                </>
                            }
                        </div>
                        <SenderInfo t={t} ref={this.senderInfoForm} initialData={initialData?.sender} />
                    </div>
                </div>
            </Content>
        )
    }

    handleChange = (e) => {
        let { initialData } = this.state;
        initialData.sender.isSender = e.target.checked
        this.setState({ initialData: initialData})
    }

    validateFields() {
        let promises = [];
        if (this.senderInfoForm && this.senderInfoForm.current) {
            let senderInfoValidation = this.senderInfoForm.current.validateFields();
            promises.push(senderInfoValidation);
        }
        
        return Promise.all(promises);
    }

    setFieldsValue(values) {
        if (this.senderInfoForm && this.senderInfoForm.current) {
            this.senderInfoForm.current.setFieldsValue(values.sender);
        }
        this.setState({ ...this.state, initialData: { ...this.state.initialData, ...values } });
    }

    getFieldsValue() {
        let bookingInfo = {};
        if (this.senderInfoForm && this.senderInfoForm.current) {
            let senderInfo = this.senderInfoForm.current.getFieldsValue();
            bookingInfo['sender'] = senderInfo;
        }
        bookingInfo['isSender'] = this.state.initialData?.sender?.isSender;

        return bookingInfo;
    }

    handleChange(e) {
        this.setState({ ...this.state, initialData: { ...this.state.initialData }});
    }
}