import { DataService } from "../base/base-data.service";

class LeasingContractDataService extends DataService {
    apiUrl = '/station';
    apiUrlType = '/ShippingFeeDetail';
    apiUrlContract = '/contract';
    apiUrlRoute = '/route';
    apiAreaStationUrl = '/AreaStation';

    async getListStation() {
        return this.get(`${this.apiUrl}/loadData`);
    }

    async getUnitAndSpecial() {
        return this.get(`${this.apiUrlType}/getDetailUnitAndSpecial`);
    }

    async getStation(stationLocation) {
        return this.get(`${this.apiUrlContract}/get-station?stationLocation=${stationLocation}`);
    }

    async getRoutesByCity(data) {
        return this.post(`${this.apiUrlRoute}/routes-by-city`, data);
    }

    async stationsForLease(productInfo) {
        return await this.post(`${this.apiAreaStationUrl}/stations-for-lease`, productInfo)
    }

    async stationsForRentArea(data) {
        return await this.post(`${this.apiAreaStationUrl}/stations-for-rent-area`, data)
    }

    async leasingFeeCalculator(data) {
        return await this.post(`${this.apiUrlContract}/leasing-fee-calculator`, data)
    }

    async addNewContract(data) {
        return await this.post(`${this.apiUrlContract}/create`, data)
    }

    async getInitData() {
        return await this.get(`${this.apiUrlContract}/getInitData`);
    }

}

let leasingContractDataService = new LeasingContractDataService();

export default leasingContractDataService;