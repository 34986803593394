import i18n from "i18next";
import languageDetector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import cache from "i18next-localstorage-cache";
import { env } from "../env";

i18n
  .use(languageDetector)
  .use(cache)
  .use(backend)
  .init({
    debug: false,
    //lng: localStorage.getItem('i18nextLng') ?? 'en',
    fallbackLng: "en",
    ns: ["translations"],
    defaultNS: "translations",
    preload: ["en", "vi"],
    keySeparator: ".",
    backend: {
      crossDomain: true,
      loadPath: `${env.REACT_APP_API_DOMAIN}/language/web/end-user/{{lng}}.json?v=${new Date().getTime()}`,
      init: {
        mode: "cors",
        credentials: "include",
        cache: "default",
      },
    },
    cache: {
      enabled: true,
      prefix: "i18next_translations_",
      expirationTime: 24 * 60 * 60 * 1000, //one day
    },
    detection: {
      order: ["localStorage", "cookie"],
      lookupCookie: "i18nextLng",
      lookupLocalStorage: "i18nextLng",
      caches: ["localStorage"],
      //cookieMinutes: 10 // if we want the cookie to expire
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
