import { TEXT_VND } from '../constants/common';
import { formatAmount } from "../utils/order";

export const formatSubRoutes = (subRoutes) => {
    var toStations = subRoutes?.map((r) => `${r.toStationNavigation?.name}`)?.join(' - ') || '';
    if (toStations.length > 1) {
        return `${subRoutes[0].fromStationNavigation?.name} - ${toStations}`;
    }
    return '';
}

export const getShippingRoutesDatatable = (listRoutes) => {
    if (!listRoutes || listRoutes.length === 0) {
        return null;
    }

    return listRoutes.map((x, i) => ({
        key: x.id,
        id: x.id,
        routeId: x.code,
        routeName: x.routeName,
        from: (getSubRoutes(x.subRoutes)),
        to: getToStationDetail(x.subRoutes),
        subRoutes: formatSubRoutes(x.subRoutes),
        location: x.fromStationDetail?.location,
        eta: x.estimate,
        price: formatAmount(x.price) + TEXT_VND,
        company: x.company,
        shippingFeeDetails: x.shippingFeeDetails
    }));
}

export const getSubRoutes = (subRoutes) => {
    const list = subRoutes.map((value) => {
        return {
            fromStationId: value.fromStationNavigation.id,
            from: value.fromStationNavigation.name,
            fromCity: value.fromStationNavigation?.address?.city,
            to: value.toStationNavigation.name,
            toStationId: value.toStationNavigation.id,
        }
    });

    return list;
}

export const getToStationDetail = (subRoutes) => {
    const list = subRoutes.map((value) => {
        return {
            to: value.toStationNavigation.name,
            toStationId: value.toStationNavigation.id,
        }
    });

    return list;
}