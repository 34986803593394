import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CreateLeasingContractScreen } from './create-leasing-contract.screen';
import {
  getInitData,
  addNewContract,
} from '../../../modules/leasing-contract/leasing-contract.actions';
import { getCities } from '../../../modules/address/address.actions';
import { withRouter } from 'react-router-dom';

import { compose } from 'redux';
import { withTranslation, translate, Trans } from 'react-i18next';

const mapStateToProps = ({ session }) => {
  return {
    userId: session.auth.user.userid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCities,
      getInitData,
      addNewContract,
    },
    dispatch
  );
};

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(CreateLeasingContractScreen);
