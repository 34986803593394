import { DataService } from "../base/base-data.service";

class DeliveryDataService extends DataService {
    apiUrl = '/delivery';

    async getDeliveryByDeliveryType(deliveryType) {
        return await this.get(`${this.apiUrl}/get-delivery-by-type/${deliveryType}`);
    }
}

let deliveryDataService = new DeliveryDataService();

export default deliveryDataService;