import React, { Component } from "react";
import { Link, Route, Redirect } from "react-router-dom";
import _ from "lodash";
import {
    Table,
    Button,
    Form,
    Row,
    Modal,
    Tabs,
    Card,
    Radio,
    Select,
    Col,
    Input,
    message,
} from "antd";
import {
    ExclamationCircleOutlined,
    UnorderedListOutlined,
    AppstoreOutlined,
} from "@ant-design/icons";
import Layout, { Content } from "antd/lib/layout/layout";
import TopBar from "../../components/topbar";
import stationDataService from "../../data-services/stations/stations-data.service";
import warehouseDataService from "../../data-services/warehouse/warehouse-data.service";
import areaStationDataService from "../../data-services/area-stations/area-stations-data.service";
import contractDataService from "../../data-services/contracts/contracts-data.service";
import actionTypes from "../../modules/warehouses/warehouses.types";
import { Utilities } from "../../common/utilities";
import WarehouseThumbnail from "../../components/warehouse-thumbnail";
import { page, thumbColors } from "../../common/constants";
import './personal-warehouse-management.screen.scss';
import { data } from "jquery";

const { Option } = Select;
const { confirm } = Modal;
const { TabPane } = Tabs;
const { Search } = Input;

const filterOptions = {
    all: 0,
    onlyAreaForLease: 1,
    onlyAreaForShipping: 2,
};
const areaFilterOption = [
    {
        id: filterOptions.all,
        title: "warehouse.selectOption.all",
    },
    {
        id: filterOptions.onlyAreaForLease,
        title: "warehouse.selectOption.onlyAreaForLease",
    },
    {
        id: filterOptions.onlyAreaForShipping,
        title: "warehouse.selectOption.onlyAreaForShipping",
    },
];
const thumbDescriptions = [
    {
        id: 0,
        color: thumbColors.rentedOut,
        title: "warehouse.thumbDescription.rentedOut",
    },
    {
        id: 1,
        color: thumbColors.available,
        title: "warehouse.thumbDescription.available",
    },
    {
        id: 2,
        color: thumbColors.warning,
        title: "warehouse.thumbDescription.warning",
    },
    {
        id: 3,
        color: thumbColors.full,
        title: "warehouse.thumbDescription.full",
    },
];

export class PersonalWarehouseManagementScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thumbnailMode: false,
            infoStation: [],
            stCode: "",
            stName: "",
            stAddress: "",
            stLocation: "",
            stCategory: "",
            editingKey: "",
            dataWarehouse: [],
            thumbDataWarehouse: [],
            dataContracts: [],
            current: 1,
            page: page.pageDefault,
            pageSize: page.pageSizeDefault,
            total: 0,
            filterArea: "",
            filterContract: "",
            warehouseId: '',
            test: 0,
            firstRequest: true,
            showRegistrationPage: false,
            warehouseID: null,
            allowUpdate: false,
            warehouseAdminName: '',
            warehouseAdminPhone: '',
            warehouseAdminEmail: '',
        };
        this.editDetailWarehouse = this.editDetailWarehouse.bind(this);
    }

    editDetailWarehouse = (record, history) => {
        this.props.dispatch({ type: actionTypes.SET_AREA_ID, payload: record.key });
        const { id } = this.props?.match?.params || {};
        history.push(`/personal-warehouse/${id}/edit-area/${record.key}`);
    };

    confirmDelete = (record, title) => {
        const { t } = this.props;
        const currentComponent = this;
        var ids = [];
        confirm({
            title: title,
            content: <a>{record.areaName}</a>,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                return new Promise((resolve, reject) => {
                    ids = [record.id];
                    areaStationDataService.deleteAreaStation(ids).then(res => {
                        currentComponent.initDataTable();
                        setTimeout(resolve, 500);

                        if (res.success === false) {
                            message.error(t(res.message));
                        }
                    });
                }).catch(errors => {

                });
            },
            onCancel() { },
        });
    };

    componentWillMount() {
        this.initDataTable();
        const { userId } = this.props;
        if (userId) {
            // this http request to get data.
            warehouseDataService.getListOfWarehouseByUserId(userId).then((res) => {
                let showRegistrationPage = false;

                if (res.redirectToWarehouseRegistration) {
                    showRegistrationPage = true;
                }
                this.setState({ firstRequest: false, showRegistrationPage, warehouseID: res.warehouseID });
            });
        }
    }

    getWarehouseColumns(dataWarehouse) {
        const { t } = this.props;

        let typesArea = dataWarehouse?.map(area => {
            return {
                text: area?.areaType,
                value: area?.areaType,
            };
        });

        let typeAreaOptions = typesArea?.filter(
            (d, i) => typesArea?.findIndex(o => o.value === d.value) === i
        );

        let columns = [
            {
                title: `${t("warehouse.area.id")}`,
                dataIndex: "areaID",
                sorter: (a, b) => a?.areaID?.localeCompare(b.areaID),
                width: "14%",
            },
            {
                title: `${t("warehouse.area.name")}`,
                dataIndex: "areaName",
                sorter: (a, b) => a?.areaName?.localeCompare(b.areaName),
                width: "14%",
            },
            {
                title: `${t("warehouse.area.category")}`,
                dataIndex: "stationName",
                render:(text, data)=> {
                    return <>{t(data.stationName)}</>
                },
                sorter: (a, b) => a?.areaName?.localeCompare(b.areaName),
                width: "14%",
            },
            {
                title: `${t("warehouse.area.size")}`,
                dataIndex: "areaSize",
                width: "14%",
            },
            {
                title: `${t("warehouse.area.numberSpace")}`,
                dataIndex: "areaNumberSpace",
                sorter: (a, b) => a.areaNumberSpace - b.areaNumberSpace,
                width: "14%",
            },
            {
                title: `${t("warehouse.area.spaceSize")}`,
                dataIndex: "areaSpaceSize",
                width: "15%",
            },
            {
                title: `${t("warehouse.area.used")}`,
                dataIndex: "areaUsed",
                width: "13%",
            },
            {
                title: `${t("unit.type")}`,
                dataIndex: "areaType",
                filters: typeAreaOptions,
                onFilter: (value, record) => record.areaType.indexOf(value) === 0,
                render: (text, data) => {
                    return <>{data.isForLease ? t("warehouse.addNewArea.labelForLease") : ''}</>
                }, 
                sorter: (a, b) => a.areaType?.localeCompare(b.areaType),
                width: "13%",
            },
            {
                title: `${t("station.action")}`,
                width: "9%",
                render: (_, record) => {
                    return (
                        <span className="action">
                            <Route
                                render={({ history }) => (
                                    <a
                                        disabled={this.state.editingKey !== ""}
                                        onClick={() => this.editDetailWarehouse(record, history)}
                                    >
                                        <i
                                            className="fa fa-pencil-square-o edit"
                                            aria-hidden="true"
                                        ></i>
                                    </a>
                                )}
                            />
                            <a
                                disabled={this.state.editingKey !== ""}
                                onClick={() =>
                                    this.confirmDelete(
                                        record,
                                        "Are you sure want to remove selected Areas ?"
                                    )
                                }
                            >
                                <i className="fa fa-trash delete" aria-hidden="true"></i>
                            </a>
                        </span>
                    );
                },
            },
        ];

        return this.mergedColumns(columns);
    }

    getContractColumns(dataContracts) {
        const { t } = this.props;
        let contractStatus = dataContracts?.map(contract => {
            return {
                text: t(contract?.status?.languageKey),
                value: contract?.status?.languageKey,
            };
        });
        
        let contractStatusOptions = contractStatus?.filter(
            (d, i) => contractStatus?.findIndex((o) => o.value === d.value) === i
        );

        let columns = [
            {
                title: `${t("warehouse.tabLeasingContracts.contract.id")}`,
                dataIndex: "code",
                width: "14%",
                sorter: (a, b) => {
                    return a.code?.localeCompare(b.code);
                },
                render: (code, contract) => {
                    let href = `/personal-warehouse-contract/${contract.id}`;
                    return (
                        <p className="text-primary code">
                            <Link to={href}>{contract.code}</Link>
                        </p>
                    );
                },
            },
            {
                title: `${t("warehouse.tabLeasingContracts.contract.info")}`,
                dataIndex: "user",
                width: "14%",
                sorter: (a, b) => {
                    return a.user?.localeCompare(b.user);
                },
                render: text => <span dangerouslySetInnerHTML={{ __html: text }} />,
            },
            {
                title: `${t("warehouse.tabLeasingContracts.contract.createdDate")}`,
                dataIndex: "createdTime",
                width: "14%",
                sorter: (a, b) => a?.createdTime?.localeCompare(b?.createdTime),
                render: (_, { createdTime, expiryDate }) => (
                    <p>
                        {createdTime} - {expiryDate}
                    </p>
                ),
            },
            {
                title: `${t("warehouse.tabLeasingContracts.contract.rentalPrice")}`,
                dataIndex: "priceLeasing",
                width: "15%",
                sorter: (a, b) => {
                    return a?.priceLeasing?.localeCompare(b?.priceLeasing);
                },
                render: price => <p>{price} VNĐ</p>,
            },
            {
                title: `${t("status.status")}`,
                dataIndex: "status",
                width: "13%",
                filters: contractStatusOptions,
                onFilter: (value, record) =>
                record.status?.languageKey?.indexOf(value) === 0,
                sorter: (a, b) => {
                    return a.status.id - b.status.id;
                },
                render: status => {
                    return <p className="status">{t(status?.languageKey || status?.statusName)}</p>;
                },
            },
        ];

        return this.mergedColumns(columns);
    }

    mergedColumns(columns) {
        let mergedColumns = columns.map(col => {
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputType: "text",
                    dataIndex: col.dataIndex,
                    title: col.title,
                }),
            };
        });

        return mergedColumns;
    }

    getUserInfo(user) {
        let formattedAddress =
            this.getFormattedAddress(user?.address) || "No address";
        let senderInfo = [user.fullName, user.phone, formattedAddress];
        return senderInfo.join("<br/>");
    }

    getFormattedAddress(address) {
        let arr = [];
        if (address && address.street) {
            arr.push(address.street);
        }
        if (address && address.ward) {
            let ward = address.ward.prefix + " " + address.ward.name;
            arr.push(ward);
        }
        if (address && address.district) {
            let district = address.district.prefix + " " + address.district.name;
            arr.push(district);
        }
        if (address && address.city) {
            arr.push(address.city.name);
        }
        return arr.join(", ");
    }

    getStationAddress(station) {
        return this.getFormattedAddress(station?.address) || "No address";
    }

    initDataTable() {
        const { id } = this.props?.match?.params || {};
        warehouseDataService.getWarehouseById(id).then(res => {
            const address = {
                street: res.data.address?.street,
                ward: res.data.ward,
                district: res.data.district,
                city: res.data.city
            };
            const warehouse = { ...res.data, address };
            this.setState({
                stCode: warehouse.code,
                stName: warehouse.name,
                stAddress: this.getStationAddress(warehouse),
                stLocation: warehouse.location.name,
                stCategory: warehouse?.warehouseType?.name,
                warehouseId: id,
                allowUpdate: warehouse?.isUpdate,
                warehouseAdminName: warehouse?.ownerUser?.fullName,
                warehouseAdminPhone: warehouse?.ownerUser?.phone,
                warehouseAdminEmail: warehouse?.ownerUser?.email
            });
        });

        //get list areaStation by StationId
        areaStationDataService.getAreaByWarehouseId(id).then(data => {
            const areas = this.getData(data);
            this.currentData = areas;
            let initDataForThumbnail = areas?.filter(item => item.isForLease === true);
            this.setState({
                dataWarehouse: areas,
                thumbDataWarehouse: initDataForThumbnail,
            });
        });

        this.fetchSpaces(
            this.state.page,
            this.state.pageSize,
            this.state.filterContract
        );
    }

    onPageChanged(page, pageSize) {
        this.setState({
            pageSize: pageSize,
        });
        this.fetchSpaces(page, pageSize, this.state.filterContract);
    }

    fetchSpaces(page, pageSize, filterContract) {
        const { id } = this.props?.match?.params || {};
        contractDataService
            .getContractsByStationId(id, page, pageSize, filterContract)
            .then(response => {
                var rows = response.rows;
                var contracts = this.mappingDataContracts(rows);
                this.setState({
                    dataContracts: contracts,
                    page: response.page,
                    total: response.records,
                    current: response.page,
                });
            });
    }

    getData = areaStationList => {
        const { t } = this.props;
        if (!areaStationList) {
            return;
        }
        
        return areaStationList.map((x, i) => ({
            key: x.id,
            areaID: x.code,
            areaName: x.name,
            areaSize:
                x.length?.toString() +
                "x" +
                x.width?.toString() +
                "x" +
                x.height?.toString(),
            areaNumberSpace: x.spaceUnused,
            areaSpaceSize:
                x.spaceLength?.toString() +
                "x" +
                x.spaceWidth?.toString() +
                "x" +
                x.spaceHeight?.toString(),
            areaUsed: x.spaceUsed?.toString() + "/" + x.spaceUnused?.toString(),
            totalSpaceUsed: x.spaceUsed,
            totalSpace: x.spaceUnused,
            areaType: x.nameTypeArea?.length === 0 ? " " : x.nameTypeArea,
            isForLease: x.isForLease,
            checked: false,
            id: x.id,
            stationName: x.stationTypeInfo.languageKey,
        }));
    };

    mappingDataContracts = contracts => {
        return contracts?.map((item, index) => ({
            key: item.id,
            id: item.id,
            code: item.code,
            user: this.getUserInfo(item.user),
            createdTime: item.createdTime,
            expiryDate: item.expiryDate,
            packageType: item.packageType,
            priceLeasing: Utilities.formatAmount(item.priceLeasing),
            status: item.status,
        }));
    };

    handleAddArea = () => {
        var { t } = this.props;
        const { warehouseId, allowUpdate } = this.state;
        return (
            <div className="tabs">
                <Route
                    render={({ history }) => (
                        <>
                           {
                            allowUpdate && <Button
                            className="add-new"
                            type="warning"
                            style={{marginRight:5}}
                            onClick={() => history.push(`/edit-warehouse-registration/${warehouseId}`)}>
                            <span className="btn-label">
                                <i className="fa fa-edit" aria-hidden="true"></i>
                            </span>
                            {t("warehouse.addNewArea.edit")}
                            </Button>
                           } 
                        
                        <Button
                            className="add-new"
                            type="warning"
                            onClick={() => history.push(`/warehouse/${warehouseId}/add-area`)}>
                            <span className="btn-label">
                                <i className="fa fa-plus-circle" aria-hidden="true"></i>
                            </span>
                            {t("warehouse.addNewArea.title")}
                        </Button>
                        </>
                    )}
                />
            </div>
        );
    };

    onChangeViewMode = e => {
        let initDataForThumbnail = this.state?.dataWarehouse?.filter(
            item => item.isForLease === true
        );
        this.setState({
            thumbnailMode: e.target.value,
            thumbDataWarehouse: initDataForThumbnail,
        });
    };

    getColorSpace = (usedAmount, total) => {
        if (usedAmount >= total) return thumbColors.rentedOut;

        if (usedAmount >= total / 2 && usedAmount < total * (7 / 10))
            return thumbColors.warning;

        if (usedAmount >= total * (7 / 10) && usedAmount < total)
            return thumbColors.full;

        return thumbColors.available;
    };

    onChangeFilterArea = value => {
        const { dataWarehouse } = this.state;
        let thumbDataWarehouse = [];
        if (value === filterOptions.all) {
            thumbDataWarehouse = dataWarehouse;
        }
        if (value === filterOptions.onlyAreaForLease) {
            thumbDataWarehouse = dataWarehouse.filter(
                item => item.isForLease === true
            );
        }
        if (value === filterOptions.onlyAreaForShipping) {
            thumbDataWarehouse = dataWarehouse.filter(
                item => item.isForLease === false
            );
        }
        this.setState({
            thumbDataWarehouse: thumbDataWarehouse,
        });
    };

    renderThumbs = () => {
        const { thumbDataWarehouse } = this.state;
        return thumbDataWarehouse?.map(data => {
            const actions = (
                <span className="action thumb-action mt-3">
                    <Route
                        render={({ history }) => (
                            <a
                                className="mr-4"
                                disabled={this.state.editingKey !== ""}
                                onClick={() => this.editDetailWarehouse(data, history)}
                            >
                                <i
                                    className="text-success fa fa-pencil-square-o"
                                    aria-hidden="true"
                                ></i>
                            </a>
                        )}
                    />
                    <a
                        disabled={this.state.editingKey !== ""}
                        onClick={() =>
                            this.confirmDelete(
                                data,
                                "Are you sure want to remove selected Areas ?"
                            )
                        }
                    >
                        <i className="text-danger fa fa-trash" aria-hidden="true"></i>
                    </a>
                </span>
            );

            const content = (
                <>
                    <div className="content-center">
                        <p className="m-0 fs-14">
                            <b>{data.areaID}</b>
                        </p>
                        <p className="m-0">{data.areaUsed}</p>
                        <p className="m-0 fs-13">{data.areaType}</p>
                    </div>
                </>
            );

            const color = this.getColorSpace(data?.totalSpaceUsed, data?.totalSpace);
            return (
                <WarehouseThumbnail
                    width="110px"
                    height="110px"
                    color={color}
                    className="ml-2 pointer text-center border fs-12 mt-2"
                    content={content}
                    renderActionButton={actions}
                />
            );
        });
    };

    handleSearchArea = (filterArea, dataWarehouse) => {
        filterArea = filterArea.toLowerCase();
        if (filterArea && dataWarehouse) {
            const filteredData = dataWarehouse?.filter(
                area =>
                    area.areaID?.toLowerCase().includes(filterArea) ||
                    area.areaName?.toLowerCase().includes(filterArea) ||
                    area.areaSize?.toLowerCase().includes(filterArea) ||
                    area.areaNumberSpace
                        ?.toString()
                        ?.toLowerCase()
                        .includes(filterArea) ||
                    area.areaSpaceSize?.toLowerCase().includes(filterArea) ||
                    area.areaUsed?.toLowerCase().includes(filterArea) ||
                    area.areaType?.toLowerCase().includes(filterArea)
            );
            return filteredData;
        }

        return dataWarehouse;
    };

    handleSearchContract = value => {
        this.fetchSpaces(page.pageDefault, page.pageSizeDefault, value);
        this.setState({ filterContract: value });
    };

    render() {
        var { t } = this.props;
        const {
            dataWarehouse,
            dataContracts,
            stCode,
            stName,
            stAddress,
            stLocation,
            filterArea,
            firstRequest,
            showRegistrationPage,
            warehouseAdminName,
            warehouseAdminPhone,
            warehouseAdminEmail
        } = this.state;
        
        let dataWarehouseFilter = dataWarehouse;
        const paging = {
            current: this.state.current,
            pageSize: this.state.pageSize,
            total: this.state.total,
            onChange: this.onPageChanged.bind(this),
        };

        if (firstRequest === false) {
            if (showRegistrationPage) {
                return <Redirect to="/warehouse-registration" />
            }
        }

        if (filterArea) {
            dataWarehouseFilter = this.handleSearchArea(filterArea, dataWarehouse);
        }

        let warehouseColumns = this.getWarehouseColumns(dataWarehouse);
        let contractColumns = this.getContractColumns(dataContracts);

        return (
            <Layout className="bg-content">
                <div className="bg-body">
                    <TopBar title={t("warehouse.title")} />
                    <Content className="content">
                        <Card>
                            <Tabs
                                className="bg-warehouse"
                                tabBarExtraContent={this.handleAddArea()}
                            >
                                <TabPane tab={t("warehouse.tabWarehouse.title")} key="1">
                                    <div>
                                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            {" "}
                            {t("warehouse.tabWarehouse.code")}: {stCode}
                          </div>

                          <div className="col-md-6">
                            {" "}
                            {t("warehouseAdmin.warehouseAdminName")}:{" "}
                            {warehouseAdminName}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            {" "}
                            {t("warehouse.tabWarehouse.name")}: {stName}
                          </div>

                          <div className="col-md-6">
                            {" "}
                            {t("warehouseAdmin.warehouseAdminPhone")}:{" "}
                            <a href={`tel:${warehouseAdminPhone}`}>{warehouseAdminPhone}</a>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            {" "}
                            {t("warehouse.tabWarehouse.address")}: {stAddress}
                          </div>

                          <div className="col-md-6">
                            {" "}
                            {t("warehouseAdmin.warehouseAdminEmail")}:{" "}
                            <a href={`mailto:${warehouseAdminEmail}`}>{warehouseAdminEmail}</a>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            {" "}
                            {t("warehouse.tabWarehouse.location")}:{" "}
                            {t(stLocation)}
                          </div>
                        </div>
                      </div>
                    </div>

                                        <div className="mt-3">
                                            <Radio.Group
                                                value={this.state.thumbnailMode}
                                                onChange={this.onChangeViewMode}
                                            >
                                                <Radio.Button className="btn-icon" value={false}>
                                                    <UnorderedListOutlined />
                                                    <span className="ml-1">{t("button.listView")}</span>
                                                </Radio.Button>
                                                <Radio.Button className="btn-icon m-3" value={true}>
                                                    <AppstoreOutlined />
                                                    <span className="ml-1">{t("button.thumbnail")}</span>
                                                </Radio.Button>
                                            </Radio.Group>
                                        </div>
                                        <div className="mt-3">
                                            {this.state.thumbnailMode ? (
                                                <div
                                                    className="p-2 pb-3"
                                                    style={{ backgroundColor: "#f0f2f5" }}
                                                >
                                                    <Row>
                                                        <Select
                                                            className="ml-2 mt-3"
                                                            defaultValue={areaFilterOption[1].id}
                                                            style={{ width: 200 }}
                                                            onChange={this.onChangeFilterArea}
                                                        >
                                                            {areaFilterOption?.map(i => (
                                                                <Option key={i.id} value={i.id}>
                                                                    {t(i.title)}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Row>
                                                    <Row className="mt-4 d-flex justify-content-center">
                                                        {thumbDescriptions?.map(data => {
                                                            return (
                                                                <>
                                                                    <Col>
                                                                        <Row>
                                                                            <Col key={`${data.id}-1`}>
                                                                                <WarehouseThumbnail
                                                                                    width="50px"
                                                                                    height="50px"
                                                                                    color={data.color}
                                                                                    className="ml-2 pointer text-center border fs-11"
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                key={`${data.id}-2`}
                                                                                style={{ width: "60px" }}
                                                                                className="mt-2 ml-2 mr-5"
                                                                            >
                                                                                <span className="center">
                                                                                    {t(data.title)}
                                                                                </span>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </>
                                                            );
                                                        })}
                                                    </Row>
                                                    <hr />
                                                    <Row
                                                        className="mt-4"
                                                        style={{ maxHeight: "500px", overflowY: "auto" }}
                                                    >
                                                        {this.renderThumbs()}
                                                    </Row>
                                                </div>
                                            ) : (
                                                <Form>
                                                    <Card className="mt-5">
                                                        <div
                                                            style={{ display: "flex", alignItems: "center" }}
                                                        >
                                                            <Search
                                                                className="mr-3"
                                                                placeholder={t("placeholder.searchArea")}
                                                                allowClear
                                                                enterButton={t("placeholder.search")}
                                                                size="large"
                                                                onSearch={value =>
                                                                    this.setState({ filterArea: value })
                                                                }
                                                            />
                                                        </div>
                                                    </Card>
                                                    <Card>
                                                        <Table
                                                            rowClassName={() => "editable-row"}
                                                            bordered
                                                            dataSource={dataWarehouseFilter}
                                                            columns={warehouseColumns}
                                                        />
                                                    </Card>
                                                </Form>
                                            )}
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab={t('warehouse.tabLeasingContracts.title')} key='2'>
                                    <div>
                                        <Form>
                                            <Card>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Search
                                                        className='mr-3'
                                                        placeholder={t('placeholder.searchContract')}
                                                        allowClear
                                                        enterButton={t('placeholder.search')}
                                                        size='large'
                                                        onSearch={this.handleSearchContract}
                                                    />
                                                </div>
                                            </Card>
                                            <Table
                                                rowClassName={() => 'editable-row'}
                                                bordered
                                                dataSource={dataContracts}
                                                columns={contractColumns}
                                                pagination={paging}
                                                onchange={this.onChange}
                                            />
                                        </Form>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Content>
                </div>
            </Layout>
        );
    }
}
