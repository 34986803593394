import { connect } from 'react-redux';
import { RentNewAreaScreen } from './rent-new-area.screen';
import { withRouter } from 'react-router-dom';

import { compose } from 'redux';
import { withTranslation, translate, Trans } from 'react-i18next';

const mapStateToProps = ({ session }) => {
  return {
    userId: session.auth.user.userid,
  };
};

export default compose(
  withTranslation('translations'),
  connect(mapStateToProps, null),
  withRouter
)(RentNewAreaScreen);
