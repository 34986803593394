import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AddAreaScreen } from "./add-area.screen";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const mapStateToProps = ({ warehouse, state }) => {
    return {
        id: warehouse?.id,
        idArea: warehouse?.idArea,
        idSpace: warehouse?.idSpace
    };
}

export default compose(
    withTranslation('translations'),
    connect(mapStateToProps, null), withRouter
)(AddAreaScreen);