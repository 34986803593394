import React, { Component } from 'react';
import { Button, Col, message, Row, Steps } from "antd";
import Layout from 'antd/lib/layout/layout';
import { contractStep } from '../../constants/order-constant';
import TopBar from '../../components/topbar';
import userDataService from '../../data-services/user/user-data.service';
import ContentExtendContract from '../extend-contract/content-extend-contract';
import PaymentExtendContract from '../extend-contract/content-extend-contract/payment-extend-contract';
import SummaryExtendContract from '../extend-contract/content-extend-contract/summary-extend-contract';
import contractDataService from '../../data-services/contracts/contracts-data.service';
import { cash } from '../../constants/leasing-contract-constant';

const { Step } = Steps;

class ExtendContractScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentForm: contractStep.infor,
      current: 0,
      months: 0
    }
    this.extendFormRef = React.createRef();
  }

  render() {
    const { t } = this.props;
    const { current } = this.state;
    const steps = this.createSteps();
    const { extendContract } = this.props
    return (
      <Layout className='bg-content'>
        <div className='bg-body'>
          <TopBar title={t('extendContractPage.title')} />
          <div className="bg-white clearfix beecow-leasing-contract">
            <Steps
              type="navigation"
              current={current}
              className="site-navigation-steps"
            >
              {steps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <Row>
              <Col span={20}>
                <h3><p className="pull-right clearfix">{t('extendContractPage.contactId')}: {extendContract?.code}</p></h3>
              </Col>
            </Row>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action" style={{ float: "right", margin: "20px" }}>
              {current > 0 && (
                <Button type="primary" style={{ margin: "0 5px" }} onClick={this.prev.bind(this)}>
                  {t('button.back')}
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button type="primary" style={{ margin: "0 5px" }} onClick={this.next.bind(this)}>
                  {t('button.next')}
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button type="primary" style={{ margin: "0 5px" }} onClick={() => this.onDone()}>
                  {t('button.done')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Layout>
    )
  }

  createSteps() {
    const { t } = this.props;
    const { months, paymentTypeId } = this.state
    const steps = [
      {
        title: t('extendContractPage.steps.extendContract'),
        content: <ContentExtendContract t={t} ref={this.extendFormRef} months={months} />,
      },
      {
        title: t('extendContractPage.steps.payment'),
        content: <PaymentExtendContract t={t} months={months} paymentTypeId={paymentTypeId} onChangeTab={this.onChangePaymentMenthod.bind(this)} />,
      },
      {
        title: t('extendContractPage.steps.summarize'),
        content: <SummaryExtendContract t={t} months={months} paymentTypeId={paymentTypeId} />,
      },
    ];
    return steps;
  }

  next() {
    let { current, paymentTypeId } = this.state
    if (this.extendFormRef?.current && current === 0) {
      this.extendFormRef.current.formRef?.current?.validateFields()
        .then(() => {
          const { months } = this.extendFormRef?.current?.formRef?.current?.getFieldsValue() || {}
          if (!paymentTypeId) paymentTypeId = this.props.extendContract?.paymentTypes?.find((x) => x.name === cash)?.id || ''
          this.setState({
            current: this.state.current + 1,
            months,
            paymentTypeId
          })
        })
        .catch(ex => console.log(ex))
    } else {
      this.setState({
        current: this.state.current + 1
      })
    }
  }

  onDone() {
    const createdBy = this.props.userId
    const {
      months, nameRenter, phoneRenter, emailRenter, paymentTypeId,
      wardRenter, districtRenter, cityRenter, addressRenter,
      isSender, nameSender, phoneSender, addressSender, citySender, districtSender, wardSender
    } = this.state;

    const { id } = this.props.extendContract

    const extendContractRequest =
    {
      id,
      createdBy,
      months,
      isSender: isSender,
      paymentTypeId,
      user:
      {
        id: this.props.userId,
        fullName: nameRenter,
        phone: phoneRenter,
        email: emailRenter,
        paymentTypeId: paymentTypeId,
        isSender: isSender,
        address:
        {
          wardId: wardRenter,
          districtId: districtRenter,
          cityId: cityRenter,
          street: addressRenter,
        }
      },
      sender: {
        fullName: isSender ? nameRenter : nameSender,
        phone: isSender ? phoneRenter : phoneSender,
        address: {
          wardId: isSender ? wardRenter : wardSender,
          districtId: isSender ? districtRenter : districtSender,
          cityId: isSender ? cityRenter : citySender,
          street: isSender ? addressRenter : addressSender
        }
      },
    };

    contractDataService.renewContract(extendContractRequest)
      .then(res => {
        this.props.history?.goBack();
      })
      .catch((err) => {
        message.error('Failed to renew contract.');
      });
  }

  prev() {
    const { current } = this.state
    this.setState({
      current: current - 1
    })
  }

  initializeData() {
    userDataService.getUserProfileById(this.props.userId);
  }

  onChangePaymentMenthod(value) {
    this.setState({ paymentTypeId: value })
  }

}
export default ExtendContractScreen