import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DetailPackages } from "./detail-packages.component";
import { getOrderDetails, getInitData, clearData } from "../../../../../modules/orders/orders.actions"
import { withRouter } from "react-router-dom";

const mapStateToProps = ({ order }) => {
    return {
        order: order.orderDetails || {},
        initData: order.create.initData || {},
        amount : order.create?.amount||1,
        deliverySelected:order.create?.deliverySelected
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getOrderDetails,
        getInitData,
        clearData
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DetailPackages))