import React, { Component } from 'react'
import Layout, { Header } from 'antd/lib/layout/layout';
import { Button, message, Steps } from 'antd';
import TopBar from "../../../components/topbar";

import ShippingInfoComponent from './shipping-info/shipping-info.component'
import ProductInfoComponent from './product-info/product-info.component'
import DeliveryComponent from './delivery/delivery.component'
import PaymentMethodComponent from './payment/payment.component'
import SummaryComponent from './summarize/summarize.component'

import { DeliveryConstants } from "./../../../constants/delivery.constants"
import { createOrderTypes } from "./../../../constants/order-constant"
import orderDataService from "./../../../data-services/orders/orders-data.service";
import contractDataService from '../../../data-services/contracts/contracts-data.service';
import userDataService from '../../../data-services/user/user-data.service';

import { showErrorMessage } from "../../../services/notification/notification.services";

const { Step } = Steps;
export class PlaceOrderScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            title: "Create Order",
            current: 0,
            productCategories: [],
            deliveryOptions: [],
            shippingServices: [],
            locations: [],
            paymentTypes: [],
            paymentByOptions: [],
            listProducts: [],
            initialData: {
                contractInfo: null,
                shippingInfo: {
                    senderInfo: {
                        fullName: null,
                        phoneNumber: null,
                        address: {
                            street: null,
                            city: {
                                id: null,
                                name: null
                            },
                            district: {
                                id: null,
                                name: null
                            },
                            ward: {
                                id: null,
                                name: null
                            }
                        },
                    },
                    receiverInfo: {
                        fullName: null,
                        phoneNumber: null,
                        address: {
                            street: null,
                            city: {
                                id: null,
                                name: null
                            },
                            district: {
                                id: null,
                                name: null
                            },
                            ward: {
                                id: null,
                                name: null
                            }
                        },
                    },
                },
                productInfo: {
                    totalProduduct: null,
                    description: null,
                    products: []
                },
                deliveryInfo: {
                    shippingService: {
                        id: null,
                        name: null,
                    },
                    deliveryOption: {
                        id: null,
                        name: null,
                    },
                    route: {
                        id: null,
                        name: null,
                        code: null,
                        provider: null
                    }
                },
                paymentInfo: {
                    payBy: null,
                    paymentType: null,
                    isCod: null,
                    codAmount: null,
                }
            },
        }

        this.shippingInfoForm = React.createRef();
        this.productInfoForm = React.createRef();
        this.deliveryInfoForm = React.createRef();
        this.paymentInfoForm = React.createRef();
    }

    async componentDidMount() {
        orderDataService.getInitData(DeliveryConstants.PLACE_ORDER_FROM_WAREHOUSE).then((res) => {
            this.setState({
                ...this.state,
                productCategories: res.packageCategory,
                shippingServices: res.specialServices,
                locations: res.locations,
                paymentByOptions: res.paymentBy,
                paymentTypes: res.paymentTypes
            }, () => {
                const { id } = this.props?.match?.params;
                contractDataService.GetDataContractPlaceOrder(id).then((res) => {
                    this.getContractInfo(res);
                    this.setState({
                        ...this.state,
                        deliveryOptions: res.deliveryOptions.delivery
                    })
                });

                userDataService.getUserInfoById(this.props.userId).then((res) => {
                    this.getSenderInfo(res);
                });

                this.refresh();
            });
        });
    }

    render() {
        const { t } = this.props;
        const { loading, current, initialData } = this.state;
        const steps = this.createSteps();
        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t('createOrEditOrderPage.titleForCreate')} />
                    <div className="bg-white clearfix beecow-leasing-contract">
                        <Steps
                            type="navigation"
                            current={current}
                            className="site-navigation-steps"
                        >
                            {
                                steps.map(item => <Step key={item.title} title={item.title} />)
                            }
                        </Steps>
                        {
                            initialData?.contractInfo?.code &&
                            <div className="float-right">
                                <h2 className="mr-5">{t('createOrEditOrderPage.contractId')}: {initialData?.contractInfo?.code}</h2>
                            </div>
                        }
                        <div className="steps-content">{steps[current].content}</div>
                        <div className="steps-action" className="float-right mr-5 mt-5">
                            {current > 0 && (
                                <a className="pr-5" onClick={this.prev.bind(this)}>
                                {t('button.back')}
                                </a>
                            )}
                            {current < steps.length - 1 && (
                                <Button type="primary" onClick={this.next.bind(this)}>
                                {t('button.next')}
                                </Button>
                            )}
                            {current === steps.length - 1 && (
                                <Button type="primary" onClick={this.submit}>
                                {t('button.done')}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }

    getContractInfo = (res) => {
        this.setState({
            ...this.state,
            listProducts: this.getProductInfo(res.products),
            initialData: {
                ...this.state.initialData,
                contractInfo: {
                    id: res.id,
                    code: res.code,
                    stationId: res.stationId,
                    station: res.station
                },
            }
        });
    }

    getSenderInfo = (res) => {
        var senderInfo = {
            fullName: res.fullName,
            phoneNumber: res.phone,
            address: res.address
        }

        var { initialData } = this.state;
        var { shippingInfo } = initialData;
        shippingInfo.senderInfo = senderInfo;
        this.setState({
            initialData: {
                ...initialData,
                shippingInfo: shippingInfo
            }
        });

        this.shippingInfoForm.current.setFieldsValue(shippingInfo);
    }

    getProductInfo = (products) => {
        if (products) {
            return products.map((i) => {
                return {
                    id: i.id,
                    code: i.code,
                    name: i.name,
                    categoryId: i.packageTypeId,
                    length: i.length,
                    width: i.width,
                    height: i.height,
                    weight: i.weight,
                    amount: 0,
                    inventory: i.inventory,
                    inWarehouse: i.inWarehouse,
                    total: i.total
                }
            });
        }
    }

    createSteps = () => {
        const { t } = this.props;
        var { initialData, listProducts, deliveryOptions, shippingServices, productCategories, paymentByOptions, locations, paymentTypes } = this.state;
        var { shippingInfo, productInfo, deliveryInfo, paymentInfo, contractInfo } = initialData;
        const steps = [
            {
                title: t("createOrEditOrderPage.steps.shippingInformation"),
                content: <ShippingInfoComponent
                    t={t}
                    ref={this.shippingInfoForm}
                    initialData={shippingInfo} />,
            },
            {
                title: t("createOrEditOrderPage.steps.productInformation"),
                content: <ProductInfoComponent
                    t={t}
                    ref={this.productInfoForm}
                    listProducts={listProducts}
                    initialData={productInfo}
                    productCategories={productCategories}
                />,
            },
            {
                title: t("createOrEditOrderPage.steps.deliveryMethod"),
                content: <DeliveryComponent
                    t={t}
                    ref={this.deliveryInfoForm}
                    initialData={deliveryInfo}
                    deliveryOptions={deliveryOptions}
                    shippingServices={shippingServices}
                    shippingInfo={shippingInfo}
                    productInfo={productInfo}
                    contractInfo={contractInfo}
                />,
            },
            {
                title: t("createOrEditOrderPage.steps.payment"),
                content: <PaymentMethodComponent
                    t={t}
                    ref={this.paymentInfoForm}
                    initialData={paymentInfo}
                    paymentByOptions={paymentByOptions}
                    paymentTypes={paymentTypes}
                    deliveryInfo={deliveryInfo}
                    shippingInfo={shippingInfo}
                />,
            },
            {
                title: t("createOrEditOrderPage.steps.summarize"),
                content: <SummaryComponent
                    t={t}
                    contractInfo={contractInfo}
                    shippingInfo={shippingInfo}
                    productInfo={productInfo}
                    deliveryInfo={deliveryInfo}
                    paymentInfo={paymentInfo}
                    deliveryOptions={deliveryOptions}
                    shippingServices={shippingServices}
                    productCategories={productCategories}
                />,
            },
        ];

        return steps;
    }

    next() {
        const { t } = this.props;
        var currentFormRef;
        switch (this.state.current) {
            case 0:
                currentFormRef = this.shippingInfoForm;
                var formInfo = currentFormRef?.current?.getFieldsValue();
                this.setState({
                    ...this.state,
                    initialData: {
                        ...this.state.initialData,
                        shippingInfo: {
                            ...this.state.initialData.shippingInfo,
                            receiverInfo: formInfo["receiverInfo"]
                        }
                    }
                });

                break;
            case 1:
                currentFormRef = this.productInfoForm;
                this.setState({
                    ...this.state,
                    initialData: {
                        ...this.state.initialData,
                        productInfo: currentFormRef.current.getFieldsValue()
                    }
                });
                break;
            case 2:
                currentFormRef = this.deliveryInfoForm;
                this.setState({
                    ...this.state,
                    initialData: {
                        ...this.state.initialData,
                        deliveryInfo: currentFormRef.current.getFieldsValue()
                    }

                });
                break;
            case 3:
                currentFormRef = this.paymentInfoForm;
                this.setState({
                    ...this.state,
                    initialData: {
                        ...this.state.initialData,
                        paymentInfo: currentFormRef.current.getFieldsValue()
                    }
                });

                var paymentInfo = currentFormRef.current.getFieldsValue();
                if (paymentInfo.isCod === true && paymentInfo.paymentDetail && (isNaN(paymentInfo.paymentDetail.codFee) || paymentInfo.paymentDetail.codFee <= 0)) {
                    showErrorMessage(t('placeOrderPage.canNotSupportCod'));
                    return;
                }
                break;
            case 4:
                break;
            default:
                break;
        }

        if (currentFormRef && currentFormRef.current) {
            currentFormRef.current.validateFields()
                .then(() => {
                    if(this.state.current === 1 && currentFormRef.current.props.initialData?.products?.length == 0){
                        message.warning(t('createOrEditOrderPage.pleaseSelectProductItem'));
                        return;
                    }
                    let nextStep = this.state.current + 1;
                    if (nextStep < 5) {
                        this.setState({ current: nextStep }, () => {
                            this.refresh();
                        });
                    }
                })
                .catch(ex => console.log(ex));
        }



    }

    prev() {
        this.setState({
            current: this.state.current - 1
        }, () => {
            this.refresh();
        });
    }

    refresh() {
        switch (this.state.current) {
            case 0:
                this.shippingInfoForm.current.setFieldsValue(this.state.initialData.shippingInfo);
                break;
            case 1:
                this.productInfoForm.current.setFieldsValue(this.state.initialData.productInfo);
                break;
            case 2:
                //this.deliveryInfoForm.current.setFieldsValue(this.state.initialData.deliveryInfo);
                break;
            case 3:
                this.paymentInfoForm.current.setFieldsValue(this.state.initialData.deliveryInfo);
                break;
            default:
                break;
        }
    }

    submit = () => {
        const { t } = this.props;
        var { initialData } = this.state;
        var createOrderRequest = {
            senderInfo: initialData.shippingInfo.senderInfo,
            receiverInfo: initialData.shippingInfo.receiverInfo,
            productInfo: initialData.productInfo,
            deliveryInfo: {
                shippingServiceId: initialData.deliveryInfo.shippingService.id,
                deliveryTypeId: initialData.deliveryInfo.deliveryOption.deliveryTypeId,
                route: {
                    routeId: initialData.deliveryInfo.route.id,
                    companyId: initialData.deliveryInfo.route.company?.id,
                    shippingFeeDetails: initialData.deliveryInfo.route.shippingFeeDetails,
                },
            },
            paymentInfo: {
                payById: initialData.paymentInfo.payBy.id,
                paymentTypeId: initialData.paymentInfo.paymentType.id,
                isCod: initialData.paymentInfo.isCod,
                codAmount: initialData.paymentInfo.codAmount,
            },
            contractId: initialData.contractInfo?.id,
            fromStationId: initialData.contractInfo?.stationId,
            createOrderType: createOrderTypes.WAREHOUSE
        }

        orderDataService.createOrder(createOrderRequest).then(res => {
            if (res.success) {
                message.success(t('createOrEditOrderPage.orderHasBeenCreatedSuccessfully'));
                this.props.history.push(`/list-orders`);
            } else {
                message.error(t('createOrEditOrderPage.orderCreateRequestFailed'));
            }

        });
    }
}
