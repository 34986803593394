import { notification } from "antd";

export const leftMenu = [];
export const EStatusContractLeasing = {
    Active: {
        statusName: "Active",
        color: "#52c41a"
    },
    WaitingForVerify: {
        statusName: "Waiting for Verify",
        color: "#faad14"
    },
    WaitingForImport: {
        statusName: "Waiting for Import",
        color: "#faad14"
    },
    Renewing: {
        statusName: "Renewing",
        color: "#52c41a"
    },
    Expired: {
        statusName: "Expired",
        color: "#ff4d4f"
    },
    Cleared:{
 
        statusName : "Cleared",
        color : "#ff4d4f"
    }
}
export const EStatusProductLeasing = {
    WaitingForImport: {

        statusName: "Waiting for Import",
        color: "#faad14"
    },
    OutOfStock: {

        statusName: "Out of Stock",
        color: "#ff4d4f"
    },
    ReadyToSell: {

        statusName: "Ready to Sell",
        color: "#52c41a"
    }
}

export const listOptionPrint = [
    {
        id: 0,
        name: 'Print this Order sheet with Order'
    },
    {
        id: 1,
        name: 'Print only Order barcode'
    },
    {
        id: 2,
        name: 'Select to Print Product barcodes'
    }
]

export const initalContract = "Inital Contract"
export const orderToSell = "Order to sell"
export const addMore = "Add more"

export const alertType = {
    success: 'success',
    warning: 'warning',
    error: 'error',
};

export const alert = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
    });
}

export const showAlert = (message, description, isActive) => {
    alert(
        isActive ? alertType.success : alertType.warning,
        message,
        description
    );
}