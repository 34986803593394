import { Component } from 'react';
import Layout, { Content } from 'antd/lib/layout/layout';
import { Card, Input } from 'antd';
import TopBar from '../../components/topbar';
import SliderComponent from '../../components/slider/slider.component';
import slide01 from '../../assets/images/dashboard-slide01.jpg';
import slide02 from '../../assets/images/dashboard-slide02.jpg';
import slide03 from '../../assets/images/dashboard-slide03.jpg';
import orderDataService from '../../data-services/orders/orders-data.service';
import { TableOrders } from './table-orders/table-orders.component';
import { formatDate } from '../../utils/common';

const { Search } = Input;

export class DashboardScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterBy: '',
            datasource: []
        };
    }

    render() {
        const { t } = this.props;
        var { datasource } = this.state;
        let images = [slide01, slide02, slide03];
        let orders = datasource?.map(data => {
            return {
                id: data?.id,
                orderId: data?.code,
                from: this.getSenderInfo(data),
                to: this.getReceiverInfo(data),
                deliveryMethod: data?.deliveryType?.languageKey,
                route: this.getRouteInfo(data),
                packageInfo: data?.packageType?.name,
                createdDate: formatDate(data?.createdTime, 'hh:mm A DD/MM/YYYY'),
                status: t(data?.status?.languageKey || data?.status?.statusName),
                orderTrackingCode: data.orderTrackingCode
            }
        });

        if (this.state.filterBy) {
            let value = this.state.filterBy?.toLowerCase();
            orders = orders.filter(data =>
                data?.orderId?.toLowerCase().indexOf(value) >= 0 || data?.from?.toLowerCase().indexOf(value) >= 0 || data?.to?.toLowerCase().indexOf(value) >= 0
            );
        }

        return (
            <Layout className='bg-content'>
                <div className='bg-body'>
                    <TopBar title={t('dashboardPage.title')} />
                    <Content className='content'>
                        <Card className='mt-3'>
                            <SliderComponent imageSource={images} />
                        </Card>
                        {
                            this.props.userId && <>
                                <Card className='mt-5'>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Search
                                            className='mr-3'
                                            placeholder={t('placeholder.searchDashboard')}
                                            allowClear
                                            enterButton={t('button.search')}
                                            size='large'
                                            onSearch={this.handleTextChanged} />
                                    </div>
                                </Card>
                                <Card className='card-body mt-3'>
                                    <TableOrders
                                        t={t}
                                        dataSource={orders} />
                                </Card>
                            </>
                        }
                    </Content>
                </div>
            </Layout>
        );
    }

    componentDidMount() {
        this.InitDataTable();
    }

    InitDataTable() {
        if (this.props.userId) {
            orderDataService.listUserOrders(this.props.userId).then((res) => {
                if (res?.orders?.length > 5) {
                    this.setState({ datasource: res?.orders?.slice(0, 5) })
                } else {
                    this.setState({ datasource: res?.orders ? res?.orders : [] })
                }
            })
        }
    }

    getSenderInfo = (order) => {
        const { t } = this.props;

        let formattedAddress = this.getFormattedAddress(order?.addressSender) || t('orderListPage.noAddress');
        let senderInfo = [order?.senderName, order?.senderPhone, formattedAddress];
        return senderInfo.join('<br/>');
    }

    getReceiverInfo = (order) => {
        const { t } = this.props;

        let formattedAddress = this.getFormattedAddress(order?.addressRecipient) || t('orderListPage.noAddress');
        let senderInfo = [order?.receiverName, order?.receiverPhone, formattedAddress];
        return senderInfo.join('<br/>');
    }

    getFormattedAddress = (address) => {
        let arr = [];
        if (address && address.street) {
            arr.push(address.street);
        }
        if (address && address.ward) {
            let ward = address.ward.prefix + ' ' + address.ward.name;
            arr.push(ward);
        }
        if (address && address.district) {
            let district = address.district.prefix + ' ' + address.district.name;
            arr.push(district);
        }
        if (address && address.city) {
            arr.push(address.city.name);
        }
        return arr.join(', ');
    }

    getRouteInfo = (order) => {
        let routeInfo = [order?.route?.code, order?.route?.routeName];
        return routeInfo.join('<br/>');
    }

    handleTextChanged = (value) => {
        this.setState({
            filterBy: value,
        });
    }
}