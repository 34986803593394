import React, { Component } from 'react';
import { Form } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import FormInfoComponent from '../../../../components/form-info/form-info.component';

export default class ShippingInfoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isValidSender: false,
      isValidReceiver: false,
      initialData: props.initialData ?? {
        senderInfo: {
          fullName: null,
          phoneNumber: null,
          address: {
            street: null,
            city: {
              id: null,
              name: null,
            },
            district: {
              id: null,
              name: null,
            },
            ward: {
              id: null,
              name: null,
            },
          },
        },
        receiverInfo: {
          fullName: null,
          phoneNumber: null,
          address: {
            street: null,
            city: {
              id: null,
              name: null,
            },
            district: {
              id: null,
              name: null,
            },
            ward: {
              id: null,
              name: null,
            },
          },
        },
      },
    };

    this.senderFormRef = React.createRef();
  }

  render() {
    const { t } = this.props;
    var { initialData } = this.state;
    return (
      <Content className='beecow-shipping-info'>
        <div className='row'>
          <Form.Item name='senderInfo' className='col-sm-5 col-lg-5 ml-5'>
            <FormInfoComponent
              t={t}
              ref={this.senderFormRef}
              initialData={initialData?.senderInfo}
              title='createOrEditOrderPage.form.senderInformation'
            />
          </Form.Item>
        </div>
      </Content>
    );
  }

  validateFields = () => {
    let promises = [];

    if (this.senderFormRef && this.senderFormRef?.current) {
      let senderFormValidation = this.senderFormRef?.current?.validateFields();
      promises.push(senderFormValidation);
    }

    if (this.receiverFormRef && this.receiverFormRef?.current) {
      let receiverFormValidation =
        this.receiverFormRef?.current?.validateFields();
      promises.push(receiverFormValidation);
    }

    return Promise.all(promises);
  };

  getFieldsValue = () => {
    let shippingInfo = {};
    var { initialData } = this.state;

    if (this.senderFormRef && this.senderFormRef?.current) {
      let senderInfo = this.senderFormRef?.current?.getFieldsValue();
      shippingInfo['senderInfo'] = senderInfo;
    }

    shippingInfo['receiverInfo'] = initialData?.receiverInfo;

    return shippingInfo;
  };

  setFieldsValue = (values) => {

    if (this.senderFormRef && this.senderFormRef.current) {
      this.senderFormRef?.current?.setFieldsValue(values?.senderInfo);
    }

    this.setState({
      initialData: { ...values },
    });
  };
}
