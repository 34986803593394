import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import ProductDetail from "./view-product-detail.screen";
import { getProductDetail } from "../../modules/products/products.actions";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

const mapStateToProps = ({ product }) => {
  return {
    productDetails: product.productDetails || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProductDetail,
    },
    dispatch
  );
};

export default compose(
  withTranslation("translations"),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ProductDetail);
