import React, { Component } from "react";
import { Content } from "antd/lib/layout/layout";
import { Table, Form, Select, Radio, Col, Row, Space } from "antd";

import RouteTableComponent from "./route-table/route-table.component";
import routeDataService from "../../../../data-services/routes/routes-data.service";
import { DeliveryConstants } from "./../../../../constants/delivery.constants";
import { init } from "i18next";

export default class DeliveryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreOptions: false,
      deliveryOptions: props.deliveryOptions ?? [],
      shippingServices: props.shippingServices ?? [],
      shippingInfo: props.shippingInfo ?? [],
      productInfo: props.productInfo ?? [],
      locations: [],
      stations: [],
      routes: [],
      cachedRoutes: [],
      stationInfo: props.stationInfo,
      initialData: props?.initialData,
      selectedLocation: props?.initialData?.selectedLocation,
      selectedStation: props?.initialData?.selectedStation,
    };

    this.deliveryFormRef = React.createRef();
    this.routeTableRef = React.createRef();
  }

  componentDidMount() {
    this.refresh(true);
  }

  checkRouteValidation(routes) {
    const { initialData } = this.state;
    const { route } = initialData;

    if (routes) {
      let flag =
        routes?.filter((aRoute) => aRoute.id === route?.id)?.length > 0;

      var deliveryInfo = this.getFieldsValue();
      if (!flag && this.deliveryFormRef && this.deliveryFormRef.current) {
        deliveryInfo.route = null;
      } else {
        var routeInfo = routes?.find((i) => i.key === route?.id) ?? null;
        deliveryInfo.route = routeInfo;
      }
      this.deliveryFormRef.current.setFieldsValue(deliveryInfo);
    }
  }

  render() {
    const { t } = this.props;
    const {
      initialData,
      deliveryOptions,
      shippingServices,
      locations,
      routes,
      showMoreOptions,
      stations,
    } = this.state;
    const { deliveryOption, shippingService, route } = initialData;
    return (
      <Content>
        <Form ref={this.deliveryFormRef}>
          <div className="row mx-auto">
            <div className="col-3 mx-auto">
              <label>{t('createOrEditOrderPage.deliveryForm.shippingService')}</label>
              <Form.Item
                name={["shippingService", "id"]}
                rules={[
                  { required: true, message: t('createOrEditOrderPage.deliveryForm.pleaseSelectShippingService') },
                ]}
              >
                <Select
                  onChange={this.onChangeShippingService}
                  optionFilterProp="children"
                  placeholder={t('createOrEditOrderPage.deliveryForm.selectService')}
                >
                  {shippingServices?.map(({ id, name, languageKey }) => (
                    <Select.Option key={id} value={id}>
                      {t(languageKey)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-6 offset-2">
              <div className="title">
                <label>
                  {" "}
                  <i className="fa fa-truck" aria-hidden="true"></i> {t('createOrEditOrderPage.deliveryForm.deliveryOptions')}
                </label>
              </div>
              <div className="action">
                <Form.Item
                  name={["deliveryOption", "id"]}
                  rules={[
                    {
                      required: true,
                      message: t('createOrEditOrderPage.deliveryForm.pleaseSelectDeliveryOption'),
                    },
                  ]}
                >
                  <Space direction="vertical">
                    {deliveryOptions?.map(({ id, name, languageKey }) => {
                      return (
                        <Radio
                          className="row"
                          key={id}
                          value={id}
                          onChange={() => this.onChangeDeliveryOption(id)}
                          checked={id === deliveryOption?.id}
                        >
                          {t(languageKey)}
                        </Radio>
                      );
                    })}
                  </Space>
                </Form.Item>
                {showMoreOptions && (
                  <div className="choose-delivery">
                    <div className="mt-3 title-sub">
                      <span>
                        <i className="fa fa-snowflake-o" aria-hidden="true"></i>
                        {t('createOrEditOrderPage.deliveryForm.pleaseSelectLocationAndStationWhereYouWantReceiver')}
                      </span>
                    </div>
                    <Row className="mt-3">
                      <Col span={7} className="mr-4">
                        <Form.Item>
                          <Select
                            value={this.state.selectedLocation}
                            onChange={this.onChangeLocation}
                            placeholder={t('createOrEditOrderPage.deliveryForm.selectLocation')}
                          >
                            {locations?.map(({ id, name, languageKey }) => (
                              <Select.Option key={id} value={id}>
                                {t(languageKey)}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item name={["station", "id"]}>
                          <Select
                            value={this.state.selectedStation}
                            onChange={(value) => this.onChangeStation(value)}
                            placeholder={t('createOrEditOrderPage.deliveryForm.selectStation')}
                          >
                            {stations?.map(({ id, name }) => (
                              <Select.Option key={id} value={id}>
                                {name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Form.Item>
            <RouteTableComponent
              t={t}
              ref={this.routeTableRef}
              initialData={routes}
              defaultRoute={route}
              onChange={this.onSelectRoute}
            />
          </Form.Item>
        </Form>
      </Content>
    );
  }

  getFieldsValue = () => {
    var { deliveryOptions, shippingServices, cachedRoutes } = this.state;
    var deliveryInfo = {};
    if (this.routeTableRef && this.routeTableRef.current) {
      var route = this.routeTableRef.current.getFieldsValue();
      var routeInfo = cachedRoutes?.find((i) => i.key === route.id) ?? null;
      deliveryInfo.route = routeInfo;
      this.setState({ ...this.state, route: route });
    }

    if (this.deliveryFormRef && this.deliveryFormRef.current) {
      var deliveryMethod = this.deliveryFormRef.current.getFieldsValue();
      var delivery =
        deliveryOptions?.find(
          (i) => i.id === deliveryMethod.deliveryOption.id
        ) ?? null;
      var shippingService =
        shippingServices?.find(
          (i) => i.id === deliveryMethod.shippingService.id
        ) ?? null;
      deliveryInfo.shippingService = shippingService;
      deliveryInfo.deliveryOption = delivery;
      deliveryInfo.toStationId = deliveryMethod?.station?.id ?? null;
      this.setState({ ...this.state, route: route });
    }

    return deliveryInfo;
  };

  setFieldsValue = (values) => {
    if (this.deliveryFormRef && this.deliveryFormRef.current) {
      return this.deliveryFormRef.current.setFieldsValue(values);
    }

    this.setState({
      ...this.state,
      initialData: { ...this.state.initialData, ...values },
    });
  };

  validateFields = () => {
    if (this.deliveryFormRef && this.deliveryFormRef.current) {
      return this.deliveryFormRef.current.validateFields();
    }
  };

  onChangeShippingService = (value) => {
    const { shippingServices, initialData } = this.state;
    if (shippingServices) {
      const shippingService = shippingServices.find((i) => i.id === value);
      if (shippingService) {
        this.setState(
          {
            ...this.state,
            initialData: {
              ...initialData,
              shippingService: shippingService,
            },
          },
          () => {
            this.refresh();
          }
        );
      }
    }
  };

  onChangeDeliveryOption = (value) => {
    var { deliveryOptions, initialData } = this.state;
    if (deliveryOptions) {
      const deliveryOption = deliveryOptions?.find((i) => i.id === value);
      if (deliveryOption) {
        var showMoreOptions = false;
        if (this.isShowMoreOption(deliveryOption)) {
          showMoreOptions = true;
        }
        this.setState(
          {
            ...this.state,
            showMoreOptions: showMoreOptions,
            initialData: {
              ...initialData,
              deliveryOption: deliveryOption,
              station: null,
            },
          },
          () => {
            this.refresh();
          }
        );
      }
    }
  };

  refresh = (isFirstLoad) => {
    const { initialData, shippingInfo, productInfo, station, stationInfo } =
      this.state;
    var { shippingService, deliveryOption, route } = initialData;
    if (!shippingService || !deliveryOption || !shippingInfo || !productInfo) {
      console.log("cannot refresh");
      return;
    }

    var units = this.state.productInfo?.products?.map((i, index) => {
      return {
        key: index + 1,
        length: i.length,
        width: i.width,
        height: i.height,
        weight: i.weight,
        amount: i.amount,
      };
    });

    var routesByDeliveryRequest = {
      deliveryTypeId: deliveryOption?.id,
      shippingServiceId: shippingService?.id,
      units: units ?? [],
      senderAddress: this.state.shippingInfo?.senderInfo?.address,
      receiverAddress: this.state.shippingInfo?.receiverInfo?.address,
      fromStationId: stationInfo.id ?? null,
    };

    if (this.isValidModel(routesByDeliveryRequest)) {
      if (this.routeTableRef?.current?.onUpdate) {
        this.routeTableRef?.current?.onUpdate([], true);
        routeDataService
          .getRouteByDelivery(routesByDeliveryRequest)
          .then((res) => {
            var routes = res.map((r) => this.transform(r));

            this.setState({
              cachedRoutes: routes,
            });

            this.filterRoutesToReceiverAddress(routes);
            var toStationOption = deliveryOption?.name
              ?.toLowerCase()
              .includes(DeliveryConstants.TO_STATION);
            if (!toStationOption) {
              this.routeTableRef?.current?.onUpdate(routes, false);
            }
            if (isFirstLoad) {
              var deliveryInfo = this.getFieldsValue();
              deliveryInfo.deliveryOption = deliveryOption;
              deliveryInfo.shippingService = shippingService;
              this.deliveryFormRef.current.setFieldsValue(deliveryInfo);
              this.onSelectRoute(route?.id);
              this.onChangeDeliveryOption(deliveryOption?.id);
              
              let showMoreOptions = this.isShowMoreOption(deliveryOption);
              if (showMoreOptions) {
                if (this.state.selectedLocation && this.state.selectedStation) {
                  this.onChangeLocation(this.state.selectedLocation);
                  this.onChangeStation(this.state.selectedStation);
                } else {
                  let toStation = this.props?.initialData?.toStation;
                  if(toStation){
                    let location =  this.state.locations?.find(item=> item.stations?.find(station => station.id === toStation.id));
                    let stations = location?.stations;
                    if(stations){
                      let station = stations.find(station => station.id === toStation.id);
                      this.onChangeLocation(location?.id);
                      this.onChangeStation(station?.id);

                      let initialData = {
                        ...this.state.initialData,
                        station: station,
                        location: location,
                      }

                      this.setState({
                        selectedLocation: location?.id,
                        selectedStation: station?.id,
                        initialData: initialData,
                      });
                      this.deliveryFormRef.current.setFieldsValue(initialData);
                    }
                  }
                }
              }
            }

            this.checkRouteValidation(routes);
          })
          .catch((error) => {
            this.routeTableRef?.current?.onUpdate([], false);
            console.error(error);
          });
      }
    }
  };

  isValidModel = (routesByDeliveryRequest) => {
    if (
      !routesByDeliveryRequest.deliveryTypeId ||
      !routesByDeliveryRequest.shippingServiceId ||
      !routesByDeliveryRequest.units ||
      !routesByDeliveryRequest.senderAddress ||
      !routesByDeliveryRequest.receiverAddress ||
      !routesByDeliveryRequest.fromStationId
    ) {
      console.log("Invalid request model");
      return false;
    }
    return true;
  };

  isShowMoreOption = (deliveryOption) => {
    if (deliveryOption) {
      return deliveryOption?.name
        .toLowerCase()
        .includes(DeliveryConstants.TO_STATION)
        ? true
        : false;
    }

    return false;
  };

  onChangeLocation = (locationId) => {
    this.routeTableRef?.current?.onUpdate([], false);
    var { initialData, locations } = this.state;
    if (locations) {
      var location = locations.find((i) => i.id === locationId);
      this.setState({
        selectedLocation: locationId,
        stations: location.stations ?? [],
        initialData: {
          ...initialData,
          station: null,
          location: location,
        },
      });

      this.deliveryFormRef?.current?.setFieldsValue(this.state.initialData);
      this.props?.onUpdateMoreDeliveryInfo({
        selectedLocation: locationId,
        selectedStation: this.state.selectedStation,
      });
    }
  };

  onChangeStation = (stationId) => {
    var { cachedRoutes } = this.state;
    var routes = [];
    cachedRoutes?.forEach((route) => {
      var subRouteDetails = route.subRoutesDetails;
      subRouteDetails.forEach((s) => {
        if (s.toStationNavigation.id === stationId) {
          routes.push(route);
          return;
        }
      });
    });

    this.routeTableRef?.current?.onUpdate(routes, false);
    this.props?.onUpdateMoreDeliveryInfo({
      selectedLocation: this.state.selectedLocation,
      selectedStation: stationId,
    });
    this.checkRouteValidation(routes);
  };

  onSelectRoute = (routeId) => {
    var { deliveryOptions, initialData, cachedRoutes } = this.state;

    if (this.routeTableRef && this.routeTableRef.current) {
      var routeInfo = cachedRoutes?.find((i) => i.key === routeId) ?? null;
      this.setState({ ...this.state, route: routeInfo });
      if (this.deliveryFormRef && this.deliveryFormRef.current) {
        var deliveryInfo = this.getFieldsValue();
        deliveryInfo.route = routeInfo;
        this.deliveryFormRef.current.setFieldsValue(deliveryInfo);
      }
    }
  };

  filterRoutesToReceiverAddress = (list) => {
    var { shippingInfo } = this.state;
    var routes = [];
    var stations = [];
    var receiverAddress = shippingInfo.receiverInfo?.address;
    list?.forEach((route) => {
      var subRouteDetails = route.subRoutesDetails;
      subRouteDetails.forEach((s) => {
        if (s.toStationNavigation.address) {
          var toStation = s.toStationNavigation.address.city.id;
          if (toStation == receiverAddress.city.id) {
            routes.push(route);
            stations.push(s.toStationNavigation);
            return;
          }
        }
      });
    });

    // Get locations and group stations by location
    var uniqueStationsById = [
      ...new Map(stations.map((item) => [item["id"], item])).values(),
    ];
    var groups = uniqueStationsById?.reduce((r, a) => {
      r[a.location] = [...(r[a.location] || []), a];
      return r;
    }, {});
    var locationNames = Object.getOwnPropertyNames(groups);
    var location = locationNames.map((locationName, index) => {
      return {
        id: index + 1,
        name: locationName,
        stations: groups[locationName],
        languageKey: groups[locationName]?.find(g => g.location === locationName)?.languageKey
      };
    });

    this.setState({
      ...this.state,
      locations: location,
    });
  };

  transform = (route) => {
    return {
      key: route.id,
      id: route.id,
      routeId: route.code,
      routeName: route.routeName,
      subRoutes: this.formatSubRoutes(route.subRoutes),
      location: route.fromStationDetail?.location,
      eta: route.estimate,
      price: this.formatAmount(route.price) + " VND",
      company: route.company,
      subRoutesDetails: route.subRoutes,
      shippingFeeDetails: route.shippingFeeDetails,
    };
  };

  formatSubRoutes = (subRoutes) => {
    var toStations =
      subRoutes?.map((r) => `${r.toStationNavigation?.name}`)?.join(" - ") ||
      "";
    if (toStations.length > 1) {
      return `${subRoutes[0].fromStationNavigation?.name} - ${toStations}`;
    }
    return "";
  };

  formatAmount = (value) => {
    if (isNaN(value) || value == null) {
      return "0";
    }

    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
}
