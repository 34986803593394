import { compose } from "redux";
import { connect } from "react-redux";
import { LoginScreen } from "./login.screen";
import { withRouter } from "react-router-dom";
import { withTranslation} from "react-i18next";
import { setJustLoggedIn } from "../../modules/app/app.actions";
import { resetSession, setAuth, setWorkspace } from "../../modules/session/session.actions";

const mapStateToProps = (state) => {
    return {
        isAuthenticated: (state.session?.auth?.token && state.session?.auth?.user?.role == "USER") ? true : false,
        userId: state?.session?.auth?.user?.userId
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAuth: (auth) => dispatch(setAuth(auth)),
        setWorkspace: (workspaceId) => dispatch(setWorkspace(workspaceId)),
        setJustLoggedIn: (value) => dispatch(setJustLoggedIn(value)),
        signOut: (userId) => dispatch(resetSession(userId))
    }
}

export default compose(
    withTranslation("translations"),
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(LoginScreen);
